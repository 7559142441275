var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var U$=function(a){return new $CLJS.uf(null,function(){return $CLJS.kf(a.v?a.v():a.call(null),U$(a))},null,null)},V$=function(a,b){return new $CLJS.uf(null,function(){var c=$CLJS.z(b);if(c){var d=$CLJS.A(c),e=a.g?a.g(d):a.call(null,d),f=$CLJS.kf(d,$CLJS.gi(function(g){return $CLJS.E.h(e,a.g?a.g(g):a.call(null,g))},$CLJS.B(c)));return $CLJS.kf(f,V$(a,new $CLJS.uf(null,function(){return $CLJS.jg($CLJS.D(f),c)},null,null)))}return null},null,null)},Exa=function(a,b){switch(a){case "minutes":return $CLJS.ZX("Minute",
"Minutes",b);case "hours":return $CLJS.ZX("Hour","Hours",b);case "days":return $CLJS.ZX("Day","Days",b);case "weeks":return $CLJS.ZX("Week","Weeks",b);case "months":return $CLJS.ZX("Month","Months",b);case "quarters":return $CLJS.ZX("Quarter","Quarters",b);case "years":return $CLJS.ZX("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}},Fxa=function(a,b,c){b=parseInt(b);c=Exa(c,b);a=new $CLJS.Q(null,2,5,$CLJS.R,[a,$CLJS.E.h(b,1)],null);if($CLJS.E.h(new $CLJS.Q(null,
2,5,$CLJS.R,["past",!0],null),a))return $CLJS.ZF("Previous {0}",$CLJS.F([c]));if($CLJS.E.h(new $CLJS.Q(null,2,5,$CLJS.R,["past",!1],null),a))return $CLJS.ZF("Previous {0} {1}",$CLJS.F([b,c]));if($CLJS.E.h(new $CLJS.Q(null,2,5,$CLJS.R,["next",!0],null),a))return $CLJS.ZF("Next {0}",$CLJS.F([c]));if($CLJS.E.h(new $CLJS.Q(null,2,5,$CLJS.R,["next",!1],null),a))return $CLJS.ZF("Next {0} {1}",$CLJS.F([b,c]));throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));},Hxa=function(a){return $CLJS.Ys(a,
Gxa,function(b){return["\\",$CLJS.p.g(b)].join("")})},W$=function(a,b,c,d,e){this.tag=a;this.source=b;this.F=c;this.m=d;this.H=e;this.C=2230716170;this.J=139264},Jxa=function(a){var b=$CLJS.$s(a,Ixa),c=$CLJS.kg.h($CLJS.A,$CLJS.WF(X$,a)),d=function(){var e=$CLJS.D(b),f=$CLJS.D(c);return e>f?e:f}();a=$CLJS.sg.h(b,$CLJS.la(d-$CLJS.D(b),U$($CLJS.ag(""))));d=$CLJS.sg.h(c,$CLJS.la(d-$CLJS.D(c),U$($CLJS.ag(""))));a=$CLJS.Vs.h(a,d);return $CLJS.kg.h(function(e){var f=$CLJS.pi(X$,e);return $CLJS.m(f)?($CLJS.G(f,
0,null),f=$CLJS.G(f,1,null),new W$(f,e,null,null,null)):e},a)},Kxa=function(a){return $CLJS.tg(function(b){return"string"===typeof $CLJS.A(b)?new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.IW(b)],null):b},$CLJS.F([V$(function(b){return"string"===typeof b||$CLJS.Bb($CLJS.Yk.g(b))},a)]))},Lxa=function(a,b,c,d){return $CLJS.kg.h(function(e){if(e instanceof W$){var f=$CLJS.T.j;var g=$CLJS.cl.g(e),l=$CLJS.K.h(a,g);g=$CLJS.Yk.g(l);l=$CLJS.Ik.g(l);if($CLJS.m(g))try{var n=Y$.j(l,g,b);var q=$CLJS.m(c)?Hxa(n):n}catch(u){if(u instanceof
Error)q=Y$.j($CLJS.Dj,g,b);else throw u;}else q=null;e=f.call($CLJS.T,e,$CLJS.Yk,q)}return e},d)},Oxa=function(a){a=$CLJS.IW($CLJS.kg.h(function(b){return b instanceof W$?b:$CLJS.Ys(b,Mxa,"")},a));return $CLJS.Ys(a,Nxa,$CLJS.Vd)},Pxa=function(a){return $CLJS.xE($CLJS.A($CLJS.Z$(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.VN],null),new $CLJS.Q(null,1,5,$CLJS.R,[a],null))),$CLJS.zi)};var Y$=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.zi.g(f)},e,a,b,c,d)}();Y$.o(null,$CLJS.xP,function(a,b,c){return(0,$CLJS.WJ)(b).locale(c).format("MMMM D, YYYY")});
Y$.o(null,$CLJS.$N,function(a,b,c){a=(0,$CLJS.WJ)(b,"YYYY-MM").locale(c);return $CLJS.m(a.isValid())?a.format("MMMM, YYYY"):""});Y$.o(null,$CLJS.UO,function(a,b,c){a=(0,$CLJS.WJ)(b,"[Q]Q-YYYY").locale(c);return $CLJS.m(a.isValid())?a.format("[Q]Q, YYYY"):""});Y$.o(null,$CLJS.DN,function(a,b,c){b=$CLJS.$s(b,/~/);a=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);return $CLJS.m($CLJS.m(a)?b:a)?[$CLJS.p.g(Y$.j($CLJS.xP,a,c))," - ",$CLJS.p.g(Y$.j($CLJS.xP,b,c))].join(""):""});
Y$.o(null,$CLJS.VO,function(a,b){function c(d,e){return $CLJS.B($CLJS.qi(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.$F("Today");if(c(/^thisweek$/,b))return $CLJS.$F("This Week");if(c(/^thismonth$/,b))return $CLJS.$F("This Month");if(c(/^thisquarter$/,b))return $CLJS.$F("This Quarter");if(c(/^thisyear$/,b))return $CLJS.$F("This Year");if(c(/^past1days$/,b))return $CLJS.$F("Yesterday");if(c(/^next1days$/,b))return $CLJS.$F("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.P.h(Fxa,
a);throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));});Y$.o(null,$CLJS.PV,function(a,b,c){function d(e,f){return $CLJS.Vd($CLJS.qi(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.m(a))return Y$.j($CLJS.VO,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.m(a))return Y$.j($CLJS.xP,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.m(a)?Y$.j($CLJS.DN,a,c):Y$.j($CLJS.VO,b,c)});
Y$.o(null,$CLJS.Dj,function(a,b){$CLJS.ie(b)?(a=$CLJS.D(b),b=$CLJS.m($CLJS.E.h?$CLJS.E.h(1,a):$CLJS.E.call(null,1,a))?$CLJS.p.g($CLJS.A(b)):$CLJS.m($CLJS.E.h?$CLJS.E.h(2,a):$CLJS.E.call(null,2,a))?$CLJS.ZF("{0} and {1}",$CLJS.F([$CLJS.A(b),$CLJS.Vd(b)])):$CLJS.ZF("{0}, {1}, and {2}",$CLJS.F([$CLJS.Tt(", ",$CLJS.lg(2,b)),$CLJS.Pd(b,$CLJS.D(b)-2),$CLJS.Wd(b)]))):b=$CLJS.p.g(b);return b});var Gxa=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,X$=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,Ixa=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.h=W$.prototype;$CLJS.h.toString=function(){var a=$CLJS.Yk.g(this);return $CLJS.m(a)?a:this.source};$CLJS.h.la=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.M?b.R:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.K.j(this.m,b,c)}};$CLJS.h.wa=function(a,b,c){return $CLJS.Ob(function(d,e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.Y=function(a,b,c){return $CLJS.ti(b,function(d){return $CLJS.ti(b,$CLJS.yi,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.sg.h(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,this.tag],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yz,this.source],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.bh(this,2,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,$CLJS.yz],null),$CLJS.m(this.m)?$CLJS.bd(this.m):$CLJS.Rf())};$CLJS.h.K=function(){return this.F};
$CLJS.h.ia=function(){return 2+$CLJS.D(this.m)};$CLJS.h.ga=function(){var a=this.H;return null!=a?a:this.H=a=-884441273^$CLJS.Bd(this)};$CLJS.h.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.tag,b.tag)&&$CLJS.E.h(this.source,b.source)&&$CLJS.E.h(this.m,b.m)};
$CLJS.h.Ba=function(a,b){return $CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.yz,null,$CLJS.cl,null],null),null),b)?$CLJS.$l.h($CLJS.zc($CLJS.mh.h($CLJS.O,this),this.F),b):new W$(this.tag,this.source,this.F,$CLJS.Pf($CLJS.$l.h(this.m,b)),null)};$CLJS.h.ua=function(a,b){switch(b instanceof $CLJS.M?b.R:null){case "tag":case "source":return!0;default:return $CLJS.Ee(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.N.h?$CLJS.N.h($CLJS.cl,b):$CLJS.N.call(null,$CLJS.cl,b))?new W$(c,this.source,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h($CLJS.yz,b):$CLJS.N.call(null,$CLJS.yz,b))?new W$(this.tag,c,this.F,this.m,null):new W$(this.tag,this.source,this.F,$CLJS.T.j(this.m,b,c),null)};$CLJS.h.fa=function(){return $CLJS.z($CLJS.sg.h(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Fe($CLJS.cl,this.tag),new $CLJS.Fe($CLJS.yz,this.source)],null),this.m))};
$CLJS.h.M=function(a,b){return new W$(this.tag,this.source,b,this.m,this.H)};$CLJS.h.ja=function(a,b){return $CLJS.le(b)?this.Z(null,$CLJS.Yd(b,0),$CLJS.Yd(b,1)):$CLJS.Ob($CLJS.ac,this,b)};var Mxa=/\[\[.+\]\]/,Nxa=/\[\[(.+?)\]\]/,$$=function $$(a){switch(arguments.length){case 2:return $$.h(arguments[0],arguments[1]);case 4:return $$.B(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
$CLJS.Oa("metabase.shared.parameters.parameters.substitute_tags",$$);$$.h=function(a,b){return $$.B(a,b,"en",!0)};$$.B=function(a,b,c,d){return $CLJS.m(a)?(b=$CLJS.iE(b),b=$CLJS.Us(b,Pxa),Oxa(Kxa(Lxa(b,c,d,Jxa(a))))):null};$$.A=4;module.exports={tag_names:function(a){a=$CLJS.di($CLJS.kg.h($CLJS.Vd,$CLJS.WF(X$,$CLJS.m(a)?a:"")));return $CLJS.zm(a)},substitute_tags:$$};