var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var By,Oea,Pea,Ey,Qea,Hy,Iy;
$CLJS.Ay=function(a,b,c){return function(){function d(q,u,v){return a.O?a.O(b,c,q,u,v):a.call(null,b,c,q,u,v)}function e(q,u){return a.B?a.B(b,c,q,u):a.call(null,b,c,q,u)}function f(q){return a.j?a.j(b,c,q):a.call(null,b,c,q)}function g(){return a.h?a.h(b,c):a.call(null,b,c)}var l=null,n=function(){function q(v,x,y,C){var H=null;if(3<arguments.length){H=0;for(var J=Array(arguments.length-3);H<J.length;)J[H]=arguments[H+3],++H;H=new $CLJS.rd(J,0,null)}return u.call(this,v,x,y,H)}function u(v,x,y,C){return $CLJS.P.l(a,
b,c,v,x,$CLJS.F([y,C]))}q.A=3;q.D=function(v){var x=$CLJS.A(v);v=$CLJS.B(v);var y=$CLJS.A(v);v=$CLJS.B(v);var C=$CLJS.A(v);v=$CLJS.vd(v);return u(x,y,C,v)};q.l=u;return q}();l=function(q,u,v,x){switch(arguments.length){case 0:return g.call(this);case 1:return f.call(this,q);case 2:return e.call(this,q,u);case 3:return d.call(this,q,u,v);default:var y=null;if(3<arguments.length){y=0;for(var C=Array(arguments.length-3);y<C.length;)C[y]=arguments[y+3],++y;y=new $CLJS.rd(C,0,null)}return n.l(q,u,v,y)}throw Error("Invalid arity: "+
arguments.length);};l.A=3;l.D=n.D;l.v=g;l.g=f;l.h=e;l.j=d;l.l=n.l;return l}()};By=function(a,b){return $CLJS.E.h(a,b)?new $CLJS.Q(null,3,5,$CLJS.R,[null,null,a],null):new $CLJS.Q(null,3,5,$CLJS.R,[a,b,null],null)};Oea=function(a){return $CLJS.z(a)?$CLJS.Ob(function(b,c){var d=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);return $CLJS.T.j(b,d,c)},$CLJS.Ng($CLJS.pg($CLJS.P.h($CLJS.em,$CLJS.Uh(a)),null)),a):null};
Pea=function(a,b,c){var d=$CLJS.K.h(a,c),e=$CLJS.K.h(b,c),f=$CLJS.Cy(d,e),g=$CLJS.G(f,0,null),l=$CLJS.G(f,1,null);f=$CLJS.G(f,2,null);a=$CLJS.Ee(a,c);b=$CLJS.Ee(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.Q(null,3,5,$CLJS.R,[!a||null==g&&d?null:$CLJS.Nf([c,g]),!b||null==l&&d?null:$CLJS.Nf([c,l]),d?$CLJS.Nf([c,f]):null],null)};
Ey=function(a,b){var c=$CLJS.kg.h,d=Dy.j,e=$CLJS.le(a)?a:$CLJS.Ng(a),f=$CLJS.le(b)?b:$CLJS.Ng(b);a=$CLJS.D(a);b=$CLJS.D(b);return $CLJS.Ng(c.call($CLJS.kg,Oea,d.call(Dy,e,f,$CLJS.li(0,a>b?a:b))))};Qea=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pf($CLJS.Fy.h(a,b)),$CLJS.Pf($CLJS.Fy.h(b,a)),$CLJS.Pf($CLJS.Gy.h(a,b))],null)};
Hy=function(a){if(null!=a&&null!=a.Lh)a=a.Lh(a);else{var b=Hy[$CLJS.Ka(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Hy._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Lb("EqualityPartition.equality-partition",a);}return a};Iy=function(a,b){if(null!=a&&null!=a.Kh)a=a.Kh(a,b);else{var c=Iy[$CLJS.Ka(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=Iy._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Lb("Diff.diff-similar",a);}return a};
$CLJS.Cy=function(a,b){return $CLJS.E.h(a,b)?new $CLJS.Q(null,3,5,$CLJS.R,[null,null,a],null):$CLJS.E.h(Hy(a),Hy(b))?Iy(a,b):By(a,b)};$CLJS.Gy=function Gy(a){switch(arguments.length){case 1:return Gy.g(arguments[0]);case 2:return Gy.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Gy.l(arguments[0],arguments[1],new $CLJS.rd(c.slice(2),0,null))}};$CLJS.Gy.g=function(a){return a};
$CLJS.Gy.h=function(a,b){for(;;)if($CLJS.D(b)<$CLJS.D(a)){var c=a;a=b;b=c}else return $CLJS.Ob(function(d,e){return function(f,g){return $CLJS.Ee(e,g)?f:$CLJS.Jy.h(f,g)}}(a,b),a,a)};$CLJS.Gy.l=function(a,b,c){a=$CLJS.ry(function(d){return-$CLJS.D(d)},$CLJS.gf.l(c,b,$CLJS.F([a])));return $CLJS.Ob($CLJS.Gy,$CLJS.A(a),$CLJS.vd(a))};$CLJS.Gy.D=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.Gy.A=2;
$CLJS.Fy=function Fy(a){switch(arguments.length){case 1:return Fy.g(arguments[0]);case 2:return Fy.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Fy.l(arguments[0],arguments[1],new $CLJS.rd(c.slice(2),0,null))}};$CLJS.Fy.g=function(a){return a};$CLJS.Fy.h=function(a,b){return $CLJS.D(a)<$CLJS.D(b)?$CLJS.Ob(function(c,d){return $CLJS.Ee(b,d)?$CLJS.Jy.h(c,d):c},a,a):$CLJS.Ob($CLJS.Jy,a,b)};
$CLJS.Fy.l=function(a,b,c){return $CLJS.Ob($CLJS.Fy,a,$CLJS.gf.h(c,b))};$CLJS.Fy.D=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.Fy.A=2;var vu=new $CLJS.M(null,"atom","atom",-397043653);var Dy=function Dy(a){switch(arguments.length){case 2:return Dy.h(arguments[0],arguments[1]);case 3:return Dy.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};Dy.h=function(a,b){return Dy.j(a,b,$CLJS.yy.h($CLJS.Uh(a),$CLJS.Uh(b)))};Dy.j=function(a,b,c){return $CLJS.Ob(function(d,e){return $CLJS.oi($CLJS.kg.j($CLJS.xm,d,e))},new $CLJS.Q(null,3,5,$CLJS.R,[null,null,null],null),$CLJS.kg.h($CLJS.Ay(Pea,a,b),c))};Dy.A=3;
Hy["null"]=function(){return vu};Hy.string=function(){return vu};Hy.number=function(){return vu};Hy.array=function(){return $CLJS.zj};Hy["function"]=function(){return vu};Hy["boolean"]=function(){return vu};
Hy._=function(a){return(null!=a?a.C&1024||$CLJS.t===a.yh||(a.C?0:$CLJS.Jb($CLJS.oc,a)):$CLJS.Jb($CLJS.oc,a))?$CLJS.Wj:(null!=a?a.C&4096||$CLJS.t===a.Dh||(a.C?0:$CLJS.Jb($CLJS.sc,a)):$CLJS.Jb($CLJS.sc,a))?$CLJS.rg:(null!=a?a.C&16777216||$CLJS.t===a.Tf||(a.C?0:$CLJS.Jb($CLJS.Hc,a)):$CLJS.Jb($CLJS.Hc,a))?$CLJS.zj:vu};Iy["null"]=function(a,b){return By(a,b)};Iy.string=function(a,b){return By(a,b)};Iy.number=function(a,b){return By(a,b)};Iy.array=function(a,b){return Ey(a,b)};
Iy["function"]=function(a,b){return By(a,b)};Iy["boolean"]=function(a,b){return By(a,b)};Iy._=function(a,b){var c=function(){var d=Hy(a);d=d instanceof $CLJS.M?d.R:null;switch(d){case "atom":return By;case "set":return Qea;case "sequential":return Ey;case "map":return Dy;default:throw Error(["No matching clause: ",$CLJS.p.g(d)].join(""));}}();return c.h?c.h(a,b):c.call(null,a,b)};