var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var vW,wW,xW,zW,AW,BW,CW,DW,EW,FW;vW=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);wW=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);xW=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.yW=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);zW=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);AW=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);BW=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
CW=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);DW=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);EW=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);FW=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.oJ(vW,$CLJS.mh.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid template tag :widget-type"],null),$CLJS.Oz],null),$CLJS.Uh($CLJS.aT)));$CLJS.oJ(zW,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ks,$CLJS.fN,$CLJS.WN,$CLJS.JM,$CLJS.nu,$CLJS.QB,$CLJS.bQ],null));
$CLJS.oJ(CW,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HF],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vF,$CLJS.HF],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rk,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,$CLJS.HF,$CLJS.As],null)],null)],null));
$CLJS.oJ(DW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Dj,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ib],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xO,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null)],null)],null));
$CLJS.oJ(AW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DW],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.JM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JM,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.vG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qO,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qi,new $CLJS.k(null,1,[$CLJS.Js,
!0],null),$CLJS.Wj],null)],null)],null));
$CLJS.oJ(BW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CW],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.fN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SM,$CLJS.HF],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IQ,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.NL],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KQ,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.GL],null)],null)],null));
$CLJS.oJ(FW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.WN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JU,$CLJS.FL],null)],null)],null));$CLJS.oJ(EW,$CLJS.mh.h(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ks],null),$CLJS.nW));
$CLJS.oJ(wW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,EW],null)],null)],null)],null));
$CLJS.oJ(xW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zW],null)],null)],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,1,[$CLJS.Wf,$CLJS.Ik],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JM,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,AW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fN,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WN,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FW],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Vk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wW],null)],null)],null)],null));
$CLJS.oJ($CLJS.yW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ts,$CLJS.HF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,xW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Uf(function(b){var c=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);return $CLJS.E.h(c,$CLJS.W.g(b))},a)}],null)],null));