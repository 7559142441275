var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var Ska,Tka,g3,k3,Uka,l3,m3;
$CLJS.$2=function(a){return $CLJS.m($CLJS.N_.h?$CLJS.N_.h($CLJS.F0,a):$CLJS.N_.call(null,$CLJS.F0,a))?$CLJS.H2:$CLJS.m($CLJS.N_.h?$CLJS.N_.h($CLJS.q0,a):$CLJS.N_.call(null,$CLJS.q0,a))?$CLJS.H2:$CLJS.m($CLJS.N_.h?$CLJS.N_.h($CLJS.f0,a):$CLJS.N_.call(null,$CLJS.f0,a))?$CLJS.Fka:$CLJS.m($CLJS.N_.h?$CLJS.N_.h($CLJS.I0,a):$CLJS.N_.call(null,$CLJS.I0,a))?$CLJS.Gka:$CLJS.m($CLJS.N_.h?$CLJS.N_.h($CLJS.H0,a):$CLJS.N_.call(null,$CLJS.H0,a))?$CLJS.Hka:$CLJS.m($CLJS.N_.h?$CLJS.N_.h($CLJS.e0,a):$CLJS.N_.call(null,
$CLJS.e0,a))?$CLJS.Ika:$CLJS.m($CLJS.N_.h?$CLJS.N_.h($CLJS.z0,a):$CLJS.N_.call(null,$CLJS.z0,a))?$CLJS.Lka:$CLJS.m($CLJS.N_.h?$CLJS.N_.h($CLJS.a0,a):$CLJS.N_.call(null,$CLJS.a0,a))?$CLJS.Jka:$CLJS.m($CLJS.N_.h?$CLJS.N_.h($CLJS.c0,a):$CLJS.N_.call(null,$CLJS.c0,a))?$CLJS.Kka:$CLJS.Mka};$CLJS.i3=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.M?a.R:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.j3=function(a,b){a=$CLJS.i3(a);b=$CLJS.m(b)?b:$CLJS.uu;return 0===a?$CLJS.E.h(b,$CLJS.uu)?$CLJS.$F("Today"):$CLJS.ZF("This {0}",$CLJS.F([$CLJS.v2.g(b)])):$CLJS.E.h(a,1)?$CLJS.E.h(b,$CLJS.uu)?$CLJS.$F("Tomorrow"):$CLJS.ZF("Next {0}",$CLJS.F([$CLJS.v2.g(b)])):$CLJS.E.h(a,-1)?$CLJS.E.h(b,$CLJS.uu)?$CLJS.$F("Yesterday"):$CLJS.ZF("Previous {0}",$CLJS.F([$CLJS.v2.g(b)])):0>a?$CLJS.ZF("Previous {0} {1}",$CLJS.F([Math.abs(a),$CLJS.v2.h(Math.abs(a),b)])):0<a?$CLJS.ZF("Next {0} {1}",$CLJS.F([a,$CLJS.v2.h(a,
b)])):null};$CLJS.f3=function(a){var b=$CLJS.$2(a);b=$CLJS.Pf(b);return null==b?a:$CLJS.T.j(a,$CLJS.oM,b)};Ska=new $CLJS.M(null,"legacy-filter","legacy-filter",-948552546);Tka=new $CLJS.M(null,"query-filters","query-filters",409521440);g3=new $CLJS.M("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);k3=new $CLJS.M("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);Uka=new $CLJS.M(null,"matching-filters","matching-filters",-326129659);
l3=new $CLJS.M("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);m3=new $CLJS.M("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var n3=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vs,$CLJS.os],null)),o3=null,p3=0,q3=0;;)if(q3<p3){var Vka=o3.ha(null,q3);$CLJS.mT(Vka,l3);q3+=1}else{var r3=$CLJS.z(n3);if(r3){var s3=r3;if($CLJS.me(s3)){var t3=$CLJS.Wc(s3),Wka=$CLJS.Xc(s3),Xka=t3,Yka=$CLJS.D(t3);n3=Wka;o3=Xka;p3=Yka}else{var Zka=$CLJS.A(s3);$CLJS.mT(Zka,l3);n3=$CLJS.B(s3);o3=null;p3=0}q3=0}else break}
for(var u3=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.GH],null)),v3=null,w3=0,x3=0;;)if(x3<w3){var $ka=v3.ha(null,x3);$CLJS.mT($ka,k3);x3+=1}else{var y3=$CLJS.z(u3);if(y3){var z3=y3;if($CLJS.me(z3)){var A3=$CLJS.Wc(z3),ala=$CLJS.Xc(z3),bla=A3,cla=$CLJS.D(A3);u3=ala;v3=bla;w3=cla}else{var dla=$CLJS.A(z3);$CLJS.mT(dla,k3);u3=$CLJS.B(z3);v3=null;w3=0}x3=0}else break}
for(var B3=$CLJS.z(new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Vr,$CLJS.Wr,$CLJS.Tr,$CLJS.Ur,$CLJS.fH,$CLJS.zH,$CLJS.nH,$CLJS.gH],null)),C3=null,D3=0,E3=0;;)if(E3<D3){var ela=C3.ha(null,E3);$CLJS.mT(ela,m3);E3+=1}else{var F3=$CLJS.z(B3);if(F3){var G3=F3;if($CLJS.me(G3)){var H3=$CLJS.Wc(G3),fla=$CLJS.Xc(G3),gla=H3,hla=$CLJS.D(H3);B3=fla;C3=gla;D3=hla}else{var ila=$CLJS.A(G3);$CLJS.mT(ila,m3);B3=$CLJS.B(G3);C3=null;D3=0}E3=0}else break}
for(var I3=$CLJS.z(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.PG,$CLJS.aH,$CLJS.RG,$CLJS.YG,$CLJS.qs],null)),J3=null,K3=0,L3=0;;)if(L3<K3){var jla=J3.ha(null,L3);$CLJS.mT(jla,g3);L3+=1}else{var M3=$CLJS.z(I3);if(M3){var N3=M3;if($CLJS.me(N3)){var O3=$CLJS.Wc(N3),kla=$CLJS.Xc(N3),lla=O3,mla=$CLJS.D(O3);I3=kla;J3=lla;K3=mla}else{var nla=$CLJS.A(N3);$CLJS.mT(nla,g3);I3=$CLJS.B(N3);J3=null;K3=0}L3=0}else break}
$CLJS.m_.o(null,$CLJS.nX,function(a,b){var c=$CLJS.Pf($CLJS.nX.g($CLJS.JZ(a,b)));return $CLJS.m(c)?$CLJS.ZF("Filtered by {0}",$CLJS.F([$CLJS.L_($CLJS.$F("and"),function(){return function f(e){return new $CLJS.uf(null,function(){for(;;){var g=$CLJS.z(e);if(g){if($CLJS.me(g)){var l=$CLJS.Wc(g),n=$CLJS.D(l),q=$CLJS.xf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.Yd(l,u);v=$CLJS.MZ.B(a,b,v,$CLJS.U0);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Af($CLJS.Ef(q),f($CLJS.Xc(g))):$CLJS.Af($CLJS.Ef(q),null)}q=$CLJS.A(g);
return $CLJS.kf($CLJS.MZ.B(a,b,q,$CLJS.U0),f($CLJS.vd(g)))}return null}},null,null)}(c)}())])):null});
$CLJS.j_.o(null,l3,function(a,b,c,d){c=$CLJS.z(c);var e=$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var f=$CLJS.B(c);return $CLJS.L_(function(){var g=e instanceof $CLJS.M?e.R:null;switch(g){case "and":return $CLJS.$F("and");case "or":return $CLJS.$F("or");default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}(),function(){return function n(l){return new $CLJS.uf(null,function(){for(;;){var q=$CLJS.z(l);if(q){if($CLJS.me(q)){var u=$CLJS.Wc(q),v=$CLJS.D(u),x=$CLJS.xf(v);a:for(var y=0;;)if(y<
v){var C=$CLJS.Yd(u,y);C=$CLJS.MZ.B(a,b,C,d);x.add(C);y+=1}else{u=!0;break a}return u?$CLJS.Af($CLJS.Ef(x),n($CLJS.Xc(q))):$CLJS.Af($CLJS.Ef(x),null)}x=$CLJS.A(q);return $CLJS.kf($CLJS.MZ.B(a,b,x,d),n($CLJS.vd(q)))}return null}},null,null)}(f)}())});
$CLJS.j_.o(null,k3,function(a,b,c,d){var e=$CLJS.z(c);c=$CLJS.A(e);e=$CLJS.B(e);$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.kg.h(function(g){return $CLJS.MZ.B(a,b,g,d)},f);if($CLJS.E.h($CLJS.D(f),2))switch(f=$CLJS.G(e,0,null),e=$CLJS.G(e,1,null),c=c instanceof $CLJS.M?c.R:null,c){case "\x3d":return $CLJS.ZF("{0} equals {1}",$CLJS.F([f,e]));case "!\x3d":return $CLJS.ZF("{0} does not equal {1}",$CLJS.F([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}else switch(f=$CLJS.A(e),e=$CLJS.L_($CLJS.$F("or"),
$CLJS.vd(e)),c=c instanceof $CLJS.M?c.R:null,c){case "\x3d":return $CLJS.ZF("{0} equals any of {1}",$CLJS.F([f,e]));case "!\x3d":return $CLJS.ZF("{0} does not equal any of {1}",$CLJS.F([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.j_.o(null,m3,function(a,b,c,d){var e=$CLJS.G(c,0,null);$CLJS.G(c,1,null);var f=$CLJS.G(c,2,null);c=$CLJS.G(c,3,null);f=$CLJS.MZ.B(a,b,f,d);a=$CLJS.MZ.B(a,b,c,d);e=e instanceof $CLJS.M?e.R:null;switch(e){case "\x3c":return $CLJS.ZF("{0} is less than {1}",$CLJS.F([f,a]));case "\x3c\x3d":return $CLJS.ZF("{0} is less than or equal to {1}",$CLJS.F([f,a]));case "\x3e":return $CLJS.ZF("{0} is greater than {1}",$CLJS.F([f,a]));case "\x3e\x3d":return $CLJS.ZF("{0} is greater than or equal to {1}",$CLJS.F([f,
a]));case "starts-with":return $CLJS.ZF("{0} starts with {1}",$CLJS.F([f,a]));case "ends-with":return $CLJS.ZF("{0} ends with {1}",$CLJS.F([f,a]));case "contains":return $CLJS.ZF("{0} contains {1}",$CLJS.F([f,a]));case "does-not-contain":return $CLJS.ZF("{0} does not contain {1}",$CLJS.F([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});
$CLJS.j_.o(null,$CLJS.QG,function(a,b,c,d){$CLJS.G(c,0,null);$CLJS.G(c,1,null);var e=$CLJS.G(c,2,null),f=$CLJS.G(c,3,null);c=$CLJS.G(c,4,null);return $CLJS.ZF("{0} is between {1} and {2}",$CLJS.F([$CLJS.MZ.B(a,b,e,d),$CLJS.MZ.B(a,b,f,d),$CLJS.MZ.B(a,b,c,d)]))});
$CLJS.j_.o(null,$CLJS.WG,function(a,b,c,d){$CLJS.G(c,0,null);var e=$CLJS.G(c,1,null),f=$CLJS.G(c,2,null),g=$CLJS.G(c,3,null),l=$CLJS.G(c,4,null),n=$CLJS.G(c,5,null),q=$CLJS.G(c,6,null);c=$CLJS.G(c,7,null);return $CLJS.MZ.B(a,b,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.vs,e,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.QG,e,f,q,l],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.QG,e,g,n,c],null)],null),d)});
$CLJS.j_.o(null,g3,function(a,b,c,d){var e=$CLJS.G(c,0,null);$CLJS.G(c,1,null);c=$CLJS.G(c,2,null);a=$CLJS.MZ.B(a,b,c,d);e=e instanceof $CLJS.M?e.R:null;switch(e){case "is-null":return $CLJS.ZF("{0} is empty",$CLJS.F([a]));case "not-null":return $CLJS.ZF("{0} is not empty",$CLJS.F([a]));case "is-empty":return $CLJS.ZF("{0} is empty",$CLJS.F([a]));case "not-empty":return $CLJS.ZF("{0} is not empty",$CLJS.F([a]));case "not":return $CLJS.ZF("not {0}",$CLJS.F([a]));default:throw Error(["No matching clause: ",
$CLJS.p.g(e)].join(""));}});$CLJS.j_.o(null,$CLJS.AH,function(a,b,c,d){$CLJS.G(c,0,null);$CLJS.G(c,1,null);var e=$CLJS.G(c,2,null),f=$CLJS.G(c,3,null);c=$CLJS.G(c,4,null);return $CLJS.ZF("{0} is within {1}",$CLJS.F([$CLJS.MZ.B(a,b,e,d),$CLJS.j3(f,c).toLowerCase()]))});
$CLJS.P3=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var g=Array(arguments.length-2);f<g.length;)g[f]=arguments[f+2],++f;f=new $CLJS.rd(g,0,null)}return b.call(this,c,d,f)}function b(c,d,e){return $CLJS.Y2($CLJS.Zr,$CLJS.kf(c,$CLJS.kf(d,e)))}a.A=2;a.D=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.vd(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.Q3=function(){function a(d,e,f){for(;;)if($CLJS.E.h($CLJS.IF(f),$CLJS.zL))f=$CLJS.eU(f);else return e=$CLJS.m(e)?e:-1,f=$CLJS.iU.g(f),$CLJS.R3.l(d,e,$CLJS.nm,$CLJS.F([$CLJS.nX,$CLJS.cg($CLJS.gf,$CLJS.Mg),f]))}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.S3=function(){function a(d,e){return $CLJS.Pf($CLJS.nX.g($CLJS.JZ(d,$CLJS.m(e)?e:-1)))}function b(d){return c.h?c.h(d,null):c.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.U8=function(){function a(d,e){var f=$CLJS.JZ(d,e);return $CLJS.Pf($CLJS.mh.j($CLJS.Mg,$CLJS.gm.h($CLJS.kg.g($CLJS.f3),$CLJS.ug($CLJS.oM)),$CLJS.w1.j(d,e,f)))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.A8=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var g=Array(arguments.length-2);f<g.length;)g[f]=arguments[f+2],++f;f=new $CLJS.rd(g,0,null)}return b.call(this,c,d,f)}function b(c,d,e){c=$CLJS.je(c)?$CLJS.hH.g(c):$CLJS.zi.g(c);return $CLJS.gU($CLJS.mh.h(new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.O,$CLJS.iU.g(d)],null),$CLJS.kg.h($CLJS.iU,e)))}a.A=2;a.D=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.vd(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.ola=function(){function a(d,e,f){var g=$CLJS.G(f,0,null);$CLJS.G(f,1,null);f=$CLJS.G(f,2,null);var l=$CLJS.JZ(d,e);l=$CLJS.w1.j(d,e,l);d=$CLJS.D2.B(d,e,f,l);d=$CLJS.uU(function(n){return $CLJS.E.h($CLJS.hH.g(n),g)},$CLJS.$2(d));return $CLJS.m(d)?d:$CLJS.G2.g(g)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;
c.j=a;return c}();
$CLJS.pla=function(){function a(d,e,f){var g=$CLJS.Z$(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hN,$CLJS.SG],null),f);d=$CLJS.Ng($CLJS.S3.h(d,e));e=$CLJS.vg(function(l){return $CLJS.E.h($CLJS.Z$(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hN,$CLJS.SG],null),$CLJS.v0.g(l)),g)},d);if($CLJS.z(e)){if($CLJS.B(e))throw $CLJS.nj("Multiple matching filters found",new $CLJS.k(null,3,[Ska,g,Tka,d,Uka,e],null));return $CLJS.A(e)}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,
e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.qla=function(){function a(d,e,f){f=$CLJS.VZ.j(d,e,f);d=$CLJS.U8.h(d,e);return $CLJS.D2.h(f,d)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();