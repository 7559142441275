var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.AK=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.BK=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.CK=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var DK=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vs,$CLJS.os],null)),EK=null,FK=0,GK=0;;)if(GK<FK){var qda=EK.ha(null,GK);$CLJS.zT(qda,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fs,new $CLJS.k(null,1,[$CLJS.bk,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rG],null)],null)],null)],null)]));GK+=1}else{var HK=$CLJS.z(DK);if(HK){var IK=HK;if($CLJS.me(IK)){var JK=$CLJS.Wc(IK),rda=$CLJS.Xc(IK),
sda=JK,tda=$CLJS.D(JK);DK=rda;EK=sda;FK=tda}else{var uda=$CLJS.A(IK);$CLJS.zT(uda,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fs,new $CLJS.k(null,1,[$CLJS.bk,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rG],null)],null)],null)],null)]));DK=$CLJS.B(IK);EK=null;FK=0}GK=0}else break}$CLJS.xT($CLJS.qs,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rG],null)]));
for(var KK=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.GH],null)),LK=null,MK=0,NK=0;;)if(NK<MK){var vda=LK.ha(null,NK);$CLJS.zT(vda,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fs,new $CLJS.k(null,1,[$CLJS.bk,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.xG],null)],null)],null)],null)]));NK+=1}else{var OK=$CLJS.z(KK);if(OK){var PK=OK;if($CLJS.me(PK)){var QK=$CLJS.Wc(PK),wda=$CLJS.Xc(PK),
xda=QK,yda=$CLJS.D(QK);KK=wda;LK=xda;MK=yda}else{var zda=$CLJS.A(PK);$CLJS.zT(zda,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fs,new $CLJS.k(null,1,[$CLJS.bk,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.xG],null)],null)],null)],null)]));KK=$CLJS.B(PK);LK=null;MK=0}NK=0}else break}
for(var RK=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Vr,$CLJS.Wr,$CLJS.Tr,$CLJS.Ur],null)),SK=null,TK=0,UK=0;;)if(UK<TK){var Ada=SK.ha(null,UK);$CLJS.xT(Ada,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null)]));UK+=1}else{var VK=$CLJS.z(RK);if(VK){var WK=VK;if($CLJS.me(WK)){var XK=$CLJS.Wc(WK),Bda=$CLJS.Xc(WK),Cda=XK,Dda=$CLJS.D(XK);RK=Bda;SK=Cda;TK=Dda}else{var Eda=$CLJS.A(WK);$CLJS.xT(Eda,$CLJS.F([$CLJS.Ft,$CLJS.Gj,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null)]));RK=$CLJS.B(WK);SK=null;TK=0}UK=0}else break}$CLJS.xT($CLJS.QG,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null)]));
$CLJS.xT($CLJS.WG,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null)]));
for(var YK=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PG,$CLJS.aH],null)),ZK=null,$K=0,aL=0;;)if(aL<$K){var Fda=ZK.ha(null,aL);$CLJS.xT(Fda,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sG],null)]));aL+=1}else{var bL=$CLJS.z(YK);if(bL){var cL=bL;if($CLJS.me(cL)){var dL=$CLJS.Wc(cL),Gda=$CLJS.Xc(cL),Hda=dL,Ida=$CLJS.D(dL);YK=Gda;ZK=Hda;$K=Ida}else{var Jda=$CLJS.A(cL);$CLJS.xT(Jda,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sG],null)]));YK=$CLJS.B(cL);
ZK=null;$K=0}aL=0}else break}
for(var eL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RG,$CLJS.YG],null)),fL=null,gL=0,hL=0;;)if(hL<gL){var Kda=fL.ha(null,hL);$CLJS.xT(Kda,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.BG],null)]));hL+=1}else{var iL=$CLJS.z(eL);if(iL){var jL=iL;if($CLJS.me(jL)){var kL=$CLJS.Wc(jL),Lda=$CLJS.Xc(jL),Mda=kL,Nda=$CLJS.D(kL);eL=Lda;fL=Mda;gL=Nda}else{var Oda=$CLJS.A(jL);$CLJS.xT(Oda,$CLJS.F([$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.BG],null)]));eL=$CLJS.B(jL);
fL=null;gL=0}hL=0}else break}
for(var SW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wH,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null)],null),lL=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.fH,$CLJS.zH,$CLJS.nH,$CLJS.gH],null)),mL=null,nL=0,oL=0;;)if(oL<nL){var pL=mL.ha(null,oL);$CLJS.PH.B(pL,$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,pL],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,$CLJS.dG,SW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,
$CLJS.CG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.CG],null)],null));oL+=1}else{var qL=$CLJS.z(lL);if(qL){var rL=qL;if($CLJS.me(rL)){var sL=$CLJS.Wc(rL),Pda=$CLJS.Xc(rL),Qda=sL,Rda=$CLJS.D(sL);lL=Pda;mL=Qda;nL=Rda}else{var tL=$CLJS.A(rL);$CLJS.PH.B(tL,$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,tL],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,$CLJS.dG,SW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.CG],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.CG],null)],null));lL=$CLJS.B(rL);mL=null;nL=0}oL=0}else break}
$CLJS.PH.B($CLJS.AH,$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.AH],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,$CLJS.dG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DH,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ks,$CLJS.hv,$CLJS.Lw,$CLJS.VG],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,$CLJS.FG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.nF],null)],null));$CLJS.PH.B($CLJS.MG,$CLJS.Ft,$CLJS.Gj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.MG],null),$CLJS.dG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,$CLJS.pF,$CLJS.HF],null)],null));
$CLJS.oJ($CLJS.AK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.BK],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hH,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.ks,$CLJS.Zr,$CLJS.GH,$CLJS.WG,$CLJS.QG,$CLJS.Vr,$CLJS.Tr,$CLJS.Wr,$CLJS.Ur,$CLJS.PG,$CLJS.aH,$CLJS.RG,$CLJS.YG,$CLJS.nH,$CLJS.gH,$CLJS.fH,$CLJS.zH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.CK,$CLJS.as],null)],null));