var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var cF,jJ,iJ,aia,kJ,eF,mJ;
$CLJS.cg=function(a,b){return function(){function c(l,n,q){l=null==l?b:l;return a.j?a.j(l,n,q):a.call(null,l,n,q)}function d(l,n){l=null==l?b:l;return a.h?a.h(l,n):a.call(null,l,n)}function e(l){l=null==l?b:l;return a.g?a.g(l):a.call(null,l)}var f=null,g=function(){function l(q,u,v,x){var y=null;if(3<arguments.length){y=0;for(var C=Array(arguments.length-3);y<C.length;)C[y]=arguments[y+3],++y;y=new $CLJS.rd(C,0,null)}return n.call(this,q,u,v,y)}function n(q,u,v,x){return $CLJS.P.O(a,null==q?b:q,u,
v,x)}l.A=3;l.D=function(q){var u=$CLJS.A(q);q=$CLJS.B(q);var v=$CLJS.A(q);q=$CLJS.B(q);var x=$CLJS.A(q);q=$CLJS.vd(q);return n(u,v,x,q)};l.l=n;return l}();f=function(l,n,q,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,n);case 3:return c.call(this,l,n,q);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.rd(x,0,null)}return g.l(l,n,q,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.D=g.D;f.g=e;f.h=d;f.j=c;f.l=g.l;return f}()};cF=function(a,b){this.db=a;this.sf=b;this.C=393216;this.J=0};jJ=function(a){for(var b=null;;){var c;a=$CLJS.Mo.h(a,b);a=c=$CLJS.lo(a)?$CLJS.eo(a):a;if(!$CLJS.lo(c))return a}};iJ=function(a){$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return!($CLJS.je(a)&&!0===$CLJS.Js.g(a))};
aia=function(a,b,c){var d=jJ($CLJS.Mo.h(a,c));return $CLJS.Eq($CLJS.Vn(d),$CLJS.Sn(d),function(){var e=$CLJS.Un(d);return b.g?b.g(e):b.call(null,e)}(),function(){var e=$CLJS.Tn($CLJS.Mo.h(d,null));return $CLJS.m(e)?e:c}())};kJ=function(a){return function(b,c,d){var e=$CLJS.z(c);b=$CLJS.A(e);e=$CLJS.B(e);c=$CLJS.mm.h(function(f){return $CLJS.Mo.h(f,d)},c);return new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.mm.h($CLJS.Cs,c),$CLJS.Ob(function(f,g){return a.j?a.j(f,g,d):a.call(null,f,g,d)},b,e)],null)}};
$CLJS.dF=function(a,b,c,d,e,f,g,l,n,q,u,v,x,y,C,H,J,S,V){this.form=a;this.options=b;this.we=c;this.forms=d;this.X=e;this.Fe=f;this.W=g;this.children=l;this.min=n;this.Mb=q;this.ac=u;this.parent=v;this.type=x;this.cache=y;this.Se=C;this.wi=H;this.max=J;this.Qc=S;this.bi=V;this.C=393216;this.J=0};eF=function(a,b,c,d,e,f,g,l,n){this.Se=a;this.Qc=b;this.type=c;this.min=d;this.max=e;this.Fe=f;this.Mb=g;this.ac=l;this.we=n;this.C=393216;this.J=0};
mJ=function(a){var b=$CLJS.Of(a),c=$CLJS.K.h(b,$CLJS.Ik),d=$CLJS.K.h(b,$CLJS.bk),e=$CLJS.K.h(b,$CLJS.xl),f=$CLJS.K.h(b,$CLJS.Rt),g=$CLJS.K.h(b,$CLJS.im),l=$CLJS.K.h(b,$CLJS.ls);return new eF(a,b,c,d,e,f,g,l,new $CLJS.k(null,1,[$CLJS.Ik,$CLJS.zl],null))};$CLJS.oJ=function(a,b){$CLJS.Ri.B(nJ,$CLJS.T,a,b);$CLJS.gg($CLJS.lF,$CLJS.O);return null};cF.prototype.M=function(a,b){return new cF(this.db,b)};cF.prototype.K=function(){return this.sf};cF.prototype.Zd=$CLJS.t;
cF.prototype.Dd=function(a,b){return $CLJS.cn($CLJS.fn($CLJS.r(this.db)),b)};var qJ=function qJ(a){switch(arguments.length){case 2:return qJ.h(arguments[0],arguments[1]);case 3:return qJ.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};qJ.h=function(a,b){return qJ.j(a,b,null)};
qJ.j=function(a,b,c){function d(v,x){return $CLJS.E.h($CLJS.Wj,v)?new $CLJS.Q(null,2,5,$CLJS.R,[null,x],null):$CLJS.sg.h(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Kq.g(x)],null),$CLJS.Lq.g(x))}function e(v,x){return $CLJS.m($CLJS.m(v)?x:v)?$CLJS.xm.l($CLJS.F([v,x])):$CLJS.m(v)?v:x}a=$CLJS.m(a)?jJ($CLJS.Mo.h(a,c)):null;b=$CLJS.m(b)?jJ($CLJS.Mo.h(b,c)):null;var f=$CLJS.m(a)?$CLJS.yo.g(a):null,g=$CLJS.m(b)?$CLJS.yo.g(b):null,l=$CLJS.Of(c),n=$CLJS.K.j(l,$CLJS.Nt,function(v,x){return x}),q=$CLJS.K.j(l,$CLJS.Qt,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Bb(function(){var v=new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.vs,null,$CLJS.Wj,null],null),null);v=v.g?v.g(f):v.call(null,f);return $CLJS.m(v)?(v=new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.vs,null,$CLJS.Wj,null],null),null),v.g?v.g(g):v.call(null,g)):v}()))return n.j?n.j(a,b,c):n.call(null,a,b,c);if($CLJS.E.h(f,$CLJS.Wj)&&$CLJS.E.h(g,$CLJS.Wj)){l=e($CLJS.Sn(a),$CLJS.Sn(b));var u=$CLJS.ka($CLJS.ai);a=$CLJS.Ob(function(v,x){var y=
$CLJS.G(x,0,null);if($CLJS.m(function(){var C=$CLJS.r(u);return C.g?C.g(y):C.call(null,y)}()))return $CLJS.Ob(function(C,H){var J=$CLJS.G(H,0,null),S=$CLJS.gf.h;if($CLJS.E.h(J,y)){J=$CLJS.G(H,0,null);var V=$CLJS.G(H,1,null),da=$CLJS.G(H,2,null);$CLJS.G(x,0,null);var fa=$CLJS.G(x,1,null),ma=$CLJS.G(x,2,null);H=iJ(H);var Fa=iJ(x);H=q.h?q.h(H,Fa):q.call(null,H,Fa);V=$CLJS.xm.l($CLJS.F([V,fa]));ma=new $CLJS.Q(null,3,5,$CLJS.R,[J,$CLJS.T.j(V,$CLJS.Js,$CLJS.Bb(H)),qJ.j?qJ.j(da,ma,c):qJ.call(null,da,ma,
c)],null);J=$CLJS.G(ma,0,null);da=$CLJS.G(ma,1,null);ma=$CLJS.G(ma,2,null);J=$CLJS.Bb(ma)?new $CLJS.Q(null,2,5,$CLJS.R,[J,da],null):$CLJS.m($CLJS.m(da)?!1===$CLJS.Js.g(da)&&$CLJS.E.h(1,$CLJS.D(da)):da)?new $CLJS.Q(null,2,5,$CLJS.R,[J,ma],null):$CLJS.Bb($CLJS.z(da))?new $CLJS.Q(null,2,5,$CLJS.R,[J,ma],null):!1===$CLJS.Js.g(da)?new $CLJS.Q(null,3,5,$CLJS.R,[J,$CLJS.$l.h(da,$CLJS.Js),ma],null):new $CLJS.Q(null,3,5,$CLJS.R,[J,da,ma],null)}else J=H;return S.call($CLJS.gf,C,J)},$CLJS.Mg,v);$CLJS.Ri.j(u,
$CLJS.gf,y);return $CLJS.gf.h(v,x)},$CLJS.Mg,$CLJS.mh.h($CLJS.Un(a),$CLJS.Un(b)));return $CLJS.Eq($CLJS.Wj,l,a,c)}return function(v,x){var y=$CLJS.z(v);v=$CLJS.A(y);var C=$CLJS.B(y);y=$CLJS.A(C);C=$CLJS.B(C);var H=$CLJS.z(x);x=$CLJS.A(H);var J=$CLJS.B(H);H=$CLJS.A(J);J=$CLJS.B(J);return $CLJS.Eq($CLJS.vs,e(v,x),$CLJS.sg.l(new $CLJS.Q(null,1,5,$CLJS.R,[qJ.j(y,H,c)],null),C,$CLJS.F([J])),c)}(d(f,a),d(g,b))};qJ.A=3;
var rJ=function rJ(a){switch(arguments.length){case 2:return rJ.h(arguments[0],arguments[1]);case 3:return rJ.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};rJ.h=function(a,b){return rJ.j(a,b,null)};
rJ.j=function(a,b,c){return qJ.j(a,b,$CLJS.nm.j($CLJS.nm.j(c,$CLJS.Nt,$CLJS.cg($CLJS.Ve,function(d,e,f){var g=$CLJS.E.h($CLJS.Cs.h(d,null),$CLJS.Cs.h(e,null));return $CLJS.m(g)?d:$CLJS.Mo.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,d,e],null),f)})),$CLJS.Qt,$CLJS.cg($CLJS.Ve,function(d,e){return $CLJS.m(d)?e:d})))};rJ.A=3;
var pJ=function pJ(a){switch(arguments.length){case 2:return pJ.h(arguments[0],arguments[1]);case 3:return pJ.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};pJ.h=function(a,b){return pJ.j(a,b,null)};pJ.j=function(a,b,c){var d=$CLJS.di(b);return aia(a,function(e){return $CLJS.vg(function(f){f=$CLJS.G(f,0,null);return d.g?d.g(f):d.call(null,f)},e)},c)};pJ.A=3;$CLJS.h=$CLJS.dF.prototype;
$CLJS.h.M=function(a,b){return new $CLJS.dF(this.form,this.options,this.we,this.forms,this.X,this.Fe,this.W,this.children,this.min,this.Mb,this.ac,this.parent,this.type,this.cache,this.Se,this.wi,this.max,this.Qc,b)};$CLJS.h.K=function(){return this.bi};$CLJS.h.eb=$CLJS.t;$CLJS.h.Ha=function(){return $CLJS.Pn(this.W)};$CLJS.h.Ra=function(){return this.options};$CLJS.h.$a=function(a,b,c,d){return $CLJS.jp(this,new $CLJS.Q(null,1,5,$CLJS.R,[this.W],null),b,c,d)};$CLJS.h.gb=function(){return this.X};
$CLJS.h.xa=function(){return this.children};$CLJS.h.Za=function(){return $CLJS.r(this.form)};$CLJS.h.Ya=function(a,b){return $CLJS.Qn(this.W,b)};$CLJS.h.fb=function(){return this.parent};$CLJS.h.bb=$CLJS.t;$CLJS.h.pa=$CLJS.Ia(5);$CLJS.h.Pe=$CLJS.t;$CLJS.h.ue=function(){return this.W};$CLJS.h=eF.prototype;$CLJS.h.M=function(a,b){return new eF(this.Se,this.Qc,this.type,this.min,this.max,this.Fe,this.Mb,this.ac,b)};$CLJS.h.K=function(){return this.we};$CLJS.h.cb=$CLJS.t;$CLJS.h.Wa=function(){return this.type};
$CLJS.h.Xa=function(){return this.Mb};$CLJS.h.Va=function(a,b,c,d){var e=this;$CLJS.ro(e.type,b,c,e.min,e.max);a=function(){var n=$CLJS.Ng(c);return e.ac.j?e.ac.j(b,n,d):e.ac.call(null,b,n,d)}();var f=$CLJS.G(a,0,null),g=$CLJS.G(a,1,null),l=$CLJS.G(a,2,null);return new $CLJS.dF(new $CLJS.Ji(function(){return $CLJS.Ro(e.type,b,g,d)}),d,e.we,g,b,e.Fe,l,f,e.min,e.Mb,e.ac,this,e.type,$CLJS.Qo(),e.Se,a,e.max,e.Qc,new $CLJS.k(null,1,[$CLJS.Ik,$CLJS.Hm],null))};
$CLJS.mF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.nF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);var nJ;$CLJS.lF=$CLJS.ka($CLJS.O);
nJ=$CLJS.ka($CLJS.xm.l($CLJS.F([$CLJS.es(),new $CLJS.k(null,3,[$CLJS.Ot,mJ(new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.Ot,$CLJS.ls,kJ(qJ)],null)),$CLJS.Kt,mJ(new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.Kt,$CLJS.ls,kJ(rJ)],null)),$CLJS.St,mJ(new $CLJS.k(null,5,[$CLJS.Ik,$CLJS.St,$CLJS.Rt,1,$CLJS.bk,2,$CLJS.xl,2,$CLJS.ls,function(a,b,c){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nm.j(b,0,function(d){return $CLJS.Mo.h(d,c)}),$CLJS.nm.j(b,0,function(d){return $CLJS.Cs.h(d,c)}),$CLJS.P.h(pJ,$CLJS.gf.h(b,c))],null)}],null))],
null)])));$CLJS.gg($CLJS.Im,$CLJS.fn(new cF(nJ,$CLJS.O)));