var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var tqa,FZ,uqa,zZ,BZ,h8,XZ,cka,dka,l1,W0,n1,eka,Y0;tqa=function(a){return $CLJS.z(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};FZ=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Ee($CLJS.gha,a)?a:"_":$CLJS.m(b)?encodeURIComponent(a):a};
uqa=function(a){var b=$CLJS.Of(new $CLJS.k(null,1,[$CLJS.NB,!0],null)),c=$CLJS.K.h(b,$CLJS.KB),d=$CLJS.K.h(b,$CLJS.NB);return $CLJS.z(a)?(b=$CLJS.IW(function(){return function g(f){return new $CLJS.uf(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.me(l)){var n=$CLJS.Wc(l),q=$CLJS.D(n),u=$CLJS.xf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.Yd(n,v);x=FZ(x,$CLJS.Bb(d));u.add(x);v+=1}else{n=!0;break a}return n?$CLJS.Af($CLJS.Ef(u),g($CLJS.Xc(l))):$CLJS.Af($CLJS.Ef(u),null)}u=$CLJS.A(l);return $CLJS.kf(FZ(u,
$CLJS.Bb(d)),g($CLJS.vd(l)))}return null}},null,null)}(tqa($CLJS.BE(a)))}()),$CLJS.m(c)?$CLJS.IW($CLJS.la(c,b)):b):null};$CLJS.Q0=function(a){return $CLJS.FP.g($CLJS.A($CLJS.$L.g(a)))};zZ=function(a,b){if(null!=a&&null!=a.bh)a=a.bh(a,b);else{var c=zZ[$CLJS.Ka(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=zZ._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Lb("MetadataProvider.table",a);}return a};
BZ=function(a,b){if(null!=a&&null!=a.Wg)a=a.Wg(a,b);else{var c=BZ[$CLJS.Ka(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=BZ._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Lb("MetadataProvider.card",a);}return a};h8=function(a,b){if(null!=a&&null!=a.Zg)a=a.Zg(a,b);else{var c=h8[$CLJS.Ka(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=h8._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Lb("MetadataProvider.fields",a);}return a};
$CLJS.DZ=function(a){return $CLJS.CZ(a)?a:$CLJS.oF.g(a)};$CLJS.R0=function(a,b){return zZ($CLJS.DZ(a),b)};$CLJS.S0=function(a,b){return h8($CLJS.DZ(a),b)};$CLJS.V_=function(a,b){return BZ($CLJS.DZ(a),b)};
XZ=function(a,b,c){var d=$CLJS.T0.j(a,b,c);return $CLJS.xm.l($CLJS.F([$CLJS.Zh(d,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.W,$CLJS.vF,$CLJS.Lj],null)),function(){var e=$CLJS.MZ.B(a,b,c,$CLJS.U0);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.V0,e],null):null}(),function(){var e=$CLJS.OA($CLJS.Bf,$CLJS.JB)(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.Bf,e],null):null}(),function(){var e=$CLJS.IL.g(d);return $CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.DX,$CLJS.PZ.j(a,b,$CLJS.ve(e)?$CLJS.R0(a,e):"string"===typeof e?
$CLJS.V_(a,$CLJS.yX(e)):null)],null):null}(),function(){var e=$CLJS.xL.g(d);return $CLJS.m(e)?new $CLJS.k(null,4,[W0,$CLJS.E.h(e,$CLJS.YZ),$CLJS.X0,$CLJS.E.h(e,$CLJS.h_),Y0,$CLJS.E.h(e,$CLJS.e_),$CLJS.Z0,$CLJS.E.h(e,$CLJS.g_)],null):null}(),function(){var e=$CLJS.u_.g(d);return null==e?null:new $CLJS.k(null,1,[$CLJS.d1,e],null)}(),$CLJS.Zh(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.e1,$CLJS.h1],null))]))};$CLJS.i1=new $CLJS.M(null,"direction","direction",-633359395);
$CLJS.Z0=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.e1=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.j1=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.d1=new $CLJS.M(null,"selected","selected",574897764);cka=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);
dka=new $CLJS.w("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.k1=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);l1=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.m1=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);
W0=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);n1=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);$CLJS.o1=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.X0=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.p1=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.h1=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);
eka=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.V0=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);$CLJS.q1=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.U0=new $CLJS.M(null,"long","long",-171452093);$CLJS.r1=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.s1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);
$CLJS.t1=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);Y0=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.j_=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.metadata.calculation","display-name-method"),function(f,g,l){return $CLJS.IF(l)},e,a,b,c,d)}();
$CLJS.k_=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.metadata.calculation","column-name-method"),function(f,g,l){return $CLJS.IF(l)},e,a,b,c,d)}();
$CLJS.MZ=function(){function a(f,g,l,n){var q=$CLJS.vF.g($CLJS.KF(l));if($CLJS.m(q))return q;try{return $CLJS.j_.B(f,g,l,n)}catch(u){if(u instanceof Error)throw g=u,$CLJS.mj($CLJS.ZF("Error calculating display name for {0}: {1}",$CLJS.F([$CLJS.Oi.l($CLJS.F([l])),$CLJS.MX(g)])),new $CLJS.k(null,2,[$CLJS.hN,f,$CLJS.fG,l],null),g);throw u;}}function b(f,g,l){return e.B?e.B(f,g,l,$CLJS.Dj):e.call(null,f,g,l,$CLJS.Dj)}function c(f,g){return e.j?e.j(f,-1,g):e.call(null,f,-1,g)}function d(f){return e.h?
e.h(f,f):e.call(null,f,f)}var e=null;e=function(f,g,l,n){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,g);case 3:return b.call(this,f,g,l);case 4:return a.call(this,f,g,l,n)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.h=c;e.j=b;e.B=a;return e}();
$CLJS.l_=function(){function a(d,e,f){var g=$CLJS.W.g($CLJS.KF(f));if($CLJS.m(g))return g;try{return $CLJS.k_.j(d,e,f)}catch(l){if(l instanceof Error)throw g=l,$CLJS.mj($CLJS.ZF("Error calculating column name for {0}: {1}",$CLJS.F([$CLJS.Oi.l($CLJS.F([f])),$CLJS.MX(g)])),new $CLJS.k(null,3,[$CLJS.fG,f,$CLJS.hN,d,$CLJS.BX,e],null),g);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();$CLJS.j_.o(null,$CLJS.Dj,function(a,b,c){a=$CLJS.uE($CLJS.xx);$CLJS.m($CLJS.yE("metabase.lib.metadata.calculation",a))&&$CLJS.zE("metabase.lib.metadata.calculation",a,$CLJS.SY("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.F([$CLJS.Oi.l($CLJS.F([c])),dka,$CLJS.IF(c)])),null);return $CLJS.le(c)&&$CLJS.A(c)instanceof $CLJS.M?$CLJS.fi($CLJS.A(c)):$CLJS.Oi.l($CLJS.F([c]))});
$CLJS.k_.o(null,$CLJS.Dj,function(a,b,c){return uqa($CLJS.Ys($CLJS.MZ.j(a,b,c),/[\(\)]/,""))});$CLJS.m_=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,g,l){return l},e,a,b,c,d)}();
$CLJS.a6=function(){function a(d,e,f){return $CLJS.m_.j(d,e,$CLJS.zi.g(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.n_=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.metadata.calculation","type-of-method"),function(f,g,l){return $CLJS.IF(l)},e,a,b,c,d)}();
$CLJS.o_=function(){function a(d,e,f){var g=$CLJS.KF(f);g=$CLJS.Of(g);var l=$CLJS.K.h(g,$CLJS.uH),n=$CLJS.Bf.g(g);if($CLJS.m(n))return n;l=$CLJS.m($CLJS.m(l)?$CLJS.Ee($CLJS.WT,l):l)?$CLJS.wj:null;if($CLJS.m(l))return l;g=$CLJS.JB.g(g);if($CLJS.m(g))return g;d=$CLJS.n_.j(d,e,f);return $CLJS.XF(d,$CLJS.Aj)?d:$CLJS.Aj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.j=a;return c}();$CLJS.n_.o(null,$CLJS.Dj,function(a,b,c){return $CLJS.EJ(c)});$CLJS.n_.o(null,$CLJS.yG,function(a,b,c){$CLJS.G(c,0,null);$CLJS.G(c,1,null);c=$CLJS.G(c,2,null);return $CLJS.o_.j(a,b,c)});$CLJS.n_.o(null,$CLJS.yJ,function(a,b,c){$CLJS.G(c,0,null);$CLJS.G(c,1,null);var d=$CLJS.G(c,2,null);return"string"===typeof d?$CLJS.EJ(c):$CLJS.o_.j(a,b,d)});
$CLJS.KZ=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.metadata.calculation","metadata-method"),function(f,g,l){return $CLJS.IF(l)},e,a,b,c,d)}();
$CLJS.KZ.o(null,$CLJS.Dj,function(a,b,c){try{return new $CLJS.k(null,4,[$CLJS.su,$CLJS.vL,$CLJS.JB,$CLJS.o_.j(a,b,c),$CLJS.W,$CLJS.l_.j(a,b,c),$CLJS.vF,$CLJS.MZ.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.mj($CLJS.ZF("Error calculating metadata for {0}: {1}",$CLJS.F([$CLJS.Oi.l($CLJS.F([$CLJS.IF(c)])),$CLJS.MX(d)])),new $CLJS.k(null,3,[$CLJS.hN,a,$CLJS.BX,b,$CLJS.fG,c],null),d);}throw e;}});
$CLJS.T0=function(){function a(e,f,g){return $CLJS.KZ.j(e,f,g)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,g){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,g)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.h=b;d.j=a;return d}();
$CLJS.p_=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.metadata.calculation","display-info-method"),function(f,g,l){return $CLJS.IF(l)},e,a,b,c,d)}();
$CLJS.oJ(l1,new $CLJS.Q(null,19,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vF,$CLJS.ql],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.V0,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.ql],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DX,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,l1],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[W0,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Ds],
null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.X0,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Ds],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Y0,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Ds],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Z0,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Ds],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[n1,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.ns,$CLJS.Ds],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[eka,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Ds],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[cka,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Ds],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.W,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.ql],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZG,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.ql],null),
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.du,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.ql],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.r1,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.ql],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.p1,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.d1,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Dj,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.i1,
new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ks,$CLJS.lH,$CLJS.eH],null)],null)],null));
$CLJS.PZ=function(){function a(d,e,f){try{return $CLJS.p_.j(d,e,f)}catch(l){if(l instanceof Error){var g=l;throw $CLJS.mj($CLJS.ZF("Error calculating display info for {0}: {1}",$CLJS.F([$CLJS.IF(f),$CLJS.MX(g)])),new $CLJS.k(null,3,[$CLJS.hN,d,$CLJS.BX,e,$CLJS.fG,f],null),g);}throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.j=a;return c}();$CLJS.p_.o(null,$CLJS.Dj,function(a,b,c){return XZ(a,b,c)});$CLJS.p_.o(null,$CLJS.CL,function(a,b,c){return $CLJS.xm.l($CLJS.F([XZ(a,b,c),new $CLJS.k(null,1,[n1,$CLJS.E.h($CLJS.Q0(a),$CLJS.rk.g(c))],null)]))});
$CLJS.u1=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.metadata.calculation","returned-columns-method"),function(f,g,l){return $CLJS.IF(l)},e,a,b,c,d)}();$CLJS.u1.o(null,$CLJS.OB,function(){return $CLJS.Mg});
$CLJS.v1=function(){function a(f,g,l,n){n=$CLJS.xm.l($CLJS.F([new $CLJS.k(null,1,[$CLJS.o1,$CLJS.M5()],null),n]));return $CLJS.u1.B(f,g,l,n)}function b(f,g,l){return e.B?e.B(f,g,l,null):e.call(null,f,g,l,null)}function c(f,g){return e.j?e.j(f,-1,g):e.call(null,f,-1,g)}function d(f){var g=$CLJS.JZ(f,-1);return e.h?e.h(f,g):e.call(null,f,g)}var e=null;e=function(f,g,l,n){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,g);case 3:return b.call(this,f,g,l);case 4:return a.call(this,
f,g,l,n)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.h=c;e.j=b;e.B=a;return e}();$CLJS.q_=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.metadata.calculation","visible-columns-method"),function(f,g,l){return $CLJS.IF(l)},e,a,b,c,d)}();$CLJS.q_.o(null,$CLJS.OB,function(){return $CLJS.Mg});
$CLJS.q_.o(null,$CLJS.Dj,function(a,b,c,d){return $CLJS.u1.B(a,b,c,d)});
$CLJS.w1=function(){function a(f,g,l,n){n=$CLJS.xm.l($CLJS.F([$CLJS.xm.l($CLJS.F([new $CLJS.k(null,1,[$CLJS.o1,$CLJS.M5()],null),new $CLJS.k(null,4,[$CLJS.m1,!0,$CLJS.t1,!0,$CLJS.j1,!0,$CLJS.q1,!0],null)])),n]));return $CLJS.q_.B(f,g,l,n)}function b(f,g,l){return e.B?e.B(f,g,l,null):e.call(null,f,g,l,null)}function c(f,g){return e.j?e.j(f,-1,g):e.call(null,f,-1,g)}function d(f){var g=$CLJS.JZ(f,-1);return e.h?e.h(f,g):e.call(null,f,g)}var e=null;e=function(f,g,l,n){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,g);case 3:return b.call(this,f,g,l);case 4:return a.call(this,f,g,l,n)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.h=c;e.j=b;e.B=a;return e}();module.exports={display_name:$CLJS.MZ};