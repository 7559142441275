var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var g1,W4,X4,Y4,Xla;g1=function(a,b,c){var d=$CLJS.JZ(a,b);d=$CLJS.Of(d);d=$CLJS.K.h(d,$CLJS.dH);d=$CLJS.uU($CLJS.gm.j($CLJS.bi([c]),$CLJS.eG,$CLJS.Vd),d);if(!$CLJS.m(d))throw $CLJS.nj($CLJS.ZF("No aggregation with uuid {0}",$CLJS.F([c])),new $CLJS.k(null,3,[$CLJS.As,c,$CLJS.hN,a,$CLJS.BX,b],null));return d};$CLJS.V4=function(a){return $CLJS.Y2($CLJS.BH,new $CLJS.Q(null,1,5,$CLJS.R,[a],null))};$CLJS.Wla=new $CLJS.M(null,"aggregation-index","aggregation-index",-1057045587);
W4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);X4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);Y4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Xla=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.m_.o(null,$CLJS.dH,function(a,b){var c=$CLJS.Pf($CLJS.dH.g($CLJS.JZ(a,b)));return $CLJS.m(c)?$CLJS.L_($CLJS.$F("and"),function(){return function f(e){return new $CLJS.uf(null,function(){for(;;){var g=$CLJS.z(e);if(g){if($CLJS.me(g)){var l=$CLJS.Wc(g),n=$CLJS.D(l),q=$CLJS.xf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.Yd(l,u);v=$CLJS.MZ.B(a,b,v,$CLJS.U0);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Af($CLJS.Ef(q),f($CLJS.Xc(g))):$CLJS.Af($CLJS.Ef(q),null)}q=$CLJS.A(g);return $CLJS.kf($CLJS.MZ.B(a,
b,q,$CLJS.U0),f($CLJS.vd(g)))}return null}},null,null)}(c)}()):null});$CLJS.KZ.o(null,$CLJS.dH,function(a,b,c){$CLJS.G(c,0,null);var d=$CLJS.G(c,1,null),e=$CLJS.Of(d);d=$CLJS.K.h(e,$CLJS.JB);e=$CLJS.K.h(e,$CLJS.Bf);c=$CLJS.G(c,2,null);c=g1(a,b,c);return $CLJS.xm.l($CLJS.F([$CLJS.T0.j(a,b,c),new $CLJS.k(null,2,[$CLJS.xL,$CLJS.QL,$CLJS.s1,$CLJS.eG.g($CLJS.Vd(c))],null),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.JB,d],null):null,$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.Bf,e],null):null]))});
$CLJS.j_.o(null,$CLJS.dH,function(a,b,c,d){$CLJS.G(c,0,null);$CLJS.G(c,1,null);c=$CLJS.G(c,2,null);return $CLJS.MZ.B(a,b,g1(a,b,c),d)});$CLJS.mT(X4,Y4);
for(var Z4=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Aw,$CLJS.pH],null)),$4=null,a5=0,b5=0;;)if(b5<a5){var Yla=$4.ha(null,b5);$CLJS.mT(Yla,X4);b5+=1}else{var c5=$CLJS.z(Z4);if(c5){var d5=c5;if($CLJS.me(d5)){var e5=$CLJS.Wc(d5),Zla=$CLJS.Xc(d5),bma=e5,cma=$CLJS.D(e5);Z4=Zla;$4=bma;a5=cma}else{var dma=$CLJS.A(d5);$CLJS.mT(dma,X4);Z4=$CLJS.B(d5);$4=null;a5=0}b5=0}else break}
$CLJS.j_.o(null,X4,function(a,b,c,d){var e=$CLJS.G(c,0,null);$CLJS.G(c,1,null);c=$CLJS.G(c,2,null);if($CLJS.m(c))switch(a=$CLJS.MZ.B(a,b,c,d),e=e instanceof $CLJS.M?e.R:null,e){case "count":return $CLJS.ZF("Count of {0}",$CLJS.F([a]));case "cum-count":return $CLJS.ZF("Cumulative count of {0}",$CLJS.F([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.R:null,e){case "count":return $CLJS.$F("Count");case "cum-count":return $CLJS.$F("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.k_.o(null,X4,function(a,b,c){a=$CLJS.G(c,0,null);a=a instanceof $CLJS.M?a.R:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}});$CLJS.KZ.o(null,X4,function(a,b,c){var d=$CLJS.T.j,e=$CLJS.fj($CLJS.KZ,Y4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.T,a,$CLJS.Lj,$CLJS.zD)});$CLJS.j_.o(null,$CLJS.XG,function(){return $CLJS.$F("Case")});
$CLJS.k_.o(null,$CLJS.XG,function(){return"case"});$CLJS.mT(W4,Y4);for(var f5=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.iH,$CLJS.LH,$CLJS.TG,$CLJS.xl,$CLJS.CH,$CLJS.bk,$CLJS.EH,$CLJS.BH,$CLJS.UG],null)),g5=null,h5=0,i5=0;;)if(i5<h5){var ema=g5.ha(null,i5);$CLJS.mT(ema,W4);i5+=1}else{var j5=$CLJS.z(f5);if(j5){var k5=j5;if($CLJS.me(k5)){var l5=$CLJS.Wc(k5),fma=$CLJS.Xc(k5),hma=l5,ima=$CLJS.D(l5);f5=fma;g5=hma;h5=ima}else{var jma=$CLJS.A(k5);$CLJS.mT(jma,W4);f5=$CLJS.B(k5);g5=null;h5=0}i5=0}else break}
$CLJS.k_.o(null,W4,function(a,b,c){a=$CLJS.G(c,0,null);$CLJS.G(c,1,null);$CLJS.G(c,2,null);c=a instanceof $CLJS.M?a.R:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}});
$CLJS.j_.o(null,W4,function(a,b,c,d){var e=$CLJS.G(c,0,null);$CLJS.G(c,1,null);c=$CLJS.G(c,2,null);a=$CLJS.MZ.B(a,b,c,d);e=e instanceof $CLJS.M?e.R:null;switch(e){case "avg":return $CLJS.ZF("Average of {0}",$CLJS.F([a]));case "cum-sum":return $CLJS.ZF("Cumulative sum of {0}",$CLJS.F([a]));case "distinct":return $CLJS.ZF("Distinct values of {0}",$CLJS.F([a]));case "max":return $CLJS.ZF("Max of {0}",$CLJS.F([a]));case "median":return $CLJS.ZF("Median of {0}",$CLJS.F([a]));case "min":return $CLJS.ZF("Min of {0}",
$CLJS.F([a]));case "stddev":return $CLJS.ZF("Standard deviation of {0}",$CLJS.F([a]));case "sum":return $CLJS.ZF("Sum of {0}",$CLJS.F([a]));case "var":return $CLJS.ZF("Variance of {0}",$CLJS.F([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});$CLJS.j_.o(null,$CLJS.$G,function(a,b,c,d){$CLJS.G(c,0,null);$CLJS.G(c,1,null);var e=$CLJS.G(c,2,null);c=$CLJS.G(c,3,null);return $CLJS.ZF("{0}th percentile of {1}",$CLJS.F([c,$CLJS.MZ.B(a,b,e,d)]))});$CLJS.k_.o(null,$CLJS.$G,function(){return"percentile"});
$CLJS.mT($CLJS.$G,Y4);$CLJS.j_.o(null,$CLJS.JH,function(a,b,c,d){$CLJS.G(c,0,null);$CLJS.G(c,1,null);var e=$CLJS.G(c,2,null);$CLJS.G(c,3,null);return $CLJS.ZF("Sum of {0} matching condition",$CLJS.F([$CLJS.MZ.B(a,b,e,d)]))});$CLJS.k_.o(null,$CLJS.JH,function(a,b,c){$CLJS.G(c,0,null);$CLJS.G(c,1,null);var d=$CLJS.G(c,2,null);$CLJS.G(c,3,null);return["sum_where_",$CLJS.p.g($CLJS.l_.j(a,b,d))].join("")});$CLJS.mT($CLJS.JH,Y4);$CLJS.j_.o(null,$CLJS.vH,function(){return $CLJS.$F("Share of rows matching condition")});
$CLJS.k_.o(null,$CLJS.vH,function(){return"share"});$CLJS.mT($CLJS.vH,Y4);$CLJS.j_.o(null,$CLJS.xH,function(){return $CLJS.$F("Count of rows matching condition")});$CLJS.k_.o(null,$CLJS.xH,function(){return"count-where"});$CLJS.mT($CLJS.xH,Y4);
$CLJS.KZ.o(null,Y4,function(a,b,c){$CLJS.G(c,0,null);$CLJS.G(c,1,null);var d=$CLJS.G(c,2,null),e=$CLJS.xm.l;d=$CLJS.m(d)?$CLJS.Zh($CLJS.T0.j(a,b,d),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.JL],null)):null;var f=$CLJS.fj($CLJS.KZ,$CLJS.Dj);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.xm,$CLJS.F([d,a]))});
$CLJS.nma=function(){function a(d){return $CLJS.Y2($CLJS.Aw,new $CLJS.Q(null,1,5,$CLJS.R,[d],null))}function b(){return $CLJS.Y2($CLJS.Aw,$CLJS.Mg)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.g=a;return c}();$CLJS.cU.o(null,$CLJS.dH,function(a){return a});
$CLJS.T8=function(){function a(d,e,f){for(;;)if($CLJS.E.h($CLJS.IF(f),$CLJS.BL))f=$CLJS.eU(f);else return $CLJS.t8(d,e,$CLJS.dH,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.Q8=function(){function a(d,e){return $CLJS.Pf($CLJS.dH.g($CLJS.JZ(d,e)))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.T5=function(){function a(d,e){var f=$CLJS.Pf($CLJS.dH.g($CLJS.JZ(d,e)));return null==f?null:$CLJS.mh.j($CLJS.Mg,$CLJS.kg.g(function(g){var l=$CLJS.T0.j(d,e,g),n=$CLJS.T.l,q=$CLJS.JB.g(l);return n.call($CLJS.T,$CLJS.F5(l,$CLJS.Bf,$CLJS.m(q)?q:$CLJS.Aj),$CLJS.xL,$CLJS.QL,$CLJS.F([$CLJS.s1,$CLJS.eG.g($CLJS.Vd(g))]))}),f)}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.h=a;return c}();$CLJS.j_.o(null,$CLJS.XH,function(a,b,c){a=$CLJS.Of(c);a=$CLJS.K.h(a,$CLJS.TH);return $CLJS.vF.g(a.v?a.v():a.call(null))});$CLJS.p_.o(null,$CLJS.XH,function(a,b,c){var d=$CLJS.Of(c);a=$CLJS.K.h(d,$CLJS.hH);b=$CLJS.K.h(d,$CLJS.TH);c=$CLJS.K.h(d,$CLJS.SH);d=$CLJS.K.h(d,$CLJS.u_);a=$CLJS.T.l(b.v?b.v():b.call(null),$CLJS.r1,$CLJS.AE(a),$CLJS.F([$CLJS.p1,c]));return null!=d?$CLJS.T.j(a,$CLJS.d1,d):a});
$CLJS.Y8=function(){function a(d,e){var f=function(){var n=$CLJS.Rz.g($CLJS.yZ($CLJS.DZ(d)));return $CLJS.m(n)?n:$CLJS.ai}(),g=$CLJS.JZ(d,e),l=$CLJS.w1.j(d,e,g);return $CLJS.Pf($CLJS.mh.j($CLJS.Mg,$CLJS.gm.j($CLJS.ug(function(n){n=$CLJS.VH.g(n);var q=null==n;return q?q:f.g?f.g(n):f.call(null,n)}),$CLJS.Nm(function(n){n=$CLJS.Of(n);var q=$CLJS.K.h(n,$CLJS.SH),u=$CLJS.K.h(n,$CLJS.QH);if($CLJS.Bb(q))return n;if($CLJS.E.h(u,$CLJS.Fs))return $CLJS.T.j(n,$CLJS.wX,l);q=$CLJS.Pf($CLJS.OX(function(v){return $CLJS.N_(u,
v)},l));return $CLJS.m(q)?$CLJS.T.j(n,$CLJS.wX,q):null}),$CLJS.kg.g(function(n){return $CLJS.T.j(n,$CLJS.su,$CLJS.XH)})),$CLJS.tU))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.B8=function(){function a(d,e){return $CLJS.gU(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hH.g(d),$CLJS.O,$CLJS.iU.g(e)],null))}function b(d){if($CLJS.Bb($CLJS.SH.g(d)))return $CLJS.gU(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hH.g(d),$CLJS.O],null));var e=$CLJS.hH.g(d);e=$CLJS.va.h?$CLJS.va.h("aggregation operator %s requires an argument",e):$CLJS.va.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.nj(e,new $CLJS.k(null,1,[Xla,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();