var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var XD=function(){},aE=function(a){return $CLJS.K.h($CLJS.NC,a).value},Tga=function(a){var b=aE(a);return $CLJS.m(function(){var c=aE($CLJS.Cx);return $CLJS.dm.h?$CLJS.dm.h(c,b):$CLJS.dm.call(null,c,b)}())?$CLJS.gy:$CLJS.m(function(){var c=aE($CLJS.Bx);return $CLJS.dm.h?$CLJS.dm.h(c,b):$CLJS.dm.call(null,c,b)}())?$CLJS.vy:$CLJS.m(function(){var c=aE($CLJS.zx);return $CLJS.dm.h?$CLJS.dm.h(c,b):$CLJS.dm.call(null,c,b)}())?$CLJS.my:$CLJS.m(function(){var c=aE($CLJS.ox);return $CLJS.dm.h?
$CLJS.dm.h(c,b):$CLJS.dm.call(null,c,b)}())?$CLJS.uy:$CLJS.m(function(){var c=aE($CLJS.Ax);return $CLJS.dm.h?$CLJS.dm.h(c,b):$CLJS.dm.call(null,c,b)}())?$CLJS.wy:$CLJS.m(function(){var c=aE($CLJS.vx);return $CLJS.dm.h?$CLJS.dm.h(c,b):$CLJS.dm.call(null,c,b)}())?$CLJS.py:$CLJS.m(function(){var c=aE($CLJS.jx);return $CLJS.dm.h?$CLJS.dm.h(c,b):$CLJS.dm.call(null,c,b)}())?$CLJS.sy:$CLJS.ty},YD=function(a){var b=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.g(b),$CLJS.p.g(" ")].join(""),
a],null)},$D=function(a,b,c){var d=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.g(d),"%c",$CLJS.p.g(b),"%c"].join(""),$CLJS.gf.l(a,["color:",$CLJS.p.g($CLJS.K.h($CLJS.WD,c))].join(""),$CLJS.F(["color:black"]))],null)},ZD=function(a,b,c){var d=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.g(d),"%c",$CLJS.p.g(b),"%c"].join(""),$CLJS.gf.l(a,["color:",$CLJS.p.g($CLJS.K.h($CLJS.WD,$CLJS.xy)),";background-color:",$CLJS.p.g($CLJS.K.h($CLJS.WD,
c))].join(""),$CLJS.F(["color:black;background-color:inherit"]))],null)},Uga=function(a){function b(c,d){return d>=c}a=aE(a);if(b(aE($CLJS.Cx),a))return"error";if(b(aE($CLJS.Bx),a))return"warn";if(b(aE($CLJS.zx),a))return"info";b(aE($CLJS.ox),a);return"log"},bE=function(a){return function(b){var c=$CLJS.Of(b),d=$CLJS.K.h(c,$CLJS.kx),e=$CLJS.K.h(c,$CLJS.bu);b=$CLJS.K.h(c,$CLJS.Dx);e=Uga(e);e=$CLJS.Ra(console,e);e=$CLJS.m(e)?e:console.log;$CLJS.P.h(e,a.g?a.g(c):a.call(null,c));return $CLJS.m(b)?(c=
["[",$CLJS.p.g(d),"]"].join(""),d=$CLJS.p.g(b),b=b.stack,e.B?e.B(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},cE=function cE(a,b){for(;;){if($CLJS.E.h($CLJS.fy,b))return $D(a,", ",$CLJS.ty);if($CLJS.E.h($CLJS.ky,b))return YD(a);if(b instanceof $CLJS.M)return $D(a,b,$CLJS.my);if(b instanceof $CLJS.w)return $D(a,b,$CLJS.uy);if("string"===typeof b)return $D(a,$CLJS.Oi.l($CLJS.F([b])),$CLJS.ly);if($CLJS.Jg(b)){var d=YD(function(){var f=a,g=$CLJS.qc(b);return cE.h?cE.h(f,g):cE.call(null,f,g)}()),e=$CLJS.rc(b);
return cE.h?cE.h(d,e):cE.call(null,d,e)}if(b instanceof $CLJS.k||b instanceof $CLJS.Ph)return d=a,d=$D(d,"{",$CLJS.Ky),d=$CLJS.Ob(cE,d,$CLJS.Ws($CLJS.fy,b)),$D(d,"}",$CLJS.Ky);if($CLJS.je(b))return d=a,d=$D(d,["#",$CLJS.p.g(function(){var f=$CLJS.Kb(b),g=f.name;return $CLJS.ee(g)?$CLJS.Oi.l($CLJS.F([f])):g}())," "].join(""),$CLJS.ny),d=$D(d,"{",$CLJS.Ky),d=$CLJS.Ob(cE,d,$CLJS.Ws($CLJS.fy,b)),$D(d,"}",$CLJS.Ky);if($CLJS.ge(b))return d=a,d=$D(d,"#{",$CLJS.Ky),d=$CLJS.Ob(cE,d,$CLJS.Ws($CLJS.ky,b)),$D(d,
"}",$CLJS.Ky);if($CLJS.le(b))return d=a,d=$D(d,"[",$CLJS.Ky),d=$CLJS.Ob(cE,d,$CLJS.Ws($CLJS.ky,b)),$D(d,"]",$CLJS.Ky);if(b instanceof $CLJS.pa)d=$D(a,"#queue ",$CLJS.ny),e=$CLJS.mh.h($CLJS.Mg,b),a=d,b=e;else{if($CLJS.re(b))return d=a,d=$D(d,"(",$CLJS.ny),d=$CLJS.Ob(cE,d,$CLJS.Ws($CLJS.ky,b)),$D(d,")",$CLJS.ny);if(null!=b?b.J&16384||$CLJS.t===b.Nj||(b.J?0:$CLJS.Jb(XD,b)):$CLJS.Jb(XD,b))d=$D(a,"#atom ",$CLJS.ny),e=$CLJS.r(b),a=d,b=e;else if($CLJS.kj(b))d=$D(a,"#uuid ",$CLJS.ny),e=$CLJS.p.g(b),a=d,b=
e;else if($CLJS.Fb(b))d=$D(a,"#js ",$CLJS.ny),e=$CLJS.Ob(function(f,g){return function(l,n){return $CLJS.T.j(l,$CLJS.zi.g(n),$CLJS.Ra(g,n))}}(a,b),$CLJS.O,Object.keys(b)),a=d,b=e;else if($CLJS.zb(b))d=$D(a,"#js ",$CLJS.ny),e=$CLJS.mh.h($CLJS.Mg,b),a=d,b=e;else return $D(a,$CLJS.Oi.l($CLJS.F([b])),$CLJS.oy)}}};$CLJS.Vga=bE(function(a){a=$CLJS.Of(a);$CLJS.K.h(a,$CLJS.bu);var b=$CLJS.K.h(a,$CLJS.kx),c=$CLJS.K.h(a,$CLJS.ik);$CLJS.K.h(a,$CLJS.Dx);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.g(b),"]"].join(""),c],null)});
$CLJS.Wga=bE(function(a){var b=$CLJS.Of(a),c=$CLJS.K.h(b,$CLJS.bu);a=$CLJS.K.h(b,$CLJS.kx);var d=$CLJS.K.h(b,$CLJS.ik);$CLJS.K.h(b,$CLJS.Dx);b=Tga(c);d=cE(YD(ZD(ZD(ZD(new $CLJS.Q(null,2,5,$CLJS.R,["",$CLJS.Mg],null),"[",b),a,b),"]",b)),d);a=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);return $CLJS.kf(a,d)});
$CLJS.Xga=bE(function(a){a=$CLJS.Of(a);$CLJS.K.h(a,$CLJS.bu);var b=$CLJS.K.h(a,$CLJS.kx),c=$CLJS.K.h(a,$CLJS.ik);$CLJS.K.h(a,$CLJS.Dx);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.p.g(b),"]"].join(""),$CLJS.Oi.l($CLJS.F([c]))],null)});