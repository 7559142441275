var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var fna=function(a){return function(b){var c=$CLJS.ig(-1);return function(){function d(l,n){var q=c.le(null,c.fc(null)+1);n=a.h?a.h(q,n):a.call(null,q,n);return null==n?l:b.h?b.h(l,n):b.call(null,l,n)}function e(l){return b.g?b.g(l):b.call(null,l)}function f(){return b.v?b.v():b.call(null)}var g=null;g=function(l,n){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,n)}throw Error("Invalid arity: "+arguments.length);};g.v=f;g.g=
e;g.h=d;return g}()}},Fva=function(a){a=$CLJS.Of(a);var b=$CLJS.K.h(a,$CLJS.JB);return $CLJS.Vf(function(c){return $CLJS.XF(b,c)},$CLJS.VJ)},a7=new $CLJS.M("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.mT($CLJS.lH,a7);$CLJS.mT($CLJS.eH,a7);
$CLJS.m_.o(null,$CLJS.xV,function(a,b){var c=$CLJS.Pf($CLJS.xV.g($CLJS.JZ(a,b)));return $CLJS.m(c)?$CLJS.ZF("Sorted by {0}",$CLJS.F([$CLJS.L_($CLJS.$F("and"),function(){return function f(e){return new $CLJS.uf(null,function(){for(;;){var g=$CLJS.z(e);if(g){if($CLJS.me(g)){var l=$CLJS.Wc(g),n=$CLJS.D(l),q=$CLJS.xf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.Yd(l,u);v=$CLJS.MZ.B(a,b,v,$CLJS.U0);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Af($CLJS.Ef(q),f($CLJS.Xc(g))):$CLJS.Af($CLJS.Ef(q),null)}q=$CLJS.A(g);
return $CLJS.kf($CLJS.MZ.B(a,b,q,$CLJS.U0),f($CLJS.vd(g)))}return null}},null,null)}(c)}())])):null});$CLJS.j_.o(null,a7,function(a,b,c,d){var e=$CLJS.G(c,0,null);$CLJS.G(c,1,null);c=$CLJS.G(c,2,null);a=$CLJS.MZ.B(a,b,c,d);e=e instanceof $CLJS.M?e.R:null;switch(e){case "asc":return $CLJS.ZF("{0} ascending",$CLJS.F([a]));case "desc":return $CLJS.ZF("{0} descending",$CLJS.F([a]));default:throw Error(["No matching clause: ",$CLJS.p.g(e)].join(""));}});
$CLJS.p_.o(null,a7,function(a,b,c){var d=$CLJS.G(c,0,null);$CLJS.G(c,1,null);c=$CLJS.G(c,2,null);return $CLJS.T.j($CLJS.PZ.j(a,b,c),$CLJS.i1,d)});var b7,Bqa=$CLJS.ka($CLJS.O),Cqa=$CLJS.ka($CLJS.O),Dqa=$CLJS.ka($CLJS.O),Eqa=$CLJS.ka($CLJS.O),Fqa=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));b7=new $CLJS.hj($CLJS.Fi.h("metabase.lib.order-by","order-by-clause-method"),$CLJS.IF,Fqa,Bqa,Cqa,Dqa,Eqa);b7.o(null,a7,function(a){return $CLJS.gU(a)});
b7.o(null,$CLJS.Dj,function(a){if(null==a)throw $CLJS.nj($CLJS.$F("Can''t order by nil"),$CLJS.O);return $CLJS.gU(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lH,$CLJS.eU(a)],null))});
$CLJS.D8=function(){function a(d,e){d=b7.g(d);e=$CLJS.m(e)?e:$CLJS.lH;return $CLJS.T.j($CLJS.Ng(d),0,e)}function b(d){return c.h?c.h(d,$CLJS.lH):c.call(null,d,$CLJS.lH)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.F8=function(){function a(e,f,g,l){f=$CLJS.m(f)?f:-1;var n=function(){var q=b7.g(g);return $CLJS.m(l)?$CLJS.T.j($CLJS.Ng(q),0,l):q}();return $CLJS.R3.l(e,f,$CLJS.nm,$CLJS.F([$CLJS.xV,function(q){return $CLJS.gf.h($CLJS.Ng(q),n)}]))}function b(e,f,g){return d.B?d.B(e,-1,f,g):d.call(null,e,-1,f,g)}function c(e,f){return d.B?d.B(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,g,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,g);case 4:return a.call(this,
e,f,g,l)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.j=b;d.B=a;return d}();$CLJS.H8=function(){function a(d,e){return $CLJS.Pf($CLJS.K.h($CLJS.JZ(d,e),$CLJS.xV))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.P8=function(){function a(d,e){var f=$CLJS.Pf($CLJS.P5(d,e)),g=$CLJS.Pf($CLJS.T5.h(d,e));f=$CLJS.m($CLJS.m(f)?f:g)?$CLJS.sg.h(f,g):function(){var q=$CLJS.JZ(d,e);return $CLJS.w1.B(d,e,q,new $CLJS.k(null,1,[$CLJS.q1,!1],null))}();var l=$CLJS.vg(Fva,f);f=$CLJS.kg.h(function(q){$CLJS.G(q,0,null);$CLJS.G(q,1,null);return $CLJS.G(q,2,null)},$CLJS.H8.h(d,e));if($CLJS.ee(l))return null;if($CLJS.ee(f))return $CLJS.Ng(l);var n=$CLJS.mh.j($CLJS.O,$CLJS.gm.h($CLJS.kg.g($CLJS.eU),fna(function(q,u){u=$CLJS.D2.B(d,
e,u,l);return $CLJS.m(u)?new $CLJS.Q(null,2,5,$CLJS.R,[u,q],null):null})),f);return $CLJS.mm.h(function(q){var u=n.g?n.g(q):n.call(null,q);return $CLJS.m(u)?$CLJS.T.j(q,$CLJS.h1,u):q},l)}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();$CLJS.D5=new $CLJS.k(null,2,[$CLJS.lH,$CLJS.eH,$CLJS.eH,$CLJS.lH],null);