var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var Ly,Ny,Oy,Ns,Ps,Py,Ry,Uy,Vy,Wy,Xy,Yy,Rea,az,Sea,zu,ez,fz,gz,hz,iz,jz,kz,pz,sz,uz,xz,Tea,Uea,Cz,Vea,Fz,Gz,Hz,Nz,Su,Wz,$z,fA,hA,mA,qA,vA,xA,yA,sA,zA,AA,CA,BA,DA,Zea,IA,RA,SA,LA,afa,WA,ZA,aB,bB,cfa,dfa,efa,fB,jB,kB,lB,mB,ffa,qB,tB,gfa,hfa,ifa,jfa,kfa,lfa,mfa,nfa,ofa,qfa,zB,pfa,Ov,rfa,Pv,sfa,tfa,Qv,ufa,Rv,vfa,wfa,xfa,zfa,Afa,Cfa,Dfa,Ffa,Hfa,LB,PB,Jfa,Ifa,Kfa,rA,uA,WB,XB,YB,ZB,bC,cC,Nfa,Ofa,Pfa,fC,Qfa,gC,eC,hC,Du,fw,Mu,Fv,gw,dv,hw,iw,Eu,Ku,dw,jw,av,nv,Nu,kw,lw,Zu,mw,Wv,Ru,mv,Xu,Hv,nw,Lu,
ow,Nv,Uv,pw,qw,uv,sw,wv,$u,lv,Cu,Tv,Wu,cv,Uu,Gu,tw,jv,uw,qv,vv,Zv,vw,$v,Vu,ww,xw,yw,gv,zw,Lv,fv,Bw,rv,pv,Cw,Hu,Dw,cw,aw,Ou,Ew,yv,Fw,Gw,sv,Hw,Qu,Iw,bw,Jw,iv,Kv,kv,Kw,zv,ew,Mw,Jv,Nw,ov,Ju,Ow,bv,Pw,xv,Qw,Fu,Sv,ev,Tu,Vv,Iu,Av,Xv,Ev,Bv,Dv,Mv,tv,Rw,Sw,Tw,Au,Uw,yu,Bu,Vw,Pu,Ww;Ly=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.My=function(){};
Ny=function(a){if(null!=a&&null!=a.Mc)a=a.Mc(a);else{var b=Ny[$CLJS.Ka(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Ny._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Lb("IWriter.-flush",a);}return a};Oy=function(){};Ns=function(a){if(null!=a&&null!=a.Lc)a=a.Lc(a);else{var b=Ns[$CLJS.Ka(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Ns._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Lb("IPending.-realized?",a);}return a};
Ps=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};Py=function(a,b){return a-b*$CLJS.Ye(a,b)};Ry=function(a,b,c){if(null!=a&&null!=a.o)a.o(a,b,c);else{var d=Ry[$CLJS.Ka(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=Ry._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.Lb("IMultiFn.-add-method",a);}};
$CLJS.Ts=function(a,b){var c=$CLJS.Pc($CLJS.O);a=$CLJS.z(a);for(b=$CLJS.z(b);;)if(a&&b)c=$CLJS.ph.j(c,$CLJS.A(a),$CLJS.A(b)),a=$CLJS.B(a),b=$CLJS.B(b);else return $CLJS.Rc(c)};$CLJS.wu=function(a,b){return $CLJS.li(a,b)};$CLJS.gi=function(a,b){return new $CLJS.uf(null,function(){var c=$CLJS.z(b);if(c){var d=$CLJS.A(c);d=a.g?a.g(d):a.call(null,d);c=$CLJS.m(d)?$CLJS.kf($CLJS.A(c),$CLJS.gi(a,$CLJS.vd(c))):null}else c=null;return c},null,null)};
$CLJS.lg=function(a,b){return $CLJS.kg.j(function(c){return c},b,$CLJS.jg(a,b))};Uy=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.P.h(Sy,0<b.length?new $CLJS.rd(b.slice(0),0,null):null);$CLJS.Mc($CLJS.Ty,"\n")};Vy=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
Wy=function(a,b,c){var d=c;for(c=$CLJS.Mg;;){if($CLJS.ee(d))return new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null);var e=$CLJS.A(d);d=$CLJS.B(d);e=$CLJS.P.h(a,new $CLJS.Q(null,2,5,$CLJS.R,[e,b],null));b=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);c=$CLJS.gf.h(c,b);b=e}};Xy=function(a,b){var c=b;for(b=$CLJS.Mg;;){var d=$CLJS.P.h(a,new $CLJS.Q(null,1,5,$CLJS.R,[c],null));c=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);if($CLJS.Bb(c))return new $CLJS.Q(null,2,5,$CLJS.R,[b,d],null);b=$CLJS.gf.h(b,c);c=d}};
Yy=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mh.h($CLJS.O,function(){return function d(c){return new $CLJS.uf(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.me(e)){var f=$CLJS.Wc(e),g=$CLJS.D(f),l=$CLJS.xf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.Yd(f,n),u=$CLJS.G(q,0,null);q=$CLJS.G(q,1,null);var v=$CLJS.G(q,0,null);$CLJS.G(q,1,null);l.add(new $CLJS.Q(null,2,5,$CLJS.R,[u,v],null));n+=1}else{f=!0;break a}return f?$CLJS.Af($CLJS.Ef(l),d($CLJS.Xc(e))):$CLJS.Af($CLJS.Ef(l),null)}f=
$CLJS.A(e);l=$CLJS.G(f,0,null);f=$CLJS.G(f,1,null);g=$CLJS.G(f,0,null);$CLJS.G(f,1,null);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[l,g],null),d($CLJS.vd(e)))}return null}},null,null)}(a)}()),$CLJS.mh.h($CLJS.O,function(){return function d(c){return new $CLJS.uf(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.me(e)){var f=$CLJS.Wc(e),g=$CLJS.D(f),l=$CLJS.xf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.Yd(f,n),u=$CLJS.G(q,0,null);q=$CLJS.G(q,1,null);$CLJS.G(q,0,null);q=$CLJS.G(q,1,null);l.add(new $CLJS.Q(null,
2,5,$CLJS.R,[u,q],null));n+=1}else{f=!0;break a}return f?$CLJS.Af($CLJS.Ef(l),d($CLJS.Xc(e))):$CLJS.Af($CLJS.Ef(l),null)}f=$CLJS.A(e);l=$CLJS.G(f,0,null);f=$CLJS.G(f,1,null);$CLJS.G(f,0,null);f=$CLJS.G(f,1,null);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[l,f],null),d($CLJS.vd(e)))}return null}},null,null)}(a)}())],null)};
Rea=function(a,b){return $CLJS.mh.h($CLJS.O,function(){return function e(d){return new $CLJS.uf(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.me(f)){var g=$CLJS.Wc(f),l=$CLJS.D(g),n=$CLJS.xf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.Yd(g,q),v=$CLJS.G(u,0,null);u=$CLJS.G(u,1,null);n.add(new $CLJS.Q(null,2,5,$CLJS.R,[v,new $CLJS.Q(null,2,5,$CLJS.R,[u,b],null)],null));q+=1}else{g=!0;break a}return g?$CLJS.Af($CLJS.Ef(n),e($CLJS.Xc(f))):$CLJS.Af($CLJS.Ef(n),null)}g=$CLJS.A(f);n=$CLJS.G(g,0,null);
g=$CLJS.G(g,1,null);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[n,new $CLJS.Q(null,2,5,$CLJS.R,[g,b],null)],null),e($CLJS.vd(f)))}return null}},null,null)}(a)}())};$CLJS.Zy=function(){var a=$CLJS.Ty;if(null!=a&&null!=a.Xf)a.Xf(a);else{var b=$CLJS.Zy[$CLJS.Ka(null==a?null:a)];if(null!=b)b.g?b.g(a):b.call(null,a);else if(b=$CLJS.Zy._,null!=b)b.g?b.g(a):b.call(null,a);else throw $CLJS.Lb("IPrettyFlush.-ppflush",a);}};$CLJS.$y=function(a,b){a=$CLJS.r($CLJS.r(a));return b.g?b.g(a):b.call(null,a)};
az=function(a,b,c){$CLJS.Ri.B($CLJS.r(a),$CLJS.T,b,c)};Sea=function(a,b){$CLJS.E.h(b,"\n")?(az(a,$CLJS.xu,0),az(a,$CLJS.rj,$CLJS.$y(a,$CLJS.rj)+1)):az(a,$CLJS.xu,$CLJS.$y(a,$CLJS.xu)+1);return $CLJS.Mc($CLJS.$y(a,yu),b)};zu=function(a,b,c,d){this.La=a;this.rg=b;this.wd=c;this.uf=d;this.C=1074167808;this.J=0};
ez=function(a,b,c,d,e,f,g,l,n,q,u,v,x){this.parent=a;this.Kb=b;this.Cb=c;this.zb=d;this.Eb=e;this.Fb=f;this.prefix=g;this.Jb=l;this.Lb=n;this.Gb=q;this.F=u;this.m=v;this.H=x;this.C=2230716170;this.J=139264};fz=function(a,b){for(b=Au.g(b);;){if(null==b)return!1;if(a===b)return!0;b=Au.g(b)}};gz=function(a,b,c,d,e,f,g,l){this.ea=a;this.data=b;this.uc=c;this.da=d;this.ca=e;this.F=f;this.m=g;this.H=l;this.C=2230716170;this.J=139264};
hz=function(a,b,c,d,e,f,g,l){this.ea=a;this.type=b;this.ka=c;this.da=d;this.ca=e;this.F=f;this.m=g;this.H=l;this.C=2230716170;this.J=139264};iz=function(a,b,c,d,e,f,g){this.ea=a;this.ka=b;this.da=c;this.ca=d;this.F=e;this.m=f;this.H=g;this.C=2230716170;this.J=139264};jz=function(a,b,c,d,e,f,g){this.ea=a;this.ka=b;this.da=c;this.ca=d;this.F=e;this.m=f;this.H=g;this.C=2230716170;this.J=139264};
kz=function(a,b,c,d,e,f,g,l,n){this.ea=a;this.ka=b;this.jc=c;this.offset=d;this.da=e;this.ca=f;this.F=g;this.m=l;this.H=n;this.C=2230716170;this.J=139264};
pz=function(a,b,c){b=$CLJS.z(b);for(var d=null,e=0,f=0;;)if(f<e){var g=d.ha(null,f);if(!$CLJS.E.h(Bu.g(g),Cu)){var l=Du.g($CLJS.r($CLJS.r(a)));$CLJS.m(l)&&$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),l)}oz.h(a,g);$CLJS.Ri.B($CLJS.r(a),$CLJS.T,Du,Du.g(g));g=Du.g($CLJS.r($CLJS.r(a)));$CLJS.m($CLJS.m(c)?g:c)&&($CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),g),$CLJS.Ri.B($CLJS.r(a),$CLJS.T,Du,null));f+=1}else if(b=$CLJS.z(b))$CLJS.me(b)?(d=$CLJS.Wc(b),b=$CLJS.Xc(b),g=d,e=$CLJS.D(d),d=g):(g=$CLJS.A(b),$CLJS.E.h(Bu.g(g),Cu)||
(d=Du.g($CLJS.r($CLJS.r(a))),$CLJS.m(d)&&$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),d)),oz.h(a,g),$CLJS.Ri.B($CLJS.r(a),$CLJS.T,Du,Du.g(g)),g=Du.g($CLJS.r($CLJS.r(a))),$CLJS.m($CLJS.m(c)?g:c)&&($CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),g),$CLJS.Ri.B($CLJS.r(a),$CLJS.T,Du,null)),b=$CLJS.B(b),d=null,e=0),f=0;else break};sz=function(a,b){var c=$CLJS.$y(yu.g($CLJS.r($CLJS.r(a))),$CLJS.xl),d;(d=null==c)||(a=$CLJS.$y(yu.g($CLJS.r($CLJS.r(a))),$CLJS.xu),b=(b=$CLJS.z(b))?Eu.g($CLJS.Wd(b))-Fu.g($CLJS.A(b)):0,d=a+b<c);return d};
uz=function(a,b,c){b=$CLJS.r(Gu.g(b));return $CLJS.m(b)?b:!sz(a,c)};xz=function(a,b,c){var d=Hu.g($CLJS.r($CLJS.r(a))),e=$CLJS.$y(yu.g($CLJS.r($CLJS.r(a))),$CLJS.xl);return $CLJS.m(d)?$CLJS.m(e)?(d=$CLJS.r(Iu.g(b))>=e-d)?uz(a,b,c):d:e:d};Tea=function(a){var b=$CLJS.A(a),c=Ju.g(b);b=$CLJS.z($CLJS.gi(function(d){return!($CLJS.E.h(Bu.g(d),Cu)&&fz(Ju.g(d),c))},$CLJS.B(a)));return new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.z($CLJS.jg($CLJS.D(b)+1,a))],null)};
Uea=function(a){var b=$CLJS.A(a),c=Ju.g(b);return $CLJS.z($CLJS.gi(function(d){var e=Ju.g(d);return!($CLJS.E.h(Bu.g(d),Cu)&&($CLJS.E.h(e,c)||fz(e,c)))},$CLJS.B(a)))};
Cz=function(a,b){$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),"\n");$CLJS.Ri.B($CLJS.r(a),$CLJS.T,Du,null);b=Ju.g(b);var c=Ku.g(b);$CLJS.m(c)&&$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),c);c=$CLJS.P.h($CLJS.p,$CLJS.pg($CLJS.r(Lu.g(b))-$CLJS.D(c)," "));$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),c);a:for($CLJS.gg(Mu.g(b),!0),$CLJS.gg(Gu.g(b),!0),a=Au.g(b);;)if($CLJS.m(a))$CLJS.gg(Gu.g(a),!0),$CLJS.gg(Mu.g(a),!0),a=Au.g(a);else break a;return null};
Vea=function(a){var b=$CLJS.z($CLJS.gi(function(c){return!$CLJS.E.h(Bu.g(c),Cu)},a));return new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.z($CLJS.jg($CLJS.D(b),a))],null)};Fz=function(a){for(var b=$CLJS.Vt.g($CLJS.r($CLJS.r(a)));;){$CLJS.Ri.B($CLJS.r(a),$CLJS.T,$CLJS.Vt,$CLJS.mh.h($CLJS.Mg,b));if(sz(a,b))return null;var c=Ez(a,b);if(b!==c)b=c;else return null}};
Gz=function(a,b){$CLJS.Ri.B($CLJS.r(a),$CLJS.T,$CLJS.Vt,$CLJS.gf.h($CLJS.Vt.g($CLJS.r($CLJS.r(a))),b));return sz(a,$CLJS.Vt.g($CLJS.r($CLJS.r(a))))?null:Fz(a)};Hz=function(a){var b=Du.g($CLJS.r($CLJS.r(a)));return $CLJS.m(b)?($CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),b),$CLJS.Ri.B($CLJS.r(a),$CLJS.T,Du,null)):null};
Nz=function(a,b){var c=$CLJS.Zs(b,"\n",-1);if($CLJS.E.h($CLJS.D(c),1))return b;b=Ku.g($CLJS.A(Nu.g($CLJS.r($CLJS.r(a)))));var d=$CLJS.A(c);if($CLJS.E.h(Ou,$CLJS.Pj.g($CLJS.r($CLJS.r(a))))){var e=Pu.g($CLJS.r($CLJS.r(a))),f=e+$CLJS.D(d);$CLJS.Ri.B($CLJS.r(a),$CLJS.T,Pu,f);Gz(a,new gz(Qu,d,null,e,f,null,null,null));Fz(a);d=$CLJS.Vt.g($CLJS.r($CLJS.r(a)));$CLJS.m(d)&&(pz(a,d,!0),$CLJS.Ri.B($CLJS.r(a),$CLJS.T,$CLJS.Vt,$CLJS.Mg))}else Hz(a),$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),d);$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),
"\n");d=$CLJS.z($CLJS.B($CLJS.ei(c)));e=null;for(var g=f=0;;)if(g<f){var l=e.ha(null,g);$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),l);$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),"\n");$CLJS.m(b)&&$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),b);g+=1}else if(d=$CLJS.z(d))e=d,$CLJS.me(e)?(d=$CLJS.Wc(e),g=$CLJS.Xc(e),e=d,f=$CLJS.D(d),d=g):(d=$CLJS.A(e),$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),d),$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),"\n"),$CLJS.m(b)&&$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),b),d=$CLJS.B(e),e=null,f=0),g=0;else break;$CLJS.Ri.B($CLJS.r(a),
$CLJS.T,Ou,Ru);return $CLJS.Wd(c)};Su=function(a,b,c,d,e,f){this.La=a;this.rg=b;this.bj=c;this.Xh=d;this.wd=e;this.Hf=f;this.C=1074167808;this.J=0};
$CLJS.Sz=function(a){var b=$CLJS.Pz,c=Qz,d=new ez(null,null,$CLJS.ka(0),$CLJS.ka(0),$CLJS.ka(!1),$CLJS.ka(!1),null,null,null,null,null,null,null),e=$CLJS.ka($CLJS.Qh([Nu,Hu,Tu,Uu,Vu,$CLJS.Pj,Pu,Du,yu,Wu,$CLJS.Vt],[d,c,d,!0,null,Ru,0,null,new zu(a,b,$CLJS.ka(new $CLJS.k(null,4,[$CLJS.xl,b,$CLJS.xu,0,$CLJS.rj,0,yu,a],null)),$CLJS.O),1,$CLJS.Mg]));return new Su(a,b,c,d,e,$CLJS.O)};
Wz=function(a,b){var c=$CLJS.Ty;b=new ez(Nu.g($CLJS.r($CLJS.r(c))),null,$CLJS.ka(0),$CLJS.ka(0),$CLJS.ka(!1),$CLJS.ka(!1),a,null,b,null,null,null,null);$CLJS.Ri.B($CLJS.r(c),$CLJS.T,Nu,b);if($CLJS.E.h($CLJS.Pj.g($CLJS.r($CLJS.r(c))),Ru)){Hz(c);var d=Xu.g($CLJS.r($CLJS.r(c)));$CLJS.m(d)&&(d.g?d.g($CLJS.Yu):d.call(null,$CLJS.Yu));$CLJS.m(a)&&$CLJS.Mc(yu.g($CLJS.r($CLJS.r(c))),a);c=$CLJS.$y(yu.g($CLJS.r($CLJS.r(c))),$CLJS.xu);$CLJS.gg(b.Cb,c);$CLJS.gg(b.zb,c)}else d=Pu.g($CLJS.r($CLJS.r(c))),a=d+($CLJS.m(a)?
$CLJS.D(a):0),$CLJS.Ri.B($CLJS.r(c),$CLJS.T,Pu,a),Gz(c,new iz(Zu,b,d,a,null,null,null))};
$z=function(){var a=$CLJS.Ty,b=Nu.g($CLJS.r($CLJS.r(a))),c=$u.g(b);if($CLJS.E.h($CLJS.Pj.g($CLJS.r($CLJS.r(a))),Ru)){Hz(a);$CLJS.m(c)&&$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),c);var d=Xu.g($CLJS.r($CLJS.r(a)));$CLJS.m(d)&&(d.g?d.g(av):d.call(null,av))}else d=Pu.g($CLJS.r($CLJS.r(a))),c=d+($CLJS.m(c)?$CLJS.D(c):0),$CLJS.Ri.B($CLJS.r(a),$CLJS.T,Pu,c),Gz(a,new jz(bv,b,d,c,null,null,null));$CLJS.Ri.B($CLJS.r(a),$CLJS.T,Nu,Au.g(b))};
$CLJS.aA=function(a){var b=null!=a?a.C&32768||$CLJS.t===a.Of?!0:a.C?!1:$CLJS.Jb($CLJS.My,a):$CLJS.Jb($CLJS.My,a);return b?Uu.g($CLJS.r($CLJS.r(a))):b};$CLJS.eA=function(a){var b=bA;$CLJS.m(b)&&(b=$CLJS.ub,b=$CLJS.m(b)?bA>=$CLJS.ub:b);$CLJS.Bb($CLJS.cA)?Sy.call(null,a):$CLJS.m(b)?$CLJS.Mc($CLJS.Ty,"..."):($CLJS.m(bA)&&(bA+=1),dA.call(null,a));return b};fA=function(a,b){if($CLJS.Bb(b.g?b.g(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.g(a),". It must be one of ",$CLJS.p.g(b)].join(""));};
hA=function(){var a=$CLJS.ri;return $CLJS.m(a)?gA>=$CLJS.ri:a};mA=function(a){fA(a,new $CLJS.U(null,new $CLJS.k(null,4,[cv,null,dv,null,ev,null,fv,null],null),null));var b=$CLJS.Ty;$CLJS.Ri.B($CLJS.r(b),$CLJS.T,$CLJS.Pj,Ou);var c=Pu.g($CLJS.r($CLJS.r(b))),d=Nu.g($CLJS.r($CLJS.r(b)));Gz(b,new hz(Cu,a,d,c,c,null,null,null))};
qA=function(a,b){fA(a,new $CLJS.U(null,new $CLJS.k(null,2,[gv,null,$CLJS.hv,null],null),null));var c=$CLJS.Ty,d=Nu.g($CLJS.r($CLJS.r(c)));if($CLJS.E.h($CLJS.Pj.g($CLJS.r($CLJS.r(c))),Ru)){Hz(c);var e=Lu.g(d);if($CLJS.m($CLJS.E.h?$CLJS.E.h(gv,a):$CLJS.E.call(null,gv,a)))a=$CLJS.r(Iu.g(d));else if($CLJS.m($CLJS.E.h?$CLJS.E.h($CLJS.hv,a):$CLJS.E.call(null,$CLJS.hv,a)))a=$CLJS.$y(yu.g($CLJS.r($CLJS.r(c))),$CLJS.xu);else throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));$CLJS.gg(e,b+a)}else e=
Pu.g($CLJS.r($CLJS.r(c))),Gz(c,new kz(iv,d,a,b,e,e,null,null,null))};vA=function(a,b,c){b="string"===typeof b?rA(b):b;c=sA(c);a:{var d=new $CLJS.nb,e=$CLJS.Bb(a)||!0===a?new $CLJS.cd(d):a,f=tA(b)&&$CLJS.Bb($CLJS.aA(e))?$CLJS.m($CLJS.aA(e))?e:$CLJS.Sz(e):e,g=$CLJS.Ty;$CLJS.Ty=f;try{try{uA(b,c)}finally{e!==f&&Ny(f)}var l=$CLJS.Bb(a)?$CLJS.p.g(d):!0===a?$CLJS.Qy($CLJS.p.g(d)):null;break a}finally{$CLJS.Ty=g}l=void 0}return l};
xA=function(a,b){a=[$CLJS.p.g(a),"\n",$CLJS.p.g(wA),"\n",$CLJS.p.g($CLJS.P.h($CLJS.p,$CLJS.pg(b," "))),"^\n"].join("");throw Error(a);};yA=function(a,b,c,d,e,f){this.Rc=a;this.ub=b;this.sc=c;this.F=d;this.m=e;this.H=f;this.C=2230716170;this.J=139264};sA=function(a){a=$CLJS.z(a);return new yA(a,a,0,null,null,null)};
zA=function(a){var b=$CLJS.Yf.g(a);if($CLJS.m(b))return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.A(b),new yA(jv.g(a),$CLJS.B(b),Pu.g(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};AA=function(a){var b=zA(a);a=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);a="string"===typeof a?rA(a):a;return new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null)};CA=function(a,b){return b>=Pu.g(a)?(b=Pu.g(a)-b,BA.h?BA.h(a,b):BA.call(null,a,b)):new yA(jv.g(a),$CLJS.jg(b,jv.g(a)),b,null,null,null)};
BA=function(a,b){var c=Pu.g(a)+b;return 0>b?CA(a,c):new yA(jv.g(a),$CLJS.jg(b,$CLJS.Yf.g(a)),c,null,null,null)};DA=function(a,b,c,d,e,f,g){this.xc=a;this.vc=b;this.Ec=c;this.offset=d;this.F=e;this.m=f;this.H=g;this.C=2230716170;this.J=139264};
Zea=function(a,b){var c=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);var d=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);d=$CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,2,[kv,null,lv,null],null),null),c)?new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null):$CLJS.E.h(d,mv)?zA(b):$CLJS.E.h(d,nv)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.D($CLJS.Yf.g(b)),b],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[c,new $CLJS.Q(null,2,5,$CLJS.R,
[b,a],null)],null),d],null)};IA=function(a,b){b=Wy(Zea,b,a);a=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mh.h($CLJS.O,a),b],null)};
RA=function(a,b,c){c=zA(c);var d=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);if($CLJS.ve(d))if($CLJS.E.h(JA,10))var e=[$CLJS.p.g(d),$CLJS.m(KA)?".":null].join("");else{e=$CLJS.p;var f=e.g;if($CLJS.m(KA)){var g=$CLJS.K.h($ea,JA);g=$CLJS.m(g)?g:["#",$CLJS.p.g(JA),"r"].join("")}else g=null;e=[f.call(e,g),$CLJS.p.g(LA(JA,d))].join("")}else e=null;a=$CLJS.m(e)?e:a.g?a.g(d):a.call(null,d);d=a.length;e=d+ov.g(b);e=e>=pv.g(b)?e:e+($CLJS.Ye(pv.g(b)-e-1,qv.g(b))+1)*qv.g(b);d=$CLJS.P.h($CLJS.p,$CLJS.pg(e-d,rv.g(b)));
$CLJS.m(lv.g(b))?QA.l($CLJS.F([[$CLJS.p.g(d),$CLJS.p.g(a)].join("")])):QA.l($CLJS.F([[$CLJS.p.g(a),$CLJS.p.g(d)].join("")]));return c};SA=function(a,b){return $CLJS.hf($CLJS.A(Xy(function(c){return 0<c?new $CLJS.Q(null,2,5,$CLJS.R,[Py(c,a),$CLJS.Ye(c,a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,null],null)},b)))};LA=function(a,b){return 0===b?"0":$CLJS.P.h($CLJS.p,$CLJS.kg.h(function(c){return 10>c?Ps(Vy("0")+c):Ps(Vy("a")+(c-10))},SA(a,b)))};
afa=function(a,b){return $CLJS.hf($CLJS.A(Xy(function(c){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.z($CLJS.hf($CLJS.la(a,c))),$CLJS.z($CLJS.jg(a,c))],null)},$CLJS.hf(b))))};
WA=function(a,b,c){c=zA(c);var d=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);if($CLJS.ve(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.E.h(d,Math.floor(d))){var e=0>d,f=LA(a,e?-d:d);a=$CLJS.m(kv.g(b))?function(){var g=$CLJS.kg.h(function(n){return $CLJS.P.h($CLJS.p,n)},afa(sv.g(b),f)),l=$CLJS.pg($CLJS.D(g),tv.g(b));return $CLJS.P.h($CLJS.p,$CLJS.B($CLJS.Vs.h(l,g)))}():f;a=e?["-",$CLJS.p.g(a)].join(""):$CLJS.m(lv.g(b))?["+",$CLJS.p.g(a)].join(""):a;a=a.length<pv.g(b)?
[$CLJS.p.g($CLJS.P.h($CLJS.p,$CLJS.pg(pv.g(b)-a.length,rv.g(b)))),$CLJS.p.g(a)].join(""):a;QA.l($CLJS.F([a]))}else RA($CLJS.VA,new $CLJS.k(null,5,[pv,pv.g(b),qv,1,ov,0,rv,rv.g(b),lv,!0],null),sA(new $CLJS.Q(null,1,5,$CLJS.R,[d],null)));return c};
ZA=function(a){var b=$CLJS.Ye(a,100);a=Py(a,100);var c=0<b?[$CLJS.p.g($CLJS.Pd(XA,b))," hundred"].join(""):null,d=$CLJS.p,e=d.g;if(0<a)if(20>a)var f=$CLJS.Pd(XA,a);else{f=$CLJS.Ye(a,10);var g=Py(a,10);f=[$CLJS.p.g(0<f?$CLJS.Pd(YA,f):null),0<f&&0<g?"-":null,$CLJS.p.g(0<g?$CLJS.Pd(XA,g):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
aB=function(a,b){var c=$CLJS.D(a),d=$CLJS.Mg;--c;var e=$CLJS.A(a);for(a=$CLJS.B(a);;){if(null==a)return[$CLJS.p.g($CLJS.P.h($CLJS.p,$CLJS.Ws(", ",d))),$CLJS.ee(e)||$CLJS.ee(d)?null:", ",$CLJS.p.g(e),!$CLJS.ee(e)&&0<c+b?[" ",$CLJS.p.g($CLJS.Pd($A,c+b))].join(""):null].join("");d=$CLJS.ee(e)?d:$CLJS.gf.h(d,[$CLJS.p.g(e)," ",$CLJS.p.g($CLJS.Pd($A,c+b))].join(""));--c;e=$CLJS.A(a);a=$CLJS.B(a)}};
bB=function(a,b){b=zA(b);var c=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=SA(10,c);c=$CLJS.Mg;for(var e=$CLJS.D(d)-1;;)if($CLJS.ee(d)){QA.l($CLJS.F([$CLJS.P.h($CLJS.p,c)]));break}else{var f=$CLJS.A(d);c=$CLJS.E.h(0,f)?c:$CLJS.gf.h(c,$CLJS.Pd($CLJS.Pd(a,e),f-1));--e;d=$CLJS.B(d)}}else WA(10,new $CLJS.k(null,5,[pv,0,rv," ",tv,",",sv,3,kv,!0],null),sA(new $CLJS.Q(null,1,5,$CLJS.R,[c],null)));return b};
cfa=function(a,b){a=zA(b);b=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);var c=Vy(b);b=c&127;c&=128;var d=$CLJS.K.h(bfa,b);0<c&&QA.l($CLJS.F(["Meta-"]));QA.l($CLJS.F([$CLJS.m(d)?d:32>b?["Control-",$CLJS.p.g(Ps(b+64))].join(""):$CLJS.E.h(b,127)?"Control-?":Ps(b)]));return a};
dfa=function(a,b){var c=zA(b);b=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);a=uv.g(a);if($CLJS.m($CLJS.E.h?$CLJS.E.h("o",a):$CLJS.E.call(null,"o",a)))vA(!0,"\\o~3,'0o",$CLJS.F([Vy(b)]));else if($CLJS.m($CLJS.E.h?$CLJS.E.h("u",a):$CLJS.E.call(null,"u",a)))vA(!0,"\\u~4,'0x",$CLJS.F([Vy(b)]));else if($CLJS.m($CLJS.E.h?$CLJS.E.h(null,a):$CLJS.E.call(null,null,a)))$CLJS.Mc($CLJS.Ty,$CLJS.m($CLJS.E.h?$CLJS.E.h("\b",b):$CLJS.E.call(null,"\b",b))?"\\backspace":$CLJS.m($CLJS.E.h?$CLJS.E.h(" ",b):$CLJS.E.call(null,
" ",b))?"\\space":$CLJS.m($CLJS.E.h?$CLJS.E.h("\t",b):$CLJS.E.call(null,"\t",b))?"\\tab":$CLJS.m($CLJS.E.h?$CLJS.E.h("\n",b):$CLJS.E.call(null,"\n",b))?"\\newline":$CLJS.m($CLJS.E.h?$CLJS.E.h("\f",b):$CLJS.E.call(null,"\f",b))?"\\formfeed":$CLJS.m($CLJS.E.h?$CLJS.E.h("\r",b):$CLJS.E.call(null,"\r",b))?"\\return":$CLJS.m($CLJS.E.h?$CLJS.E.h('"',b):$CLJS.E.call(null,'"',b))?'\\"':$CLJS.m($CLJS.E.h?$CLJS.E.h("\\",b):$CLJS.E.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.g(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.g(a)].join(""));return c};efa=function(a,b){b=zA(b);a=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);QA.l($CLJS.F([a]));return b};fB=function(a){a=$CLJS.A(a);return $CLJS.E.h(vv,a)||$CLJS.E.h(wv,a)};
jB=function(a,b,c){return $CLJS.Vd(Wy(function(d,e){if(fB(e))return new $CLJS.Q(null,2,5,$CLJS.R,[null,e],null);e=IA(xv.g(d),e);var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);var g=Yy(f);f=$CLJS.G(g,0,null);g=$CLJS.G(g,1,null);f=$CLJS.T.j(f,yv,c);return new $CLJS.Q(null,2,5,$CLJS.R,[null,$CLJS.P.h(zv.g(d),new $CLJS.Q(null,3,5,$CLJS.R,[f,e,g],null))],null)},b,a))};
kB=function(a){a=$CLJS.p.g(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.Q(null,2,5,$CLJS.R,[a,$CLJS.p.g(a.length-1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.g(c-1)],null):0>c?new $CLJS.Q(null,2,5,$CLJS.R,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);a:if(c=$CLJS.D(b),0<c&&$CLJS.E.h($CLJS.Pd(b,
$CLJS.D(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.E.h($CLJS.Pd(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.D(c);if(0<d&&$CLJS.E.h($CLJS.Pd(c,0),"0"))for(var e=0;;){if($CLJS.E.h(e,d)||!$CLJS.E.h($CLJS.Pd(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.D(b)-$CLJS.D(c);a=0<$CLJS.D(a)&&$CLJS.E.h($CLJS.Pd(a,0),"+")?a.substring(1):a;return $CLJS.ee(c)?new $CLJS.Q(null,2,5,$CLJS.R,["0",0],null):new $CLJS.Q(null,2,5,$CLJS.R,[c,parseInt(a,10)-b],null)};
lB=function(a,b,c,d){if($CLJS.m($CLJS.m(c)?c:d)){var e=$CLJS.D(a);d=$CLJS.m(d)?2>d?2:d:0;$CLJS.m(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.E.h(c,0)?new $CLJS.Q(null,4,5,$CLJS.R,[["0",$CLJS.p.g(a)].join(""),b+1,1,e+1],null):new $CLJS.Q(null,4,5,$CLJS.R,[a,b,c,e],null);c=$CLJS.G(f,0,null);e=$CLJS.G(f,1,null);d=$CLJS.G(f,2,null);f=$CLJS.G(f,3,null);if($CLJS.m(d)){if(0>d)return new $CLJS.Q(null,3,5,$CLJS.R,["0",0,!1],null);if(f>d){b=$CLJS.Pd(c,d);a=c.substring(0,d);if(Vy(b)>=Vy("5")){a:for(b=
$CLJS.D(a)-1,c=b|0;;){if(0>c){b=$CLJS.P.j($CLJS.p,"1",$CLJS.pg(b+1,"0"));break a}if($CLJS.E.h("9",a.charAt(c)))--c;else{b=$CLJS.P.B($CLJS.p,a.substring(0,c),Ps(Vy(a.charAt(c))+1),$CLJS.pg(b-c,"0"));break a}}a=$CLJS.D(b)>a.length;c=$CLJS.R;a&&(d=$CLJS.D(b)-1,b=b.substring(0,d));return new $CLJS.Q(null,3,5,c,[b,e,a],null)}return new $CLJS.Q(null,3,5,$CLJS.R,[a,e,!1],null)}}}return new $CLJS.Q(null,3,5,$CLJS.R,[a,b,!1],null)};
mB=function(a,b,c){var d=0>b?new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.g($CLJS.P.h($CLJS.p,$CLJS.pg(-b-1,"0"))),$CLJS.p.g(a)].join(""),-1],null):new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null);a=$CLJS.G(d,0,null);var e=$CLJS.G(d,1,null);d=$CLJS.D(a);c=$CLJS.m(c)?e+c+1:e+1;c=d<c?[$CLJS.p.g(a),$CLJS.p.g($CLJS.P.h($CLJS.p,$CLJS.pg(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.g(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
ffa=function(a,b){return 0>b?[".",$CLJS.p.g(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
qB=function(a,b){var c=Av.g(a),d=Bv.g(a);b=zA(b);var e=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);var f=0>e?new $CLJS.Q(null,2,5,$CLJS.R,["-",-e],null):new $CLJS.Q(null,2,5,$CLJS.R,["+",e],null),g=$CLJS.G(f,0,null);f=$CLJS.G(f,1,null);f=kB(f);var l=$CLJS.G(f,0,null),n=$CLJS.G(f,1,null)+$CLJS.Cv.g(a);f=function(){var x=lv.g(a);return $CLJS.m(x)?x:0>e}();var q=$CLJS.Bb(d)&&$CLJS.D(l)-1<=n,u=lB(l,n,d,$CLJS.m(c)?c-($CLJS.m(f)?1:0):null);l=$CLJS.G(u,0,null);n=$CLJS.G(u,1,null);u=$CLJS.G(u,2,null);l=mB(l,$CLJS.m(u)?
n+1:n,d);d=$CLJS.m($CLJS.m(c)?$CLJS.m(d)?1<=d&&$CLJS.E.h(l.charAt(0),"0")&&$CLJS.E.h(l.charAt(1),".")&&l.length>c-($CLJS.m(f)?1:0):d:c)?l.substring(1):l;n=$CLJS.E.h($CLJS.A(d),".");if($CLJS.m(c)){l=d.length;l=$CLJS.m(f)?l+1:l;n=n&&!(l>=c);q=q&&!(l>=c);var v=n||q?l+1:l;$CLJS.m(function(){var x=v>c;return x?Dv.g(a):x}())?QA.l($CLJS.F([$CLJS.P.h($CLJS.p,$CLJS.pg(c,Dv.g(a)))])):QA.l($CLJS.F([[$CLJS.p.g($CLJS.P.h($CLJS.p,$CLJS.pg(c-v,rv.g(a)))),$CLJS.p.g($CLJS.m(f)?g:null),n?"0":null,d,q?"0":null].join("")]))}else QA.l($CLJS.F([[$CLJS.p.g($CLJS.m(f)?
g:null),n?"0":null,d,q?"0":null].join("")]));return b};
tB=function(a,b){b=zA(b);var c=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);var d=kB(0>c?-c:c);$CLJS.G(d,0,null);for($CLJS.G(d,1,null);;){var e=$CLJS.G(d,0,null),f=$CLJS.G(d,1,null),g=Av.g(a),l=Bv.g(a),n=Ev.g(a),q=$CLJS.Cv.g(a),u=function(){var J=Fv.g(a);return $CLJS.m(J)?J:"E"}();d=function(){var J=lv.g(a);return $CLJS.m(J)?J:0>c}();var v=0>=q,x=f-(q-1),y=$CLJS.p.g(Math.abs(x));u=[$CLJS.p.g(u),0>x?"-":"+",$CLJS.p.g($CLJS.m(n)?$CLJS.P.h($CLJS.p,$CLJS.pg(n-y.length,"0")):null),y].join("");var C=u.length;
x=$CLJS.D(e);e=[$CLJS.p.g($CLJS.P.h($CLJS.p,$CLJS.pg(-q,"0"))),$CLJS.p.g(e),$CLJS.p.g($CLJS.m(l)?$CLJS.P.h($CLJS.p,$CLJS.pg(l-(x-1)-(0>q?-q:0),"0")):null)].join("");x=$CLJS.m(g)?g-C:null;e=lB(e,0,$CLJS.E.h(q,0)?l-1:0<q?l:0>q?l-1:null,$CLJS.m(x)?x-($CLJS.m(d)?1:0):null);x=$CLJS.G(e,0,null);$CLJS.G(e,1,null);y=$CLJS.G(e,2,null);e=ffa(x,q);l=$CLJS.E.h(q,$CLJS.D(x))&&null==l;if($CLJS.Bb(y)){if($CLJS.m(g)){f=e.length+C;f=$CLJS.m(d)?f+1:f;var H=(v=v&&!$CLJS.E.h(f,g))?f+1:f;f=l&&H<g;$CLJS.m(function(){var J=
H>g;J||(J=n,J=$CLJS.m(J)?C-2>n:J);return $CLJS.m(J)?Dv.g(a):J}())?QA.l($CLJS.F([$CLJS.P.h($CLJS.p,$CLJS.pg(g,Dv.g(a)))])):QA.l($CLJS.F([[$CLJS.p.g($CLJS.P.h($CLJS.p,$CLJS.pg(g-H-(f?1:0),rv.g(a)))),$CLJS.m(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else QA.l($CLJS.F([[$CLJS.m(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.Q(null,2,5,$CLJS.R,[x,f+1],null)}return b};
gfa=function(a,b,c){var d=zA(b),e=$CLJS.G(d,0,null);$CLJS.G(d,1,null);d=kB(0>e?-e:e);var f=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);var g=Av.g(a),l=Bv.g(a),n=Ev.g(a);d=$CLJS.E.h(e,0)?0:d+1;e=$CLJS.m(n)?n+2:4;g=$CLJS.m(g)?g-e:null;$CLJS.m(l)?f=l:(f=$CLJS.D(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=qB(new $CLJS.k(null,6,[Av,g,Bv,d,$CLJS.Cv,0,Dv,Dv.g(a),rv,rv.g(a),lv,lv.g(a)],null),b,c),QA.l($CLJS.F([$CLJS.P.h($CLJS.p,$CLJS.pg(e," "))])),a):tB(a,b,c)};
hfa=function(a,b){b=zA(b);var c=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);var d=kB(Math.abs(c)),e=$CLJS.G(d,0,null),f=$CLJS.G(d,1,null),g=Bv.g(a),l=$CLJS.Gv.g(a);d=Av.g(a);var n=function(){var u=lv.g(a);return $CLJS.m(u)?u:0>c}(),q=lB(e,f,g,null);e=$CLJS.G(q,0,null);f=$CLJS.G(q,1,null);q=$CLJS.G(q,2,null);g=mB(e,$CLJS.m(q)?f+1:f,g);l=[$CLJS.p.g($CLJS.P.h($CLJS.p,$CLJS.pg(l-g.indexOf("."),"0"))),g].join("");g=l.length+($CLJS.m(n)?1:0);QA.l($CLJS.F([[$CLJS.m(function(){var u=kv.g(a);return $CLJS.m(u)?n:
u}())?0>c?"-":"+":null,$CLJS.p.g($CLJS.P.h($CLJS.p,$CLJS.pg(d-g,rv.g(a)))),$CLJS.m(function(){var u=$CLJS.Bb(kv.g(a));return u?n:u}())?0>c?"-":"+":null,l].join("")]));return b};ifa=function(a,b){var c=Hv.g(a);c=$CLJS.m(c)?new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null):zA(b);b=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);var d=$CLJS.Iv.g(a);b=0>b||b>=$CLJS.D(d)?$CLJS.A($CLJS.Wt.g(a)):$CLJS.Pd(d,b);return $CLJS.m(b)?jB(b,c,yv.g(a)):c};
jfa=function(a,b){var c=zA(b);b=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);var d=$CLJS.Iv.g(a);b=$CLJS.m(b)?$CLJS.Vd(d):$CLJS.A(d);return $CLJS.m(b)?jB(b,c,yv.g(a)):c};kfa=function(a,b){var c=zA(b),d=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);var e=$CLJS.Iv.g(a);e=$CLJS.m(d)?$CLJS.A(e):null;return $CLJS.m(d)?$CLJS.m(e)?jB(e,b,yv.g(a)):b:c};
lfa=function(a,b){var c=Jv.g(a),d=$CLJS.A($CLJS.Iv.g(a));d=$CLJS.ee(d)?AA(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);d=zA(d);var e=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);var f=0;e=sA(e);for(var g=-1;;){if($CLJS.Bb(c)&&$CLJS.E.h(Pu.g(e),g)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");g=$CLJS.ee($CLJS.Yf.g(e))&&($CLJS.Bb(kv.g(Kv.g(a)))||0<f);if($CLJS.m(g?g:$CLJS.m(c)?f>=c:c))return d;g=jB(b,e,yv.g(a));if($CLJS.E.h(vv,$CLJS.A(g)))return d;
f+=1;var l=Pu.g(e);e=g;g=l}};mfa=function(a,b){var c=Jv.g(a),d=$CLJS.A($CLJS.Iv.g(a));d=$CLJS.ee(d)?AA(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);d=zA(d);var e=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);for(var f=0;;){var g=$CLJS.ee(e)&&($CLJS.Bb(kv.g(Kv.g(a)))||0<f);if($CLJS.m(g?g:$CLJS.m(c)?f>=c:c))return d;g=jB(b,sA($CLJS.A(e)),sA($CLJS.B(e)));if($CLJS.E.h(wv,$CLJS.A(g)))return d;f+=1;e=$CLJS.B(e)}};
nfa=function(a,b){var c=Jv.g(a),d=$CLJS.A($CLJS.Iv.g(a)),e=$CLJS.ee(d)?AA(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.G(e,0,null);d=0;e=$CLJS.G(e,1,null);for(var f=-1;;){if($CLJS.Bb(c)&&$CLJS.E.h(Pu.g(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.ee($CLJS.Yf.g(e))&&($CLJS.Bb(kv.g(Kv.g(a)))||0<d);if($CLJS.m(f?f:$CLJS.m(c)?d>=c:c))return e;f=jB(b,e,yv.g(a));if($CLJS.E.h(vv,$CLJS.A(f)))return $CLJS.Vd(f);d+=1;var g=Pu.g(e);e=f;f=g}};
ofa=function(a,b){var c=Jv.g(a),d=$CLJS.A($CLJS.Iv.g(a)),e=$CLJS.ee(d)?AA(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.G(e,0,null);d=0;for(e=$CLJS.G(e,1,null);;){var f=$CLJS.ee($CLJS.Yf.g(e))&&($CLJS.Bb(kv.g(Kv.g(a)))||0<d);if($CLJS.m(f?f:$CLJS.m(c)?d>=c:c))return e;f=$CLJS.Yf.g(e);f=$CLJS.m(f)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.A(f),new yA(jv.g(e),$CLJS.B(f),Pu.g(e)+1,null,null,null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,e],null);e=$CLJS.G(f,0,null);f=$CLJS.G(f,1,null);e=jB(b,sA(e),f);
if($CLJS.E.h(wv,$CLJS.A(e)))return f;e=f;d+=1}};qfa=function(a,b){if($CLJS.m(kv.g(Kv.g(a)))){var c=$CLJS.Iv.g(a),d=$CLJS.D(c),e=1<d?$CLJS.ql.g(xv.g($CLJS.A($CLJS.A(c)))):$CLJS.m(kv.g(a))?"(":null,f=$CLJS.Pd(c,1<d?1:0);c=2<d?$CLJS.ql.g(xv.g($CLJS.A($CLJS.Pd(c,2)))):$CLJS.m(kv.g(a))?")":null;d=zA(b);b=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{var g=gA,l=bA;gA+=1;bA=0;try{Wz(e,c),jB(f,sA(b),yv.g(a)),$z()}finally{bA=l,gA=g}}a=d}else a=pfa(a,b);return a};
zB=function(a,b,c){for(var d=$CLJS.Mg;;){if($CLJS.ee(a))return new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);var e=$CLJS.A(a);a:{var f=new $CLJS.nb,g=$CLJS.Ty;$CLJS.Ty=new $CLJS.cd(f);try{var l=new $CLJS.Q(null,2,5,$CLJS.R,[jB(e,b,c),$CLJS.p.g(f)],null);break a}finally{$CLJS.Ty=g}l=void 0}b=$CLJS.G(l,0,null);e=$CLJS.G(l,1,null);if($CLJS.E.h(vv,$CLJS.A(b)))return new $CLJS.Q(null,2,5,$CLJS.R,[d,$CLJS.Vd(b)],null);a=$CLJS.B(a);d=$CLJS.gf.h(d,e)}};
pfa=function(a,b){var c=function(){var V=$CLJS.Wt.g(a);return $CLJS.m(V)?zB(V,b,yv.g(a)):null}(),d=$CLJS.G(c,0,null);d=$CLJS.G(d,0,null);c=$CLJS.G(c,1,null);var e=$CLJS.m(c)?c:b;c=function(){var V=Lv.g(a);return $CLJS.m(V)?IA(V,e):null}();var f=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);var g=$CLJS.m(c)?c:e;c=function(){var V=$CLJS.A(Mv.g(f));return $CLJS.m(V)?V:0}();var l=function(){var V=$CLJS.A(Nv.g(f));return $CLJS.m(V)?V:$CLJS.$y($CLJS.Ty,$CLJS.xl)}(),n=$CLJS.Iv.g(a);g=zB(n,g,yv.g(a));var q=$CLJS.G(g,
0,null);g=$CLJS.G(g,1,null);var u=function(){var V=$CLJS.D(q)-1+($CLJS.m(kv.g(a))?1:0)+($CLJS.m(lv.g(a))?1:0);return 1>V?1:V}();n=$CLJS.Re($CLJS.bm,$CLJS.kg.h($CLJS.D,q));var v=pv.g(a),x=ov.g(a),y=qv.g(a),C=n+u*x;v=C<=v?v:v+y*(1+$CLJS.Ye(C-v-1,y));var H=v-n;n=function(){var V=$CLJS.Ye(H,u);return x>V?x:V}();y=H-n*u;n=$CLJS.P.h($CLJS.p,$CLJS.pg(n,rv.g(a)));$CLJS.m($CLJS.m(d)?$CLJS.$y(yu.g($CLJS.r($CLJS.r($CLJS.Ty))),$CLJS.xu)+c+v>l:d)&&QA.l($CLJS.F([d]));c=y;for(var J=q,S=function(){var V=kv.g(a);
return $CLJS.m(V)?V:$CLJS.E.h($CLJS.D(J),1)&&$CLJS.Bb(lv.g(a))}();;)if($CLJS.z(J))QA.l($CLJS.F([[$CLJS.p.g($CLJS.Bb(S)?$CLJS.A(J):null),$CLJS.p.g($CLJS.m(function(){var V=S;return $CLJS.m(V)?V:(V=$CLJS.B(J))?V:lv.g(a)}())?n:null),$CLJS.p.g(0<c?rv.g(a):null)].join("")])),--c,J=d=$CLJS.m(S)?J:$CLJS.B(J),S=!1;else break;return g};Ov=function(a,b){this.La=a;this.Jg=b;this.C=1074135040;this.J=0};rfa=function(a){return new Ov(a,$CLJS.O)};Pv=function(a,b){this.La=a;this.eh=b;this.C=1074135040;this.J=0};
sfa=function(a){return new Pv(a,$CLJS.O)};
tfa=function(a,b){var c=$CLJS.A(a);return $CLJS.P.h($CLJS.p,$CLJS.A(Xy(function(d){if($CLJS.ee(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.m(e)?e.index+1:e;return $CLJS.m(e)?new $CLJS.Q(null,2,5,$CLJS.R,[[d.substring(0,e),$CLJS.Pd(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,null],null)},$CLJS.m($CLJS.m(b)?$CLJS.m(c)?Ly(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
Qv=function(a,b,c){this.La=a;this.se=b;this.hh=c;this.C=1074135040;this.J=0};ufa=function(a){return new Qv(a,$CLJS.ka(!0),$CLJS.O)};Rv=function(a,b,c){this.La=a;this.ce=b;this.Nh=c;this.C=1074135040;this.J=0};vfa=function(a){return new Rv(a,$CLJS.ka(!1),$CLJS.O)};wfa=function(a,b){var c=$CLJS.m(kv.g(a))?$CLJS.hv:gv;qA(c,$CLJS.Gv.g(a));return b};xfa=function(a,b){a=$CLJS.m(kv.g(a))?$CLJS.m(lv.g(a))?cv:ev:$CLJS.m(lv.g(a))?dv:fv;mA(a);return b};
zfa=function(a){var b=$CLJS.G(a,0,null),c=$CLJS.G(a,1,null),d=$CLJS.G(a,2,null);a=new RegExp(yfa.source,"g");var e=a.exec(b);return $CLJS.m(e)?(d=$CLJS.A(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.E.h(",",$CLJS.Pd(b,0))?new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[b.substring(1),a+1,!0],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[b,a,!1],null)],null)):$CLJS.m(d)?xA("Badly formed parameters in format directive",
c):new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,2,5,$CLJS.R,[b,c],null)],null)};Afa=function(a){var b=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.E.h(b.length,0)?null:$CLJS.E.h(b.length,1)&&$CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,2,["V",null,"v",null],null),null),$CLJS.Pd(b,0))?mv:$CLJS.E.h(b.length,1)&&$CLJS.E.h("#",$CLJS.Pd(b,0))?nv:$CLJS.E.h(b.length,2)&&$CLJS.E.h("'",$CLJS.Pd(b,0))?$CLJS.Pd(b,1):parseInt(b,10),a],null)};
Cfa=function(a,b){return Xy(function(c){var d=$CLJS.G(c,0,null),e=$CLJS.G(c,1,null);c=$CLJS.G(c,2,null);if($CLJS.ee(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,3,5,$CLJS.R,[d,e,c],null)],null);var f=$CLJS.K.h(Bfa,$CLJS.A(d));return $CLJS.m(f)?$CLJS.Ee(c,f)?xA(['Flag "',$CLJS.p.g($CLJS.A(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[d.substring(1),e+1,$CLJS.T.j(c,f,new $CLJS.Q(null,2,5,$CLJS.R,[!0,e],null))],
null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,3,5,$CLJS.R,[d,e,c],null)],null)},new $CLJS.Q(null,3,5,$CLJS.R,[a,b,$CLJS.O],null))};
Dfa=function(a,b){var c=Sv.g(a);$CLJS.m(function(){var d=$CLJS.Bb(lv.g(c));return d?lv.g(b):d}())&&xA(['"@" is an illegal flag for format directive "',$CLJS.p.g(Tv.g(a)),'"'].join(""),$CLJS.Pd(lv.g(b),1));$CLJS.m(function(){var d=$CLJS.Bb(kv.g(c));return d?kv.g(b):d}())&&xA(['":" is an illegal flag for format directive "',$CLJS.p.g(Tv.g(a)),'"'].join(""),$CLJS.Pd(kv.g(b),1));$CLJS.m(function(){var d=$CLJS.Bb(Uv.g(c));return d?(d=lv.g(b),$CLJS.m(d)?kv.g(b):d):d}())&&xA(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.g(Tv.g(a)),'"'].join(""),function(){var d=$CLJS.Pd(kv.g(b),1),e=$CLJS.Pd(lv.g(b),1);return d<e?d:e}())};
Ffa=function(a,b,c,d){Dfa(a,c);$CLJS.D(b)>$CLJS.D(xv.g(a))&&xA(vA(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.F([Tv.g(a),$CLJS.D(b),$CLJS.D(xv.g(a))])),$CLJS.Vd($CLJS.A(b)));$CLJS.oi($CLJS.kg.j(function(e,f){var g=$CLJS.A(e);return null==g||$CLJS.Ee(Efa,g)||$CLJS.E.h($CLJS.Vd($CLJS.Vd(f)),$CLJS.Kb(g))?null:xA(["Parameter ",$CLJS.fi($CLJS.A(f)),' has bad type in directive "',$CLJS.p.g(Tv.g(a)),'": ',$CLJS.p.g($CLJS.Kb(g))].join(""),
$CLJS.Vd(e))},b,xv.g(a)));return $CLJS.xm.l($CLJS.F([$CLJS.mh.h($CLJS.O,$CLJS.hf(function(){return function g(f){return new $CLJS.uf(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.me(l)){var n=$CLJS.Wc(l),q=$CLJS.D(n),u=$CLJS.xf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.Yd(n,v),y=$CLJS.G(x,0,null);x=$CLJS.G(x,1,null);x=$CLJS.G(x,0,null);u.add(new $CLJS.Q(null,2,5,$CLJS.R,[y,new $CLJS.Q(null,2,5,$CLJS.R,[x,d],null)],null));v+=1}else{n=!0;break a}return n?$CLJS.Af($CLJS.Ef(u),g($CLJS.Xc(l))):
$CLJS.Af($CLJS.Ef(u),null)}n=$CLJS.A(l);u=$CLJS.G(n,0,null);n=$CLJS.G(n,1,null);n=$CLJS.G(n,0,null);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[u,new $CLJS.Q(null,2,5,$CLJS.R,[n,d],null)],null),g($CLJS.vd(l)))}return null}},null,null)}(xv.g(a))}())),$CLJS.Ob(function(e,f){return $CLJS.P.j($CLJS.T,e,f)},$CLJS.O,$CLJS.vg(function(e){return $CLJS.A($CLJS.Pd(e,1))},$CLJS.Ts($CLJS.Uh(xv.g(a)),b))),c]))};
Hfa=function(a,b){b=Xy(zfa,new $CLJS.Q(null,3,5,$CLJS.R,[a,b,!1],null));a=$CLJS.G(b,0,null);var c=$CLJS.G(b,1,null);b=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);b=Cfa(b,c);$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);var d=$CLJS.G(b,0,null),e=$CLJS.G(b,1,null);b=$CLJS.G(b,2,null);c=$CLJS.A(d);var f=$CLJS.K.h(Gfa,c.toUpperCase()),g=$CLJS.m(f)?Ffa(f,$CLJS.kg.h(Afa,a),b,e):null;$CLJS.Bb(c)&&xA("Format string ended in the middle of a directive",e);$CLJS.Bb(f)&&xA(['Directive "',$CLJS.p.g(c),'" is undefined'].join(""),
e);return new $CLJS.Q(null,2,5,$CLJS.R,[new DA(function(){var l=Vv.g(f);return l.h?l.h(g,e):l.call(null,g,e)}(),f,g,e,null,null,null),function(){var l=d.substring(1),n=e+1;if($CLJS.E.h("\n",Tv.g(f))&&$CLJS.Bb(kv.g(g)))a:{var q=new $CLJS.Q(null,2,5,$CLJS.R,[" ","\t"],null);q=$CLJS.fe(q)?$CLJS.di(q):$CLJS.bi([q]);for(var u=0;;){var v;(v=$CLJS.E.h(u,$CLJS.D(l)))||(v=$CLJS.Pd(l,u),v=q.g?q.g(v):q.call(null,v),v=$CLJS.Bb(v));if(v){q=u;break a}u+=1}}else q=0;return new $CLJS.Q(null,2,5,$CLJS.R,[l.substring(q),
n+q],null)}()],null)};LB=function(a,b){return new DA(function(c,d){QA.l($CLJS.F([a]));return d},null,new $CLJS.k(null,1,[$CLJS.ql,a],null),b,null,null,null)};PB=function(a,b){var c=Ifa(Wv.g(Xv.g(a)),$CLJS.Xt.g(a),b);b=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[new DA(zv.g(a),Xv.g(a),$CLJS.xm.l($CLJS.F([xv.g(a),Rea(b,$CLJS.Xt.g(a))])),$CLJS.Xt.g(a),null,null,null),c],null)};
Jfa=function(a,b,c){return Xy(function(d){if($CLJS.ee(d))return xA("No closing bracket found.",b);var e=$CLJS.A(d);d=$CLJS.B(d);if($CLJS.m($CLJS.Yv.g(Wv.g(Xv.g(e)))))e=PB(e,d);else if($CLJS.E.h($CLJS.Yv.g(a),Tv.g(Xv.g(e))))e=new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[Zv,xv.g(e),null,d],null)],null);else{var f=$CLJS.Zt.g(Wv.g(Xv.g(e)));f=$CLJS.m(f)?kv.g(xv.g(e)):f;e=$CLJS.m(f)?new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wt,null,xv.g(e),d],null)],null):
$CLJS.m($CLJS.Zt.g(Wv.g(Xv.g(e))))?new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Zt,null,null,d],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[e,d],null)}return e},c)};
Ifa=function(a,b,c){return $CLJS.Vd(Xy(function(d){var e=$CLJS.G(d,0,null),f=$CLJS.G(d,1,null);d=$CLJS.G(d,2,null);var g=Jfa(a,b,d);d=$CLJS.G(g,0,null);var l=$CLJS.G(g,1,null);g=$CLJS.G(l,0,null);var n=$CLJS.G(l,1,null),q=$CLJS.G(l,2,null);l=$CLJS.G(l,3,null);return $CLJS.E.h(g,Zv)?new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SB.l($CLJS.sg,$CLJS.F([e,$CLJS.Nf([$CLJS.m(f)?$CLJS.Wt:$CLJS.Iv,new $CLJS.Q(null,1,5,$CLJS.R,[d],null),Kv,n])])),l],null)],null):$CLJS.E.h(g,$CLJS.Wt)?
$CLJS.m($CLJS.Wt.g(e))?xA('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Bb($CLJS.Wt.g(a))?xA('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.E.h($v,$CLJS.Wt.g(a))&&$CLJS.z($CLJS.Iv.g(e))?xA('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.E.h($v,$CLJS.Wt.g(a))?new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SB.l($CLJS.sg,$CLJS.F([e,new $CLJS.k(null,2,[$CLJS.Wt,new $CLJS.Q(null,1,5,
$CLJS.R,[d],null),Lv,q],null)])),!1,l],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SB.l($CLJS.sg,$CLJS.F([e,new $CLJS.k(null,1,[$CLJS.Iv,new $CLJS.Q(null,1,5,$CLJS.R,[d],null)],null)])),!0,l],null)],null):$CLJS.E.h(g,$CLJS.Zt)?$CLJS.m(f)?xA('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Bb(aw.g(a))?xA('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.SB.l($CLJS.sg,$CLJS.F([e,new $CLJS.k(null,1,[$CLJS.Iv,new $CLJS.Q(null,1,5,$CLJS.R,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.k(null,1,[$CLJS.Iv,$CLJS.Mg],null),!1,c],null)))};Kfa=function(a){return $CLJS.A(Xy(function(b){var c=$CLJS.A(b);b=$CLJS.B(b);var d=Wv.g(Xv.g(c));return $CLJS.m($CLJS.Yv.g(d))?PB(c,b):new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null)},a))};
rA=function(a){var b=wA;wA=a;try{return Kfa($CLJS.A(Xy(function(c){var d=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);if($CLJS.ee(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.Q(null,2,5,$CLJS.R,[LB(d,c),new $CLJS.Q(null,2,5,$CLJS.R,["",c+d.length],null)],null):0===e?Hfa(d.substring(1),c+1):new $CLJS.Q(null,2,5,$CLJS.R,[LB(d.substring(0,e),c),new $CLJS.Q(null,2,5,$CLJS.R,[d.substring(e),e+c],null)],null)},new $CLJS.Q(null,2,5,$CLJS.R,[a,0],null))))}finally{wA=
b}};uA=function(a,b){Wy(function(c,d){if(fB(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);d=IA(xv.g(c),d);var e=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);var f=Yy(e);e=$CLJS.G(f,0,null);f=$CLJS.G(f,1,null);e=$CLJS.T.j(e,yv,d);return new $CLJS.Q(null,2,5,$CLJS.R,[null,$CLJS.P.h(zv.g(c),new $CLJS.Q(null,3,5,$CLJS.R,[e,d,f],null))],null)},b,a);return null};
WB=function(a){var b=$CLJS.A(a);b=VB.g?VB.g(b):VB.call(null,b);return $CLJS.m($CLJS.m(b)?$CLJS.E.h(2,$CLJS.D(a)):b)?($CLJS.Mc($CLJS.Ty,b),$CLJS.eA($CLJS.Vd(a)),!0):null};
XB=function(a){if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{var b=gA,c=bA;gA+=1;bA=0;try{Wz("[","]");for(var d=0,e=$CLJS.z(a);;){if($CLJS.Bb($CLJS.ub)||d<$CLJS.ub){if(e&&($CLJS.eA($CLJS.A(e)),$CLJS.B(e))){$CLJS.Mc($CLJS.Ty," ");mA(fv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.Mc($CLJS.Ty,"...");break}$z()}finally{bA=c,gA=b}}return null};
YB=function(a){var b=$CLJS.ke(a)?null:function(){var n=new $CLJS.pd(function(){return $CLJS.Hi},bw,$CLJS.Qh([$CLJS.hk,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,$CLJS.Zf,$CLJS.rj,$CLJS.jk,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[!0,$CLJS.fb,cw,"cljs/core.cljs",15,1,10543,10543,$CLJS.Y(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.kaa],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.m($CLJS.Hi)?$CLJS.Hi.I:null]));return n.g?n.g(a):n.call(null,a)}(),c=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);var d=$CLJS.m(b)?
b:a,e=$CLJS.m(c)?["#:",$CLJS.p.g(c),"{"].join(""):"{";if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{c=gA;b=bA;gA+=1;bA=0;try{Wz(e,"}");e=0;for(var f=$CLJS.z(d);;){if($CLJS.Bb($CLJS.ub)||e<$CLJS.ub){if(f){if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{d=gA;var g=bA;gA+=1;bA=0;try{Wz(null,null),$CLJS.eA($CLJS.A($CLJS.A(f))),$CLJS.Mc($CLJS.Ty," "),mA(fv),bA=0,$CLJS.eA($CLJS.A($CLJS.B($CLJS.A(f)))),$z()}finally{bA=g,gA=d}}if($CLJS.B(f)){$CLJS.Mc($CLJS.Ty,", ");mA(fv);d=e+1;var l=$CLJS.B(f);e=d;f=l;continue}}}else $CLJS.Mc($CLJS.Ty,
"...");break}$z()}finally{bA=b,gA=c}}return null};ZB=function(a){return $CLJS.Mc($CLJS.Ty,$CLJS.Oi.l($CLJS.F([a])))};bC=function(a){return a instanceof $CLJS.pa?dw:(null!=a?a.C&32768||$CLJS.t===a.Of||(a.C?0:$CLJS.Jb($CLJS.My,a)):$CLJS.Jb($CLJS.My,a))?ew:a instanceof $CLJS.w?$CLJS.Yj:$CLJS.re(a)?$CLJS.Bj:$CLJS.je(a)?$CLJS.Wj:$CLJS.le(a)?$CLJS.Fj:$CLJS.ge(a)?$CLJS.rg:null==a?null:$CLJS.Dj};
cC=function(a){return $CLJS.le(a)?new $CLJS.Q(null,2,5,$CLJS.R,["[","]"],null):new $CLJS.Q(null,2,5,$CLJS.R,["(",")"],null)};
Nfa=function(a){if($CLJS.ie(a)){var b=cC(a),c=$CLJS.G(b,0,null),d=$CLJS.G(b,1,null),e=$CLJS.z(a),f=$CLJS.A(e),g=$CLJS.B(e);if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{var l=gA,n=bA,q=gA+1;gA=q;bA=0;try{Wz(c,d);(function(){var Fa=dC("~w~:i");return function(){function ab(Rb){var Xb=null;if(0<arguments.length){Xb=0;for(var jc=Array(arguments.length-0);Xb<jc.length;)jc[Xb]=arguments[Xb+0],++Xb;Xb=new $CLJS.rd(jc,0,null)}return Cb.call(this,Xb)}function Cb(Rb){Rb=sA(Rb);return uA(Fa,Rb)}ab.A=0;ab.D=
function(Rb){Rb=$CLJS.z(Rb);return Cb(Rb)};ab.l=Cb;return ab}()})()(f);for(var u=g;;)if($CLJS.z(u)){(function(){var Fa=dC(" ");return function(ab,Cb,Rb){return function(){function Xb(Db){var Vb=null;if(0<arguments.length){Vb=0;for(var xd=Array(arguments.length-0);Vb<xd.length;)xd[Vb]=arguments[Vb+0],++Vb;Vb=new $CLJS.rd(xd,0,null)}return jc.call(this,Vb)}function jc(Db){Db=sA(Db);return uA(Rb,Db)}Xb.A=0;Xb.D=function(Db){Db=$CLJS.z(Db);return jc(Db)};Xb.l=jc;return Xb}()}(u," ",Fa,l,n,q,0,b,c,d,a,
e,f,g,f,g)})()();var v=$CLJS.A(u);if($CLJS.ie(v)){var x=cC(v),y=$CLJS.G(x,0,null),C=$CLJS.G(x,1,null);if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{var H=gA,J=bA,S=gA+1;gA=S;bA=0;try{Wz(y,C);if($CLJS.E.h($CLJS.D(v),3)&&$CLJS.Vd(v)instanceof $CLJS.M){var V=v,da=$CLJS.G(V,0,null),fa=$CLJS.G(V,1,null),ma=$CLJS.G(V,2,null);(function(){var Fa=dC("~w ~w ");return function(ab,Cb,Rb){return function(){function Xb(Db){var Vb=null;if(0<arguments.length){Vb=0;for(var xd=Array(arguments.length-0);Vb<xd.length;)xd[Vb]=
arguments[Vb+0],++Vb;Vb=new $CLJS.rd(xd,0,null)}return jc.call(this,Vb)}function jc(Db){Db=sA(Db);return uA(Rb,Db)}Xb.A=0;Xb.D=function(Db){Db=$CLJS.z(Db);return jc(Db)};Xb.l=jc;return Xb}()}(u,"~w ~w ",Fa,V,da,fa,ma,H,J,S,0,x,y,C,v,l,n,q,0,b,c,d,a,e,f,g,f,g)})()(da,fa);$CLJS.ie(ma)?function(){var Fa=$CLJS.le(ma)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",ab="string"===typeof Fa?dC(Fa):Fa;return function(Cb,Rb,Xb){return function(){function jc(Vb){var xd=null;if(0<arguments.length){xd=
0;for(var $t=Array(arguments.length-0);xd<$t.length;)$t[xd]=arguments[xd+0],++xd;xd=new $CLJS.rd($t,0,null)}return Db.call(this,xd)}function Db(Vb){Vb=sA(Vb);return uA(Xb,Vb)}jc.A=0;jc.D=function(Vb){Vb=$CLJS.z(Vb);return Db(Vb)};jc.l=Db;return jc}()}(u,Fa,ab,V,da,fa,ma,H,J,S,0,x,y,C,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()(ma):$CLJS.eA(ma)}else $CLJS.P.h(function(){var Fa=dC("~w ~:i~@{~w~^ ~:_~}");return function(ab,Cb,Rb){return function(){function Xb(Db){var Vb=null;if(0<arguments.length){Vb=0;for(var xd=
Array(arguments.length-0);Vb<xd.length;)xd[Vb]=arguments[Vb+0],++Vb;Vb=new $CLJS.rd(xd,0,null)}return jc.call(this,Vb)}function jc(Db){Db=sA(Db);return uA(Rb,Db)}Xb.A=0;Xb.D=function(Db){Db=$CLJS.z(Db);return jc(Db)};Xb.l=jc;return Xb}()}(u,"~w ~:i~@{~w~^ ~:_~}",Fa,H,J,S,0,x,y,C,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}(),v);$z()}finally{bA=J,gA=H}}$CLJS.B(u)&&function(){var Fa=dC("~_");return function(ab,Cb,Rb){return function(){function Xb(Db){var Vb=null;if(0<arguments.length){Vb=0;for(var xd=Array(arguments.length-
0);Vb<xd.length;)xd[Vb]=arguments[Vb+0],++Vb;Vb=new $CLJS.rd(xd,0,null)}return jc.call(this,Vb)}function jc(Db){Db=sA(Db);return uA(Rb,Db)}Xb.A=0;Xb.D=function(Db){Db=$CLJS.z(Db);return jc(Db)};Xb.l=jc;return Xb}()}(u,"~_",Fa,x,y,C,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()()}else $CLJS.eA(v),$CLJS.B(u)&&function(){var Fa=dC("~:_");return function(ab,Cb,Rb){return function(){function Xb(Db){var Vb=null;if(0<arguments.length){Vb=0;for(var xd=Array(arguments.length-0);Vb<xd.length;)xd[Vb]=arguments[Vb+0],++Vb;
Vb=new $CLJS.rd(xd,0,null)}return jc.call(this,Vb)}function jc(Db){Db=sA(Db);return uA(Rb,Db)}Xb.A=0;Xb.D=function(Db){Db=$CLJS.z(Db);return jc(Db)};Xb.l=jc;return Xb}()}(u,"~:_",Fa,v,l,n,q,0,b,c,d,a,e,f,g,f,g)}()();u=$CLJS.B(u)}else break;$z()}finally{bA=n,gA=l}}}else $CLJS.eA(a)};
Ofa=function(a,b){$CLJS.z(a)&&($CLJS.m(b)?function(){var c=dC(" ~_");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.rd(l,0,null)}return e.call(this,g)}function e(f){f=sA(f);return uA(c,f)}d.A=0;d.D=function(f){f=$CLJS.z(f);return e(f)};d.l=e;return d}()}()():function(){var c=dC(" ~@_");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<
l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.rd(l,0,null)}return e.call(this,g)}function e(f){f=sA(f);return uA(c,f)}d.A=0;d.D=function(f){f=$CLJS.z(f);return e(f)};d.l=e;return d}()}()(),function(){var c=dC("~{~w~^ ~_~}");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var l=Array(arguments.length-0);g<l.length;)l[g]=arguments[g+0],++g;g=new $CLJS.rd(l,0,null)}return e.call(this,g)}function e(f){f=sA(f);return uA(c,f)}d.A=0;d.D=function(f){f=$CLJS.z(f);return e(f)};d.l=
e;return d}()}()(a))};Pfa=function(a){$CLJS.z(a)&&function(){var b=dC(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var g=Array(arguments.length-0);f<g.length;)g[f]=arguments[f+0],++f;f=new $CLJS.rd(g,0,null)}return d.call(this,f)}function d(e){e=sA(e);return uA(b,e)}c.A=0;c.D=function(e){e=$CLJS.z(e);return d(e)};c.l=d;return c}()}()(a)};
fC=function(a){if($CLJS.B(a)){var b=$CLJS.z(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d="string"===typeof $CLJS.A(c)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,c],null);c=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);var e=$CLJS.je($CLJS.A(d))?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);d=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{var f=
gA,g=bA;gA+=1;bA=0;try{Wz("(",")"),function(){var l=dC("~w ~1I~@_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.rd(x,0,null)}return q.call(this,v)}function q(u){u=sA(u);return uA(l,u)}n.A=0;n.D=function(u){u=$CLJS.z(u);return q(u)};n.l=q;return n}()}()(a,b),$CLJS.m(c)&&function(){var l=dC(" ~_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.rd(x,0,null)}return q.call(this,v)}function q(u){u=sA(u);return uA(l,u)}n.A=0;n.D=function(u){u=$CLJS.z(u);return q(u)};n.l=q;return n}()}()(c),$CLJS.m(d)&&function(){var l=dC(" ~_~w");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.rd(x,0,null)}return q.call(this,v)}function q(u){u=sA(u);return uA(l,u)}n.A=0;n.D=function(u){u=$CLJS.z(u);return q(u)};
n.l=q;return n}()}()(d),$CLJS.le($CLJS.A(e))?Ofa(e,$CLJS.m(c)?c:d):Pfa(e),$z()}finally{bA=g,gA=f}}return null}return eC(a)};
Qfa=function(a){if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{var b=gA,c=bA;gA+=1;bA=0;try{Wz("[","]");for(var d=0;;){if($CLJS.Bb($CLJS.ub)||d<$CLJS.ub){if($CLJS.z(a)){if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{var e=gA,f=bA;gA+=1;bA=0;try{Wz(null,null),$CLJS.eA($CLJS.A(a)),$CLJS.B(a)&&($CLJS.Mc($CLJS.Ty," "),mA(dv),$CLJS.eA($CLJS.Vd(a))),$z()}finally{bA=f,gA=e}}if($CLJS.B($CLJS.vd(a))){$CLJS.Mc($CLJS.Ty," ");mA(fv);e=d+1;var g=$CLJS.B($CLJS.vd(a));d=e;a=g;continue}}}else $CLJS.Mc($CLJS.Ty,"...");
break}$z()}finally{bA=c,gA=b}}};
gC=function(a){var b=$CLJS.A(a);if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{var c=gA,d=bA;gA+=1;bA=0;try{Wz("(",")"),$CLJS.B(a)&&$CLJS.le($CLJS.Vd(a))?(function(){var e=dC("~w ~1I~@_");return function(){function f(l){var n=null;if(0<arguments.length){n=0;for(var q=Array(arguments.length-0);n<q.length;)q[n]=arguments[n+0],++n;n=new $CLJS.rd(q,0,null)}return g.call(this,n)}function g(l){l=sA(l);return uA(e,l)}f.A=0;f.D=function(l){l=$CLJS.z(l);return g(l)};f.l=g;return f}()}()(b),Qfa($CLJS.Vd(a)),
function(){var e=dC(" ~_~{~w~^ ~_~}");return function(){function f(l){var n=null;if(0<arguments.length){n=0;for(var q=Array(arguments.length-0);n<q.length;)q[n]=arguments[n+0],++n;n=new $CLJS.rd(q,0,null)}return g.call(this,n)}function g(l){l=sA(l);return uA(e,l)}f.A=0;f.D=function(l){l=$CLJS.z(l);return g(l)};f.l=g;return f}()}()($CLJS.B($CLJS.vd(a)))):eC(a),$z()}finally{bA=d,gA=c}}return null};
eC=function(a){if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{var b=gA,c=bA;gA+=1;bA=0;try{Wz("(",")");qA(gv,1);for(var d=0,e=$CLJS.z(a);;){if($CLJS.Bb($CLJS.ub)||d<$CLJS.ub){if(e&&($CLJS.eA($CLJS.A(e)),$CLJS.B(e))){$CLJS.Mc($CLJS.Ty," ");mA(fv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.Mc($CLJS.Ty,"...");break}$z()}finally{bA=c,gA=b}}return null};$CLJS.uf.prototype.Lc=$CLJS.Ja(2,function(){return $CLJS.Bb(this.ac)});$CLJS.ng.prototype.Lc=$CLJS.Ja(1,function(){return!1});
$CLJS.Ji.prototype.Lc=$CLJS.Ja(0,function(){return $CLJS.Bb(this.f)});$CLJS.Ty=null;hC=function hC(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return hC.l(0<c.length?new $CLJS.rd(c.slice(0),0,null):null)};hC.l=function(a){a=$CLJS.z(a);for(var b=$CLJS.Pc($CLJS.oh);;)if(a){var c=$CLJS.B(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.g($CLJS.A(a))].join(""));var d=$CLJS.B(c);b=$CLJS.ph.j(b,$CLJS.A(a),$CLJS.A(c));a=d}else return $CLJS.Rc(b)};
hC.A=0;hC.D=function(a){return this.l($CLJS.z(a))};$CLJS.SB=function SB(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return SB.l(arguments[0],1<c.length?new $CLJS.rd(c.slice(1),0,null):null)};
$CLJS.SB.l=function(a,b){if($CLJS.m($CLJS.Vf($CLJS.Ve,b))){var c=function(d,e){var f=$CLJS.qc(e),g=$CLJS.rc(e);if($CLJS.Ee(d,f)){e=$CLJS.T.j;var l=$CLJS.K.h(d,f);g=a.h?a.h(l,g):a.call(null,l,g);d=e.call($CLJS.T,d,f,g)}else d=$CLJS.T.j(d,f,g);return d};return $CLJS.Re(function(d,e){return $CLJS.Ob(c,$CLJS.m(d)?d:$CLJS.O,$CLJS.z(e))},b)}return null};$CLJS.SB.A=1;$CLJS.SB.D=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.VA=function VA(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return VA.l(0<c.length?new $CLJS.rd(c.slice(0),0,null):null)};$CLJS.VA.l=function(a){return $CLJS.Ei(a,$CLJS.T.j($CLJS.vb(),$CLJS.pb,!1))};$CLJS.VA.A=0;$CLJS.VA.D=function(a){return this.l($CLJS.z(a))};Du=new $CLJS.M(null,"trailing-white-space","trailing-white-space",1496006996);fw=new $CLJS.M(null,"relative-to","relative-to",-470100051);Mu=new $CLJS.M(null,"intra-block-nl","intra-block-nl",1808826875);
Fv=new $CLJS.M(null,"exponentchar","exponentchar",1986664222);gw=new $CLJS.w(null,"when-let","when-let",-1383043480,null);dv=new $CLJS.M(null,"miser","miser",-556060186);$CLJS.hv=new $CLJS.M(null,"current","current",-1088038603);hw=new $CLJS.w(null,"-\x3e","-\x3e",-2139605430,null);iw=new $CLJS.w(null,"..","..",-300507420,null);Eu=new $CLJS.M(null,"end-pos","end-pos",-1643883926);Ku=new $CLJS.M(null,"per-line-prefix","per-line-prefix",846941813);dw=new $CLJS.M(null,"queue","queue",1455835879);
jw=new $CLJS.w(null,"if-let","if-let",1803593690,null);av=new $CLJS.M(null,"end","end",-268185958);nv=new $CLJS.M(null,"remaining-arg-count","remaining-arg-count",-1216589335);Nu=new $CLJS.M(null,"logical-blocks","logical-blocks",-1466339776);kw=new $CLJS.w(null,"defmacro","defmacro",2054157304,null);lw=new $CLJS.w(null,"when-first","when-first",821699168,null);Zu=new $CLJS.M(null,"start-block-t","start-block-t",-373430594);mw=new $CLJS.w(null,"binding","binding",-2114503176,null);
Wv=new $CLJS.M(null,"bracket-info","bracket-info",-1600092774);Ru=new $CLJS.M(null,"writing","writing",-1486865108);mv=new $CLJS.M(null,"parameter-from-args","parameter-from-args",-758446196);Xu=new $CLJS.M(null,"logical-block-callback","logical-block-callback",1612691194);Hv=new $CLJS.M(null,"selector","selector",762528866);nw=new $CLJS.w(null,"struct","struct",325972931,null);Lu=new $CLJS.M(null,"indent","indent",-148200125);ow=new $CLJS.w(null,"loop","loop",1244978678,null);
Nv=new $CLJS.M(null,"max-columns","max-columns",1742323262);Uv=new $CLJS.M(null,"both","both",-393648840);pw=new $CLJS.M(null,"colnum","colnum",2023796854);qw=new $CLJS.w(null,"doseq","doseq",221164135,null);$CLJS.rw=new $CLJS.M(null,"length","length",588987862);uv=new $CLJS.M(null,"char-format","char-format",-1016499218);sw=new $CLJS.M(null,"prefix","prefix",-265908465);wv=new $CLJS.M(null,"colon-up-arrow","colon-up-arrow",244853007);$u=new $CLJS.M(null,"suffix","suffix",367373057);
lv=new $CLJS.M(null,"at","at",1476951349);Cu=new $CLJS.M(null,"nl-t","nl-t",-1608382114);Tv=new $CLJS.M(null,"directive","directive",793559132);Wu=new $CLJS.M(null,"buffer-level","buffer-level",928864731);cv=new $CLJS.M(null,"mandatory","mandatory",542802336);Uu=new $CLJS.M(null,"pretty-writer","pretty-writer",-1222834267);Gu=new $CLJS.M(null,"done-nl","done-nl",-381024340);tw=new $CLJS.w(null,"condp","condp",1054325175,null);jv=new $CLJS.M(null,"seq","seq",-1817803783);
uw=new $CLJS.w(null,"defn","defn",-126010802,null);qv=new $CLJS.M(null,"colinc","colinc",-584873385);vv=new $CLJS.M(null,"up-arrow","up-arrow",1705310333);Zv=new $CLJS.M(null,"right-bracket","right-bracket",951856080);vw=new $CLJS.M(null,"radix","radix",857016463);$v=new $CLJS.M(null,"first","first",-644103046);Vu=new $CLJS.M(null,"sections","sections",-886710106);$CLJS.Yu=new $CLJS.M(null,"start","start",-355208981);ww=new $CLJS.w("cljs.core","unquote","cljs.core/unquote",1013085760,null);
xw=new $CLJS.w(null,"defn-","defn-",1097765044,null);yw=new $CLJS.w("cljs.core","deref","cljs.core/deref",1901963335,null);gv=new $CLJS.M(null,"block","block",664686210);zw=new $CLJS.w(null,"when","when",1064114221,null);Lv=new $CLJS.M(null,"else-params","else-params",-832171646);$CLJS.Aw=new $CLJS.M(null,"count","count",2139924085);$CLJS.Yv=new $CLJS.M(null,"right","right",-452581833);fv=new $CLJS.M(null,"linear","linear",872268697);Bw=new $CLJS.w(null,"when-not","when-not",-1223136340,null);
rv=new $CLJS.M(null,"padchar","padchar",2018584530);pv=new $CLJS.M(null,"mincol","mincol",1230695445);Cw=new $CLJS.M(null,"not-delivered","not-delivered",1599158697);Hu=new $CLJS.M(null,"miser-width","miser-width",-1310049437);Dw=new $CLJS.w(null,"with-local-vars","with-local-vars",837642072,null);cw=new $CLJS.w(null,"lift-ns","lift-ns",602311926,null);aw=new $CLJS.M(null,"allows-separator","allows-separator",-818967742);Ou=new $CLJS.M(null,"buffering","buffering",-876713613);
Ew=new $CLJS.M(null,"arg1","arg1",951899358);yv=new $CLJS.M(null,"base-args","base-args",-1268706822);Fw=new $CLJS.M(null,"arg3","arg3",-1486822496);Gw=new $CLJS.M(null,"arg2","arg2",1729550917);sv=new $CLJS.M(null,"commainterval","commainterval",-1980061083);Hw=new $CLJS.M(null,"right-margin","right-margin",-810413306);Qu=new $CLJS.M(null,"buffer-blob","buffer-blob",-1830112173);Iw=new $CLJS.w(null,"with-open","with-open",172119667,null);
bw=new $CLJS.w("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);Jw=new $CLJS.M(null,"lines","lines",-700165781);iv=new $CLJS.M(null,"indent-t","indent-t",528318969);Kv=new $CLJS.M(null,"right-params","right-params",-1790676237);kv=new $CLJS.M(null,"colon","colon",-965200945);Kw=new $CLJS.w(null,"if-not","if-not",-265415609,null);zv=new $CLJS.M(null,"func","func",-238706040);$CLJS.Lw=new $CLJS.M(null,"last","last",1105735132);ew=new $CLJS.M(null,"deref","deref",-145586795);
Mw=new $CLJS.w(null,"dotimes","dotimes",-818708397,null);Jv=new $CLJS.M(null,"max-iterations","max-iterations",2021275563);Nw=new $CLJS.w(null,"cond","cond",1606708055,null);ov=new $CLJS.M(null,"minpad","minpad",323570901);Ju=new $CLJS.M(null,"logical-block","logical-block",-581022564);Ow=new $CLJS.w(null,"struct-map","struct-map",-1387540878,null);bv=new $CLJS.M(null,"end-block-t","end-block-t",1544648735);Pw=new $CLJS.M(null,"stream","stream",1534941648);xv=new $CLJS.M(null,"params","params",710516235);
Qw=new $CLJS.M(null,"circle","circle",1903212362);Fu=new $CLJS.M(null,"start-pos","start-pos",668789086);Sv=new $CLJS.M(null,"flags","flags",1775418075);ev=new $CLJS.M(null,"fill","fill",883462889);Tu=new $CLJS.M(null,"buffer-block","buffer-block",-10937307);Vv=new $CLJS.M(null,"generator-fn","generator-fn",811851656);Iu=new $CLJS.M(null,"start-col","start-col",668080143);Av=new $CLJS.M(null,"w","w",354169001);$CLJS.Gv=new $CLJS.M(null,"n","n",562130025);Xv=new $CLJS.M(null,"def","def",-1043430536);
$CLJS.Cv=new $CLJS.M(null,"k","k",-2146297393);Ev=new $CLJS.M(null,"e","e",1381269198);Bv=new $CLJS.M(null,"d","d",1972142424);Dv=new $CLJS.M(null,"overflowchar","overflowchar",-1620088106);Mv=new $CLJS.M(null,"min-remaining","min-remaining",962687677);tv=new $CLJS.M(null,"commachar","commachar",652859327);Rw=new $CLJS.M(null,"section","section",-300141526);Sw=new $CLJS.M(null,"pretty","pretty",-1916372486);Tw=new $CLJS.w(null,"let","let",358118826,null);Au=new $CLJS.M(null,"parent","parent",-878878779);
$CLJS.Iv=new $CLJS.M(null,"clauses","clauses",1454841241);Uw=new $CLJS.w(null,"defonce","defonce",-1681484013,null);yu=new $CLJS.M(null,"base","base",185279322);Bu=new $CLJS.M(null,"type-tag","type-tag",-1873863267);Vw=new $CLJS.w(null,"locking","locking",1542862874,null);Pu=new $CLJS.M(null,"pos","pos",-864607220);Ww=new $CLJS.M(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.xu=new $CLJS.M(null,"cur","cur",1153190599);var QA=function QA(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return QA.l(0<c.length?new $CLJS.rd(c.slice(0),0,null):null)},dA,Qz,uC,vC,wC,KA,JA,gA,bA,xC;QA.l=function(a){return $CLJS.Mc($CLJS.Ty,$CLJS.P.h($CLJS.VA,a))};QA.A=0;QA.D=function(a){return this.l($CLJS.z(a))};var Sy=function Sy(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Sy.l(0<c.length?new $CLJS.rd(c.slice(0),0,null):null)};
Sy.l=function(a){return $CLJS.Mc($CLJS.Ty,$CLJS.P.h($CLJS.Oi,a))};Sy.A=0;Sy.D=function(a){return this.l($CLJS.z(a))};$CLJS.h=zu.prototype;$CLJS.h.M=function(a,b){return new zu(this.La,this.rg,this.wd,b)};$CLJS.h.K=function(){return this.uf};$CLJS.h.fc=function(){return this.wd};$CLJS.h.Mc=function(){return Ny(this.La)};
$CLJS.h.ed=function(a,b){a=$CLJS.Kb(b);if($CLJS.m($CLJS.E.h?$CLJS.E.h(String,a):$CLJS.E.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?az(this,$CLJS.xu,$CLJS.$y(this,$CLJS.xu)+$CLJS.D(b)):(az(this,$CLJS.xu,$CLJS.D(b)-a-1),az(this,$CLJS.rj,$CLJS.$y(this,$CLJS.rj)+$CLJS.D($CLJS.vg(function(c){return $CLJS.E.h(c,"\n")},b)))),$CLJS.Mc($CLJS.$y(this,yu),b);if($CLJS.m($CLJS.E.h?$CLJS.E.h(Number,a):$CLJS.E.call(null,Number,a)))return Sea(this,b);throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));
};$CLJS.h=ez.prototype;$CLJS.h.la=function(a,b){return this.U(null,b,null)};
$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.M?b.R:null){case "parent":return this.parent;case "section":return this.Kb;case "start-col":return this.Cb;case "indent":return this.zb;case "done-nl":return this.Eb;case "intra-block-nl":return this.Fb;case "prefix":return this.prefix;case "per-line-prefix":return this.Jb;case "suffix":return this.Lb;case "logical-block-callback":return this.Gb;default:return $CLJS.K.j(this.m,b,c)}};
$CLJS.h.wa=function(a,b,c){return $CLJS.Ob(function(d,e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.Y=function(a,b,c){return $CLJS.ti(b,function(d){return $CLJS.ti(b,$CLJS.yi,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.sg.h(new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[Au,this.parent],null),new $CLJS.Q(null,2,5,$CLJS.R,[Rw,this.Kb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Iu,this.Cb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Lu,this.zb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Gu,this.Eb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Mu,this.Fb],null),new $CLJS.Q(null,2,5,$CLJS.R,
[sw,this.prefix],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ku,this.Jb],null),new $CLJS.Q(null,2,5,$CLJS.R,[$u,this.Lb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Xu,this.Gb],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.bh(this,10,new $CLJS.Q(null,10,5,$CLJS.R,[Au,Rw,Iu,Lu,Gu,Mu,sw,Ku,$u,Xu],null),$CLJS.m(this.m)?$CLJS.bd(this.m):$CLJS.Rf())};$CLJS.h.K=function(){return this.F};$CLJS.h.ia=function(){return 10+$CLJS.D(this.m)};
$CLJS.h.ga=function(){var a=this.H;return null!=a?a:this.H=a=1977012399^$CLJS.Bd(this)};$CLJS.h.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.parent,b.parent)&&$CLJS.E.h(this.Kb,b.Kb)&&$CLJS.E.h(this.Cb,b.Cb)&&$CLJS.E.h(this.zb,b.zb)&&$CLJS.E.h(this.Eb,b.Eb)&&$CLJS.E.h(this.Fb,b.Fb)&&$CLJS.E.h(this.prefix,b.prefix)&&$CLJS.E.h(this.Jb,b.Jb)&&$CLJS.E.h(this.Lb,b.Lb)&&$CLJS.E.h(this.Gb,b.Gb)&&$CLJS.E.h(this.m,b.m)};
$CLJS.h.Ba=function(a,b){return $CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,10,[$u,null,Lu,null,Au,null,Rw,null,Gu,null,Iu,null,sw,null,Ku,null,Xu,null,Mu,null],null),null),b)?$CLJS.$l.h($CLJS.zc($CLJS.mh.h($CLJS.O,this),this.F),b):new ez(this.parent,this.Kb,this.Cb,this.zb,this.Eb,this.Fb,this.prefix,this.Jb,this.Lb,this.Gb,this.F,$CLJS.Pf($CLJS.$l.h(this.m,b)),null)};
$CLJS.h.ua=function(a,b){switch(b instanceof $CLJS.M?b.R:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Ee(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.N.h?$CLJS.N.h(Au,b):$CLJS.N.call(null,Au,b))?new ez(c,this.Kb,this.Cb,this.zb,this.Eb,this.Fb,this.prefix,this.Jb,this.Lb,this.Gb,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Rw,b):$CLJS.N.call(null,Rw,b))?new ez(this.parent,c,this.Cb,this.zb,this.Eb,this.Fb,this.prefix,this.Jb,this.Lb,this.Gb,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Iu,b):$CLJS.N.call(null,Iu,b))?new ez(this.parent,this.Kb,c,this.zb,this.Eb,this.Fb,this.prefix,this.Jb,this.Lb,
this.Gb,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Lu,b):$CLJS.N.call(null,Lu,b))?new ez(this.parent,this.Kb,this.Cb,c,this.Eb,this.Fb,this.prefix,this.Jb,this.Lb,this.Gb,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Gu,b):$CLJS.N.call(null,Gu,b))?new ez(this.parent,this.Kb,this.Cb,this.zb,c,this.Fb,this.prefix,this.Jb,this.Lb,this.Gb,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Mu,b):$CLJS.N.call(null,Mu,b))?new ez(this.parent,this.Kb,this.Cb,this.zb,this.Eb,c,this.prefix,this.Jb,this.Lb,
this.Gb,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(sw,b):$CLJS.N.call(null,sw,b))?new ez(this.parent,this.Kb,this.Cb,this.zb,this.Eb,this.Fb,c,this.Jb,this.Lb,this.Gb,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Ku,b):$CLJS.N.call(null,Ku,b))?new ez(this.parent,this.Kb,this.Cb,this.zb,this.Eb,this.Fb,this.prefix,c,this.Lb,this.Gb,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h($u,b):$CLJS.N.call(null,$u,b))?new ez(this.parent,this.Kb,this.Cb,this.zb,this.Eb,this.Fb,this.prefix,this.Jb,c,this.Gb,
this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Xu,b):$CLJS.N.call(null,Xu,b))?new ez(this.parent,this.Kb,this.Cb,this.zb,this.Eb,this.Fb,this.prefix,this.Jb,this.Lb,c,this.F,this.m,null):new ez(this.parent,this.Kb,this.Cb,this.zb,this.Eb,this.Fb,this.prefix,this.Jb,this.Lb,this.Gb,this.F,$CLJS.T.j(this.m,b,c),null)};
$CLJS.h.fa=function(){return $CLJS.z($CLJS.sg.h(new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.Fe(Au,this.parent),new $CLJS.Fe(Rw,this.Kb),new $CLJS.Fe(Iu,this.Cb),new $CLJS.Fe(Lu,this.zb),new $CLJS.Fe(Gu,this.Eb),new $CLJS.Fe(Mu,this.Fb),new $CLJS.Fe(sw,this.prefix),new $CLJS.Fe(Ku,this.Jb),new $CLJS.Fe($u,this.Lb),new $CLJS.Fe(Xu,this.Gb)],null),this.m))};$CLJS.h.M=function(a,b){return new ez(this.parent,this.Kb,this.Cb,this.zb,this.Eb,this.Fb,this.prefix,this.Jb,this.Lb,this.Gb,b,this.m,this.H)};
$CLJS.h.ja=function(a,b){return $CLJS.le(b)?this.Z(null,$CLJS.Yd(b,0),$CLJS.Yd(b,1)):$CLJS.Ob($CLJS.ac,this,b)};$CLJS.h=gz.prototype;$CLJS.h.la=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.M?b.R:null){case "type-tag":return this.ea;case "data":return this.data;case "trailing-white-space":return this.uc;case "start-pos":return this.da;case "end-pos":return this.ca;default:return $CLJS.K.j(this.m,b,c)}};
$CLJS.h.wa=function(a,b,c){return $CLJS.Ob(function(d,e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.Y=function(a,b,c){return $CLJS.ti(b,function(d){return $CLJS.ti(b,$CLJS.yi,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.sg.h(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[Bu,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Nk,this.data],null),new $CLJS.Q(null,2,5,$CLJS.R,[Du,this.uc],null),new $CLJS.Q(null,2,5,$CLJS.R,[Fu,this.da],null),new $CLJS.Q(null,2,5,$CLJS.R,[Eu,this.ca],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.bh(this,5,new $CLJS.Q(null,5,5,$CLJS.R,[Bu,$CLJS.Nk,Du,Fu,Eu],null),$CLJS.m(this.m)?$CLJS.bd(this.m):$CLJS.Rf())};$CLJS.h.K=function(){return this.F};$CLJS.h.ia=function(){return 5+$CLJS.D(this.m)};$CLJS.h.ga=function(){var a=this.H;return null!=a?a:this.H=a=1809113693^$CLJS.Bd(this)};
$CLJS.h.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.ea,b.ea)&&$CLJS.E.h(this.data,b.data)&&$CLJS.E.h(this.uc,b.uc)&&$CLJS.E.h(this.da,b.da)&&$CLJS.E.h(this.ca,b.ca)&&$CLJS.E.h(this.m,b.m)};
$CLJS.h.Ba=function(a,b){return $CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,5,[Eu,null,Du,null,Bu,null,Fu,null,$CLJS.Nk,null],null),null),b)?$CLJS.$l.h($CLJS.zc($CLJS.mh.h($CLJS.O,this),this.F),b):new gz(this.ea,this.data,this.uc,this.da,this.ca,this.F,$CLJS.Pf($CLJS.$l.h(this.m,b)),null)};$CLJS.h.ua=function(a,b){switch(b instanceof $CLJS.M?b.R:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ee(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.N.h?$CLJS.N.h(Bu,b):$CLJS.N.call(null,Bu,b))?new gz(c,this.data,this.uc,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h($CLJS.Nk,b):$CLJS.N.call(null,$CLJS.Nk,b))?new gz(this.ea,c,this.uc,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Du,b):$CLJS.N.call(null,Du,b))?new gz(this.ea,this.data,c,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Fu,b):$CLJS.N.call(null,Fu,b))?new gz(this.ea,this.data,this.uc,
c,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Eu,b):$CLJS.N.call(null,Eu,b))?new gz(this.ea,this.data,this.uc,this.da,c,this.F,this.m,null):new gz(this.ea,this.data,this.uc,this.da,this.ca,this.F,$CLJS.T.j(this.m,b,c),null)};$CLJS.h.fa=function(){return $CLJS.z($CLJS.sg.h(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Fe(Bu,this.ea),new $CLJS.Fe($CLJS.Nk,this.data),new $CLJS.Fe(Du,this.uc),new $CLJS.Fe(Fu,this.da),new $CLJS.Fe(Eu,this.ca)],null),this.m))};
$CLJS.h.M=function(a,b){return new gz(this.ea,this.data,this.uc,this.da,this.ca,b,this.m,this.H)};$CLJS.h.ja=function(a,b){return $CLJS.le(b)?this.Z(null,$CLJS.Yd(b,0),$CLJS.Yd(b,1)):$CLJS.Ob($CLJS.ac,this,b)};$CLJS.h=hz.prototype;$CLJS.h.la=function(a,b){return this.U(null,b,null)};
$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.M?b.R:null){case "type-tag":return this.ea;case "type":return this.type;case "logical-block":return this.ka;case "start-pos":return this.da;case "end-pos":return this.ca;default:return $CLJS.K.j(this.m,b,c)}};$CLJS.h.wa=function(a,b,c){return $CLJS.Ob(function(d,e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.Y=function(a,b,c){return $CLJS.ti(b,function(d){return $CLJS.ti(b,$CLJS.yi,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.sg.h(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[Bu,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,this.type],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ju,this.ka],null),new $CLJS.Q(null,2,5,$CLJS.R,[Fu,this.da],null),new $CLJS.Q(null,2,5,$CLJS.R,[Eu,this.ca],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.bh(this,5,new $CLJS.Q(null,5,5,$CLJS.R,[Bu,$CLJS.Ik,Ju,Fu,Eu],null),$CLJS.m(this.m)?$CLJS.bd(this.m):$CLJS.Rf())};$CLJS.h.K=function(){return this.F};$CLJS.h.ia=function(){return 5+$CLJS.D(this.m)};$CLJS.h.ga=function(){var a=this.H;return null!=a?a:this.H=a=-1640656800^$CLJS.Bd(this)};
$CLJS.h.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.ea,b.ea)&&$CLJS.E.h(this.type,b.type)&&$CLJS.E.h(this.ka,b.ka)&&$CLJS.E.h(this.da,b.da)&&$CLJS.E.h(this.ca,b.ca)&&$CLJS.E.h(this.m,b.m)};
$CLJS.h.Ba=function(a,b){return $CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,5,[Eu,null,$CLJS.Ik,null,Ju,null,Bu,null,Fu,null],null),null),b)?$CLJS.$l.h($CLJS.zc($CLJS.mh.h($CLJS.O,this),this.F),b):new hz(this.ea,this.type,this.ka,this.da,this.ca,this.F,$CLJS.Pf($CLJS.$l.h(this.m,b)),null)};$CLJS.h.ua=function(a,b){switch(b instanceof $CLJS.M?b.R:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ee(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.N.h?$CLJS.N.h(Bu,b):$CLJS.N.call(null,Bu,b))?new hz(c,this.type,this.ka,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h($CLJS.Ik,b):$CLJS.N.call(null,$CLJS.Ik,b))?new hz(this.ea,c,this.ka,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Ju,b):$CLJS.N.call(null,Ju,b))?new hz(this.ea,this.type,c,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Fu,b):$CLJS.N.call(null,Fu,b))?new hz(this.ea,this.type,this.ka,
c,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Eu,b):$CLJS.N.call(null,Eu,b))?new hz(this.ea,this.type,this.ka,this.da,c,this.F,this.m,null):new hz(this.ea,this.type,this.ka,this.da,this.ca,this.F,$CLJS.T.j(this.m,b,c),null)};$CLJS.h.fa=function(){return $CLJS.z($CLJS.sg.h(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Fe(Bu,this.ea),new $CLJS.Fe($CLJS.Ik,this.type),new $CLJS.Fe(Ju,this.ka),new $CLJS.Fe(Fu,this.da),new $CLJS.Fe(Eu,this.ca)],null),this.m))};
$CLJS.h.M=function(a,b){return new hz(this.ea,this.type,this.ka,this.da,this.ca,b,this.m,this.H)};$CLJS.h.ja=function(a,b){return $CLJS.le(b)?this.Z(null,$CLJS.Yd(b,0),$CLJS.Yd(b,1)):$CLJS.Ob($CLJS.ac,this,b)};$CLJS.h=iz.prototype;$CLJS.h.la=function(a,b){return this.U(null,b,null)};
$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.M?b.R:null){case "type-tag":return this.ea;case "logical-block":return this.ka;case "start-pos":return this.da;case "end-pos":return this.ca;default:return $CLJS.K.j(this.m,b,c)}};$CLJS.h.wa=function(a,b,c){return $CLJS.Ob(function(d,e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.Y=function(a,b,c){return $CLJS.ti(b,function(d){return $CLJS.ti(b,$CLJS.yi,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.sg.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[Bu,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ju,this.ka],null),new $CLJS.Q(null,2,5,$CLJS.R,[Fu,this.da],null),new $CLJS.Q(null,2,5,$CLJS.R,[Eu,this.ca],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.bh(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[Bu,Ju,Fu,Eu],null),$CLJS.m(this.m)?$CLJS.bd(this.m):$CLJS.Rf())};$CLJS.h.K=function(){return this.F};$CLJS.h.ia=function(){return 4+$CLJS.D(this.m)};$CLJS.h.ga=function(){var a=this.H;return null!=a?a:this.H=a=-414877272^$CLJS.Bd(this)};
$CLJS.h.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.ea,b.ea)&&$CLJS.E.h(this.ka,b.ka)&&$CLJS.E.h(this.da,b.da)&&$CLJS.E.h(this.ca,b.ca)&&$CLJS.E.h(this.m,b.m)};$CLJS.h.Ba=function(a,b){return $CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,4,[Eu,null,Ju,null,Bu,null,Fu,null],null),null),b)?$CLJS.$l.h($CLJS.zc($CLJS.mh.h($CLJS.O,this),this.F),b):new iz(this.ea,this.ka,this.da,this.ca,this.F,$CLJS.Pf($CLJS.$l.h(this.m,b)),null)};
$CLJS.h.ua=function(a,b){switch(b instanceof $CLJS.M?b.R:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ee(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.N.h?$CLJS.N.h(Bu,b):$CLJS.N.call(null,Bu,b))?new iz(c,this.ka,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Ju,b):$CLJS.N.call(null,Ju,b))?new iz(this.ea,c,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Fu,b):$CLJS.N.call(null,Fu,b))?new iz(this.ea,this.ka,c,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Eu,b):$CLJS.N.call(null,Eu,b))?new iz(this.ea,this.ka,this.da,c,this.F,this.m,null):new iz(this.ea,this.ka,
this.da,this.ca,this.F,$CLJS.T.j(this.m,b,c),null)};$CLJS.h.fa=function(){return $CLJS.z($CLJS.sg.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Fe(Bu,this.ea),new $CLJS.Fe(Ju,this.ka),new $CLJS.Fe(Fu,this.da),new $CLJS.Fe(Eu,this.ca)],null),this.m))};$CLJS.h.M=function(a,b){return new iz(this.ea,this.ka,this.da,this.ca,b,this.m,this.H)};$CLJS.h.ja=function(a,b){return $CLJS.le(b)?this.Z(null,$CLJS.Yd(b,0),$CLJS.Yd(b,1)):$CLJS.Ob($CLJS.ac,this,b)};$CLJS.h=jz.prototype;
$CLJS.h.la=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.M?b.R:null){case "type-tag":return this.ea;case "logical-block":return this.ka;case "start-pos":return this.da;case "end-pos":return this.ca;default:return $CLJS.K.j(this.m,b,c)}};$CLJS.h.wa=function(a,b,c){return $CLJS.Ob(function(d,e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.Y=function(a,b,c){return $CLJS.ti(b,function(d){return $CLJS.ti(b,$CLJS.yi,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.sg.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[Bu,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ju,this.ka],null),new $CLJS.Q(null,2,5,$CLJS.R,[Fu,this.da],null),new $CLJS.Q(null,2,5,$CLJS.R,[Eu,this.ca],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.bh(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[Bu,Ju,Fu,Eu],null),$CLJS.m(this.m)?$CLJS.bd(this.m):$CLJS.Rf())};$CLJS.h.K=function(){return this.F};$CLJS.h.ia=function(){return 4+$CLJS.D(this.m)};$CLJS.h.ga=function(){var a=this.H;return null!=a?a:this.H=a=1365867980^$CLJS.Bd(this)};
$CLJS.h.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.ea,b.ea)&&$CLJS.E.h(this.ka,b.ka)&&$CLJS.E.h(this.da,b.da)&&$CLJS.E.h(this.ca,b.ca)&&$CLJS.E.h(this.m,b.m)};$CLJS.h.Ba=function(a,b){return $CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,4,[Eu,null,Ju,null,Bu,null,Fu,null],null),null),b)?$CLJS.$l.h($CLJS.zc($CLJS.mh.h($CLJS.O,this),this.F),b):new jz(this.ea,this.ka,this.da,this.ca,this.F,$CLJS.Pf($CLJS.$l.h(this.m,b)),null)};
$CLJS.h.ua=function(a,b){switch(b instanceof $CLJS.M?b.R:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ee(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.N.h?$CLJS.N.h(Bu,b):$CLJS.N.call(null,Bu,b))?new jz(c,this.ka,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Ju,b):$CLJS.N.call(null,Ju,b))?new jz(this.ea,c,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Fu,b):$CLJS.N.call(null,Fu,b))?new jz(this.ea,this.ka,c,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Eu,b):$CLJS.N.call(null,Eu,b))?new jz(this.ea,this.ka,this.da,c,this.F,this.m,null):new jz(this.ea,this.ka,
this.da,this.ca,this.F,$CLJS.T.j(this.m,b,c),null)};$CLJS.h.fa=function(){return $CLJS.z($CLJS.sg.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Fe(Bu,this.ea),new $CLJS.Fe(Ju,this.ka),new $CLJS.Fe(Fu,this.da),new $CLJS.Fe(Eu,this.ca)],null),this.m))};$CLJS.h.M=function(a,b){return new jz(this.ea,this.ka,this.da,this.ca,b,this.m,this.H)};$CLJS.h.ja=function(a,b){return $CLJS.le(b)?this.Z(null,$CLJS.Yd(b,0),$CLJS.Yd(b,1)):$CLJS.Ob($CLJS.ac,this,b)};$CLJS.h=kz.prototype;
$CLJS.h.la=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.M?b.R:null){case "type-tag":return this.ea;case "logical-block":return this.ka;case "relative-to":return this.jc;case "offset":return this.offset;case "start-pos":return this.da;case "end-pos":return this.ca;default:return $CLJS.K.j(this.m,b,c)}};$CLJS.h.wa=function(a,b,c){return $CLJS.Ob(function(d,e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.Y=function(a,b,c){return $CLJS.ti(b,function(d){return $CLJS.ti(b,$CLJS.yi,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.sg.h(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[Bu,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ju,this.ka],null),new $CLJS.Q(null,2,5,$CLJS.R,[fw,this.jc],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Xt,this.offset],null),new $CLJS.Q(null,2,5,$CLJS.R,[Fu,this.da],null),new $CLJS.Q(null,2,5,$CLJS.R,[Eu,this.ca],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.bh(this,6,new $CLJS.Q(null,6,5,$CLJS.R,[Bu,Ju,fw,$CLJS.Xt,Fu,Eu],null),$CLJS.m(this.m)?$CLJS.bd(this.m):$CLJS.Rf())};$CLJS.h.K=function(){return this.F};$CLJS.h.ia=function(){return 6+$CLJS.D(this.m)};$CLJS.h.ga=function(){var a=this.H;return null!=a?a:this.H=a=-1602780238^$CLJS.Bd(this)};
$CLJS.h.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.ea,b.ea)&&$CLJS.E.h(this.ka,b.ka)&&$CLJS.E.h(this.jc,b.jc)&&$CLJS.E.h(this.offset,b.offset)&&$CLJS.E.h(this.da,b.da)&&$CLJS.E.h(this.ca,b.ca)&&$CLJS.E.h(this.m,b.m)};
$CLJS.h.Ba=function(a,b){return $CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,6,[$CLJS.Xt,null,Eu,null,fw,null,Ju,null,Bu,null,Fu,null],null),null),b)?$CLJS.$l.h($CLJS.zc($CLJS.mh.h($CLJS.O,this),this.F),b):new kz(this.ea,this.ka,this.jc,this.offset,this.da,this.ca,this.F,$CLJS.Pf($CLJS.$l.h(this.m,b)),null)};
$CLJS.h.ua=function(a,b){switch(b instanceof $CLJS.M?b.R:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ee(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.N.h?$CLJS.N.h(Bu,b):$CLJS.N.call(null,Bu,b))?new kz(c,this.ka,this.jc,this.offset,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Ju,b):$CLJS.N.call(null,Ju,b))?new kz(this.ea,c,this.jc,this.offset,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(fw,b):$CLJS.N.call(null,fw,b))?new kz(this.ea,this.ka,c,this.offset,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h($CLJS.Xt,b):$CLJS.N.call(null,$CLJS.Xt,b))?new kz(this.ea,
this.ka,this.jc,c,this.da,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Fu,b):$CLJS.N.call(null,Fu,b))?new kz(this.ea,this.ka,this.jc,this.offset,c,this.ca,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Eu,b):$CLJS.N.call(null,Eu,b))?new kz(this.ea,this.ka,this.jc,this.offset,this.da,c,this.F,this.m,null):new kz(this.ea,this.ka,this.jc,this.offset,this.da,this.ca,this.F,$CLJS.T.j(this.m,b,c),null)};
$CLJS.h.fa=function(){return $CLJS.z($CLJS.sg.h(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Fe(Bu,this.ea),new $CLJS.Fe(Ju,this.ka),new $CLJS.Fe(fw,this.jc),new $CLJS.Fe($CLJS.Xt,this.offset),new $CLJS.Fe(Fu,this.da),new $CLJS.Fe(Eu,this.ca)],null),this.m))};$CLJS.h.M=function(a,b){return new kz(this.ea,this.ka,this.jc,this.offset,this.da,this.ca,b,this.m,this.H)};$CLJS.h.ja=function(a,b){return $CLJS.le(b)?this.Z(null,$CLJS.Yd(b,0),$CLJS.Yd(b,1)):$CLJS.Ob($CLJS.ac,this,b)};
var oz=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("cljs.pprint","write-token"),function(f,g){return Bu.g(g)},e,a,b,c,d)}();
oz.o(null,Zu,function(a,b){var c=Xu.g($CLJS.r($CLJS.r(a)));$CLJS.m(c)&&(c.g?c.g($CLJS.Yu):c.call(null,$CLJS.Yu));b=Ju.g(b);c=sw.g(b);$CLJS.m(c)&&$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),c);a=$CLJS.$y(yu.g($CLJS.r($CLJS.r(a))),$CLJS.xu);$CLJS.gg(Iu.g(b),a);return $CLJS.gg(Lu.g(b),a)});oz.o(null,bv,function(a,b){var c=Xu.g($CLJS.r($CLJS.r(a)));$CLJS.m(c)&&(c.g?c.g(av):c.call(null,av));b=$u.g(Ju.g(b));return $CLJS.m(b)?$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),b):null});
oz.o(null,iv,function(a,b){var c=Ju.g(b),d=Lu.g(c),e=$CLJS.Xt.g(b);b=fw.g(b);if($CLJS.m($CLJS.E.h?$CLJS.E.h(gv,b):$CLJS.E.call(null,gv,b)))a=$CLJS.r(Iu.g(c));else if($CLJS.m($CLJS.E.h?$CLJS.E.h($CLJS.hv,b):$CLJS.E.call(null,$CLJS.hv,b)))a=$CLJS.$y(yu.g($CLJS.r($CLJS.r(a))),$CLJS.xu);else throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));return $CLJS.gg(d,e+a)});oz.o(null,Qu,function(a,b){return $CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),$CLJS.Nk.g(b))});
oz.o(null,Cu,function(a,b){var c=$CLJS.E.h($CLJS.Ik.g(b),cv);c||(c=(c=!$CLJS.E.h($CLJS.Ik.g(b),ev))?$CLJS.r(Gu.g(Ju.g(b))):c);$CLJS.m(c)?Cz.h?Cz.h(a,b):Cz.call(null,a,b):(b=Du.g($CLJS.r($CLJS.r(a))),$CLJS.m(b)&&$CLJS.Mc(yu.g($CLJS.r($CLJS.r(a))),b));return $CLJS.Ri.B($CLJS.r(a),$CLJS.T,Du,null)});
var tC=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("cljs.pprint","emit-nl?"),function(f){return $CLJS.Ik.g(f)},e,a,b,c,d)}();tC.o(null,fv,function(a,b,c){a=Ju.g(a);return uz(b,a,c)});tC.o(null,dv,function(a,b,c){a=Ju.g(a);return xz(b,a,c)});
tC.o(null,ev,function(a,b,c,d){a=Ju.g(a);var e=$CLJS.r(Mu.g(a));return $CLJS.m(e)?e:(d=!sz(b,d))?d:xz(b,a,c)});tC.o(null,cv,function(){return!0});
var Ez=function Ez(a,b){var d=Vea(b);b=$CLJS.G(d,0,null);var e=$CLJS.G(d,1,null);$CLJS.m(b)&&pz(a,b,!1);if($CLJS.m(e)){d=Tea(e);b=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);var f=$CLJS.A(e),g=tC.B(f,a,b,Uea(e));$CLJS.m(g)&&(Cz(a,f),e=$CLJS.B(e));sz(a,e)?a=e:(e=Ez.h?Ez.h(a,b):Ez.call(null,a,b),$CLJS.E.h(e,b)?(pz(a,b,!1),a=d):a=$CLJS.mh.h($CLJS.Mg,$CLJS.sg.h(e,d)));return a}return null};$CLJS.h=Su.prototype;$CLJS.h.M=function(a,b){return new Su(this.La,this.rg,this.bj,this.Xh,this.wd,b)};$CLJS.h.K=function(){return this.Hf};
$CLJS.h.fc=function(){return this.wd};
$CLJS.h.ed=function(a,b){a=$CLJS.Kb(b);if($CLJS.m($CLJS.E.h?$CLJS.E.h(String,a):$CLJS.E.call(null,String,a))){var c=Nz(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.Pj.g($CLJS.r($CLJS.r(this)));if($CLJS.E.h(d,Ru))return Hz(this),$CLJS.Mc(yu.g($CLJS.r($CLJS.r(this))),b),$CLJS.Ri.B($CLJS.r(this),$CLJS.T,Du,a);d=Pu.g($CLJS.r($CLJS.r(this)));c=d+$CLJS.D(c);$CLJS.Ri.B($CLJS.r(this),$CLJS.T,Pu,c);return Gz(this,new gz(Qu,b,a,d,c,null,null,null))}if($CLJS.m($CLJS.E.h?$CLJS.E.h(Number,
a):$CLJS.E.call(null,Number,a)))return $CLJS.E.h($CLJS.Pj.g($CLJS.r($CLJS.r(this))),Ru)?(Hz(this),b=$CLJS.Mc(yu.g($CLJS.r($CLJS.r(this))),b)):$CLJS.E.h(b,"\n")?b=Nz(this,"\n"):(a=Pu.g($CLJS.r($CLJS.r(this))),c=a+1,$CLJS.Ri.B($CLJS.r(this),$CLJS.T,Pu,c),b=Ps(b),b=Gz(this,new gz(Qu,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};$CLJS.h.Mc=function(){this.Xf(null);return Ny(yu.g($CLJS.r($CLJS.r(this))))};
$CLJS.h.Xf=function(){return $CLJS.E.h($CLJS.Pj.g($CLJS.r($CLJS.r(this))),Ou)?(pz(this,$CLJS.Vt.g($CLJS.r($CLJS.r(this))),!0),$CLJS.Ri.B($CLJS.r(this),$CLJS.T,$CLJS.Vt,$CLJS.Mg)):Hz(this)};$CLJS.cA=!0;dA=null;$CLJS.Pz=72;Qz=40;uC=null;vC=null;wC=null;KA=null;JA=10;gA=0;bA=null;xC=function xC(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return xC.l(arguments[0],1<c.length?new $CLJS.rd(c.slice(1),0,null):null)};
xC.l=function(a,b){var c=$CLJS.xm.l($CLJS.F([new $CLJS.k(null,1,[Pw,!0],null),$CLJS.P.h(hC,b)]));b=JA;var d=vC,e=$CLJS.ub,f=$CLJS.ri,g=uC,l=Qz,n=dA,q=$CLJS.cA,u=KA,v=$CLJS.qb,x=$CLJS.Pz,y=wC,C=yu.h(c,JA),H=Qw.h(c,vC),J=$CLJS.rw.h(c,$CLJS.ub),S=$CLJS.bu.h(c,$CLJS.ri),V=Jw.h(c,uC),da=Hu.h(c,Qz),fa=$CLJS.Wf.h(c,dA),ma=Sw.h(c,$CLJS.cA),Fa=vw.h(c,KA),ab=$CLJS.pb.h(c,$CLJS.qb),Cb=Hw.h(c,$CLJS.Pz),Rb=Ww.h(c,wC);JA=C;vC=H;$CLJS.ub=J;$CLJS.ri=S;uC=V;Qz=da;dA=fa;$CLJS.cA=ma;KA=Fa;$CLJS.qb=ab;$CLJS.Pz=Cb;wC=
Rb;try{var Xb=new $CLJS.nb,jc=$CLJS.Ee(c,Pw)?Pw.g(c):!0,Db=!0===jc||null==jc?new $CLJS.cd(Xb):jc;if($CLJS.m($CLJS.cA)){var Vb=$CLJS.Bb($CLJS.aA(Db));c=$CLJS.Ty;$CLJS.Ty=Vb?$CLJS.Sz(Db):Db;try{$CLJS.eA(a),$CLJS.Zy()}finally{$CLJS.Ty=c}}else{Vb=$CLJS.Ty;$CLJS.Ty=Db;try{Sy.call(null,a)}finally{$CLJS.Ty=Vb}}!0===jc&&$CLJS.Qy($CLJS.p.g(Xb));return null==jc?$CLJS.p.g(Xb):null}finally{wC=y,$CLJS.Pz=x,$CLJS.qb=v,KA=u,$CLJS.cA=q,dA=n,Qz=l,uC=g,$CLJS.ri=f,$CLJS.ub=e,vC=d,JA=b}};xC.A=1;
xC.D=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};var wA=null;$CLJS.h=yA.prototype;$CLJS.h.la=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.M?b.R:null){case "seq":return this.Rc;case "rest":return this.ub;case "pos":return this.sc;default:return $CLJS.K.j(this.m,b,c)}};$CLJS.h.wa=function(a,b,c){return $CLJS.Ob(function(d,e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.Y=function(a,b,c){return $CLJS.ti(b,function(d){return $CLJS.ti(b,$CLJS.yi,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.sg.h(new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[jv,this.Rc],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf,this.ub],null),new $CLJS.Q(null,2,5,$CLJS.R,[Pu,this.sc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.bh(this,3,new $CLJS.Q(null,3,5,$CLJS.R,[jv,$CLJS.Yf,Pu],null),$CLJS.m(this.m)?$CLJS.bd(this.m):$CLJS.Rf())};
$CLJS.h.K=function(){return this.F};$CLJS.h.ia=function(){return 3+$CLJS.D(this.m)};$CLJS.h.ga=function(){var a=this.H;return null!=a?a:this.H=a=-402038447^$CLJS.Bd(this)};$CLJS.h.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.Rc,b.Rc)&&$CLJS.E.h(this.ub,b.ub)&&$CLJS.E.h(this.sc,b.sc)&&$CLJS.E.h(this.m,b.m)};
$CLJS.h.Ba=function(a,b){return $CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,3,[Pu,null,jv,null,$CLJS.Yf,null],null),null),b)?$CLJS.$l.h($CLJS.zc($CLJS.mh.h($CLJS.O,this),this.F),b):new yA(this.Rc,this.ub,this.sc,this.F,$CLJS.Pf($CLJS.$l.h(this.m,b)),null)};$CLJS.h.ua=function(a,b){switch(b instanceof $CLJS.M?b.R:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Ee(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.N.h?$CLJS.N.h(jv,b):$CLJS.N.call(null,jv,b))?new yA(c,this.ub,this.sc,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h($CLJS.Yf,b):$CLJS.N.call(null,$CLJS.Yf,b))?new yA(this.Rc,c,this.sc,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Pu,b):$CLJS.N.call(null,Pu,b))?new yA(this.Rc,this.ub,c,this.F,this.m,null):new yA(this.Rc,this.ub,this.sc,this.F,$CLJS.T.j(this.m,b,c),null)};
$CLJS.h.fa=function(){return $CLJS.z($CLJS.sg.h(new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.Fe(jv,this.Rc),new $CLJS.Fe($CLJS.Yf,this.ub),new $CLJS.Fe(Pu,this.sc)],null),this.m))};$CLJS.h.M=function(a,b){return new yA(this.Rc,this.ub,this.sc,b,this.m,this.H)};$CLJS.h.ja=function(a,b){return $CLJS.le(b)?this.Z(null,$CLJS.Yd(b,0),$CLJS.Yd(b,1)):$CLJS.Ob($CLJS.ac,this,b)};$CLJS.h=DA.prototype;$CLJS.h.la=function(a,b){return this.U(null,b,null)};
$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.M?b.R:null){case "func":return this.xc;case "def":return this.vc;case "params":return this.Ec;case "offset":return this.offset;default:return $CLJS.K.j(this.m,b,c)}};$CLJS.h.wa=function(a,b,c){return $CLJS.Ob(function(d,e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.Y=function(a,b,c){return $CLJS.ti(b,function(d){return $CLJS.ti(b,$CLJS.yi,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.sg.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[zv,this.xc],null),new $CLJS.Q(null,2,5,$CLJS.R,[Xv,this.vc],null),new $CLJS.Q(null,2,5,$CLJS.R,[xv,this.Ec],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Xt,this.offset],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.bh(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[zv,Xv,xv,$CLJS.Xt],null),$CLJS.m(this.m)?$CLJS.bd(this.m):$CLJS.Rf())};$CLJS.h.K=function(){return this.F};$CLJS.h.ia=function(){return 4+$CLJS.D(this.m)};$CLJS.h.ga=function(){var a=this.H;return null!=a?a:this.H=a=-829256337^$CLJS.Bd(this)};
$CLJS.h.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.xc,b.xc)&&$CLJS.E.h(this.vc,b.vc)&&$CLJS.E.h(this.Ec,b.Ec)&&$CLJS.E.h(this.offset,b.offset)&&$CLJS.E.h(this.m,b.m)};$CLJS.h.Ba=function(a,b){return $CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,4,[$CLJS.Xt,null,zv,null,xv,null,Xv,null],null),null),b)?$CLJS.$l.h($CLJS.zc($CLJS.mh.h($CLJS.O,this),this.F),b):new DA(this.xc,this.vc,this.Ec,this.offset,this.F,$CLJS.Pf($CLJS.$l.h(this.m,b)),null)};
$CLJS.h.ua=function(a,b){switch(b instanceof $CLJS.M?b.R:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Ee(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.N.h?$CLJS.N.h(zv,b):$CLJS.N.call(null,zv,b))?new DA(c,this.vc,this.Ec,this.offset,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(Xv,b):$CLJS.N.call(null,Xv,b))?new DA(this.xc,c,this.Ec,this.offset,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h(xv,b):$CLJS.N.call(null,xv,b))?new DA(this.xc,this.vc,c,this.offset,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h($CLJS.Xt,b):$CLJS.N.call(null,$CLJS.Xt,b))?new DA(this.xc,this.vc,this.Ec,c,this.F,this.m,null):
new DA(this.xc,this.vc,this.Ec,this.offset,this.F,$CLJS.T.j(this.m,b,c),null)};$CLJS.h.fa=function(){return $CLJS.z($CLJS.sg.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Fe(zv,this.xc),new $CLJS.Fe(Xv,this.vc),new $CLJS.Fe(xv,this.Ec),new $CLJS.Fe($CLJS.Xt,this.offset)],null),this.m))};$CLJS.h.M=function(a,b){return new DA(this.xc,this.vc,this.Ec,this.offset,b,this.m,this.H)};$CLJS.h.ja=function(a,b){return $CLJS.le(b)?this.Z(null,$CLJS.Yd(b,0),$CLJS.Yd(b,1)):$CLJS.Ob($CLJS.ac,this,b)};
var $ea=new $CLJS.k(null,3,[2,"#b",8,"#o",16,"#x"],null),XA=new $CLJS.Q(null,20,5,$CLJS.R,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),yC=new $CLJS.Q(null,20,5,$CLJS.R,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),YA=new $CLJS.Q(null,10,5,$CLJS.R,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),zga=new $CLJS.Q(null,10,5,$CLJS.R,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),$A=new $CLJS.Q(null,22,5,$CLJS.R," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),Aga=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,9,5,$CLJS.R,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.Q(null,3,5,$CLJS.R,["M","MM","MMM"],null)],null),Bga=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,9,5,$CLJS.R,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.Q(null,3,5,$CLJS.R,["M","MM","MMM"],null)],null),bfa=new $CLJS.k(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);Ov.prototype.M=function(a,b){return new Ov(this.La,b)};Ov.prototype.K=function(){return this.Jg};Ov.prototype.Mc=function(){return Ny(this.La)};
Ov.prototype.ed=function(a,b){a=$CLJS.Kb(b);if($CLJS.m($CLJS.E.h?$CLJS.E.h(String,a):$CLJS.E.call(null,String,a)))return $CLJS.Mc(this.La,b.toLowerCase());if($CLJS.m($CLJS.E.h?$CLJS.E.h(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.Mc(this.La,Ps(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};Pv.prototype.M=function(a,b){return new Pv(this.La,b)};Pv.prototype.K=function(){return this.eh};Pv.prototype.Mc=function(){return Ny(this.La)};
Pv.prototype.ed=function(a,b){a=$CLJS.Kb(b);if($CLJS.m($CLJS.E.h?$CLJS.E.h(String,a):$CLJS.E.call(null,String,a)))return $CLJS.Mc(this.La,b.toUpperCase());if($CLJS.m($CLJS.E.h?$CLJS.E.h(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.Mc(this.La,Ps(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};Qv.prototype.M=function(a,b){return new Qv(this.La,this.se,b)};Qv.prototype.K=function(){return this.hh};Qv.prototype.Mc=function(){return Ny(this.La)};
Qv.prototype.ed=function(a,b){a=$CLJS.Kb(b);if($CLJS.m($CLJS.E.h?$CLJS.E.h(String,a):$CLJS.E.call(null,String,a)))return $CLJS.Mc(this.La,tfa(b.toLowerCase(),$CLJS.r(this.se))),0<b.length?$CLJS.gg(this.se,$CLJS.Pa($CLJS.Pd(b,$CLJS.D(b)-1))):null;if($CLJS.m($CLJS.E.h?$CLJS.E.h(Number,a):$CLJS.E.call(null,Number,a)))return b=Ps(b),a=$CLJS.m($CLJS.r(this.se))?b.toUpperCase():b,$CLJS.Mc(this.La,a),$CLJS.gg(this.se,$CLJS.Pa(b));throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};
Rv.prototype.M=function(a,b){return new Rv(this.La,this.ce,b)};Rv.prototype.K=function(){return this.Nh};Rv.prototype.Mc=function(){return Ny(this.La)};
Rv.prototype.ed=function(a,b){a=$CLJS.Kb(b);if($CLJS.m($CLJS.E.h?$CLJS.E.h(String,a):$CLJS.E.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Bb($CLJS.r(this.ce))?(a=RegExp("\\S","g").exec(b),a=$CLJS.m(a)?a.index:a,$CLJS.m(a)?($CLJS.Mc(this.La,[b.substring(0,a),$CLJS.Pd(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.gg(this.ce,!0)):$CLJS.Mc(this.La,b)):$CLJS.Mc(this.La,b.toLowerCase());if($CLJS.m($CLJS.E.h?$CLJS.E.h(Number,a):$CLJS.E.call(null,Number,a)))return b=Ps(b),a=$CLJS.Bb($CLJS.r(this.ce)),
$CLJS.m(a?Ly(b):a)?($CLJS.gg(this.ce,!0),$CLJS.Mc(this.La,b.toUpperCase())):$CLJS.Mc(this.La,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));};
var Gfa=$CLJS.Qh("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.k(null,5,[Tv,"A",xv,new $CLJS.k(null,4,[pv,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),qv,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),ov,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),rv,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,Uv,null],null),null),Wv,$CLJS.O,Vv,function(){return function(a,b){return RA($CLJS.VA,a,b)}}],null),new $CLJS.k(null,
5,[Tv,"S",xv,new $CLJS.k(null,4,[pv,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),qv,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),ov,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),rv,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,Uv,null],null),null),Wv,$CLJS.O,Vv,function(){return function(a,b){return RA($CLJS.Oi,a,b)}}],null),new $CLJS.k(null,5,[Tv,"D",xv,new $CLJS.k(null,4,[pv,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),rv,new $CLJS.Q(null,
2,5,$CLJS.R,[" ",String],null),tv,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),sv,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,Uv,null],null),null),Wv,$CLJS.O,Vv,function(){return function(a,b){return WA(10,a,b)}}],null),new $CLJS.k(null,5,[Tv,"B",xv,new $CLJS.k(null,4,[pv,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),rv,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),tv,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),sv,new $CLJS.Q(null,
2,5,$CLJS.R,[3,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,Uv,null],null),null),Wv,$CLJS.O,Vv,function(){return function(a,b){return WA(2,a,b)}}],null),new $CLJS.k(null,5,[Tv,"O",xv,new $CLJS.k(null,4,[pv,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),rv,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),tv,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),sv,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,Uv,
null],null),null),Wv,$CLJS.O,Vv,function(){return function(a,b){return WA(8,a,b)}}],null),new $CLJS.k(null,5,[Tv,"X",xv,new $CLJS.k(null,4,[pv,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),rv,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),tv,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),sv,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,Uv,null],null),null),Wv,$CLJS.O,Vv,function(){return function(a,b){return WA(16,a,b)}}],null),new $CLJS.k(null,
5,[Tv,"R",xv,new $CLJS.k(null,5,[yu,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),pv,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),rv,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),tv,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),sv,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,Uv,null],null),null),Wv,$CLJS.O,Vv,function(a){return $CLJS.m($CLJS.A(yu.g(a)))?function(b,c){return WA(yu.g(b),b,c)}:$CLJS.m(function(){var b=lv.g(a);return $CLJS.m(b)?
kv.g(a):b}())?function(b,c){return bB(Aga,c)}:$CLJS.m(lv.g(a))?function(b,c){return bB(Bga,c)}:$CLJS.m(kv.g(a))?function(b,c){b=zA(c);c=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);if($CLJS.E.h(0,c))QA.l($CLJS.F(["zeroth"]));else{var d=SA(1E3,0>c?-c:c);if($CLJS.D(d)<=$CLJS.D($A)){var e=$CLJS.kg.h(ZA,$CLJS.lg(1,d));e=aB(e,1);var f=$CLJS.Wd(d);d=$CLJS.Ye(f,100);f=Py(f,100);var g=0<d?[$CLJS.p.g($CLJS.Pd(XA,d))," hundred"].join(""):null,l=$CLJS.p,n=l.g;if(0<f)if(20>f)var q=$CLJS.Pd(yC,f);else{q=$CLJS.Ye(f,10);
var u=Py(f,10);q=0<q&&!(0<u)?$CLJS.Pd(zga,q):[$CLJS.p.g(0<q?$CLJS.Pd(YA,q):null),0<q&&0<u?"-":null,$CLJS.p.g(0<u?$CLJS.Pd(yC,u):null)].join("")}else q=0<d?"th":null;d=[g,0<d&&0<f?" ":null,n.call(l,q)].join("");QA.l($CLJS.F([[0>c?"minus ":null,$CLJS.ee(e)||$CLJS.ee(d)?$CLJS.ee(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else WA(10,new $CLJS.k(null,5,[pv,0,rv," ",tv,",",sv,3,kv,!0],null),sA(new $CLJS.Q(null,1,5,$CLJS.R,[c],null))),e=Py(c,100),c=11<e||19>e,e=Py(e,10),QA.l($CLJS.F([1===e&&
c?"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=zA(c);b=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);if($CLJS.E.h(0,b))QA.l($CLJS.F(["zero"]));else{var d=SA(1E3,0>b?-b:b);$CLJS.D(d)<=$CLJS.D($A)?(d=$CLJS.kg.h(ZA,d),d=aB(d,0),QA.l($CLJS.F([[0>b?"minus ":null,d].join("")]))):WA(10,new $CLJS.k(null,5,[pv,0,rv," ",tv,",",sv,3,kv,!0],null),sA(new $CLJS.Q(null,1,5,$CLJS.R,[b],null)))}return c}}],null),new $CLJS.k(null,5,[Tv,"P",xv,$CLJS.O,Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,
null,Uv,null],null),null),Wv,$CLJS.O,Vv,function(){return function(a,b){b=$CLJS.m(kv.g(a))?BA(b,-1):b;a=$CLJS.m(lv.g(a))?new $CLJS.Q(null,2,5,$CLJS.R,["y","ies"],null):new $CLJS.Q(null,2,5,$CLJS.R,["","s"],null);var c=zA(b);b=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);QA.l($CLJS.F([$CLJS.E.h(b,1)?$CLJS.A(a):$CLJS.Vd(a)]));return c}}],null),new $CLJS.k(null,5,[Tv,"C",xv,new $CLJS.k(null,1,[uv,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,
Uv,null],null),null),Wv,$CLJS.O,Vv,function(a){return $CLJS.m(kv.g(a))?cfa:$CLJS.m(lv.g(a))?dfa:efa}],null),new $CLJS.k(null,5,[Tv,"F",xv,new $CLJS.k(null,5,[Av,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Bv,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Cv,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Dv,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),rv,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,1,[lv,null],null),null),Wv,$CLJS.O,Vv,
function(){return qB}],null),new $CLJS.k(null,5,[Tv,"E",xv,new $CLJS.k(null,7,[Av,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Bv,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Ev,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Cv,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Dv,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),rv,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),Fv,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,1,[lv,null],
null),null),Wv,$CLJS.O,Vv,function(){return tB}],null),new $CLJS.k(null,5,[Tv,"G",xv,new $CLJS.k(null,7,[Av,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Bv,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Ev,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Cv,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Dv,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),rv,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),Fv,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,
1,[lv,null],null),null),Wv,$CLJS.O,Vv,function(){return gfa}],null),new $CLJS.k(null,5,[Tv,"$",xv,new $CLJS.k(null,4,[Bv,new $CLJS.Q(null,2,5,$CLJS.R,[2,Number],null),$CLJS.Gv,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Av,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),rv,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,Uv,null],null),null),Wv,$CLJS.O,Vv,function(){return hfa}],null),new $CLJS.k(null,5,[Tv,"%",xv,new $CLJS.k(null,1,[$CLJS.Aw,
new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),Sv,$CLJS.ai,Wv,$CLJS.O,Vv,function(){return function(a,b){a=$CLJS.Aw.g(a);for(var c=0;;)if(c<a)Uy(),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[Tv,"\x26",xv,new $CLJS.k(null,1,[$CLJS.Aw,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,1,[Sw,null],null),null),Wv,$CLJS.O,Vv,function(){return function(a,b){a=$CLJS.Aw.g(a);0<a&&((null!=$CLJS.Ty?$CLJS.Ty.C&32768||$CLJS.t===$CLJS.Ty.Of||($CLJS.Ty.C?0:
$CLJS.Jb($CLJS.My,$CLJS.Ty)):$CLJS.Jb($CLJS.My,$CLJS.Ty))?$CLJS.E.h(0,$CLJS.$y(yu.g($CLJS.r($CLJS.r($CLJS.Ty))),$CLJS.xu))||Uy():Uy());--a;for(var c=0;;)if(c<a)Uy(),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[Tv,"|",xv,new $CLJS.k(null,1,[$CLJS.Aw,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),Sv,$CLJS.ai,Wv,$CLJS.O,Vv,function(){return function(a,b){a=$CLJS.Aw.g(a);for(var c=0;;)if(c<a)QA.l($CLJS.F(["\f"])),c+=1;else break;return b}}],null),new $CLJS.k(null,5,[Tv,"~",xv,new $CLJS.k(null,
1,[$CLJS.Gv,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),Sv,$CLJS.ai,Wv,$CLJS.O,Vv,function(){return function(a,b){a=$CLJS.Gv.g(a);QA.l($CLJS.F([$CLJS.P.h($CLJS.p,$CLJS.pg(a,"~"))]));return b}}],null),new $CLJS.k(null,5,[Tv,"\n",xv,$CLJS.O,Sv,new $CLJS.U(null,new $CLJS.k(null,2,[kv,null,lv,null],null),null),Wv,$CLJS.O,Vv,function(){return function(a,b){$CLJS.m(lv.g(a))&&Uy();return b}}],null),new $CLJS.k(null,5,[Tv,"T",xv,new $CLJS.k(null,2,[pw,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),
qv,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,2,[lv,null,Sw,null],null),null),Wv,$CLJS.O,Vv,function(a){return $CLJS.m(lv.g(a))?function(b,c){var d=pw.g(b);b=qv.g(b);var e=d+$CLJS.$y(yu.g($CLJS.r($CLJS.r($CLJS.Ty))),$CLJS.xu);e=0<b?Py(e,b):0;d+=$CLJS.E.h(0,e)?0:b-e;QA.l($CLJS.F([$CLJS.P.h($CLJS.p,$CLJS.pg(d," "))]));return c}:function(b,c){var d=pw.g(b);b=qv.g(b);var e=$CLJS.$y(yu.g($CLJS.r($CLJS.r($CLJS.Ty))),$CLJS.xu);d=e<d?d-e:$CLJS.E.h(b,0)?0:b-Py(e-
d,b);QA.l($CLJS.F([$CLJS.P.h($CLJS.p,$CLJS.pg(d," "))]));return c}}],null),new $CLJS.k(null,5,[Tv,"*",xv,new $CLJS.k(null,1,[$CLJS.Gv,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,2,[kv,null,lv,null],null),null),Wv,$CLJS.O,Vv,function(){return function(a,b){var c=$CLJS.Gv.g(a);return $CLJS.m(lv.g(a))?CA(b,c):BA(b,$CLJS.m(kv.g(a))?-c:c)}}],null),new $CLJS.k(null,5,[Tv,"?",xv,$CLJS.O,Sv,new $CLJS.U(null,new $CLJS.k(null,1,[lv,null],null),null),Wv,$CLJS.O,
Vv,function(a){return $CLJS.m(lv.g(a))?function(b,c){var d=AA(c);c=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);return jB(c,d,yv.g(b))}:function(b,c){var d=AA(c);c=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);var e=zA(d);d=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);d=sA(d);jB(c,d,yv.g(b));return e}}],null),new $CLJS.k(null,5,[Tv,"(",xv,$CLJS.O,Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,Uv,null],null),null),Wv,new $CLJS.k(null,3,[$CLJS.Yv,")",aw,null,$CLJS.Wt,null],null),Vv,function(a){var b=$CLJS.m(function(){var c=
lv.g(a);return $CLJS.m(c)?kv.g(a):c}())?sfa:$CLJS.m(kv.g(a))?ufa:$CLJS.m(lv.g(a))?vfa:rfa;return function(c,d){a:{var e=$CLJS.A($CLJS.Iv.g(c)),f=$CLJS.Ty;$CLJS.Ty=b.g?b.g($CLJS.Ty):b.call(null,$CLJS.Ty);try{var g=jB(e,d,yv.g(c));break a}finally{$CLJS.Ty=f}g=void 0}return g}}],null),new $CLJS.k(null,5,[Tv,")",xv,$CLJS.O,Sv,$CLJS.ai,Wv,$CLJS.O,Vv,function(){return null}],null),new $CLJS.k(null,5,[Tv,"[",xv,new $CLJS.k(null,1,[Hv,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),Sv,new $CLJS.U(null,
new $CLJS.k(null,2,[kv,null,lv,null],null),null),Wv,new $CLJS.k(null,3,[$CLJS.Yv,"]",aw,!0,$CLJS.Wt,$CLJS.Lw],null),Vv,function(a){return $CLJS.m(kv.g(a))?jfa:$CLJS.m(lv.g(a))?kfa:ifa}],null),new $CLJS.k(null,5,[Tv,";",xv,new $CLJS.k(null,2,[Mv,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Nv,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,1,[kv,null],null),null),Wv,new $CLJS.k(null,1,[$CLJS.Zt,!0],null),Vv,function(){return null}],null),new $CLJS.k(null,
5,[Tv,"]",xv,$CLJS.O,Sv,$CLJS.ai,Wv,$CLJS.O,Vv,function(){return null}],null),new $CLJS.k(null,5,[Tv,"{",xv,new $CLJS.k(null,1,[Jv,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,Uv,null],null),null),Wv,new $CLJS.k(null,2,[$CLJS.Yv,"}",aw,!1],null),Vv,function(a){var b=lv.g(a);b=$CLJS.m(b)?kv.g(a):b;return $CLJS.m(b)?ofa:$CLJS.m(kv.g(a))?mfa:$CLJS.m(lv.g(a))?nfa:lfa}],null),new $CLJS.k(null,5,[Tv,"}",xv,$CLJS.O,Sv,new $CLJS.U(null,new $CLJS.k(null,
1,[kv,null],null),null),Wv,$CLJS.O,Vv,function(){return null}],null),new $CLJS.k(null,5,[Tv,"\x3c",xv,new $CLJS.k(null,4,[pv,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),qv,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),ov,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),rv,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,4,[kv,null,lv,null,Uv,null,Sw,null],null),null),Wv,new $CLJS.k(null,3,[$CLJS.Yv,"\x3e",aw,!0,$CLJS.Wt,$v],null),Vv,function(){return qfa}],
null),new $CLJS.k(null,5,[Tv,"\x3e",xv,$CLJS.O,Sv,new $CLJS.U(null,new $CLJS.k(null,1,[kv,null],null),null),Wv,$CLJS.O,Vv,function(){return null}],null),new $CLJS.k(null,5,[Tv,"^",xv,new $CLJS.k(null,3,[Ew,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Gw,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Fw,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,1,[kv,null],null),null),Wv,$CLJS.O,Vv,function(){return function(a,b){var c=Ew.g(a),d=Gw.g(a),e=Fw.g(a),
f=$CLJS.m(kv.g(a))?wv:vv;return $CLJS.m($CLJS.m(c)?$CLJS.m(d)?e:d:c)?c<=d&&d<=e?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b:$CLJS.m($CLJS.m(c)?d:c)?$CLJS.E.h(c,d)?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b:$CLJS.m(c)?$CLJS.E.h(c,0)?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b:($CLJS.m(kv.g(a))?$CLJS.ee($CLJS.Yf.g(yv.g(a))):$CLJS.ee($CLJS.Yf.g(b)))?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b}}],null),new $CLJS.k(null,5,[Tv,"W",xv,$CLJS.O,Sv,new $CLJS.U(null,new $CLJS.k(null,4,[kv,null,lv,null,Uv,null,
Sw,null],null),null),Wv,$CLJS.O,Vv,function(a){if($CLJS.m(function(){var c=lv.g(a);return $CLJS.m(c)?c:kv.g(a)}())){var b=$CLJS.sg.h($CLJS.m(lv.g(a))?new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bu,null,$CLJS.rw,null],null):$CLJS.Mg,$CLJS.m(kv.g(a))?new $CLJS.Q(null,2,5,$CLJS.R,[Sw,!0],null):$CLJS.Mg);return function(c,d){d=zA(d);c=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);return $CLJS.m($CLJS.P.j(xC,c,b))?new $CLJS.Q(null,2,5,$CLJS.R,[vv,d],null):d}}return function(c,d){d=zA(d);c=$CLJS.G(d,0,null);d=$CLJS.G(d,
1,null);return $CLJS.m($CLJS.eA(c))?new $CLJS.Q(null,2,5,$CLJS.R,[vv,d],null):d}}],null),new $CLJS.k(null,5,[Tv,"_",xv,$CLJS.O,Sv,new $CLJS.U(null,new $CLJS.k(null,3,[kv,null,lv,null,Uv,null],null),null),Wv,$CLJS.O,Vv,function(){return xfa}],null),new $CLJS.k(null,5,[Tv,"I",xv,new $CLJS.k(null,1,[$CLJS.Gv,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null)],null),Sv,new $CLJS.U(null,new $CLJS.k(null,1,[kv,null],null),null),Wv,$CLJS.O,Vv,function(){return wfa}],null)]),yfa=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,
Efa=new $CLJS.U(null,new $CLJS.k(null,2,[nv,null,mv,null],null),null),Bfa=new $CLJS.k(null,2,[":",kv,"@",lv],null),tA=function tA(a){for(;;){if($CLJS.ee(a))return!1;var c=Sw.g(Sv.g(Xv.g($CLJS.A(a))));$CLJS.m(c)||(c=$CLJS.Vf(tA,$CLJS.A($CLJS.Iv.g(xv.g($CLJS.A(a))))),c=$CLJS.m(c)?c:$CLJS.Vf(tA,$CLJS.A($CLJS.Wt.g(xv.g($CLJS.A(a))))));if($CLJS.m(c))return!0;a=$CLJS.B(a)}},dC=$CLJS.Si(rA),VB=new $CLJS.k(null,6,[$CLJS.il,"'",$CLJS.ml,"#'",$CLJS.Yt,"@",$CLJS.au,"~",yw,"@",ww,"~"],null);
(function(){var a=dC("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.rd(f,0,null)}return c.call(this,e)}function c(d){d=sA(d);return uA(a,d)}b.A=0;b.D=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()})();
var zC=function(){var a=dC("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.rd(f,0,null)}return c.call(this,e)}function c(d){d=sA(d);return uA(a,d)}b.A=0;b.D=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),AC=new $CLJS.k(null,2,["core$future_call","Future","core$promise","Promise"],null),Cga=function(){var a=dC("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.rd(f,0,null)}return c.call(this,e)}function c(d){d=sA(d);return uA(a,d)}b.A=0;b.D=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),BC,Xw=$CLJS.ka($CLJS.O),Yw=$CLJS.ka($CLJS.O),Zw=$CLJS.ka($CLJS.O),$w=$CLJS.ka($CLJS.O),ax=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));
BC=new $CLJS.hj($CLJS.Fi.h("cljs.pprint","simple-dispatch"),bC,ax,Xw,Yw,Zw,$w);Ry(BC,$CLJS.Bj,function(a){if($CLJS.Bb(WB(a)))if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{var b=gA,c=bA;gA+=1;bA=0;try{Wz("(",")");for(var d=0,e=$CLJS.z(a);;){if($CLJS.Bb($CLJS.ub)||d<$CLJS.ub){if(e&&($CLJS.eA($CLJS.A(e)),$CLJS.B(e))){$CLJS.Mc($CLJS.Ty," ");mA(fv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.Mc($CLJS.Ty,"...");break}$z()}finally{bA=c,gA=b}}return null});Ry(BC,$CLJS.Fj,XB);Ry(BC,$CLJS.Wj,YB);
Ry(BC,$CLJS.rg,zC);Ry(BC,null,function(){return $CLJS.Mc($CLJS.Ty,$CLJS.Oi.l($CLJS.F([null])))});Ry(BC,$CLJS.Dj,ZB);dA=BC;
var CC=function(){var a=dC("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.rd(f,0,null)}return c.call(this,e)}function c(d){d=sA(d);return uA(a,d)}b.A=0;b.D=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),DC=function(){var a=dC("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.rd(f,0,null)}return c.call(this,e)}function c(d){d=sA(d);return uA(a,d)}b.A=0;b.D=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),EC=$CLJS.O,Iga=function(a){return $CLJS.mh.h($CLJS.O,$CLJS.tg($CLJS.Ve,$CLJS.F([function(){return function d(c){return new $CLJS.uf(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.me(e)){var f=$CLJS.Wc(e),g=$CLJS.D(f),l=$CLJS.xf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.Yd(f,n);q=
new $CLJS.Q(null,2,5,$CLJS.R,[q,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fi.g($CLJS.fi($CLJS.A(q))),$CLJS.Vd(q)],null)],null);l.add(q);n+=1}else{f=!0;break a}return f?$CLJS.Af($CLJS.Ef(l),d($CLJS.Xc(e))):$CLJS.Af($CLJS.Ef(l),null)}l=$CLJS.A(e);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[l,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fi.g($CLJS.fi($CLJS.A(l))),$CLJS.Vd(l)],null)],null),d($CLJS.vd(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.mh.h($CLJS.O,$CLJS.kg.h(function(b){var c=$CLJS.G(b,
0,null),d=$CLJS.G(b,1,null);var e=$CLJS.mf(c);e=$CLJS.m(e)?e:$CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,24,[$CLJS.uk,"null",$CLJS.naa,"null",$CLJS.Baa,"null",$CLJS.Daa,"null",$CLJS.maa,"null",$CLJS.uaa,"null",$CLJS.taa,"null",$CLJS.vaa,"null",$CLJS.daa,"null",$CLJS.pl,"null",$CLJS.qaa,"null",$CLJS.Pk,"null",$CLJS.zaa,"null",$CLJS.Caa,"null",$CLJS.iaa,"null",$CLJS.ek,"null",$CLJS.laa,"null",$CLJS.paa,"null",$CLJS.vk,"null",$CLJS.ml,"null",$CLJS.il,"null",$CLJS.Aaa,"null",$CLJS.haa,"null",$CLJS.vl,
"null"],null),null),c);return $CLJS.Bb(e)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Fi.h("clojure.core",$CLJS.fi(c)),d],null):b},a))}($CLJS.Qh([$CLJS.vk,$CLJS.ek,lw,$CLJS.pl,tw,iw,kw,uw,ow,nw,qw,Kw,Bw,$CLJS.vl,zw,Iw,Dw,Uw,gw,$CLJS.Pk,Mw,Nw,Tw,$CLJS.jl,xw,Vw,hw,jw,mw,Ow],[CC,function(a){var b=$CLJS.Vd(a),c=$CLJS.A($CLJS.vd($CLJS.vd(a)));if($CLJS.le(b)){a=EC;EC=$CLJS.E.h(1,$CLJS.D(b))?$CLJS.Nf([$CLJS.A(b),"%"]):$CLJS.mh.h($CLJS.O,$CLJS.kg.j(function(d,e){return new $CLJS.Q(null,2,5,$CLJS.R,[d,["%",$CLJS.p.g(e)].join("")],
null)},b,$CLJS.wu(1,$CLJS.D(b)+1)));try{return function(){var d=dC("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(g){var l=null;if(0<arguments.length){l=0;for(var n=Array(arguments.length-0);l<n.length;)n[l]=arguments[l+0],++l;l=new $CLJS.rd(n,0,null)}return f.call(this,l)}function f(g){g=sA(g);return uA(d,g)}e.A=0;e.D=function(g){g=$CLJS.z(g);return f(g)};e.l=f;return e}()}()(c)}finally{EC=a}}else return eC(a)},gC,DC,function(a){if(3<$CLJS.D(a)){if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,
"#");else{var b=gA,c=bA;gA+=1;bA=0;try{Wz("(",")");qA(gv,1);$CLJS.P.h(function(){var l=dC("~w ~@_~w ~@_~w ~_");return function(){function n(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.rd(x,0,null)}return q.call(this,v)}function q(u){u=sA(u);return uA(l,u)}n.A=0;n.D=function(u){u=$CLJS.z(u);return q(u)};n.l=q;return n}()}(),a);for(var d=0,e=$CLJS.z($CLJS.jg(3,a));;){if($CLJS.Bb($CLJS.ub)||d<$CLJS.ub){if(e){if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,
"#");else{a=gA;var f=bA;gA+=1;bA=0;try{Wz(null,null),$CLJS.eA($CLJS.A(e)),$CLJS.B(e)&&($CLJS.Mc($CLJS.Ty," "),mA(dv),$CLJS.eA($CLJS.Vd(e))),$z()}finally{bA=f,gA=a}}if($CLJS.B($CLJS.vd(e))){$CLJS.Mc($CLJS.Ty," ");mA(fv);a=d+1;var g=$CLJS.B($CLJS.vd(e));d=a;e=g;continue}}}else $CLJS.Mc($CLJS.Ty,"...");break}$z()}finally{bA=c,gA=b}}return null}return eC(a)},CC,fC,fC,gC,CC,gC,DC,DC,CC,DC,gC,gC,CC,gC,function(a){if($CLJS.B(a)){var b=$CLJS.z(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=
"string"===typeof $CLJS.A(c)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,c],null);c=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);d=$CLJS.je($CLJS.A(d))?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);var e=$CLJS.G(d,0,null),f=$CLJS.G(d,1,null);if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{d=gA;var g=bA;gA+=1;bA=0;try{Wz("(",")");(function(){var n=dC("~w ~1I~@_~w");return function(){function q(v){var x=
null;if(0<arguments.length){x=0;for(var y=Array(arguments.length-0);x<y.length;)y[x]=arguments[x+0],++x;x=new $CLJS.rd(y,0,null)}return u.call(this,x)}function u(v){v=sA(v);return uA(n,v)}q.A=0;q.D=function(v){v=$CLJS.z(v);return u(v)};q.l=u;return q}()})()(a,b);$CLJS.m($CLJS.m(c)?c:$CLJS.m(e)?e:$CLJS.z(f))&&function(){var n=dC("~@:_");return function(){function q(v){var x=null;if(0<arguments.length){x=0;for(var y=Array(arguments.length-0);x<y.length;)y[x]=arguments[x+0],++x;x=new $CLJS.rd(y,0,null)}return u.call(this,
x)}function u(v){v=sA(v);return uA(n,v)}q.A=0;q.D=function(v){v=$CLJS.z(v);return u(v)};q.l=u;return q}()}()();$CLJS.m(c)&&vA(!0,'"~a"~:[~;~:@_~]',$CLJS.F([c,$CLJS.m(e)?e:$CLJS.z(f)]));$CLJS.m(e)&&function(){var n=dC("~w~:[~;~:@_~]");return function(){function q(v){var x=null;if(0<arguments.length){x=0;for(var y=Array(arguments.length-0);x<y.length;)y[x]=arguments[x+0],++x;x=new $CLJS.rd(y,0,null)}return u.call(this,x)}function u(v){v=sA(v);return uA(n,v)}q.A=0;q.D=function(v){v=$CLJS.z(v);return u(v)};
q.l=u;return q}()}()(e,$CLJS.z(f));for(a=f;;){Nfa($CLJS.A(a));var l=$CLJS.B(a);if(l)b=l,mA(fv),a=b;else break}$z()}finally{bA=g,gA=d}}return null}return $CLJS.eA(a)},gC,function(a){if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{var b=gA,c=bA;gA+=1;bA=0;try{Wz("(",")");qA(gv,1);$CLJS.eA($CLJS.A(a));if($CLJS.B(a)){$CLJS.Mc($CLJS.Ty," ");mA(fv);for(var d=0,e=$CLJS.B(a);;){if($CLJS.Bb($CLJS.ub)||d<$CLJS.ub){if(e){if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{a=gA;var f=bA;gA+=1;bA=0;try{Wz(null,null),$CLJS.eA($CLJS.A(e)),
$CLJS.B(e)&&($CLJS.Mc($CLJS.Ty," "),mA(dv),$CLJS.eA($CLJS.Vd(e))),$z()}finally{bA=f,gA=a}}if($CLJS.B($CLJS.vd(e))){$CLJS.Mc($CLJS.Ty," ");mA(fv);a=d+1;var g=$CLJS.B($CLJS.vd(e));d=a;e=g;continue}}}else $CLJS.Mc($CLJS.Ty,"...");break}}$z()}finally{bA=c,gA=b}}return null},gC,fC,fC,CC,CC,gC,gC,CC]))),FC,bx=$CLJS.ka($CLJS.O),cx=$CLJS.ka($CLJS.O),dx=$CLJS.ka($CLJS.O),ex=$CLJS.ka($CLJS.O),fx=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));
FC=new $CLJS.hj($CLJS.Fi.h("cljs.pprint","code-dispatch"),bC,fx,bx,cx,dx,ex);Ry(FC,$CLJS.Bj,function(a){if($CLJS.Bb(WB(a))){var b=Iga.call(null,$CLJS.A(a));return $CLJS.m(b)?b.g?b.g(a):b.call(null,a):eC(a)}return null});Ry(FC,$CLJS.Yj,function(a){var b=a.g?a.g(EC):a.call(null,EC);return $CLJS.m(b)?QA.l($CLJS.F([b])):$CLJS.m(wC)?QA.l($CLJS.F([$CLJS.fi(a)])):Sy.call(null,a)});Ry(FC,$CLJS.Fj,XB);Ry(FC,$CLJS.Wj,YB);Ry(FC,$CLJS.rg,zC);Ry(FC,dw,Cga);
Ry(FC,ew,function(a){var b=$CLJS.p,c=b.g,d=$CLJS.Kb(a).name;var e=$CLJS.qi(/^[^$]+\$[^$]+/,d);e=$CLJS.m(e)?AC.g?AC.g(e):AC.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.m(e)?e:d),"@",$CLJS.p.g($CLJS.Na(a)),": "].join("");if($CLJS.m(hA()))$CLJS.Mc($CLJS.Ty,"#");else{c=gA;d=bA;gA+=1;bA=0;try{Wz(b,"\x3e");qA(gv,-(b.length-2));mA(fv);var f=null!=a?a.J&1||$CLJS.t===a.Xj?!0:a.J?!1:$CLJS.Jb(Oy,a):$CLJS.Jb(Oy,a);var g=f?!Ns(a):f;$CLJS.eA(g?Cw:$CLJS.r(a));$z()}finally{bA=d,gA=c}}return null});Ry(FC,null,Sy);
Ry(FC,$CLJS.Dj,ZB);dA=BC;