var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./no.en.core.js");
'use strict';var O1,oka,P1,Z1,d2;O1=function(a,b,c,d,e){this.pattern=a;this.Rb=b;this.F=c;this.m=d;this.H=e;this.C=2230716170;this.J=139264};oka=function(a,b){return new O1(a,b,null,null,null)};P1=function(a,b){$CLJS.Ee($CLJS.di($CLJS.r(a)),b)||$CLJS.Ri.j(a,$CLJS.gf,b)};$CLJS.Q1=function(a){if(null==a||"string"===typeof a)return a;if(a instanceof $CLJS.M||a instanceof $CLJS.w){var b=$CLJS.mf(a);return $CLJS.m(b)?[b,"/",$CLJS.fi(a)].join(""):$CLJS.fi(a)}return null};
Z1=function(a){a=$CLJS.z($CLJS.P.h(T1,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.ha(null,d);P1($CLJS.Y1,e);d+=1}else if(a=$CLJS.z(a))b=a,$CLJS.me(b)?(a=$CLJS.Wc(b),c=$CLJS.Xc(b),b=a,e=$CLJS.D(a),a=c,c=e):(e=$CLJS.A(b),P1($CLJS.Y1,e),a=$CLJS.B(b),b=null,c=0),d=0;else break};
d2=function(a){a=$CLJS.z($CLJS.P.h(T1,a));for(var b=null,c=0,d=0;;)if(d<c){var e=b.ha(null,d);P1($1,e);d+=1}else if(a=$CLJS.z(a))b=a,$CLJS.me(b)?(a=$CLJS.Wc(b),c=$CLJS.Xc(b),b=a,e=$CLJS.D(a),a=c,c=e):(e=$CLJS.A(b),P1($1,e),a=$CLJS.B(b),b=null,c=0),d=0;else break};var $1,h2;$CLJS.h=O1.prototype;$CLJS.h.la=function(a,b){return this.U(null,b,null)};$CLJS.h.U=function(a,b,c){switch(b instanceof $CLJS.M?b.R:null){case "pattern":return this.pattern;case "replacement":return this.Rb;default:return $CLJS.K.j(this.m,b,c)}};$CLJS.h.wa=function(a,b,c){return $CLJS.Ob(function(d,e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.Y=function(a,b,c){return $CLJS.ti(b,function(d){return $CLJS.ti(b,$CLJS.yi,""," ","",c,d)},"#inflections.core.Rule{",", ","}",c,$CLJS.sg.h(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iA,this.pattern],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jA,this.Rb],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.bh(this,2,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iA,$CLJS.jA],null),$CLJS.m(this.m)?$CLJS.bd(this.m):$CLJS.Rf())};$CLJS.h.K=function(){return this.F};
$CLJS.h.ia=function(){return 2+$CLJS.D(this.m)};$CLJS.h.ga=function(){var a=this.H;return null!=a?a:this.H=a=-1327743444^$CLJS.Bd(this)};$CLJS.h.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.pattern,b.pattern)&&$CLJS.E.h(this.Rb,b.Rb)&&$CLJS.E.h(this.m,b.m)};
$CLJS.h.Ba=function(a,b){return $CLJS.Ee(new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.jA,null,$CLJS.iA,null],null),null),b)?$CLJS.$l.h($CLJS.zc($CLJS.mh.h($CLJS.O,this),this.F),b):new O1(this.pattern,this.Rb,this.F,$CLJS.Pf($CLJS.$l.h(this.m,b)),null)};$CLJS.h.ua=function(a,b){switch(b instanceof $CLJS.M?b.R:null){case "pattern":case "replacement":return!0;default:return $CLJS.Ee(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.N.h?$CLJS.N.h($CLJS.iA,b):$CLJS.N.call(null,$CLJS.iA,b))?new O1(c,this.Rb,this.F,this.m,null):$CLJS.m($CLJS.N.h?$CLJS.N.h($CLJS.jA,b):$CLJS.N.call(null,$CLJS.jA,b))?new O1(this.pattern,c,this.F,this.m,null):new O1(this.pattern,this.Rb,this.F,$CLJS.T.j(this.m,b,c),null)};$CLJS.h.fa=function(){return $CLJS.z($CLJS.sg.h(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Fe($CLJS.iA,this.pattern),new $CLJS.Fe($CLJS.jA,this.Rb)],null),this.m))};
$CLJS.h.M=function(a,b){return new O1(this.pattern,this.Rb,b,this.m,this.H)};$CLJS.h.ja=function(a,b){return $CLJS.le(b)?this.Z(null,$CLJS.Yd(b,0),$CLJS.Yd(b,1)):$CLJS.Ob($CLJS.ac,this,b)};var T1=function T1(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return T1.l(0<c.length?new $CLJS.rd(c.slice(0),0,null):null)};T1.l=function(a){return $CLJS.kg.h(function(b){return $CLJS.P.h(oka,b)},$CLJS.UL(2,2,a))};T1.A=0;T1.D=function(a){return this.l($CLJS.z(a))};
$CLJS.g2=$CLJS.ka(new $CLJS.U(null,new $CLJS.k(null,81,["gossip",null,"sadness",null,"space",null,"happiness",null,"blood",null,"silver",null,"cotton",null,"species",null,"mist",null,"paper",null,"education",null,"wine",null,"up",null,"food",null,"sugar",null,"gold",null,"literature",null,"pork",null,"lightning",null,"sheep",null,"shopping",null,"fresh",null,"news",null,"pepper",null,"experience",null,"milk",null,"honey",null,"oxygen",null,"fiction",null,"luck",null,"vinegar",null,"coffee",null,"ground",
null,"peanut",null,"confusion",null,"tennis",null,"power",null,"meat",null,"butter",null,"music",null,"luggage",null,"love",null,"grass",null,"knowledge",null,"time",null,"cheese",null,"jam",null,"thunder",null,"electricity",null,"gum",null,"sunshine",null,"history",null,"snow",null,"money",null,"wool",null,"rice",null,"series",null,"liquid",null,"equipment",null,"pressure",null,"oil",null,"information",null,"steam",null,"chewing",null,"petrol",null,"research",null,"patience",null,"toothpaste",null,
"speed",null,"entertainment",null,"wood",null,"tea",null,"art",null,"washing",null,"forgiveness",null,"traffic",null,"alcohol",null,"ice",null,"homework",null,"fish",null,"air",null],null),null));$CLJS.Y1=$CLJS.ka($CLJS.Mg);$1=$CLJS.ka($CLJS.Mg);h2=$CLJS.ka(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.Ob($CLJS.ac,$CLJS.Dk,0<b.length?new $CLJS.rd(b.slice(0),0,null):null)}());
Z1($CLJS.F([/$/i,"s",/s$/i,"s",/(ax|test)is$/i,"$1es",/(octop|vir)us$/i,"$1i",/(alias|status)$/i,"$1es",/(bu)s$/i,"$1ses",/(buffal|tomat)o$/i,"$1oes",/([ti])um$/i,"$1a",/sis$/i,"ses",/(?:([^f])fe|([lr])f)$/i,"$1$2ves",/(hive)$/i,"$1s",/([^aeiouy]|qu)y$/i,"$1ies",/(x|ch|ss|sh)$/i,"$1es",/(matr|vert|ind)(?:ix|ex)$/i,"$1ices",/([m|l])ouse$/i,"$1ice",/^(ox)$/i,"$1en",/(quiz)$/i,"$1zes"]));
d2($CLJS.F([/s$/i,"",/(ss)$/i,"$1",/(n)ews$/i,"$1ews",/([ti])a$/i,"$1um",/((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)(sis|ses)$/i,"$1$2sis",/(^analy)(sis|ses)$/i,"$1sis",/([^f])ves$/i,"$1fe",/(hive)s$/i,"$1",/(tive)s$/i,"$1",/([lr])ves$/i,"$1f",/([^aeiouy]|qu)ies$/i,"$1y",/(s)eries$/i,"$1eries",/(m)ovies$/i,"$1ovie",/(x|ch|ss|sh)es$/i,"$1",/([m|l])ice$/i,"$1ouse",/(bus)(es)?$/i,"$1",/(o)es$/i,"$1",/(shoe)s$/i,"$1",/(cris|ax|test)(is|es)$/i,"$1is",/(octop|vir)(us|i)$/i,"$1us",/(alias|status)(es)?$/i,
"$1",/^(ox)en/i,"$1",/(vert|ind)ices$/i,"$1ex",/(matr)ices$/i,"$1ix",/(quiz)zes$/i,"$1",/(database)s$/i,"$1"]));
$CLJS.oi($CLJS.kg.h(function(a){var b=$CLJS.A(a);a=$CLJS.Vd(a);b=$CLJS.Q1(b).toLowerCase();a=$CLJS.Q1(a).toLowerCase();$CLJS.Ri.j($CLJS.g2,$CLJS.Jy,$CLJS.Q1(b).toLowerCase());$CLJS.Ri.j($CLJS.g2,$CLJS.Jy,$CLJS.Q1(a).toLowerCase());d2($CLJS.F([$CLJS.Bn(["^",a,"$"].join("")),b]));Z1($CLJS.F([$CLJS.Bn(["^",b,"$"].join("")),a]));$CLJS.Ri.j(h2,$CLJS.gf,b);return $CLJS.Ri.j(h2,$CLJS.gf,a)},new $CLJS.Q(null,14,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,["amenity","amenities"],null),new $CLJS.Q(null,2,5,$CLJS.R,
["child","children"],null),new $CLJS.Q(null,2,5,$CLJS.R,["cow","kine"],null),new $CLJS.Q(null,2,5,$CLJS.R,["foot","feet"],null),new $CLJS.Q(null,2,5,$CLJS.R,["louse","lice"],null),new $CLJS.Q(null,2,5,$CLJS.R,["mailman","mailmen"],null),new $CLJS.Q(null,2,5,$CLJS.R,["man","men"],null),new $CLJS.Q(null,2,5,$CLJS.R,["mouse","mice"],null),new $CLJS.Q(null,2,5,$CLJS.R,["move","moves"],null),new $CLJS.Q(null,2,5,$CLJS.R,["ox","oxen"],null),new $CLJS.Q(null,2,5,$CLJS.R,["person","people"],null),new $CLJS.Q(null,
2,5,$CLJS.R,["sex","sexes"],null),new $CLJS.Q(null,2,5,$CLJS.R,["tooth","teeth"],null),new $CLJS.Q(null,2,5,$CLJS.R,["woman","women"],null)],null)));