var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var YX;$CLJS.MX=function(a){return a instanceof Error?a.message:null};
$CLJS.Es=function(a,b,c){if($CLJS.ef(c)){var d=$CLJS.P.h($CLJS.Y,$CLJS.kg.h(a,c));return b.g?b.g(d):b.call(null,d)}return $CLJS.Jg(c)?(d=new $CLJS.Fe(function(){var e=$CLJS.qc(c);return a.g?a.g(e):a.call(null,e)}(),function(){var e=$CLJS.rc(c);return a.g?a.g(e):a.call(null,e)}()),b.g?b.g(d):b.call(null,d)):$CLJS.re(c)?(d=$CLJS.oi($CLJS.kg.h(a,c)),b.g?b.g(d):b.call(null,d)):$CLJS.ke(c)?(d=$CLJS.Ob(function(e,f){return $CLJS.gf.h(e,a.g?a.g(f):a.call(null,f))},c,c),b.g?b.g(d):b.call(null,d)):$CLJS.fe(c)?
(d=$CLJS.mh.h($CLJS.Xd(c),$CLJS.kg.h(a,c)),b.g?b.g(d):b.call(null,d)):b.g?b.g(c):b.call(null,c)};$CLJS.TX=function(a){function b(c){var d=$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);return"string"===typeof d?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zi.g(d),c],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null)}return $CLJS.Ks(function(c){return $CLJS.je(c)?$CLJS.mh.h($CLJS.O,$CLJS.kg.h(b,c)):c},a)};
$CLJS.IW=function(a){var b=new $CLJS.nb;for(a=$CLJS.z(a);;)if(null!=a)b=b.append($CLJS.p.g($CLJS.A(a))),a=$CLJS.B(a);else return b.toString()};$CLJS.ZX=function(a,b,c){a=$CLJS.Ys(a,/''/,"'");var d=$CLJS.$s(a,YX);d=$CLJS.E.h($CLJS.D(d),1)?new $CLJS.Q(null,2,5,$CLJS.R,[a,""],null):d;a=$CLJS.qi(/.*\{0\}.*/,a);return $CLJS.hy.ngettext($CLJS.hy.msgid($CLJS.zm(d),$CLJS.m(a)?c:""),$CLJS.Ys($CLJS.Ys(b,/''/,"'"),YX,$CLJS.p.g(c)),c)};
$CLJS.SL=function(a,b){return $CLJS.Ob(function(c,d){var e=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);return $CLJS.Ee(a,e)?$CLJS.T.j(c,d,$CLJS.K.h(a,e)):c},$CLJS.P.j($CLJS.$l,a,$CLJS.Uh(b)),b)};$CLJS.sX=function(a,b,c){return $CLJS.je(c)?$CLJS.tg(function(d){var e=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);e=$CLJS.gf.h(b,e);return a.h?a.h(e,d):a.call(null,e,d)},$CLJS.F([c])):$CLJS.ie(c)?$CLJS.tg($CLJS.bg(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.gf.h(b,$CLJS.A(c)):b),$CLJS.F([c])):null};
$CLJS.Ks=function Ks(a,b){return $CLJS.Es($CLJS.bg(Ks,a),a,b)};YX=/\{0\}/;$CLJS.JW=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.KW=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.LW=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var fY=$CLJS.Qh([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$J,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wJ,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OJ,$CLJS.FJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OJ,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XJ,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NJ,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.RJ,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fJ,null],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SJ,null],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.OJ,$CLJS.tJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OJ,$CLJS.MJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vJ,null],null)],[$CLJS.Ue,$CLJS.bI,$CLJS.aK,$CLJS.zJ,$CLJS.xe,$CLJS.Ej,$CLJS.pj,$CLJS.ak,$CLJS.Cf,$CLJS.zJ,$CLJS.bK,$CLJS.ZH]),RY;$CLJS.di($CLJS.kg.h($CLJS.A,$CLJS.Uh(fY)));var HY,cia=$CLJS.ka($CLJS.O),dia=$CLJS.ka($CLJS.O),eia=$CLJS.ka($CLJS.O),fia=$CLJS.ka($CLJS.O),gia=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));
HY=new $CLJS.hj($CLJS.Fi.h("metabase.mbql.util","negate*"),$CLJS.A,gia,cia,dia,eia,fia);HY.o(null,$CLJS.qs,function(a){$CLJS.G(a,0,null);return $CLJS.G(a,1,null)});HY.o(null,$CLJS.vs,function(a){a=$CLJS.z(a);$CLJS.A(a);a=$CLJS.B(a);return $CLJS.mh.h(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.os],null),$CLJS.kg.h(HY,a))});HY.o(null,$CLJS.os,function(a){a=$CLJS.z(a);$CLJS.A(a);a=$CLJS.B(a);return $CLJS.mh.h(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.vs],null),$CLJS.kg.h(HY,a))});
HY.o(null,$CLJS.Zr,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GH,b,a],null)});HY.o(null,$CLJS.GH,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zr,b,a],null)});HY.o(null,$CLJS.Tr,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wr,b,a],null)});
HY.o(null,$CLJS.Vr,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ur,b,a],null)});HY.o(null,$CLJS.Ur,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Vr,b,a],null)});HY.o(null,$CLJS.Wr,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Tr,b,a],null)});
HY.o(null,$CLJS.QG,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null),c=$CLJS.G(a,2,null);a=$CLJS.G(a,3,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Vr,b,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Tr,b,a],null)],null)});HY.o(null,$CLJS.nH,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qs,a],null)});HY.o(null,$CLJS.fH,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qs,a],null)});
HY.o(null,$CLJS.zH,function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qs,a],null)});
RY=function RY(a){return $CLJS.je(a)?$CLJS.Pf($CLJS.mh.h($CLJS.O,function(){return function e(d){return new $CLJS.uf(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.me(f)){var g=$CLJS.Wc(f),l=$CLJS.D(g),n=$CLJS.xf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.Yd(g,q),v=$CLJS.G(u,0,null);u=$CLJS.G(u,1,null);u=RY.g?RY.g(u):RY.call(null,u);null!=u&&n.add(new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null));q+=1}else{g=!0;break a}return g?$CLJS.Af($CLJS.Ef(n),e($CLJS.Xc(f))):$CLJS.Af($CLJS.Ef(n),null)}g=$CLJS.A(f);
n=$CLJS.G(g,0,null);g=$CLJS.G(g,1,null);g=RY.g?RY.g(g):RY.call(null,g);if(null!=g)return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[n,g],null),e($CLJS.vd(f)));f=$CLJS.vd(f)}else return null},null,null)}(a)}())):$CLJS.ie(a)?$CLJS.Pf($CLJS.mh.h($CLJS.Xd(a),$CLJS.vg($CLJS.Eb,$CLJS.kg.h(RY,a)))):a};
$CLJS.mY=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var g=Array(arguments.length-2);f<g.length;)g[f]=arguments[f+2],++f;f=new $CLJS.rd(g,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.G(c,0,null),g=$CLJS.G(c,1,null);c=$CLJS.G(c,2,null);d=$CLJS.Pf(RY($CLJS.P.j(d,c,e)));return $CLJS.m(d)?new $CLJS.Q(null,3,5,$CLJS.R,[f,g,d],null):$CLJS.E.h(f,$CLJS.oH)?new $CLJS.Q(null,3,5,$CLJS.R,[f,g,null],null):new $CLJS.Q(null,2,5,$CLJS.R,[f,g],null)}a.A=2;a.D=function(c){var d=
$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.vd(c);return b(d,e,c)};a.l=b;return a}();