var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var lxa=function(a){return $CLJS.Rc($CLJS.Ob(function(b,c){return $CLJS.ph.j(b,c,$CLJS.K.j(b,c,0)+1)},$CLJS.Pc($CLJS.O),a))},xra=function(a,b,c,d){var e=$CLJS.mh.j($CLJS.O,$CLJS.Gn(function(f,g){return new $CLJS.Q(null,2,5,$CLJS.R,[g,f],null)}),d);return function l(g){return new $CLJS.uf(null,function(){for(;;){var n=$CLJS.z(g);if(n){if($CLJS.me(n)){var q=$CLJS.Wc(n),u=$CLJS.D(q),v=$CLJS.xf(u);a:for(var x=0;;)if(x<u){var y=$CLJS.Yd(q,x);y=$CLJS.D2.B(a,b,y,d);y=$CLJS.K.j(e,y,-1);v.add(y);
x+=1}else{q=!0;break a}return q?$CLJS.Af($CLJS.Ef(v),l($CLJS.Xc(n))):$CLJS.Af($CLJS.Ef(v),null)}v=$CLJS.A(n);v=$CLJS.D2.B(a,b,v,d);return $CLJS.kf($CLJS.K.j(e,v,-1),l($CLJS.vd(n)))}return null}},null,null)}(c)},r9=function(a,b){return new $CLJS.Ji(function(){try{return $CLJS.o9.h(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.uE($CLJS.tx);$CLJS.m($CLJS.yE("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.zE("metabase.lib.js.metadata",d,$CLJS.SY("Error parsing %s objects: %s",$CLJS.F([a,
$CLJS.MX(c)])),c):$CLJS.zE("metabase.lib.js.metadata",d,$CLJS.SY(c,$CLJS.F(["Error parsing %s objects: %s",a,$CLJS.MX(c)])),null));return null}throw e;}})},mxa=function(a,b){return function e(d){return new $CLJS.uf(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.me(f)){var g=$CLJS.Wc(f),l=$CLJS.D(g),n=$CLJS.xf(l);return function(){for(var x=0;;)if(x<l){var y=$CLJS.Yd(g,x);$CLJS.G(y,0,null);var C=$CLJS.G(y,1,null),H=function(){var J=C;return null==J?null:$CLJS.r(J)}();$CLJS.m(function(){var J=
H;return $CLJS.m(J)?$CLJS.E.h($CLJS.IL.g(H),b):J}())&&n.add(H);x+=1}else return!0}()?$CLJS.Af($CLJS.Ef(n),e($CLJS.Xc(f))):$CLJS.Af($CLJS.Ef(n),null)}var q=$CLJS.A(f);$CLJS.G(q,0,null);var u=$CLJS.G(q,1,null),v=function(){var x=u;return null==x?null:$CLJS.r(x)}();if($CLJS.m(function(){var x=v;return $CLJS.m(x)?$CLJS.E.h($CLJS.IL.g(v),b):x}()))return $CLJS.kf(v,e($CLJS.vd(f)));f=$CLJS.vd(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.mG.g(a);return null==d?null:$CLJS.r(d)}())},
yra=function(a,b){return function e(d){return new $CLJS.uf(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.me(f)){var g=$CLJS.Wc(f),l=$CLJS.D(g),n=$CLJS.xf(l);return function(){for(var x=0;;)if(x<l){var y=$CLJS.Yd(g,x);$CLJS.G(y,0,null);var C=$CLJS.G(y,1,null),H=function(){var J=C;return null==J?null:$CLJS.r(J)}();$CLJS.m(function(){var J=H;return $CLJS.m(J)?$CLJS.E.h($CLJS.IL.g(H),b):J}())&&n.add(H);x+=1}else return!0}()?$CLJS.Af($CLJS.Ef(n),e($CLJS.Xc(f))):$CLJS.Af($CLJS.Ef(n),null)}var q=
$CLJS.A(f);$CLJS.G(q,0,null);var u=$CLJS.G(q,1,null),v=function(){var x=u;return null==x?null:$CLJS.r(x)}();if($CLJS.m(function(){var x=v;return $CLJS.m(x)?$CLJS.E.h($CLJS.IL.g(v),b):x}()))return $CLJS.kf(v,e($CLJS.vd(f)));f=$CLJS.vd(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.B9.g(a);return null==d?null:$CLJS.r(d)}())},zra=function(a,b){return function e(d){return new $CLJS.uf(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.me(f)){var g=$CLJS.Wc(f),l=$CLJS.D(g),n=
$CLJS.xf(l);return function(){for(var x=0;;)if(x<l){var y=$CLJS.Yd(g,x);$CLJS.G(y,0,null);var C=$CLJS.G(y,1,null),H=function(){var J=C;return null==J?null:$CLJS.r(J)}();$CLJS.m(function(){var J=H;return $CLJS.m(J)?$CLJS.E.h($CLJS.IL.g(H),b):J}())&&n.add(H);x+=1}else return!0}()?$CLJS.Af($CLJS.Ef(n),e($CLJS.Xc(f))):$CLJS.Af($CLJS.Ef(n),null)}var q=$CLJS.A(f);$CLJS.G(q,0,null);var u=$CLJS.G(q,1,null),v=function(){var x=u;return null==x?null:$CLJS.r(x)}();if($CLJS.m(function(){var x=v;return $CLJS.m(x)?
$CLJS.E.h($CLJS.IL.g(v),b):x}()))return $CLJS.kf(v,e($CLJS.vd(f)));f=$CLJS.vd(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.C9.g(a);return null==d?null:$CLJS.r(d)}())},K9=function(a,b,c,d){this.Kg=a;this.vj=b;this.md=c;this.ei=d;this.C=393216;this.J=0},L9=function(a){return $CLJS.Us($CLJS.iE(a),function(b){return $CLJS.nm.j($CLJS.xE(b,$CLJS.zi),$CLJS.Ik,$CLJS.zi)})},t9=function(a,b){if($CLJS.CZ(b))a=b;else{var c=new $CLJS.k(null,6,[$CLJS.H9,r9($CLJS.KQ,b),$CLJS.D9,r9($CLJS.DX,
b),$CLJS.mG,r9($CLJS.oH,b),$CLJS.A9,r9($CLJS.WN,b),$CLJS.B9,r9($CLJS.jH,b),$CLJS.C9,r9($CLJS.MG,b)],null),d=$CLJS.uE($CLJS.qx);$CLJS.m($CLJS.yE("metabase.lib.js.metadata",d))&&$CLJS.zE("metabase.lib.js.metadata",d,$CLJS.VA.l($CLJS.F(["Created metadata provider for metadata"])),null);a=new K9(a,b,c,$CLJS.O)}return a},u9=function(a,b){return $CLJS.QX($CLJS.QX((0,$CLJS.v5)($CLJS.q9($CLJS.iE(a))),$CLJS.gT,function(c){return $CLJS.xm.l($CLJS.F([new $CLJS.k(null,1,[$CLJS.aU,$CLJS.O],null),c]))}),$CLJS.hN,
function(c){var d=function(){var e=$CLJS.mG.g(c);return $CLJS.m(e)?e:function l(g){return new $CLJS.uf(null,function(){for(;;){var n=$CLJS.z(g);if(n){if($CLJS.me(n)){var q=$CLJS.Wc(n),u=$CLJS.D(q),v=$CLJS.xf(u);a:for(var x=0;;)if(x<u){var y=$CLJS.Yd(q,x);v.add(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,y,null],null));x+=1}else{q=!0;break a}return q?$CLJS.Af($CLJS.Ef(v),l($CLJS.Xc(n))):$CLJS.Af($CLJS.Ef(v),null)}v=$CLJS.A(n);return $CLJS.kf(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,v,null],null),l($CLJS.vd(n)))}return null}},
null,null)}(b)}();return $CLJS.T.j(c,$CLJS.mG,lxa(d))})},N9=function(a){return $CLJS.E0.g($CLJS.nm.j($CLJS.hE(a,$CLJS.F([$CLJS.Rj,!0])),0,$CLJS.zi))},Ara=function(a){var b=$CLJS.Fb(a)?a:null;return $CLJS.m(b)?(a=$CLJS.I9.g?$CLJS.I9.g(b):$CLJS.I9.call(null,b),$CLJS.E.h($CLJS.xL.g(a),$CLJS.QL)?(b=N9(b.field_ref),$CLJS.T.j(a,$CLJS.s1,$CLJS.Wd(b))):a):a},O9=function(a){return function(b){return new $CLJS.k(null,2,[$CLJS.ZG,(a.g?a.g(b):a.call(null,b)).name,$CLJS.Yk,b.value],null)}};$CLJS.h=K9.prototype;
$CLJS.h.M=function(a,b){return new K9(this.Kg,this.vj,this.md,b)};$CLJS.h.K=function(){return this.ei};$CLJS.h.aj=$CLJS.t;$CLJS.h.$g=function(a,b){a=this.md;a=null==a?null:$CLJS.B9.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.K.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.cf=function(a,b){return yra(this.md,b)};$CLJS.h.bh=function(a,b){a=this.md;a=null==a?null:$CLJS.D9.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.K.h(a,b);return null==b?null:$CLJS.r(b)};
$CLJS.h.Zg=function(a,b){return mxa(this.md,b)};$CLJS.h.Yg=function(a,b){a=this.md;a=null==a?null:$CLJS.mG.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.K.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.df=function(a,b){return zra(this.md,b)};$CLJS.h.Wg=function(a,b){a=this.md;a=null==a?null:$CLJS.A9.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.K.h(a,b);return null==b?null:$CLJS.r(b)};
$CLJS.h.ah=function(a,b){a=this.md;a=null==a?null:$CLJS.C9.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.K.h(a,b);return null==b?null:$CLJS.r(b)};$CLJS.h.Xg=function(){var a=this.md,b=this.Kg;a=null==a?null:$CLJS.H9.g(a);a=null==a?null:$CLJS.r(a);b=null==a?null:$CLJS.K.h(a,b);return null==b?null:$CLJS.r(b)};var Q9=function Q9(a){switch(arguments.length){case 1:return Q9.g(arguments[0]);case 2:return Q9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Oa("metabase.lib.js.extract_template_tags",Q9);Q9.g=function(a){return Q9.h(a,$CLJS.O)};Q9.h=function(a,b){return $CLJS.zm($CLJS.vqa.l($CLJS.F([a,L9(b)])))};Q9.A=2;
var w9=function w9(a){return $CLJS.tf(a)?[$CLJS.mf(a),"/",$CLJS.fi(a)].join(""):$CLJS.je(a)?$CLJS.Us(a,w9):$CLJS.ie(a)?$CLJS.kg.h(w9,a):a},R9=function R9(a){return $CLJS.je(a)?$CLJS.Us($CLJS.xE(a,$CLJS.kF),R9):$CLJS.ie(a)?$CLJS.kg.h(R9,a):a},z9=function z9(a){switch(arguments.length){case 1:return z9.g(arguments[0]);case 2:return z9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Oa("metabase.lib.js.order_by_clause",z9);
z9.g=function(a){return z9.h(a,$CLJS.lH)};z9.h=function(a,b){return $CLJS.ywa.l($CLJS.F([$CLJS.a9.l($CLJS.F([$CLJS.hE(a,$CLJS.F([$CLJS.Rj,!0]))])),$CLJS.zi.g(b)]))};z9.A=2;var F9=function F9(a){switch(arguments.length){case 2:return F9.h(arguments[0],arguments[1]);case 3:return F9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Oa("metabase.lib.js.available_binning_strategies",F9);
F9.h=function(a,b){return $CLJS.Ke($CLJS.$8.l($CLJS.F([a,b])))};F9.j=function(a,b,c){return $CLJS.Ke($CLJS.$8.l($CLJS.F([a,b,c])))};F9.A=3;var G9=function G9(a){switch(arguments.length){case 2:return G9.h(arguments[0],arguments[1]);case 3:return G9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Oa("metabase.lib.js.available_temporal_buckets",G9);G9.h=function(a,b){return $CLJS.Ke($CLJS.b9.l($CLJS.F([a,b])))};
G9.j=function(a,b,c){return $CLJS.Ke($CLJS.b9.l($CLJS.F([a,b,c])))};G9.A=3;var J9=function J9(a){switch(arguments.length){case 2:return J9.h(arguments[0],arguments[1]);case 3:return J9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Oa("metabase.lib.js.query_EQ_",J9);J9.h=function(a,b){return J9.j(a,b,null)};J9.j=function(a,b,c){a=u9(a,c);b=u9(b,c);return $CLJS.E.h(a,b)};J9.A=3;
var M9=function M9(a){switch(arguments.length){case 1:return M9.g(arguments[0]);case 2:return M9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Oa("metabase.lib.js.aggregation_clause",M9);M9.g=function(a){return $CLJS.Z8.l($CLJS.F([a]))};M9.h=function(a,b){return $CLJS.Z8.l($CLJS.F([a,b]))};M9.A=2;
var P9=function P9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return P9.l(arguments[0],arguments[1],2<c.length?new $CLJS.rd(c.slice(2),0,null):null)};$CLJS.Oa("metabase.lib.js.filter_clause",P9);P9.l=function(a,b,c){return $CLJS.P.B($CLJS.jwa,a,b,c)};P9.A=2;P9.D=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};
var U9=function U9(a){switch(arguments.length){case 2:return U9.h(arguments[0],arguments[1]);case 3:return U9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Oa("metabase.lib.js.expressionable_columns",U9);U9.h=function(a,b){return U9.h(a,b)};U9.j=function(a,b,c){return $CLJS.Ke($CLJS.dwa.l($CLJS.F([a,b,c])))};U9.A=3;
var S9=function S9(a){switch(arguments.length){case 3:return S9.j(arguments[0],arguments[1],arguments[2]);case 4:return S9.B(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.Oa("metabase.lib.js.with_different_database",S9);S9.j=function(a,b,c){return S9.B(a,b,c,null)};S9.B=function(a,b,c,d){return $CLJS.rqa.l($CLJS.F([a,t9(b,c),$CLJS.hE(d,$CLJS.F([$CLJS.Rj,!0]))]))};S9.A=4;
var Bra=O9(function(a){return a.col}),Cra=O9(function(a){return a.column}),T9=function T9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return T9.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.rd(c.slice(3),0,null):null)};$CLJS.Oa("metabase.lib.js.drill_thru",T9);T9.l=function(a,b,c,d){return $CLJS.P.O($CLJS.Fpa,a,b,c,d)};T9.A=3;
T9.D=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return this.l(b,a,c,d)};
module.exports={extract_template_tags:Q9,engine:function(a){return $CLJS.fi($CLJS.iqa.l($CLJS.F([a])))},field_id:function(a){return $CLJS.Mpa.l($CLJS.F([a]))},filter_operator:function(a,b,c){return $CLJS.Rpa.l($CLJS.F([a,b,c]))},binning:function(a){return $CLJS.Vva.l($CLJS.F([a]))},query:function(a,b,c){c=$CLJS.hE(c,$CLJS.F([$CLJS.Rj,!0]));c=$CLJS.m($CLJS.Ik.g(c))?c:$CLJS.T.j(c,$CLJS.Ik,$CLJS.hN);c=(0,$CLJS.v5)(c);c=$CLJS.E0.g(c);var d=$CLJS.uE($CLJS.qx);$CLJS.m($CLJS.yE("metabase.lib.js",d))&&$CLJS.zE("metabase.lib.js",
d,$CLJS.SY("query map: %s",$CLJS.F([$CLJS.Oi.l($CLJS.F([c]))])),null);return $CLJS.Bwa.l($CLJS.F([t9(a,b),c]))},columns_group_columns:function(a){return $CLJS.Ke($CLJS.$va.l($CLJS.F([a])))},join_fields:function(a){a=$CLJS.Ypa.l($CLJS.F([a]));return a instanceof $CLJS.M?$CLJS.AE(a):$CLJS.Ke(a)},aggregate:function(a,b,c){return $CLJS.Qva.l($CLJS.F([a,b,$CLJS.hE(c,$CLJS.F([$CLJS.Rj,!0]))]))},join_conditions:function(a){return $CLJS.Ke($CLJS.Xpa.l($CLJS.F([a])))},query_EQ_:J9,temporal_bucket:function(a){return $CLJS.Kwa.l($CLJS.F([a]))},
available_drill_thrus:function(a,b,c,d,e,f){return $CLJS.Ke($CLJS.Epa.l($CLJS.F([a,b,$CLJS.xm.l($CLJS.F([new $CLJS.k(null,2,[$CLJS.Zf,$CLJS.I9.g?$CLJS.I9.g(c):$CLJS.I9.call(null,c),$CLJS.Yk,void 0===d?null:null==d?$CLJS.$6:d],null),$CLJS.m(e)?new $CLJS.k(null,1,[$CLJS.i6,$CLJS.mm.h(Bra,e)],null):null,$CLJS.m($CLJS.Pf(f))?new $CLJS.k(null,1,[$CLJS.pM,$CLJS.mm.h(Cra,f)],null):null]))])))},remove_clause:function(a,b,c){return $CLJS.Dwa.l($CLJS.F([a,b,$CLJS.a9.l($CLJS.F([$CLJS.hE(c,$CLJS.F([$CLJS.Rj,
!0]))]))]))},suggested_join_condition:function(a,b,c){return $CLJS.bqa.l($CLJS.F([a,b,c]))},replace_clause:function(a,b,c,d){return $CLJS.Ewa.l($CLJS.F([a,b,$CLJS.a9.l($CLJS.F([$CLJS.hE(c,$CLJS.F([$CLJS.Rj,!0]))])),$CLJS.a9.l($CLJS.F([$CLJS.hE(d,$CLJS.F([$CLJS.Rj,!0]))]))]))},drop_stage:function(a){return $CLJS.Gwa.l($CLJS.F([a]))},join_lhs_display_name:function(a,b,c,d){return $CLJS.Zpa.l($CLJS.F([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.Lpa.l($CLJS.F([a,b,c]))},pivot_types:function(a){return $CLJS.Ke($CLJS.Hpa.l($CLJS.F([a])))},
native_extras:function(a){return $CLJS.zm($CLJS.pqa.l($CLJS.F([a])))},available_temporal_buckets:G9,with_binning:function(a,b){return $CLJS.Wva.l($CLJS.F([a,b]))},append_stage:function(a){return $CLJS.Fwa.l($CLJS.F([a]))},join:function(a,b,c){return $CLJS.Upa.l($CLJS.F([a,b,c]))},aggregations:function(a,b){return $CLJS.Ke($CLJS.Sva.l($CLJS.F([a,b])))},drill_thru:T9,raw_native_query:function(a){return $CLJS.gqa.l($CLJS.F([a]))},filterable_column_operators:function(a){return $CLJS.Ke($CLJS.iwa.l($CLJS.F([a])))},
with_temporal_bucket:function(a,b){return $CLJS.Lwa.l($CLJS.F([a,b]))},with_join_conditions:function(a,b){return $CLJS.dqa.l($CLJS.F([a,$CLJS.hE(b,$CLJS.F([$CLJS.Rj,!0]))]))},available_metrics:function(a){return $CLJS.Ke($CLJS.eqa.l($CLJS.F([a])))},with_different_database:S9,with_fields:function(a,b,c){return $CLJS.fwa.l($CLJS.F([a,b,c]))},is_column_metadata:function(a){return $CLJS.je(a)&&$CLJS.E.h($CLJS.vL,$CLJS.su.g(a))},join_clause:function(a,b){return $CLJS.Vpa.l($CLJS.F([a,b]))},aggregation_clause:M9,
external_op:function(a){a=$CLJS.Cpa.l($CLJS.F([a]));var b=$CLJS.Of(a);a=$CLJS.K.h(b,$CLJS.OF);var c=$CLJS.K.h(b,$CLJS.Qi);b=$CLJS.K.h(b,$CLJS.ot);return{operator:a,options:$CLJS.zm(c),args:$CLJS.Ke(b)}},with_join_fields:function(a,b){return $CLJS.cqa.l($CLJS.F([a,"string"===typeof b?$CLJS.zi.g(b):b]))},find_visible_column_for_legacy_ref:function(a,b,c){return $CLJS.Npa.l($CLJS.F([a,b,c]))},visible_columns:function(a,b){var c=$CLJS.JZ(a,b),d=$CLJS.w1.j(a,b,c);c=$CLJS.v1.j(a,b,c);return $CLJS.Ke($CLJS.r4(a,
b,d,c))},available_binning_strategies:F9,find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=$CLJS.dH.g($CLJS.JZ(a,b)),f=$CLJS.mh.j($CLJS.O,$CLJS.Gn(function(u,v){$CLJS.G(v,0,null);v=$CLJS.G(v,1,null);v=$CLJS.Of(v);v=$CLJS.K.h(v,$CLJS.eG);return new $CLJS.Q(null,2,5,$CLJS.R,[u,v],null)}),e);e=$CLJS.mh.j($CLJS.O,$CLJS.Gn(function(u,v){$CLJS.G(v,0,null);v=$CLJS.G(v,1,null);v=$CLJS.Of(v);v=$CLJS.K.h(v,$CLJS.eG);return new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null)}),e);var g=$CLJS.D0,l=$CLJS.C0;$CLJS.D0=
f;$CLJS.C0=e;try{var n=$CLJS.mm.h(Ara,c),q=$CLJS.kg.h(N9,d);return $CLJS.Ke(xra(a,b,q,n))}finally{$CLJS.C0=l,$CLJS.D0=g}},expression:function(a,b,c,d){return $CLJS.bwa.l($CLJS.F([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.zi.g(a):a;b="string"===typeof b?$CLJS.zi.g(b):b;return $CLJS.Jwa.l($CLJS.F([a,b]))},order_bys:function(a,b){return $CLJS.Ke($CLJS.zwa.l($CLJS.F([a,b])))},display_info:function(a,b,c){return $CLJS.zm.l(R9($CLJS.qwa.l($CLJS.F([$CLJS.Bua(a,b),b,
c]))),$CLJS.F([$CLJS.Kl,$CLJS.AE]))},fieldable_columns:function(a,b){return $CLJS.Ke($CLJS.gwa.l($CLJS.F([a,b])))},template_tags:function(a){return $CLJS.zm($CLJS.vwa.l($CLJS.F([a])))},selected_aggregation_operators:function(a,b){return $CLJS.Ke($CLJS.Uva.l($CLJS.F([$CLJS.z(a),b])))},order_by_clause:z9,filterable_columns:function(a,b){return $CLJS.Ke($CLJS.hwa.l($CLJS.F([a,b])))},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.Ke($CLJS.owa.l($CLJS.F([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,
b,c,d){return $CLJS.Wpa.l($CLJS.F([a,b,c,d]))},filters:function(a,b){return $CLJS.Ke($CLJS.Qpa.l($CLJS.F([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.Ke($CLJS.nwa.l($CLJS.F([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.F1(a,b)},orderable_columns:function(a,b){return $CLJS.Ke($CLJS.P8.h(a,b))},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.Spa.l($CLJS.F([a,b,$CLJS.hE(c,$CLJS.F([$CLJS.Rj,!0]))]))},suggestedName:function(a){return $CLJS.rwa.l($CLJS.F([a]))},
find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.Ipa.l($CLJS.F([a,b,c,d]))},has_write_permission:function(a){return $CLJS.sqa.l($CLJS.F([a]))},metadataProvider:t9,filter_clause:P9,fields:function(a,b){return $CLJS.Ke($CLJS.ewa.l($CLJS.F([a,b])))},available_aggregation_operators:function(a,b){return $CLJS.Ke($CLJS.Tva.l($CLJS.F([a,b])))},breakouts:function(a,b){return $CLJS.Ke($CLJS.Zva.l($CLJS.F([a,b])))},joinable_columns:function(a,b,c){return $CLJS.Ke($CLJS.$pa.l($CLJS.F([a,b,c])))},group_columns:function(a){return $CLJS.Ke($CLJS.awa.l($CLJS.F([a])))},
describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.zi.g(b):b;return $CLJS.Hwa.l($CLJS.F([a,b]))},joins:function(a,b){return $CLJS.Ke($CLJS.aqa.l($CLJS.F([a,b])))},native_query:function(a,b,c){return $CLJS.fqa.l($CLJS.F([t9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.Ke($CLJS.mwa.l($CLJS.F([a,b])))},legacy_query:function(a){return $CLJS.zm.l(w9($CLJS.v0.g(a)),$CLJS.F([$CLJS.Kl,$CLJS.AE]))},expression_clause:function(a,b,c){return $CLJS.Jpa.l($CLJS.F([$CLJS.zi.g(a),
b,$CLJS.hE(c,$CLJS.F([$CLJS.Rj,!0]))]))},with_native_extras:function(a,b){return $CLJS.qqa.l($CLJS.F([a,$CLJS.hE(b,$CLJS.F([$CLJS.Rj,!0]))]))},join_strategy:function(a){return $CLJS.kwa.l($CLJS.F([a]))},picker_info:function(a,b){var c=$CLJS.su.g(b);switch(c instanceof $CLJS.M?c.R:null){case "metadata/table":return{databaseId:$CLJS.KQ.g(a),tableId:$CLJS.rk.g(b)};case "metadata/card":return{databaseId:$CLJS.KQ.g(a),tableId:["card__",$CLJS.p.g($CLJS.rk.g(b))].join(""),cardId:$CLJS.rk.g(b),isModel:$CLJS.c_.g(b)};
default:return a=$CLJS.uE($CLJS.xx),$CLJS.m($CLJS.yE("metabase.lib.js",a))&&$CLJS.zE("metabase.lib.js",a,$CLJS.VA.l($CLJS.F(["Cannot provide picker-info for",$CLJS.su.g(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.Kpa.l($CLJS.F([a,b,c]));return $CLJS.Ks(function(d){if($CLJS.je(d)&&$CLJS.E.h($CLJS.T7,$CLJS.su.g(d))){var e=$CLJS.Of(d);d=$CLJS.K.h(e,$CLJS.OF);var f=$CLJS.K.h(e,$CLJS.Qi);e=$CLJS.K.h(e,$CLJS.ot);return{operator:$CLJS.fi(d),options:$CLJS.zm($CLJS.Zh(f,new $CLJS.Q(null,2,
5,$CLJS.R,[$CLJS.wH,$CLJS.DH],null))),args:$CLJS.Ke($CLJS.kg.h(function(g){return g instanceof $CLJS.M?$CLJS.AE(g):g},e))}}return d},a)},aggregation_operator_columns:function(a){return $CLJS.Ke($CLJS.Rva.l($CLJS.F([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.Tpa.l($CLJS.F([a,b,c]))},available_segments:function(a){return $CLJS.Ke($CLJS.zqa.l($CLJS.F([a])))},join_condition_operators:function(a,b,c,d){return $CLJS.Ke($CLJS.pwa.l($CLJS.F([a,b,c,d])))},expressionable_columns:U9,
filter:function(a,b,c){return $CLJS.Ppa.l($CLJS.F([a,b,$CLJS.hE(c,$CLJS.F([$CLJS.Rj,!0]))]))},change_direction:function(a,b){return $CLJS.wwa.l($CLJS.F([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.Gpa.l($CLJS.F([a,b]))},legacy_field_ref:function(a){return $CLJS.zm($CLJS.nm.B($CLJS.v0.g($CLJS.Cwa.l($CLJS.F([a]))),2,$CLJS.Us,function(b){return $CLJS.tf(b)?$CLJS.AE(b):b}))},remove_field:function(a,b,c){return $CLJS.Opa.l($CLJS.F([a,b,c]))},with_template_tags:function(a,b){return $CLJS.jqa.l($CLJS.F([a,
L9(b)]))},expressions:function(a,b){return $CLJS.Ke($CLJS.cwa.l($CLJS.F([a,b])))},with_join_strategy:function(a,b){return $CLJS.lwa.l($CLJS.F([a,b]))},order_by:function(a,b,c,d){return $CLJS.xwa.l($CLJS.F([a,b,c,$CLJS.zi.g(d)]))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.zi.g(a):a;b="string"===typeof b?$CLJS.zi.g(b):b;return $CLJS.Iwa.l($CLJS.F([a,b]))},breakout:function(a,b,c){return $CLJS.Xva.l($CLJS.F([a,b,$CLJS.Cwa.l($CLJS.F([c]))]))},database_id:function(a){return $CLJS.Dpa.l($CLJS.F([a]))},
required_native_extras:function(a,b){return $CLJS.Ke($CLJS.kg.h($CLJS.AE,$CLJS.nqa.l($CLJS.F([t9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.xqa.l($CLJS.F([a,b,c]))},joined_thing:function(a,b){return $CLJS.G4(a,b)},with_native_query:function(a,b){return $CLJS.hqa.l($CLJS.F([a,b]))},breakoutable_columns:function(a,b){return $CLJS.Ke($CLJS.Yva.l($CLJS.F([a,b])))},rename_join:function(a,b,c,d){return $CLJS.yqa.l($CLJS.F([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.wqa.l($CLJS.F([a,
b]))}};