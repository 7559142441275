var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var Gba,CF,MF,jG;$CLJS.Mt=function(a){return $CLJS.gm.h($CLJS.kg.g(a),$CLJS.Li)};$CLJS.ZF=function(a,b){a=$CLJS.$s($CLJS.Ys(a,/''/,"'"),/\{\d+\}/);return $CLJS.P.j($CLJS.hy.t,$CLJS.zm(a),$CLJS.zm(b))};$CLJS.oF=new $CLJS.M("lib","metadata","lib/metadata",1798917220);$CLJS.pF=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.qF=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.sF=new $CLJS.M(null,"mbql","mbql",69346710);
$CLJS.vF=new $CLJS.M(null,"display-name","display-name",694513143);Gba=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.xF=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);CF=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.DF=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);
$CLJS.HF=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);MF=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.NF=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.OF=new $CLJS.M(null,"operator","operator",-1860875338);
$CLJS.bG=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.dG=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.eG=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.fG=new $CLJS.M(null,"x","x",2099068185);$CLJS.gG=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);
$CLJS.hG=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.iG=new $CLJS.M(null,"strategy","strategy",-1471631918);jG=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.oJ($CLJS.HF,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ql,new $CLJS.k(null,1,[$CLJS.bk,1],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"non-blank string"],null),$CLJS.$f($CLJS.at)],null)],null));$CLJS.oJ($CLJS.bG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yr,new $CLJS.k(null,1,[$CLJS.bk,0],null)],null));$CLJS.oJ($CLJS.pF,$CLJS.ye);
$CLJS.oJ(jG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ql,new $CLJS.k(null,2,[$CLJS.bk,36,$CLJS.xl,36],null)],null));$CLJS.oJ(MF,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,2,[$CLJS.Pt,"valid semantic type",$CLJS.Bt,function(a){a=$CLJS.Of(a);a=$CLJS.K.h(a,$CLJS.Yk);return["Not a valid semantic type: ",$CLJS.Oi.l($CLJS.F([a]))].join("")}],null),function(a){return $CLJS.XF(a,$CLJS.vj)}],null));
$CLJS.oJ(CF,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,2,[$CLJS.Pt,"valid relation type",$CLJS.Bt,function(a){a=$CLJS.Of(a);a=$CLJS.K.h(a,$CLJS.Yk);return["Not a valid relation type: ",$CLJS.Oi.l($CLJS.F([a]))].join("")}],null),function(a){return $CLJS.XF(a,$CLJS.gb)}],null));$CLJS.oJ($CLJS.xF,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MF],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CF],null)],null));
$CLJS.oJ($CLJS.gG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,2,[$CLJS.Pt,"valid base type",$CLJS.Bt,function(a){a=$CLJS.Of(a);a=$CLJS.K.h(a,$CLJS.Yk);return["Not a valid base type: ",$CLJS.Oi.l($CLJS.F([a]))].join("")}],null),function(a){return $CLJS.XF(a,$CLJS.Aj)}],null));
$CLJS.oJ($CLJS.dG,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eG,jG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JB,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.gG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bf,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.gG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,
$CLJS.xF],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NF,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.HF],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.W,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.HF],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vF,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.HF],null)],null)],null));
$CLJS.oJ(Gba,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.DF],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OF,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,$CLJS.ql,$CLJS.as],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,$CLJS.Fs],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qi,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.dG],null)],null));