var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Ua,Va,eca,fca,gca,lca,mca,nca,oca;Ua=function(a,b){a.sort(b||$CLJS.Ta)};Va=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Ta;Ua(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};$CLJS.Je=function(a){return $CLJS.E.h(a,$CLJS.He)?$CLJS.He:function(b,c){var d=a.h?a.h(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.m(d)?-1:$CLJS.m(a.h?a.h(c,b):a.call(null,c,b))?1:0}};
$CLJS.Le=function(a,b){if($CLJS.z(b)){var c=$CLJS.Ke.g?$CLJS.Ke.g(b):$CLJS.Ke.call(null,b);Va(c,$CLJS.Je(a));return $CLJS.I($CLJS.z(c),$CLJS.be(b))}return $CLJS.ud};$CLJS.YH=new $CLJS.M(null,"second","second",-444702010);$CLJS.ZH=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);$CLJS.$H=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.aI=new $CLJS.M(null,"millisecond","millisecond",-540123566);
$CLJS.bI=new $CLJS.M(null,"year-of-era","year-of-era",682445876);eca=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);fca=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);
$CLJS.cI=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.dI=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);gca=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
lca=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);mca=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);nca=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.eI=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.fI=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.gI=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
oca=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var LT=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Cf,$CLJS.Ej,$CLJS.Cj,$CLJS.jb,$CLJS.ak,$CLJS.Ue,$CLJS.db,$CLJS.bI],null),MT=$CLJS.di(LT),Wma,Yma,VT;$CLJS.oJ(mca,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid date extraction unit"],null)],null),MT));var NT=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.uu,$CLJS.tj,$CLJS.xj,$CLJS.ib,$CLJS.db],null),OT=$CLJS.di(NT);
$CLJS.oJ(lca,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid date truncation unit"],null)],null),OT));$CLJS.PT=$CLJS.mh.j($CLJS.Mg,$CLJS.ym.v(),$CLJS.sg.h(NT,LT));Wma=$CLJS.di($CLJS.PT);$CLJS.oJ($CLJS.dI,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid date bucketing unit"],null)],null),Wma));var QT=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZH,$CLJS.pj,$CLJS.xe],null),RT=$CLJS.di(QT);
$CLJS.oJ(gca,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid time extraction unit"],null)],null),RT));var ST=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.aI,$CLJS.YH,$CLJS.ru,$CLJS.tu],null),TT=$CLJS.di(ST);$CLJS.oJ(oca,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid time truncation unit"],null)],null),TT));$CLJS.Xma=$CLJS.mh.j($CLJS.Mg,$CLJS.ym.v(),$CLJS.sg.h(ST,QT));Yma=$CLJS.di($CLJS.Xma);
$CLJS.oJ(fca,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid time bucketing unit"],null)],null),Yma));$CLJS.UT=$CLJS.mh.j($CLJS.Mg,$CLJS.ym.v(),$CLJS.sg.l(ST,NT,$CLJS.F([QT,LT])));VT=$CLJS.di($CLJS.UT);$CLJS.oJ($CLJS.$H,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid datetime bucketing unit"],null)],null),$CLJS.UT));var Zma=$CLJS.gf.h(VT,$CLJS.Dj);
$CLJS.oJ($CLJS.fI,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid temporal bucketing unit"],null)],null),Zma));var $ma=$CLJS.yy.h(OT,TT);$CLJS.oJ($CLJS.cI,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid datetime truncation unit"],null)],null),$ma));$CLJS.WT=$CLJS.yy.h(MT,RT);$CLJS.oJ(eca,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid datetime extraction unit"],null)],null),$CLJS.WT));
var XT=$CLJS.gf.h(OT,$CLJS.db);$CLJS.oJ($CLJS.gI,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid date interval unit"],null)],null),XT));$CLJS.oJ($CLJS.mF,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid time interval unit"],null)],null),TT));var ana=$CLJS.yy.h(XT,TT);$CLJS.oJ($CLJS.nF,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"Valid datetime interval unit"],null)],null),ana));
$CLJS.oJ(nca,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.eI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kb,$CLJS.fI],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Dj,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null)],null));