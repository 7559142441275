var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var k0,Lt,m0,Kqa,Zia,$ia,Pqa,bja,Qqa,cja,dja,r0,s0,t0,u0,w0,Sqa,x0,y0,fja,gja,hja,jZ,aja,kZ,lZ,eja,nZ;k0=function(a,b){var c=$CLJS.z(b);for(a=$CLJS.z($CLJS.jg(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Lt=function(){return function(){function a(g,l,n){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik.j?$CLJS.Ik.j(g,l,n):$CLJS.Ik.call(null,g,l,n),$CLJS.sk.j?$CLJS.sk.j(g,l,n):$CLJS.sk.call(null,g,l,n)],null)}function b(g,l){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik.h?$CLJS.Ik.h(g,l):$CLJS.Ik.call(null,g,l),$CLJS.sk.h?$CLJS.sk.h(g,l):$CLJS.sk.call(null,g,l)],null)}function c(g){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik.g?$CLJS.Ik.g(g):$CLJS.Ik.call(null,g),$CLJS.sk.g?$CLJS.sk.g(g):$CLJS.sk.call(null,
g)],null)}function d(){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik.v?$CLJS.Ik.v():$CLJS.Ik.call(null),$CLJS.sk.v?$CLJS.sk.v():$CLJS.sk.call(null)],null)}var e=null,f=function(){function g(n,q,u,v){var x=null;if(3<arguments.length){x=0;for(var y=Array(arguments.length-3);x<y.length;)y[x]=arguments[x+3],++x;x=new $CLJS.rd(y,0,null)}return l.call(this,n,q,u,x)}function l(n,q,u,v){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.P.O($CLJS.Ik,n,q,u,v),$CLJS.P.O($CLJS.sk,n,q,u,v)],null)}g.A=3;g.D=function(n){var q=
$CLJS.A(n);n=$CLJS.B(n);var u=$CLJS.A(n);n=$CLJS.B(n);var v=$CLJS.A(n);n=$CLJS.vd(n);return l(q,u,v,n)};g.l=l;return g}();e=function(g,l,n,q){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,g);case 2:return b.call(this,g,l);case 3:return a.call(this,g,l,n);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.rd(v,0,null)}return f.l(g,l,n,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.D=f.D;e.v=d;e.g=c;e.h=b;e.j=a;e.l=f.l;return e}()};m0=function(a){return $CLJS.Lqa($CLJS.vX)(a,$CLJS.Mg,$CLJS.Mg)};Kqa=function(a,b,c){return $CLJS.Ob(function(d,e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return null==e?d:$CLJS.T.j(d,f,e)},null==b?a:$CLJS.T.j(a,$CLJS.dH,b),$CLJS.UL(2,2,c))};Zia=function(a,b){var c=$CLJS.Ge(a,$CLJS.aU);if($CLJS.m(c)){var d=$CLJS.T.j;c=$CLJS.rc(c);b=$CLJS.Us.h?$CLJS.Us.h(c,b):$CLJS.Us.call(null,c,b);a=d.call($CLJS.T,a,$CLJS.aU,b)}return a};
$ia=function(a){return $CLJS.sg.h($CLJS.QW(a),$CLJS.RW(a))};Pqa=function(a,b,c){var d=new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.ft,null,$CLJS.Fm,null],null),null),e=$CLJS.m(d.g?d.g(b):d.call(null,b))?$CLJS.lg(2,c):$CLJS.lg(1,c);b=$CLJS.m(d.g?d.g(b):d.call(null,b))?k0(2,c):k0(1,c);var f=$CLJS.G(b,0,null);return $CLJS.z(e)?$CLJS.nY.j(a,e,function(g){var l=$CLJS.T.j(g,f,null);return $CLJS.le(g)?$CLJS.mh.j($CLJS.Mg,$CLJS.lm.g($CLJS.yb),l):$CLJS.je(g)?$CLJS.wE($CLJS.Eb,l):l}):$CLJS.$l.h(a,f)};
bja=function(a){return $CLJS.Ob(function(b,c){var d=$CLJS.G(c,0,null);$CLJS.G(c,1,null);return Pqa(b,aja,d)},a,$ia(a))};
Qqa=function(a){for(var b=0;;){var c=$CLJS.Pd($CLJS.$L.g(a),b);a:{var d=c;for(var e=$CLJS.Mg;;){var f=$CLJS.A($CLJS.kg.h(Lt(),$CLJS.vg($CLJS.gm.j(p0,$CLJS.A,$CLJS.sk),$CLJS.jt.g(m0(d)))));if($CLJS.m(f)){var g=f;f=$CLJS.G(g,0,null);g=$CLJS.G(g,1,null);var l=Pqa(d,f,g),n=$CLJS.uE($CLJS.xx);if($CLJS.m($CLJS.yE("metabase.lib.convert",n))){var q=$CLJS.fF.l($CLJS.F([$CLJS.wy,$CLJS.Oi.l($CLJS.F([g]))])),u=$CLJS.fF,v=u.l,x=$CLJS.Oi,y=x.l;var C=f;C=$CLJS.m(C)?C:$CLJS.Oqa(m0(d));$CLJS.zE("metabase.lib.convert",
n,$CLJS.SY("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.F([q,v.call(u,$CLJS.F([$CLJS.wy,y.call(x,$CLJS.F([C]))])),$CLJS.fF.l($CLJS.F([$CLJS.gy,$CLJS.TY($CLJS.A($CLJS.Cy(d,l)))]))])),null)}if($CLJS.E.h(l,d))break a;d=l;e=$CLJS.gf.h(e,new $CLJS.Q(null,2,5,$CLJS.R,[f,g],null))}else break a}}d=bja(d);if($CLJS.E.h(c,d)){if($CLJS.E.h(b,$CLJS.D($CLJS.$L.g(a))-1))return a;b+=1}else a=$CLJS.nm.O(a,$CLJS.$L,$CLJS.T,b,d)}};
cja=function(a){var b=$CLJS.M5();return $CLJS.mm.h(function(c){return $CLJS.E.h($CLJS.qG.g(c),"__join")?$CLJS.nm.j(c,$CLJS.qG,b):c},a)};dja=function(a){return"string"===typeof $CLJS.FP.g(a)?$CLJS.$l.h($CLJS.T.j(a,$CLJS.TW,$CLJS.yX($CLJS.FP.g(a))),$CLJS.FP):a};r0=function(a,b){var c=$CLJS.K.j(a,b,jZ);if($CLJS.E.h(c,jZ))throw $CLJS.nj(["Unable to find ",$CLJS.Oi.l($CLJS.F([b]))," in map."].join(""),new $CLJS.k(null,2,[eja,a,$CLJS.Cv,b],null));return c};
s0=function(){return $CLJS.lm.g(function(a){var b=$CLJS.G(a,0,null);$CLJS.G(a,1,null);(a=$CLJS.tf(b))?(b=$CLJS.mf(b),b=$CLJS.m(b)?$CLJS.E.h(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};t0=function(a){return $CLJS.mh.j($CLJS.O,s0(),a)};u0=function(a){return $CLJS.Pf($CLJS.mh.j($CLJS.O,$CLJS.gm.h(s0(),$CLJS.lm.g(function(b){var c=$CLJS.G(b,0,null);$CLJS.G(b,1,null);return $CLJS.E.h(c,$CLJS.Bf)})),a))};
w0=function(a){var b=$CLJS.z(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.mh.j(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.kg.g($CLJS.v0),c);a=$CLJS.E.h(a,$CLJS.XG)&&$CLJS.B(c)?$CLJS.gf.h($CLJS.de(d),new $CLJS.k(null,1,[$CLJS.Dj,$CLJS.ce(d)],null)):d;b=$CLJS.Pf(u0(b));return $CLJS.m(b)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UM,a,b],null):a};Sqa=function(a){return $CLJS.mh.j($CLJS.Mg,$CLJS.gm.h($CLJS.kg.g(function(b){return $CLJS.xE(b,$CLJS.jF)}),$CLJS.kg.g($CLJS.v0)),$CLJS.wX.g(a))};
x0=function(a){a=$CLJS.Of(a);a=$CLJS.K.h(a,$CLJS.$L);a=$CLJS.A($CLJS.Ob(function(b,c){var d=$CLJS.G(b,0,null),e=$CLJS.G(b,1,null);b=$CLJS.R;var f=$CLJS.v0.g(c);d=$CLJS.m(d)?$CLJS.T.j(f,$CLJS.hU,d):f;d=$CLJS.m(e)?$CLJS.T.j(d,$CLJS.rP,Sqa(e)):d;return new $CLJS.Q(null,2,5,b,[d,$CLJS.GW.g(c)],null)},null,a));return $CLJS.m($CLJS.gT.g(a))?$CLJS.SL(a,new $CLJS.k(null,1,[$CLJS.gT,$CLJS.hN],null)):a};
y0=function(a,b,c){var d=$CLJS.E.h($CLJS.D($CLJS.K.h(a,b)),1)?$CLJS.QX(a,b,$CLJS.gm.h($CLJS.v0,$CLJS.A)):a;a=1<$CLJS.D($CLJS.K.h(a,b))?$CLJS.QX(d,b,function(e){return $CLJS.mh.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.vs],null),$CLJS.kg.g($CLJS.v0),e)}):d;return $CLJS.SL(a,$CLJS.Nf([b,c]))};fja=function(a){var b=$CLJS.TW.g(a);return $CLJS.m(b)?$CLJS.T.j($CLJS.$l.h(a,$CLJS.TW),$CLJS.FP,["card__",$CLJS.p.g(b)].join("")):a};
gja=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);hja=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);jZ=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);aja=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);kZ=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
lZ=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.mZ=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);eja=new $CLJS.M(null,"m","m",1632677161);nZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var p0,ija,jja,kja,lja,mja,nja,oja,pja,qja,rja;$CLJS.C0=$CLJS.O;$CLJS.D0=$CLJS.O;p0=new $CLJS.U(null,new $CLJS.k(null,7,[$CLJS.nX,null,$CLJS.mG,null,$CLJS.dO,null,$CLJS.QR,null,$CLJS.cH,null,$CLJS.xV,null,$CLJS.dH,null],null),null);ija=$CLJS.ka($CLJS.O);jja=$CLJS.ka($CLJS.O);kja=$CLJS.ka($CLJS.O);lja=$CLJS.ka($CLJS.O);mja=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));
$CLJS.E0=new $CLJS.hj($CLJS.Fi.h("metabase.lib.convert","-\x3epMBQL"),$CLJS.IF,mja,ija,jja,kja,lja);$CLJS.E0.o(null,$CLJS.Dj,function(a){if($CLJS.le(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.ce(a);var c=$CLJS.je(b);a=c?$CLJS.de(a):a;var d=$CLJS.z(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.gU($CLJS.mh.j(new $CLJS.Q(null,2,5,$CLJS.R,[a,c?b:$CLJS.O],null),$CLJS.kg.g($CLJS.E0),d))}else b=a;return b});$CLJS.E0.o(null,$CLJS.lX,function(a){return a});
$CLJS.E0.o(null,$CLJS.ZW,function(a){var b=$CLJS.E0.g($CLJS.dH.g(a)),c=$CLJS.Pf($CLJS.mm.h(function(q){var u=$CLJS.G(q,0,null);q=$CLJS.G(q,1,null);return $CLJS.l0($CLJS.E0.g(q),u)},$CLJS.QR.g(a))),d=$CLJS.mh.j($CLJS.O,$CLJS.Gn(function(q,u){$CLJS.G(u,0,null);u=$CLJS.G(u,1,null);u=$CLJS.Of(u);u=$CLJS.K.h(u,$CLJS.eG);return new $CLJS.Q(null,2,5,$CLJS.R,[q,u],null)}),b),e=$CLJS.mh.j($CLJS.O,$CLJS.Gn(function(q,u){$CLJS.G(u,0,null);u=$CLJS.G(u,1,null);u=$CLJS.Of(u);u=$CLJS.K.h(u,$CLJS.eG);return new $CLJS.Q(null,
2,5,$CLJS.R,[u,q],null)}),b),f=$CLJS.D0,g=$CLJS.C0;$CLJS.D0=d;$CLJS.C0=e;try{var l=Kqa(dja(a),b,$CLJS.F([$CLJS.QR,c])),n=$CLJS.Ob(function(q,u){return $CLJS.Bb($CLJS.K.h(q,u))?q:$CLJS.nm.j(q,u,$CLJS.E0)},l,$CLJS.Jy.l(p0,$CLJS.dH,$CLJS.F([$CLJS.QR])));return $CLJS.m($CLJS.dO.g(n))?$CLJS.nm.j(n,$CLJS.dO,cja):n}finally{$CLJS.C0=g,$CLJS.D0=f}});$CLJS.E0.o(null,$CLJS.fX,function(a){return Zia(a,function(b){return $CLJS.QX(b,$CLJS.JM,$CLJS.E0)})});
$CLJS.E0.o(null,$CLJS.VL,function(a){a=$CLJS.nm.j($CLJS.nm.j(a,$CLJS.dM,$CLJS.E0),$CLJS.$L,$CLJS.E0);var b=$CLJS.m($CLJS.mG.g(a))?$CLJS.nm.j(a,$CLJS.mG,function(c){return $CLJS.se(c)?$CLJS.mm.h($CLJS.E0,c):$CLJS.zi.g(c)}):a;return $CLJS.Bb($CLJS.qG.g(a))?$CLJS.T.j(b,$CLJS.qG,"__join"):b});$CLJS.E0.o(null,$CLJS.DB,function(a){return $CLJS.mm.h($CLJS.E0,a)});
$CLJS.E0.o(null,$CLJS.FB,function(a){return $CLJS.m($CLJS.Ik.g(a))?Qqa($CLJS.T.j($CLJS.nm.j($CLJS.ZY(a),$CLJS.$L,function(b){return $CLJS.mm.h($CLJS.E0,b)}),$CLJS.mZ,!0)):$CLJS.Us(a,$CLJS.E0)});$CLJS.E0.o(null,$CLJS.oH,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);a=$CLJS.je(b)?new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null):new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null);b=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return $CLJS.gU(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,a,b],null))});
$CLJS.E0.o(null,$CLJS.Yk,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);a=$CLJS.SL(a,new $CLJS.k(null,3,[$CLJS.LD,$CLJS.JB,$CLJS.dP,$CLJS.Lj,$CLJS.KV,$CLJS.NF],null));var c=$CLJS.T.j;var d=$CLJS.Bf.g(a);$CLJS.m(d)||(d=$CLJS.JB.g(a),d=$CLJS.m(d)?d:$CLJS.EJ(b));a=c.call($CLJS.T,a,$CLJS.Bf,d);return $CLJS.gU(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yk,a,b],null))});
$CLJS.E0.o(null,$CLJS.XG,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null),c=$CLJS.G(a,2,null);a=$CLJS.Dj.g(c);b=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XG,$CLJS.$l.h(c,$CLJS.Dj),$CLJS.mm.h($CLJS.E0,b)],null);b=$CLJS.gU(b);return null!=a?$CLJS.gf.h(b,$CLJS.E0.g(a)):b});$CLJS.E0.o(null,$CLJS.GB,function(a){var b=$CLJS.G(a,0,null),c=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return $CLJS.gU(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,c],null))});
$CLJS.E0.o(null,$CLJS.dH,function(a){var b=$CLJS.G(a,0,null),c=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return $CLJS.gU(new $CLJS.Q(null,3,5,$CLJS.R,[b,a,r0($CLJS.D0,c)],null))});$CLJS.E0.o(null,$CLJS.UM,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);b=$CLJS.E0.g(b);var c=$CLJS.z(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.xm.l($CLJS.F([c,a]))],null),d)});nja=$CLJS.ka($CLJS.O);oja=$CLJS.ka($CLJS.O);
pja=$CLJS.ka($CLJS.O);qja=$CLJS.ka($CLJS.O);rja=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));$CLJS.v0=new $CLJS.hj($CLJS.Fi.h("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.IF,rja,nja,oja,pja,qja);
$CLJS.v0.o(null,$CLJS.Dj,function(a){if($CLJS.le(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.z(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.je(a)){c=$CLJS.mh.h;b=new $CLJS.Q(null,1,5,$CLJS.R,[b],null);var e=$CLJS.sg.h;d=$CLJS.kg.h($CLJS.v0,d);a=u0(a);a=c.call($CLJS.mh,b,e.call($CLJS.sg,d,$CLJS.m(a)?new $CLJS.Q(null,1,5,$CLJS.R,[a],null):null))}else a=$CLJS.mh.h(new $CLJS.Q(null,1,5,$CLJS.R,[b],null),$CLJS.kg.h($CLJS.v0,$CLJS.kf(a,d)))}else a=$CLJS.je(a)?$CLJS.Us(t0(a),$CLJS.v0):
a;return a});for(var oZ=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[nZ,lZ],null)),pZ=null,qZ=0,rZ=0;;)if(rZ<qZ){var sja=pZ.ha(null,rZ);$CLJS.mT(sja,kZ);rZ+=1}else{var sZ=$CLJS.z(oZ);if(sZ){var tZ=sZ;if($CLJS.me(tZ)){var uZ=$CLJS.Wc(tZ),tja=$CLJS.Xc(tZ),uja=uZ,vja=$CLJS.D(uZ);oZ=tja;pZ=uja;qZ=vja}else{var wja=$CLJS.A(tZ);$CLJS.mT(wja,kZ);oZ=$CLJS.B(tZ);pZ=null;qZ=0}rZ=0}else break}
for(var vZ=$CLJS.z(new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.Aw,$CLJS.iH,$CLJS.xH,$CLJS.TG,$CLJS.xl,$CLJS.CH,$CLJS.bk,$CLJS.$G,$CLJS.vH,$CLJS.EH,$CLJS.BH,$CLJS.JH],null)),wZ=null,EZ=0,GZ=0;;)if(GZ<EZ){var xja=wZ.ha(null,GZ);$CLJS.mT(xja,nZ);GZ+=1}else{var HZ=$CLJS.z(vZ);if(HZ){var IZ=HZ;if($CLJS.me(IZ)){var LZ=$CLJS.Wc(IZ),yja=$CLJS.Xc(IZ),zja=LZ,Aja=$CLJS.D(LZ);vZ=yja;wZ=zja;EZ=Aja}else{var Bja=$CLJS.A(IZ);$CLJS.mT(Bja,nZ);vZ=$CLJS.B(IZ);wZ=null;EZ=0}GZ=0}else break}
for(var NZ=$CLJS.z($CLJS.Ig([$CLJS.bs,$CLJS.Ft,$CLJS.cs,$CLJS.KH,$CLJS.XG,$CLJS.rH,$CLJS.OG,$CLJS.MH,$CLJS.HG,$CLJS.bH,$CLJS.KG,$CLJS.mH,$CLJS.tH,$CLJS.LG,$CLJS.BJ,$CLJS.IJ,$CLJS.yx,$CLJS.xJ,$CLJS.KJ,$CLJS.hJ,$CLJS.OJ,$CLJS.wJ,$CLJS.fJ,$CLJS.NJ,$CLJS.XJ,$CLJS.RJ,$CLJS.vJ,$CLJS.$J,$CLJS.QJ,$CLJS.YJ,$CLJS.NG,$CLJS.IG,$CLJS.NH,$CLJS.GI,$CLJS.qH,$CLJS.rw,$CLJS.FH,$CLJS.HH,$CLJS.OH,$CLJS.Ut,$CLJS.cu],!0)),OZ=null,QZ=0,RZ=0;;)if(RZ<QZ){var Cja=OZ.ha(null,RZ);$CLJS.mT(Cja,lZ);RZ+=1}else{var SZ=$CLJS.z(NZ);
if(SZ){var TZ=SZ;if($CLJS.me(TZ)){var UZ=$CLJS.Wc(TZ),Dja=$CLJS.Xc(TZ),Eja=UZ,Fja=$CLJS.D(UZ);NZ=Dja;OZ=Eja;QZ=Fja}else{var Gja=$CLJS.A(TZ);$CLJS.mT(Gja,lZ);NZ=$CLJS.B(TZ);OZ=null;QZ=0}RZ=0}else break}$CLJS.v0.o(null,kZ,function(a){return w0(a)});$CLJS.v0.o(null,$CLJS.FB,function(a){return $CLJS.mh.j($CLJS.O,$CLJS.gm.h(s0(),$CLJS.kg.g(function(b){var c=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.v0.g(b)],null)})),a)});
$CLJS.v0.o(null,$CLJS.dH,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null),c=$CLJS.G(a,2,null);if($CLJS.je(b))try{var d=u0(b),e=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dH,r0($CLJS.C0,c)],null);return $CLJS.m(d)?$CLJS.gf.h(e,d):e}catch(f){throw b=f,c=$CLJS.MX(b),c=$CLJS.va.h?$CLJS.va.h("Error converting aggregation reference to pMBQL: %s",c):$CLJS.va.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.mj(c,new $CLJS.k(null,1,[$CLJS.X,a],null),b);}else return a});
$CLJS.v0.o(null,$CLJS.DB,function(a){return $CLJS.mm.h($CLJS.v0,a)});$CLJS.v0.o(null,$CLJS.oH,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);a=null==b||$CLJS.je(b)?new $CLJS.Q(null,2,5,$CLJS.R,[b,a],null):new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null);b=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,$CLJS.v0.g(a),u0(b)],null)});
$CLJS.v0.o(null,$CLJS.Yk,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);b=u0($CLJS.SL(b,new $CLJS.k(null,3,[$CLJS.JB,$CLJS.LD,$CLJS.Lj,$CLJS.dP,$CLJS.NF,$CLJS.KV],null)));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yk,a,b],null)});
$CLJS.v0.o(null,$CLJS.VL,function(a){var b=t0(a);a=0==$CLJS.qG.g(a).lastIndexOf("__join",0)?$CLJS.$l.h(b,$CLJS.qG):b;return $CLJS.xm.l($CLJS.F([$CLJS.Us($CLJS.$l.l(a,$CLJS.$L,$CLJS.F([$CLJS.dM])),$CLJS.v0),y0($CLJS.Zh(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.dM],null)),$CLJS.dM,$CLJS.EV),x0(a)]))});
$CLJS.v0.o(null,$CLJS.ZW,function(a){var b=$CLJS.dH.g(a),c=$CLJS.mh.j($CLJS.O,$CLJS.Gn(function(f,g){$CLJS.G(g,0,null);g=$CLJS.G(g,1,null);g=$CLJS.Of(g);g=$CLJS.K.h(g,$CLJS.eG);return new $CLJS.Q(null,2,5,$CLJS.R,[f,g],null)}),b);b=$CLJS.mh.j($CLJS.O,$CLJS.Gn(function(f,g){$CLJS.G(g,0,null);g=$CLJS.G(g,1,null);g=$CLJS.Of(g);g=$CLJS.K.h(g,$CLJS.eG);return new $CLJS.Q(null,2,5,$CLJS.R,[g,f],null)}),b);var d=$CLJS.D0,e=$CLJS.C0;$CLJS.D0=c;$CLJS.C0=b;try{return $CLJS.Ob(function(f,g){return $CLJS.QX(f,
g,$CLJS.v0)},y0($CLJS.QX($CLJS.QX(fja(t0(a)),$CLJS.dH,function(f){return $CLJS.mm.h(w0,f)}),$CLJS.QR,function(f){return $CLJS.mh.h($CLJS.O,function(){return function n(l){return new $CLJS.uf(null,function(){for(;;){var q=$CLJS.z(l);if(q){if($CLJS.me(q)){var u=$CLJS.Wc(q),v=$CLJS.D(u),x=$CLJS.xf(v);a:for(var y=0;;)if(y<v){var C=$CLJS.Yd(u,y),H=$CLJS.v0.g(C);C=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VY(C),$CLJS.E.h($CLJS.Yk,$CLJS.A(H))?$CLJS.Vd(H):H],null);x.add(C);y+=1}else{u=!0;break a}return u?$CLJS.Af($CLJS.Ef(x),
n($CLJS.Xc(q))):$CLJS.Af($CLJS.Ef(x),null)}x=$CLJS.A(q);u=$CLJS.v0.g(x);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VY(x),$CLJS.E.h($CLJS.Yk,$CLJS.A(u))?$CLJS.Vd(u):u],null),n($CLJS.vd(q)))}return null}},null,null)}(f)}())}),$CLJS.nX,$CLJS.SG),$CLJS.Jy.l(p0,$CLJS.dH,$CLJS.F([$CLJS.nX,$CLJS.QR])))}finally{$CLJS.C0=e,$CLJS.D0=d}});$CLJS.v0.o(null,$CLJS.fX,function(a){return $CLJS.Us(t0(a),$CLJS.v0)});
$CLJS.v0.o(null,$CLJS.lX,function(a){try{var b=t0(a),c=$CLJS.VN.g(b),d=x0(b),e=$CLJS.E.h($CLJS.su.g($CLJS.Wd($CLJS.$L.g(a))),$CLJS.fX)?$CLJS.gT:$CLJS.hN;return $CLJS.xm.l($CLJS.F([$CLJS.Us($CLJS.$l.l(b,$CLJS.$L,$CLJS.F([$CLJS.VN,$CLJS.mZ])),$CLJS.v0),function(){var g=$CLJS.Nf([$CLJS.Ik,e,e,d]);return $CLJS.z(c)?$CLJS.T.j(g,$CLJS.VN,c):g}()]))}catch(g){var f=g;throw $CLJS.mj(function(){var l=$CLJS.MX(f);return $CLJS.va.h?$CLJS.va.h("Error converting MLv2 query to legacy query: %s",l):$CLJS.va.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.k(null,1,[$CLJS.hN,a],null),f);}});
$CLJS.VZ=function(){function a(d,e,f){f=$CLJS.Z$(null,$CLJS.hE(f,$CLJS.F([$CLJS.Rj,!0])));var g=$CLJS.JZ(d,e);g=$CLJS.Of(g);var l=$CLJS.K.h(g,$CLJS.dH);g=$CLJS.mh.j($CLJS.O,$CLJS.Gn(function(v,x){$CLJS.G(x,0,null);x=$CLJS.G(x,1,null);x=$CLJS.Of(x);x=$CLJS.K.h(x,$CLJS.eG);return new $CLJS.Q(null,2,5,$CLJS.R,[v,x],null)}),l);l=$CLJS.mh.j($CLJS.O,$CLJS.Gn(function(v,x){$CLJS.G(x,0,null);x=$CLJS.G(x,1,null);x=$CLJS.Of(x);x=$CLJS.K.h(x,$CLJS.eG);return new $CLJS.Q(null,2,5,$CLJS.R,[x,v],null)}),l);var n=
$CLJS.D0,q=$CLJS.C0;$CLJS.D0=g;$CLJS.C0=l;try{try{return $CLJS.E0.g(f)}catch(v){var u=v;throw $CLJS.mj(function(){var x=$CLJS.MX(u);return $CLJS.va.h?$CLJS.va.h("Error converting legacy ref to pMBQL: %s",x):$CLJS.va.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.k(null,4,[$CLJS.hN,d,$CLJS.BX,e,hja,f,gja,$CLJS.D0],null),u);}}finally{$CLJS.C0=q,$CLJS.D0=n}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();