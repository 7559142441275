var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.reader.js");
'use strict';var lA=function(a,b,c,d,e){this.qa=a;this.ma=b;this.T=c;this.na=d;this.H=e;this.C=2565220111;this.J=0},nA=new $CLJS.M("tailrecursion.priority-map","not-found","tailrecursion.priority-map/not-found",-436727517);$CLJS.h=lA.prototype;$CLJS.h.la=function(a,b){return $CLJS.K.h(this.ma,b)};$CLJS.h.U=function(a,b,c){return $CLJS.K.j(this.ma,b,c)};$CLJS.h.Y=function(a,b,c){return $CLJS.ti(b,function(d){return $CLJS.ti(b,$CLJS.yi,""," ","",c,d)},"#tailrecursion.priority-map {",", ","}",c,this)};$CLJS.h.K=function(){return this.T};$CLJS.h.ia=function(){return $CLJS.D(this.ma)};
$CLJS.h.Sd=function(){if(0===$CLJS.D(this.ma))return null;var a=$CLJS.A(this.qa),b=$CLJS.A($CLJS.rc(a));return $CLJS.m(this.na)?new $CLJS.Q(null,2,5,$CLJS.R,[b,this.ma.g?this.ma.g(b):this.ma.call(null,b)],null):new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.qc(a)],null)};
$CLJS.h.Td=function(){if(0===$CLJS.D(this.ma))throw Error("Can't pop empty priority map");var a=$CLJS.A(this.qa),b=$CLJS.rc(a),c=$CLJS.A(b);a=$CLJS.qc(a);return $CLJS.E.h($CLJS.D(b),1)?new lA($CLJS.$l.h(this.qa,a),$CLJS.$l.h(this.ma,c),this.T,this.na,null):new lA($CLJS.T.j(this.qa,a,$CLJS.Jy.h(b,c)),$CLJS.$l.h(this.ma,c),this.T,this.na,null)};
$CLJS.h.je=function(){var a=this,b=this;return $CLJS.m(a.na)?$CLJS.z(function(){return function e(d){return new $CLJS.uf(null,function(){for(var f=d;;){var g=$CLJS.z(f);if(g){var l=g,n=$CLJS.A(l),q=$CLJS.G(n,0,null),u=$CLJS.G(n,1,null);if(g=$CLJS.z(function(v,x,y,C,H,J,S){return function fa(da){return new $CLJS.uf(null,function(){return function(){for(;;){var ma=$CLJS.z(da);if(ma){if($CLJS.me(ma)){var Fa=$CLJS.Wc(ma),ab=$CLJS.D(Fa),Cb=$CLJS.xf(ab);a:for(var Rb=0;;)if(Rb<ab){var Xb=$CLJS.Yd(Fa,Rb);
Xb=new $CLJS.Q(null,2,5,$CLJS.R,[Xb,a.ma.g?a.ma.g(Xb):a.ma.call(null,Xb)],null);Cb.add(Xb);Rb+=1}else{Fa=!0;break a}return Fa?$CLJS.Af($CLJS.Ef(Cb),fa($CLJS.Xc(ma))):$CLJS.Af($CLJS.Ef(Cb),null)}Cb=$CLJS.A(ma);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[Cb,a.ma.g?a.ma.g(Cb):a.ma.call(null,Cb)],null),fa($CLJS.vd(ma)))}return null}}}(v,x,y,C,H,J,S),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.sg.h(g,e($CLJS.vd(f)));f=$CLJS.vd(f)}else return null}},null,null)}($CLJS.Lc(a.qa))}()):$CLJS.z(function(){return function e(d){return new $CLJS.uf(null,
function(){for(var f=d;;){var g=$CLJS.z(f);if(g){var l=g,n=$CLJS.A(l),q=$CLJS.G(n,0,null),u=$CLJS.G(n,1,null);if(g=$CLJS.z(function(v,x,y,C,H,J,S){return function fa(da){return new $CLJS.uf(null,function(ma,Fa,ab){return function(){for(;;){var Cb=$CLJS.z(da);if(Cb){if($CLJS.me(Cb)){var Rb=$CLJS.Wc(Cb),Xb=$CLJS.D(Rb),jc=$CLJS.xf(Xb);a:for(var Db=0;;)if(Db<Xb){var Vb=$CLJS.Yd(Rb,Db);jc.add(new $CLJS.Q(null,2,5,$CLJS.R,[Vb,ab],null));Db+=1}else{Rb=!0;break a}return Rb?$CLJS.Af($CLJS.Ef(jc),fa($CLJS.Xc(Cb))):
$CLJS.Af($CLJS.Ef(jc),null)}jc=$CLJS.A(Cb);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[jc,ab],null),fa($CLJS.vd(Cb)))}return null}}}(v,x,y,C,H,J,S),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.sg.h(g,e($CLJS.vd(f)));f=$CLJS.vd(f)}else return null}},null,null)}($CLJS.Lc(a.qa))}())};$CLJS.h.ga=function(){var a=this.H;return null!=a?a:this.H=a=$CLJS.Bd(this)};$CLJS.h.V=function(a,b){return $CLJS.Dc(this.ma,b)};$CLJS.h.Ia=function(){return $CLJS.I($CLJS.oA,this.T)};
$CLJS.h.Ba=function(a,b){a=this.ma.h?this.ma.h(b,nA):this.ma.call(null,b,nA);if($CLJS.E.h(a,nA))return this;a=this.na.g?this.na.g(a):this.na.call(null,a);var c=this.qa.g?this.qa.g(a):this.qa.call(null,a);return $CLJS.E.h($CLJS.D(c),1)?new lA($CLJS.$l.h(this.qa,a),$CLJS.$l.h(this.ma,b),this.T,this.na,null):new lA($CLJS.T.j(this.qa,a,$CLJS.Jy.h(c,b)),$CLJS.$l.h(this.ma,b),this.T,this.na,null)};
$CLJS.h.Z=function(a,b,c){var d=$CLJS.K.j(this.ma,b,null);if($CLJS.m(d)){if($CLJS.E.h(d,c))return this;a=this.na.g?this.na.g(c):this.na.call(null,c);d=this.na.g?this.na.g(d):this.na.call(null,d);var e=$CLJS.K.h(this.qa,d);return $CLJS.E.h($CLJS.D(e),1)?new lA($CLJS.T.j($CLJS.$l.h(this.qa,d),a,$CLJS.gf.h($CLJS.K.j(this.qa,a,$CLJS.ai),b)),$CLJS.T.j(this.ma,b,c),this.T,this.na,null):new lA($CLJS.T.l(this.qa,d,$CLJS.Jy.h($CLJS.K.h(this.qa,d),b),$CLJS.F([a,$CLJS.gf.h($CLJS.K.j(this.qa,a,$CLJS.ai),b)])),
$CLJS.T.j(this.ma,b,c),this.T,this.na,null)}a=this.na.g?this.na.g(c):this.na.call(null,c);return new lA($CLJS.T.j(this.qa,a,$CLJS.gf.h($CLJS.K.j(this.qa,a,$CLJS.ai),b)),$CLJS.T.j(this.ma,b,c),this.T,this.na,null)};$CLJS.h.ua=function(a,b){return $CLJS.Ee(this.ma,b)};
$CLJS.h.fa=function(){var a=this,b=this;return $CLJS.m(a.na)?$CLJS.z(function(){return function e(d){return new $CLJS.uf(null,function(){for(var f=d;;){var g=$CLJS.z(f);if(g){var l=g,n=$CLJS.A(l),q=$CLJS.G(n,0,null),u=$CLJS.G(n,1,null);if(g=$CLJS.z(function(v,x,y,C,H,J,S){return function fa(da){return new $CLJS.uf(null,function(){return function(){for(;;){var ma=$CLJS.z(da);if(ma){if($CLJS.me(ma)){var Fa=$CLJS.Wc(ma),ab=$CLJS.D(Fa),Cb=$CLJS.xf(ab);a:for(var Rb=0;;)if(Rb<ab){var Xb=$CLJS.Yd(Fa,Rb);
Xb=new $CLJS.Q(null,2,5,$CLJS.R,[Xb,a.ma.g?a.ma.g(Xb):a.ma.call(null,Xb)],null);Cb.add(Xb);Rb+=1}else{Fa=!0;break a}return Fa?$CLJS.Af($CLJS.Ef(Cb),fa($CLJS.Xc(ma))):$CLJS.Af($CLJS.Ef(Cb),null)}Cb=$CLJS.A(ma);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[Cb,a.ma.g?a.ma.g(Cb):a.ma.call(null,Cb)],null),fa($CLJS.vd(ma)))}return null}}}(v,x,y,C,H,J,S),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.sg.h(g,e($CLJS.vd(f)));f=$CLJS.vd(f)}else return null}},null,null)}(a.qa)}()):$CLJS.z(function(){return function e(d){return new $CLJS.uf(null,
function(){for(var f=d;;){var g=$CLJS.z(f);if(g){var l=g,n=$CLJS.A(l),q=$CLJS.G(n,0,null),u=$CLJS.G(n,1,null);if(g=$CLJS.z(function(v,x,y,C,H,J,S){return function fa(da){return new $CLJS.uf(null,function(ma,Fa,ab){return function(){for(;;){var Cb=$CLJS.z(da);if(Cb){if($CLJS.me(Cb)){var Rb=$CLJS.Wc(Cb),Xb=$CLJS.D(Rb),jc=$CLJS.xf(Xb);a:for(var Db=0;;)if(Db<Xb){var Vb=$CLJS.Yd(Rb,Db);jc.add(new $CLJS.Q(null,2,5,$CLJS.R,[Vb,ab],null));Db+=1}else{Rb=!0;break a}return Rb?$CLJS.Af($CLJS.Ef(jc),fa($CLJS.Xc(Cb))):
$CLJS.Af($CLJS.Ef(jc),null)}jc=$CLJS.A(Cb);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[jc,ab],null),fa($CLJS.vd(Cb)))}return null}}}(v,x,y,C,H,J,S),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.sg.h(g,e($CLJS.vd(f)));f=$CLJS.vd(f)}else return null}},null,null)}(a.qa)}())};$CLJS.h.M=function(a,b){return new lA(this.qa,this.ma,b,this.na,this.H)};$CLJS.h.ja=function(a,b){return $CLJS.le(b)?this.Z(null,$CLJS.Yd(b,0),$CLJS.Yd(b,1)):$CLJS.Ob($CLJS.ac,this,b)};
$CLJS.h.call=function(a){switch(arguments.length-1){case 1:return this.g(arguments[1]);case 2:return this.h(arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length-1)].join(""));}};$CLJS.h.apply=function(a,b){return this.call.apply(this,[this].concat($CLJS.Nb(b)))};$CLJS.h.g=function(a){return this.la(null,a)};$CLJS.h.h=function(a,b){return this.U(null,a,b)};
$CLJS.oA=new lA(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;a:for(b=$CLJS.z(0<b.length?new $CLJS.rd(b.slice(0),0,null):null),d=$CLJS.Ck;;)if(b)c=$CLJS.B($CLJS.B(b)),d=$CLJS.T.j(d,$CLJS.A(b),$CLJS.Vd(b)),b=c;else break a;return d}(),$CLJS.O,$CLJS.O,$CLJS.Ve,null);$CLJS.K.h($CLJS.r($CLJS.kA),"tailrecursion.priority-map");
$CLJS.Ri.B($CLJS.kA,$CLJS.T,"tailrecursion.priority-map",function(a){if($CLJS.je(a))return $CLJS.mh.h($CLJS.oA,a);throw Error("Priority map literal expects a map for its elements.");});