var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var rla,sla,tla,q4,ula,u4,v4,w4,x4,vla,wla,xla,yla,zla,Ala,Bla,Cla,Dla,n8,J4,K4,L4,Ela,Fla,I4,H4,Gla;$CLJS.T3=function(a,b){$CLJS.Ob(function(c,d){return a.g?a.g(d):a.call(null,d)},null,b)};
rla=function(a){return function(b){var c=$CLJS.ig(!0);return function(){function d(l,n){var q=$CLJS.r(c);if($CLJS.m($CLJS.m(q)?a.g?a.g(n):a.call(null,n):q))return l;$CLJS.Mm(c,null);return b.h?b.h(l,n):b.call(null,l,n)}function e(l){return b.g?b.g(l):b.call(null,l)}function f(){return b.v?b.v():b.call(null)}var g=null;g=function(l,n){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,n)}throw Error("Invalid arity: "+arguments.length);};g.v=
f;g.g=e;g.h=d;return g}()}};sla=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.rd(b.slice(3),0,null):null;return $CLJS.Bb($CLJS.z($CLJS.Ss(c,d)))?c:$CLJS.P.O($CLJS.nY,c,d,e,b)};$CLJS.$3=function(a,b){return $CLJS.UY(a)&&$CLJS.E.h($CLJS.A(a),b)};
tla=function(a,b){return $CLJS.A($CLJS.eg(function(c){if($CLJS.m($CLJS.m(c)?b:c)){var d=$CLJS.iA.g(c);c=$CLJS.jA.g(c);d=$CLJS.m($CLJS.qi(d,b))?$CLJS.Ys(b,d,c):null}else d=null;return d},a))};q4=function(a){a=$CLJS.Q1(a);var b=$CLJS.at(a);b||($CLJS.m(a)?(b=$CLJS.m(a)?!$CLJS.Ee($CLJS.r($CLJS.g2),$CLJS.Q1(a).toLowerCase()):null,b=$CLJS.Bb(b)):b=null);return $CLJS.m(b)?a:tla($CLJS.Lc($CLJS.r($CLJS.Y1)),a)};
$CLJS.r4=function(a,b,c,d){if($CLJS.z(c)){d=$CLJS.mm.h($CLJS.eU,d);var e=$CLJS.mh.j($CLJS.ai,$CLJS.kg.g(function(f){return $CLJS.D2.B(a,b,f,c)}),d);return $CLJS.mm.h(function(f){return $CLJS.T.j(f,$CLJS.u_,$CLJS.Ee(e,f))},c)}return null};$CLJS.H5=function(a){a=$CLJS.x2.g(a);return $CLJS.m(a)?new $CLJS.k(null,2,[$CLJS.su,$CLJS.eI,$CLJS.kb,a],null):null};$CLJS.s4=function(a){return $CLJS.E.h($CLJS.IF(a),$CLJS.VL)};
ula=function(a,b){return $CLJS.m($CLJS.mG.g(a))?$CLJS.nm.j(a,$CLJS.mG,function(c){return $CLJS.ie(c)?$CLJS.mm.h(function(d){return $CLJS.t4.h?$CLJS.t4.h(d,b):$CLJS.t4.call(null,d,b)},c):c}):a};
u4=function(a){if($CLJS.m(a)){var b=$CLJS.vg;a:try{if($CLJS.le(a)&&4===$CLJS.D(a))try{if($CLJS.Pd(a,0)instanceof $CLJS.M)try{var c=$CLJS.Pd(a,2);if($CLJS.le(c)&&3===$CLJS.D(c))try{var d=$CLJS.Pd(c,0);if($CLJS.N(d,$CLJS.oH))try{var e=$CLJS.Pd(a,3);if($CLJS.le(e)&&3===$CLJS.D(e))try{var f=$CLJS.Pd(e,0);if($CLJS.N(f,$CLJS.oH)){$CLJS.Pd(e,1);$CLJS.Pd(e,2);$CLJS.Pd(c,1);$CLJS.Pd(c,2);$CLJS.Pd(a,0);$CLJS.Pd(a,1);var g=new $CLJS.Q(null,1,5,$CLJS.R,[!0],null)}else throw $CLJS.Z;}catch(n){if(n instanceof Error){var l=
n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;}catch(n){if(n instanceof
Error){l=n;if(l===$CLJS.Z){g=new $CLJS.Q(null,1,5,$CLJS.R,[!1],null);break a}throw l;}throw n;}a=$CLJS.A($CLJS.z(b($CLJS.Eb,g)))}else a=null;return a};v4=function(a){if($CLJS.m(u4(a))){$CLJS.G(a,0,null);$CLJS.G(a,1,null);var b=$CLJS.G(a,2,null);$CLJS.G(a,3,null);return b}return null};w4=function(a){return $CLJS.m(u4(a))?($CLJS.G(a,0,null),$CLJS.G(a,1,null),$CLJS.G(a,2,null),$CLJS.G(a,3,null)):null};
x4=function(a,b){var c=$CLJS.t4;if($CLJS.Bb(u4(a)))return a;var d=$CLJS.G(a,0,null),e=$CLJS.G(a,1,null),f=$CLJS.G(a,2,null);a=$CLJS.G(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[d,e,f,$CLJS.P.j(c,a,b)],null)};
vla=function(a,b,c){return $CLJS.ee($CLJS.dM.g(a))?a:$CLJS.m(b)?sla(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.dM],null),function(d){return function l(f,g){try{if($CLJS.le(g)&&3===$CLJS.D(g))try{var n=$CLJS.Pd(g,0);if($CLJS.N(n,$CLJS.oH))try{var q=$CLJS.Pd(g,1);if(null!=q?q.C&256||$CLJS.t===q.Rf||(q.C?0:$CLJS.Jb($CLJS.hc,q)):$CLJS.Jb($CLJS.hc,q))try{var u=$CLJS.K.j(q,$CLJS.UP,$CLJS.KW);if($CLJS.E.h(u,b))return $CLJS.Pd(g,2),$CLJS.t4.h?$CLJS.t4.h(g,c):$CLJS.t4.call(null,g,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.UX(l,f,g);throw v;}throw x;}}($CLJS.Mg,d)}):$CLJS.nm.j(a,$CLJS.dM,function(d){return $CLJS.mm.h(function(e){return x4(e,$CLJS.F([c]))},d)})};
$CLJS.t4=function(a,b){var c=$CLJS.IF(a);switch(c instanceof $CLJS.M?c.R:null){case "field":return $CLJS.fU(a,$CLJS.dU,$CLJS.F([$CLJS.UP,b]));case "metadata/column":return $CLJS.dU(a,$CLJS.H_,b);case "mbql/join":return c=$CLJS.G1(a),vla(ula($CLJS.dU(a,$CLJS.qG,b),b),c,b);default:return b=$CLJS.uE($CLJS.tx),$CLJS.m($CLJS.yE("metabase.lib.join",b))&&$CLJS.zE("metabase.lib.join",b,$CLJS.VA.l($CLJS.F(["with-join-value should not be called with",$CLJS.Oi.l($CLJS.F([a]))])),null),a}};
$CLJS.Y_=function(a,b,c){var d=$CLJS.T.l,e=$CLJS.OA($CLJS.WZ,$CLJS.W)(c);a=$CLJS.H1($CLJS.qG.g(a),$CLJS.OA($CLJS.WZ,$CLJS.W)(c));b=b.g?b.g(a):b.call(null,a);return d.call($CLJS.T,c,$CLJS.WZ,e,$CLJS.F([$CLJS.s_,b]))};wla=function(a,b){return $CLJS.Bb(b)?a:$CLJS.mm.h(function(c){var d=w4(c);d=$CLJS.m(d)?$CLJS.m($CLJS.G1(d))?null:x4(c,$CLJS.F([b])):null;return $CLJS.m(d)?d:c},a)};$CLJS.y4=function(a,b){b=wla($CLJS.mm.h($CLJS.iU,b),$CLJS.G1(a));return $CLJS.dU(a,$CLJS.dM,$CLJS.Pf(b))};
$CLJS.C4=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.h(b,$CLJS.Mg)?$CLJS.Oz:$CLJS.Pf($CLJS.mh.j($CLJS.Mg,$CLJS.gm.h($CLJS.kg.g($CLJS.eU),function(){var c=$CLJS.G1(a);return $CLJS.m(c)?$CLJS.kg.g(function(d){return $CLJS.t4(d,c)}):$CLJS.Ve}()),b));return $CLJS.dU(a,$CLJS.mG,b)};
xla=function(a,b){b=$CLJS.eg(function(d){return $CLJS.D2.h(d,a)},b);var c=$CLJS.uU($CLJS.OA($CLJS.Q_,$CLJS.R_),b);if($CLJS.m(c))return c;b=$CLJS.A(b);if($CLJS.m(b))return b;b=$CLJS.uU($CLJS.Q_,a);if($CLJS.m(b))return b;b=$CLJS.uU($CLJS.R_,a);return $CLJS.m(b)?b:$CLJS.A(a)};yla=function(a){return"string"===typeof a?(0,$CLJS.za)($CLJS.Ys(a,/ id$/i,"")):null};
zla=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Ji(function(){return q4(b)});var d=new $CLJS.Ji(function(){return q4(a)});return $CLJS.E.h(a,b)||$CLJS.E.h(a,$CLJS.r(c))||$CLJS.E.h($CLJS.r(d),b)||$CLJS.E.h($CLJS.r(d),$CLJS.r(c))}return c}return c};
Ala=function(a,b,c){var d=$CLJS.MZ.h($CLJS.E.h($CLJS.su.g(b),$CLJS.lX)?b:a,b),e=$CLJS.m(c)?yla($CLJS.MZ.h(a,c)):null,f=zla(d,e);return function(){var g=function(){if($CLJS.m(d)){if($CLJS.m(e)){var l=$CLJS.Bb($CLJS.pi(/id/i,e));return l?(l=!f)?[$CLJS.p.g(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.m(g)?g:$CLJS.m(d)?d:$CLJS.m(e)?e:"source"}()};
Bla=function(a,b,c,d,e){return function n(g,l){try{if($CLJS.$3(l,$CLJS.oH)&&$CLJS.te($CLJS.D2.B(a,b,l,e)))return $CLJS.t4(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var q=u;if(q===$CLJS.Z)return $CLJS.UX(n,g,l);throw q;}throw u;}}($CLJS.Mg,c)};
Cla=function(a,b,c,d,e,f){c=Bla(a,b,c,d,f);return function q(l,n){try{if($CLJS.le(n)&&4===$CLJS.D(n))try{var u=$CLJS.Pd(n,2);if($CLJS.$3(u,$CLJS.oH))try{var v=$CLJS.Pd(n,3);if($CLJS.$3(v,$CLJS.oH)){var x=$CLJS.Pd(n,3),y=$CLJS.Pd(n,2),C=$CLJS.Pd(n,0),H=$CLJS.Pd(n,1),J=$CLJS.G1(y),S=$CLJS.G1(x);if($CLJS.Bb($CLJS.m(J)?J:S))return $CLJS.m($CLJS.D2.B(a,b,x,e))?new $CLJS.Q(null,4,5,$CLJS.R,[C,H,$CLJS.t4(y,d),x],null):new $CLJS.Q(null,4,5,$CLJS.R,[C,H,y,$CLJS.t4(x,d)],null);if($CLJS.E.h(J,d)&&$CLJS.E.h(S,
d)){var V=$CLJS.fU(y,$CLJS.$l,$CLJS.F([$CLJS.UP])),da=$CLJS.fU(x,$CLJS.$l,$CLJS.F([$CLJS.UP])),fa=null==$CLJS.D2.B(a,b,V,e);var ma=fa?$CLJS.D2.B(a,b,da,e):fa;return $CLJS.m(ma)?new $CLJS.Q(null,4,5,$CLJS.R,[C,H,y,da],null):new $CLJS.Q(null,4,5,$CLJS.R,[C,H,V,x],null)}return n}throw $CLJS.Z;}catch(ab){if(ab instanceof Error){var Fa=ab;if(Fa===$CLJS.Z)throw $CLJS.Z;throw Fa;}throw ab;}else throw $CLJS.Z;}catch(ab){if(ab instanceof Error){Fa=ab;if(Fa===$CLJS.Z)throw $CLJS.Z;throw Fa;}throw ab;}else throw $CLJS.Z;
}catch(ab){if(ab instanceof Error){Fa=ab;if(Fa===$CLJS.Z)return $CLJS.UX(q,l,n);throw Fa;}throw ab;}}($CLJS.Mg,c)};Dla=function(a,b){var c=$CLJS.M5();$CLJS.T3(c,b);return c(a)};
$CLJS.D4=function(a,b,c){if($CLJS.Ee(c,$CLJS.qG))return c;var d=$CLJS.JZ(a,b),e=$CLJS.w1.j(a,b,d),f=$CLJS.z($CLJS.vg($CLJS.Eb,function v(q,u){try{if($CLJS.le(u)&&1<=$CLJS.D(u))try{var x=$CLJS.tm.j(u,0,1);if($CLJS.le(x)&&1===$CLJS.D(x))try{var y=$CLJS.Pd(x,0);if($CLJS.N(y,$CLJS.oH))return new $CLJS.Q(null,1,5,$CLJS.R,[u],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var C=H;if(C===$CLJS.Z)throw $CLJS.Z;throw C;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){C=H;if(C===$CLJS.Z)throw $CLJS.Z;
throw C;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){C=H;if(C===$CLJS.Z)return $CLJS.sX(v,q,u);throw C;}throw H;}}($CLJS.Mg,$CLJS.dM.g(c))));f=xla(e,f);var g=Dla(Ala(a,c,f),$CLJS.eg($CLJS.qG,$CLJS.dO.g(d))),l=$CLJS.v1.g($CLJS.B1.h(a,$CLJS.$L.g(c)));return $CLJS.t4($CLJS.nm.j(c,$CLJS.dM,function(q){return $CLJS.mm.h(function(u){return Cla(a,b,u,g,e,l)},q)}),g)};$CLJS.E4=function(a){return $CLJS.dM.g(a)};
$CLJS.F4=function(a){return $CLJS.xm.l($CLJS.F([new $CLJS.k(null,2,[$CLJS.su,$CLJS.eM,$CLJS.iG,a],null),$CLJS.E.h(a,$CLJS.wG)?new $CLJS.k(null,1,[$CLJS.Dj,!0],null):null]))};$CLJS.G4=function(a,b){b=$CLJS.A($CLJS.$L.g(b));return $CLJS.m($CLJS.TW.g(b))?$CLJS.V_(a,$CLJS.TW.g(b)):$CLJS.m($CLJS.FP.g(b))?$CLJS.R0(a,$CLJS.FP.g(b)):null};
n8=function(a){a=$CLJS.Ti(function(d){return $CLJS.R_(d)?H4:$CLJS.Q_(d)?I4:$CLJS.pu},a);var b=$CLJS.Of(a);a=$CLJS.K.h(b,H4);var c=$CLJS.K.h(b,I4);b=$CLJS.K.h(b,$CLJS.pu);return $CLJS.sg.l(a,c,$CLJS.F([b]))};J4=function(a,b,c,d){return $CLJS.Bb(c)?d:$CLJS.mm.h(function(e){return $CLJS.m($CLJS.u_.g(e))?$CLJS.I5(e,$CLJS.H5(c)):e},$CLJS.r4(a,b,d,new $CLJS.Q(null,1,5,$CLJS.R,[c],null)))};K4=function(a,b,c,d){return $CLJS.uU($CLJS.R_,$CLJS.w1.B(a,b,c,d))};
L4=function(a,b){var c=$CLJS.rk.g(a);return $CLJS.m(c)?$CLJS.uU(function(d){d=$CLJS.Of(d);var e=$CLJS.K.h(d,$CLJS.t_);return $CLJS.Q_(d)&&$CLJS.E.h(e,c)},b):null};Ela={};Fla=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);I4=new $CLJS.M(null,"fk","fk",398442651);H4=new $CLJS.M(null,"pk","pk",-771936732);Gla=new $CLJS.w("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);$CLJS.j_.o(null,$CLJS.VL,function(a,b,c){b=$CLJS.Of(c);b=$CLJS.K.h(b,$CLJS.$L);b=$CLJS.G(b,0,null);c=$CLJS.Of(b);b=$CLJS.K.h(c,$CLJS.FP);c=$CLJS.K.h(c,$CLJS.TW);a=$CLJS.m(b)?$CLJS.vF.g($CLJS.R0(a,b)):null;if($CLJS.m(a))return a;a=$CLJS.m(c)?$CLJS.L1(c):null;return $CLJS.m(a)?a:$CLJS.$F("Native Query")});$CLJS.p_.o(null,$CLJS.VL,function(a,b,c){a=$CLJS.MZ.j(a,b,c);c=$CLJS.qG.g(c);return new $CLJS.k(null,2,[$CLJS.W,$CLJS.m(c)?c:a,$CLJS.vF,a],null)});
$CLJS.KZ.o(null,$CLJS.VL,function(){throw $CLJS.nj("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.O);});
$CLJS.j_.o(null,$CLJS.eM,function(a,b,c){a=$CLJS.Of(c);a=$CLJS.K.h(a,$CLJS.iG);a=a instanceof $CLJS.M?a.R:null;switch(a){case "left-join":return $CLJS.$F("Left outer join");case "right-join":return $CLJS.$F("Right outer join");case "inner-join":return $CLJS.$F("Inner join");case "full-join":return $CLJS.$F("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.g(a)].join(""));}});
$CLJS.p_.o(null,$CLJS.eM,function(a,b,c){var d=$CLJS.Of(c),e=$CLJS.K.h(d,$CLJS.iG);c=$CLJS.K.h(d,$CLJS.Dj);a=new $CLJS.k(null,2,[$CLJS.r1,$CLJS.AE(e),$CLJS.vF,$CLJS.MZ.j(a,b,d)],null);return $CLJS.m(c)?$CLJS.T.j(a,$CLJS.Dj,!0):a});
$CLJS.u1.o(null,$CLJS.VL,function(a,b,c,d){var e=$CLJS.Of(c),f=$CLJS.K.h(e,$CLJS.qG),g=$CLJS.K.j(e,$CLJS.mG,$CLJS.Oz),l=$CLJS.K.h(e,$CLJS.$L);c=$CLJS.Of(d);var n=$CLJS.K.h(c,$CLJS.o1);if($CLJS.E.h(g,$CLJS.Oz))return null;var q="undefined"!==typeof $CLJS.lM&&"undefined"!==typeof Ela&&"undefined"!==typeof $CLJS.Hla&&"undefined"!==typeof $CLJS.Bua?new $CLJS.pd(function(){return $CLJS.Bua},$CLJS.I(Gla,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),null):null,u=function(){var v=$CLJS.T.j(a,$CLJS.$L,l);return $CLJS.m(q)?
q.h?q.h(v,-1):q.call(null,v,-1):v}();c=$CLJS.E.h(g,$CLJS.ix)?$CLJS.v1.B(u,-1,$CLJS.ce(l),c):function(){return function y(x){return new $CLJS.uf(null,function(){for(;;){var C=$CLJS.z(x);if(C){if($CLJS.me(C)){var H=$CLJS.Wc(C),J=$CLJS.D(H),S=$CLJS.xf(J);a:for(var V=0;;)if(V<J){var da=$CLJS.Yd(H,V);da=$CLJS.fU(da,$CLJS.$l,$CLJS.F([$CLJS.UP]));da=$CLJS.T0.j(u,-1,da);S.add(da);V+=1}else{H=!0;break a}return H?$CLJS.Af($CLJS.Ef(S),y($CLJS.Xc(C))):$CLJS.Af($CLJS.Ef(S),null)}S=$CLJS.A(C);S=$CLJS.fU(S,$CLJS.$l,
$CLJS.F([$CLJS.UP]));return $CLJS.kf($CLJS.T0.j(u,-1,S),y($CLJS.vd(C)))}return null}},null,null)}(g)}();return $CLJS.mm.h(function(v){v=$CLJS.T.j(v,$CLJS.F_,f);v=$CLJS.t4($CLJS.T.l(v,$CLJS.vF,$CLJS.MZ.j(a,b,v),$CLJS.F([$CLJS.xL,$CLJS.h_])),f);return $CLJS.Y_(e,n,v)},c)});$CLJS.q_.o(null,$CLJS.VL,function(a,b,c,d){return $CLJS.v1.B(a,b,$CLJS.T.j(c,$CLJS.mG,$CLJS.ix),d)});
var d0,Ila=$CLJS.ka($CLJS.O),Jla=$CLJS.ka($CLJS.O),Kla=$CLJS.ka($CLJS.O),Lla=$CLJS.ka($CLJS.O),Mla=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));d0=new $CLJS.hj($CLJS.Fi.h("metabase.lib.join","join-clause-method"),$CLJS.IF,Mla,Ila,Jla,Kla,Lla);d0.o(null,$CLJS.VL,function(a){return a});d0.o(null,$CLJS.lX,function(a){return $CLJS.gU(new $CLJS.k(null,2,[$CLJS.su,$CLJS.VL,$CLJS.$L,$CLJS.$L.g($CLJS.ZY(a))],null))});
d0.o(null,$CLJS.ZW,function(a){return $CLJS.gU(new $CLJS.k(null,2,[$CLJS.su,$CLJS.VL,$CLJS.$L,new $CLJS.Q(null,1,5,$CLJS.R,[a],null)],null))});d0.o(null,$CLJS.PL,function(a){return $CLJS.gU(new $CLJS.k(null,2,[$CLJS.su,$CLJS.VL,$CLJS.$L,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.k(null,2,[$CLJS.TW,$CLJS.rk.g(a),$CLJS.su,$CLJS.ZW],null)],null)],null))});
d0.o(null,$CLJS.CL,function(a){var b=$CLJS.Of(a),c=$CLJS.K.h(b,$CLJS.H_);a=$CLJS.K.h(b,Fla);b=d0.g(new $CLJS.k(null,3,[$CLJS.su,$CLJS.ZW,$CLJS.MB,new $CLJS.k(null,1,[$CLJS.eG,$CLJS.p.g($CLJS.jj())],null),$CLJS.FP,$CLJS.rk.g(b)],null));c=$CLJS.m(c)?$CLJS.t4(b,c):b;return $CLJS.m(a)?$CLJS.C4.h?$CLJS.C4.h(c,a):$CLJS.C4.call(null,c,a):c});
$CLJS.i8=function(){function a(d,e){return $CLJS.y4(c.g?c.g(d):c.call(null,d),e)}function b(d){return $CLJS.F5(d0.g(d),$CLJS.mG,$CLJS.ix)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.Nla=function(){function a(d,e,f){var g=$CLJS.i8.g(f);f=$CLJS.ee($CLJS.E4.g?$CLJS.E4.g(g):$CLJS.E4.call(null,g))?function(){var n=$CLJS.G4.h?$CLJS.G4.h(d,g):$CLJS.G4.call(null,d,g);return $CLJS.M4.j?$CLJS.M4.j(d,e,n):$CLJS.M4.call(null,d,e,n)}():null;f=$CLJS.m(f)?$CLJS.y4(g,new $CLJS.Q(null,1,5,$CLJS.R,[f],null)):g;var l=$CLJS.D4(d,e,f);return $CLJS.R3.l(d,e,$CLJS.nm,$CLJS.F([$CLJS.dO,function(n){return $CLJS.gf.h($CLJS.Ng(n),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}
var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.r8=function(){function a(d,e){return $CLJS.Pf($CLJS.K.h($CLJS.JZ(d,e),$CLJS.dO))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.C8=function(){function a(d){d=$CLJS.yZ($CLJS.DZ(d));d=$CLJS.Rz.g(d);return $CLJS.mh.j($CLJS.Mg,$CLJS.gm.h($CLJS.ug($CLJS.bg($CLJS.Ee,d)),$CLJS.kg.g($CLJS.F4)),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.wG,$CLJS.lG,$CLJS.AG,$CLJS.DG],null))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.R8=function(){function a(d,e,f,g){var l=$CLJS.s4(f)?$CLJS.G1(f):null,n=$CLJS.mh.j($CLJS.ai,$CLJS.gm.h($CLJS.kg.g($CLJS.G1),rla(function(q){return $CLJS.Yh.h(q,l)})),$CLJS.r8.h(d,e));f=$CLJS.m(g)?g:$CLJS.s4(f)?v4($CLJS.A($CLJS.E4(f))):null;return n8(J4(d,e,f,$CLJS.lm.h(function(q){q=$CLJS.G1(q);return $CLJS.m(q)?$CLJS.Ee(n,q):null},$CLJS.w1.B(d,e,$CLJS.JZ(d,e),new $CLJS.k(null,1,[$CLJS.j1,!1],null)))))}function b(d,e,f,g){return c.O?c.O(d,-1,e,f,g):c.call(null,d,-1,e,f,g)}var c=null;c=function(d,
e,f,g,l){switch(arguments.length){case 4:return b.call(this,d,e,f,g);case 5:return a.call(this,d,e,f,g,l)}throw Error("Invalid arity: "+arguments.length);};c.B=b;c.O=a;return c}();
$CLJS.E8=function(){function a(d,e,f,g,l){g=$CLJS.s4(f)?$CLJS.G4(d,f):f;var n=$CLJS.s4(f)?$CLJS.G1(f):null;f=$CLJS.m(l)?l:$CLJS.s4(f)?w4($CLJS.A($CLJS.E4(f))):null;f=$CLJS.m(f)?$CLJS.Bb(n)?$CLJS.fU(f,$CLJS.$l,$CLJS.F([$CLJS.UP])):f:null;return n8(J4(d,e,f,$CLJS.kg.h(function(q){q=$CLJS.T.j(q,$CLJS.xL,$CLJS.h_);return $CLJS.m(n)?$CLJS.t4(q,n):q},$CLJS.w1.B(d,e,g,new $CLJS.k(null,1,[$CLJS.j1,!1],null)))))}function b(d,e,f,g){return c.O?c.O(d,-1,e,f,g):c.call(null,d,-1,e,f,g)}var c=null;c=function(d,
e,f,g,l){switch(arguments.length){case 4:return b.call(this,d,e,f,g);case 5:return a.call(this,d,e,f,g,l)}throw Error("Invalid arity: "+arguments.length);};c.B=b;c.O=a;return c}();$CLJS.V8=function(){function a(c,d,e){return b.B?b.B(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.I2}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.B=function(){return $CLJS.I2};return b}();
$CLJS.M4=function(){function a(d,e,f){function g(u,v){return $CLJS.A8.l($CLJS.G2.g($CLJS.Zr),u,$CLJS.F([v]))}var l=new $CLJS.k(null,2,[$CLJS.j1,!1,$CLJS.q1,!1],null),n=function(){var u=K4(d,e,f,l);if($CLJS.m(u)){if($CLJS.m(u)){var v=$CLJS.w1.j(d,e,$CLJS.JZ(d,e));v=L4(u,v)}else v=null;return $CLJS.m(v)?g(v,u):null}return null}();if($CLJS.m(n))return n;n=K4(d,e,$CLJS.JZ(d,e),null);if($CLJS.m(n)){var q=L4(n,$CLJS.w1.B(d,e,f,l));return $CLJS.m(q)?g(n,q):null}return null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.Ola=function(){function a(e,f,g,l){l=$CLJS.m(l)?l:$CLJS.s4(g)?v4($CLJS.A($CLJS.E4(g))):null;$CLJS.m(l)?(l=$CLJS.PZ.j(e,f,l),l=$CLJS.Ss(l,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.DX,$CLJS.vF],null))):l=null;if($CLJS.m(l))return l;if(l=0===$CLJS.xX(e,f)){l=$CLJS.r8.h(e,f);var n=$CLJS.ee(l);g=n?n:$CLJS.s4(g)?$CLJS.E.h($CLJS.qG.g(g),$CLJS.qG.g($CLJS.A(l))):null;g=$CLJS.m(g)?$CLJS.Q0(e):g}else g=l;$CLJS.m(g)?(g=$CLJS.Q0(e),g=$CLJS.R0(e,g),e=$CLJS.MZ.j(e,f,g)):e=null;return $CLJS.m(e)?e:$CLJS.$F("Previous results")}
function b(e,f,g){return d.B?d.B(e,-1,f,g):d.call(null,e,-1,f,g)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,g,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,g);case 4:return a.call(this,e,f,g,l)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.j=b;d.B=a;return d}();
$CLJS.Pla=function(){function a(d,e,f,g){f=$CLJS.iU.g(f);$CLJS.G(f,0,null);$CLJS.G(f,1,null);var l=$CLJS.G(f,2,null),n=$CLJS.G(f,3,null);g=g instanceof $CLJS.M?g:$CLJS.kb.g(g);e=$CLJS.xX(d,e);l=$CLJS.d3.j(d,e,l);d=$CLJS.d3.j(d,e,n);n=null==g||$CLJS.Ee($CLJS.di($CLJS.kg.h($CLJS.kb,l)),g);d=null==g||$CLJS.Ee($CLJS.di($CLJS.kg.h($CLJS.kb,d)),g);f=n?$CLJS.nm.B(f,2,$CLJS.I5,g):f;return d?$CLJS.nm.B(f,3,$CLJS.I5,g):f}function b(d,e,f){return c.B?c.B(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.B=a;return c}();$CLJS.m_.o(null,$CLJS.dO,function(a,b){var c=$CLJS.Pf($CLJS.r8.h(a,b));c=null==c?null:$CLJS.kg.h(function(d){return $CLJS.MZ.j(a,b,d)},c);return null==c?null:$CLJS.Tt(" + ",c)});