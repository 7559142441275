var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var vU,wU,xna,yU,yna,zna,Ana,iI,hI;$CLJS.Ti=function(a,b){return $CLJS.Rc($CLJS.Ob(function(c,d){var e=a.g?a.g(d):a.call(null,d);return $CLJS.ph.j(c,e,$CLJS.gf.h($CLJS.K.j(c,e,$CLJS.Mg),d))},$CLJS.Pc($CLJS.O),b))};$CLJS.uU=function(a,b){return $CLJS.Ob(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Fd(d):null},null,b)};
vU=function(a,b){$CLJS.G(a,0,null);$CLJS.G(a,1,null);$CLJS.G(a,2,null);a=$CLJS.G(a,3,null);b=$CLJS.XF(b,$CLJS.yD)?$CLJS.gI:$CLJS.XF(b,$CLJS.aF)?$CLJS.mF:$CLJS.XF(b,$CLJS.kE)?$CLJS.nF:null;return $CLJS.m(b)?$CLJS.oT(b,a):!0};
wU=function(a){a=$CLJS.z(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.Ti(function(d){return $CLJS.XF($CLJS.EJ(d),$CLJS.JD)},a);var b=$CLJS.Of(a);a=$CLJS.K.h(b,!1);b=$CLJS.K.h(b,!0);if($CLJS.Yh.h($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.EJ($CLJS.A(a));return $CLJS.Vf(function(d){$CLJS.G(d,0,null);$CLJS.G(d,1,null);$CLJS.G(d,2,null);
var e=$CLJS.G(d,3,null);return $CLJS.m(vU(d,c))?null:["Cannot add a ",$CLJS.p.g(e)," interval to a ",$CLJS.p.g(c)," expression"].join("")},b)};
xna=function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.vs,new $CLJS.k(null,1,[$CLJS.Pt,[$CLJS.p.g(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.gs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.dG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hI],null)],
null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hI],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Bt,function(b){b=$CLJS.Of(b);b=$CLJS.K.h(b,$CLJS.Yk);return["Invalid ",$CLJS.p.g(a)," clause: ",$CLJS.p.g(wU(b))].join("")}],null),$CLJS.$f(wU)],null)],null)};
yU=function(a){var b=$CLJS.R,c=xna(a);a=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gs,new $CLJS.k(null,1,[$CLJS.Pt,[$CLJS.p.g(a)," clause with numeric args"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.dG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fs,new $CLJS.k(null,1,[$CLJS.bk,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)],null)],null);
return new $CLJS.Q(null,3,5,b,[$CLJS.os,c,a],null)};yna=function(a){return $CLJS.Re($CLJS.lT,$CLJS.kg.h(function(b){var c=$CLJS.EJ(b),d=$CLJS.XF(c,$CLJS.tG);b=d?$CLJS.oT($CLJS.sH,b):d;return $CLJS.m(b)?$CLJS.Ij:c},a))};zna=function(a){a=$CLJS.uU(function(b){return!$CLJS.XF(b,$CLJS.JD)},$CLJS.kg.h($CLJS.EJ,a));return $CLJS.XF(a,$CLJS.tG)?$CLJS.Mj:a};Ana=function(a){return $CLJS.m($CLJS.Vf(function(b){return $CLJS.XF($CLJS.EJ(b),$CLJS.JD)},a))?zna(a):yna(a)};
iI=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.jI=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);hI=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.oJ(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.is,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bs,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,hI],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.z(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.EJ(a);return $CLJS.Uf(function(d){return vU(d,c)},b)}],null)],null));
$CLJS.oJ(iI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fs,new $CLJS.k(null,1,[$CLJS.bk,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)],null));$CLJS.PH.h($CLJS.bs,yU($CLJS.bs));$CLJS.PH.h($CLJS.Ft,yU($CLJS.Ft));$CLJS.zT($CLJS.cs,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ot,iI],null)]));$CLJS.zT($CLJS.KH,$CLJS.F([$CLJS.Ft,$CLJS.YE,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ot,iI],null)]));
for(var kI=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bs,$CLJS.Ft,$CLJS.cs],null)),lI=null,mI=0,nI=0;;)if(nI<mI){var pca=lI.ha(null,nI);$CLJS.mT(pca,$CLJS.jI);nI+=1}else{var oI=$CLJS.z(kI);if(oI){var pI=oI;if($CLJS.me(pI)){var qI=$CLJS.Wc(pI),qca=$CLJS.Xc(pI),rca=qI,sca=$CLJS.D(qI);kI=qca;lI=rca;mI=sca}else{var tca=$CLJS.A(pI);$CLJS.mT(tca,$CLJS.jI);kI=$CLJS.B(pI);lI=null;mI=0}nI=0}else break}$CLJS.DJ.o(null,$CLJS.jI,function(a){a=$CLJS.z(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);return Ana(a)});
$CLJS.xT($CLJS.OG,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));$CLJS.mT($CLJS.OG,$CLJS.yG);
for(var rI=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MH,$CLJS.HG,$CLJS.bH],null)),sI=null,tI=0,uI=0;;)if(uI<tI){var uca=sI.ha(null,uI);$CLJS.xT(uca,$CLJS.F([$CLJS.Ft,$CLJS.YE,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));uI+=1}else{var vI=$CLJS.z(rI);if(vI){var wI=vI;if($CLJS.me(wI)){var xI=$CLJS.Wc(wI),vca=$CLJS.Xc(wI),wca=xI,xca=$CLJS.D(xI);rI=vca;sI=wca;tI=xca}else{var yca=$CLJS.A(wI);$CLJS.xT(yca,$CLJS.F([$CLJS.Ft,$CLJS.YE,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));rI=$CLJS.B(wI);sI=null;tI=0}uI=0}else break}
for(var yI=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KG,$CLJS.mH,$CLJS.tH],null)),zI=null,AI=0,BI=0;;)if(BI<AI){var Bca=zI.ha(null,BI);$CLJS.xT(Bca,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));BI+=1}else{var CI=$CLJS.z(yI);if(CI){var DI=CI;if($CLJS.me(DI)){var EI=$CLJS.Wc(DI),Cca=$CLJS.Xc(DI),Dca=EI,Fca=$CLJS.D(EI);yI=Cca;zI=Dca;AI=Fca}else{var Kca=$CLJS.A(DI);$CLJS.xT(Kca,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));yI=$CLJS.B(DI);zI=null;AI=0}BI=0}else break}$CLJS.xT($CLJS.LG,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));
$CLJS.DJ.o(null,$CLJS.LG,function(a){$CLJS.G(a,0,null);$CLJS.G(a,1,null);var b=$CLJS.G(a,2,null);a=$CLJS.G(a,3,null);return $CLJS.XF($CLJS.EJ(b),$CLJS.wj)&&$CLJS.XF($CLJS.EJ(a),$CLJS.wj)?$CLJS.wj:$CLJS.YE});