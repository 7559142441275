var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.tools.reader.js");require("./cljs.tools.reader.edn.js");
'use strict';var Uz,Vz,Zz;$CLJS.ij=function(a){this.Md=a;this.H=null;this.C=2153775104;this.J=2048};Uz=function(a){a=parseInt(a,10);return $CLJS.Bb(isNaN(a))?a:null};Vz=function(a,b,c,d){if(!(a<=b&&b<=c))throw Error([$CLJS.p.g(d)," Failed:  ",$CLJS.p.g(a),"\x3c\x3d",$CLJS.p.g(b),"\x3c\x3d",$CLJS.p.g(c)].join(""));return b};
Zz=function(a){var b=$CLJS.pi(Xz,a);$CLJS.G(b,0,null);var c=$CLJS.G(b,1,null),d=$CLJS.G(b,2,null),e=$CLJS.G(b,3,null),f=$CLJS.G(b,4,null),g=$CLJS.G(b,5,null),l=$CLJS.G(b,6,null),n=$CLJS.G(b,7,null),q=$CLJS.G(b,8,null),u=$CLJS.G(b,9,null),v=$CLJS.G(b,10,null);if($CLJS.Bb(b))throw Error(["Unrecognized date/time syntax: ",$CLJS.p.g(a)].join(""));a=Uz(c);b=function(){var J=Uz(d);return $CLJS.m(J)?J:1}();c=function(){var J=Uz(e);return $CLJS.m(J)?J:1}();var x=function(){var J=Uz(f);return $CLJS.m(J)?J:
0}(),y=function(){var J=Uz(g);return $CLJS.m(J)?J:0}(),C=function(){var J=Uz(l);return $CLJS.m(J)?J:0}(),H=function(){a:if($CLJS.E.h(3,$CLJS.D(n)))var J=n;else if(3<$CLJS.D(n))J=n.substring(0,3);else for(J=new $CLJS.nb(n);;)if(3>J.getLength())J=J.append("0");else{J=J.toString();break a}J=Uz(J);return $CLJS.m(J)?J:0}();q=($CLJS.E.h(q,"-")?-1:1)*(60*function(){var J=Uz(u);return $CLJS.m(J)?J:0}()+function(){var J=Uz(v);return $CLJS.m(J)?J:0}());return new $CLJS.Q(null,8,5,$CLJS.R,[a,Vz(1,b,12,"timestamp month field must be in range 1..12"),
Vz(1,c,Yz(b,0===(a%4+4)%4&&(0!==(a%100+100)%100||0===(a%400+400)%400)),"timestamp day field must be in range 1..last day in month"),Vz(0,x,23,"timestamp hour field must be in range 0..23"),Vz(0,y,59,"timestamp minute field must be in range 0..59"),Vz(0,C,$CLJS.E.h(y,59)?60:59,"timestamp second field must be in range 0..60"),Vz(0,H,999,"timestamp millisecond field must be in range 0..999"),q],null)};$CLJS.h=$CLJS.ij.prototype;$CLJS.h.Hg=$CLJS.t;$CLJS.h.toString=function(){return this.Md};
$CLJS.h.equiv=function(a){return this.V(null,a)};$CLJS.h.V=function(a,b){return(a=null!=b?$CLJS.t===b.Hg?!0:!1:!1)?this.Md===b.Md:a};$CLJS.h.Y=function(a,b){return $CLJS.Mc(b,['#uuid "',$CLJS.p.g(this.Md),'"'].join(""))};$CLJS.h.ga=function(){null==this.H&&(this.H=$CLJS.ld(this.Md));return this.H};$CLJS.h.Yb=function(a,b){if(b instanceof $CLJS.ij)return $CLJS.Ta(this.Md,b.Md);throw Error(["Cannot compare ",$CLJS.p.g(this)," to ",$CLJS.p.g(b)].join(""));};
$CLJS.iA=new $CLJS.M(null,"pattern","pattern",242135423);$CLJS.jA=new $CLJS.M(null,"replacement","replacement",-1836238839);var Yz,Xz;Yz=function(){var a=new $CLJS.Q(null,13,5,$CLJS.R,[null,31,28,31,30,31,30,31,31,30,31,30,31],null),b=new $CLJS.Q(null,13,5,$CLJS.R,[null,31,29,31,30,31,30,31,31,30,31,30,31],null);return function(c,d){return $CLJS.K.h($CLJS.m(d)?b:a,c)}}();Xz=/(\d\d\d\d)(?:-(\d\d)(?:-(\d\d)(?:[T](\d\d)(?::(\d\d)(?::(\d\d)(?:[.](\d+))?)?)?)?)?)?(?:[Z]|([-+])(\d\d):(\d\d))?/;
$CLJS.kA=$CLJS.ka($CLJS.xm.l($CLJS.F([new $CLJS.k(null,4,[new $CLJS.w(null,"inst","inst",-2008473268,null),function(a){if("string"===typeof a){var b=Zz(a);if($CLJS.m(b)){a=$CLJS.G(b,0,null);var c=$CLJS.G(b,1,null),d=$CLJS.G(b,2,null),e=$CLJS.G(b,3,null),f=$CLJS.G(b,4,null),g=$CLJS.G(b,5,null),l=$CLJS.G(b,6,null);b=$CLJS.G(b,7,null);b=new Date(Date.UTC(a,c-1,d,e,f,g,l)-6E4*b)}else throw Error(["Unrecognized date/time syntax: ",$CLJS.p.g(a)].join(""));return b}throw Error("Instance literal expects a string for its timestamp.");
},$CLJS.Nj,function(a){if("string"===typeof a)return new $CLJS.ij(a.toLowerCase());throw Error("UUID literal expects a string as its representation.");},new $CLJS.w(null,"queue","queue",-1198599890,null),function(a){if($CLJS.le(a))return $CLJS.mh.h($CLJS.qk,a);throw Error("Queue literal expects a vector for its elements.");},new $CLJS.w(null,"js","js",-886355190,null),function(a){if($CLJS.le(a)){var b=[];a=$CLJS.z(a);for(var c=null,d=0,e=0;;)if(e<d){var f=c.ha(null,e);b.push(f);e+=1}else if(a=$CLJS.z(a))c=
a,$CLJS.me(c)?(a=$CLJS.Wc(c),e=$CLJS.Xc(c),c=a,d=$CLJS.D(a),a=e):(a=$CLJS.A(c),b.push(a),a=$CLJS.B(c),c=null,d=0),e=0;else break;return b}if($CLJS.je(a)){b={};a=$CLJS.z(a);c=null;for(e=d=0;;)if(e<d){var g=c.ha(null,e);f=$CLJS.G(g,0,null);g=$CLJS.G(g,1,null);f=$CLJS.fi(f);b[f]=g;e+=1}else if(a=$CLJS.z(a))$CLJS.me(a)?(d=$CLJS.Wc(a),a=$CLJS.Xc(a),c=d,d=$CLJS.D(d)):(d=$CLJS.A(a),c=$CLJS.G(d,0,null),d=$CLJS.G(d,1,null),c=$CLJS.fi(c),b[c]=d,a=$CLJS.B(a),c=null,d=0),e=0;else break;return b}throw Error("JS literal expects a vector or map containing only string or unqualified keyword keys");
}],null),$CLJS.O])));