var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var W3,zta,RX,$X,aY,bY,EX,X3,V3,Y3,Ata,Bta,Cta,wia,a4,b4,d4,e4,f4,g4,h4,i4,U3,Eta,Fta,l4,Gta,m4,Hta,o4,Ita,Jta,Kta,Lta,Mta,VF;$CLJS.WF=function(a,b){if("string"===typeof b)return VF(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.QX=function(a,b,c){var d=$CLJS.Ge(a,b);if($CLJS.m(d)){var e=$CLJS.T.j;d=$CLJS.rc(d);c=c.g?c.g(d):c.call(null,d);a=e.call($CLJS.T,a,b,c)}return a};
W3=function(a){var b=V3;return $CLJS.vE(function(c){return function(d,e,f){e=b.g?b.g(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};zta=function(a){var b=U3;return $CLJS.vE(function(c){return function(d,e,f){f=b.g?b.g(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};RX=function(a){if($CLJS.$d(a))return a;throw $CLJS.nj("Invalid pattern: don't know how to handle symbol.",new $CLJS.k(null,1,[$CLJS.Yj,a],null));};
$CLJS.UX=function(a,b,c){return $CLJS.je(c)?$CLJS.mh.h(c,function(){return function f(e){return new $CLJS.uf(null,function(){for(;;){var g=$CLJS.z(e);if(g){if($CLJS.me(g)){var l=$CLJS.Wc(g),n=$CLJS.D(l),q=$CLJS.xf(n);return function(){for(var y=0;;)if(y<n){var C=$CLJS.Yd(l,y),H=$CLJS.G(C,0,null),J=$CLJS.G(C,1,null);C=q;var S=$CLJS.R,V=H;H=$CLJS.gf.h(b,H);H=a.h?a.h(H,J):a.call(null,H,J);C.add(new $CLJS.Q(null,2,5,S,[V,H],null));y+=1}else return!0}()?$CLJS.Af($CLJS.Ef(q),f($CLJS.Xc(g))):$CLJS.Af($CLJS.Ef(q),
null)}var u=$CLJS.A(g),v=$CLJS.G(u,0,null),x=$CLJS.G(u,1,null);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[v,function(){var y=$CLJS.gf.h(b,v),C=x;return a.h?a.h(y,C):a.call(null,y,C)}()],null),f($CLJS.vd(g)))}return null}},null,null)}(c)}()):$CLJS.ie(c)?$CLJS.mm.h($CLJS.bg(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.gf.h(b,$CLJS.A(c)):b),c):c};$X=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.mf(a):b;return $CLJS.m(b)?[$CLJS.mf(a),"/",$CLJS.fi(a)].join(""):$CLJS.fi(a)};
aY=function(a,b){var c=$CLJS.ie(b)&&!$CLJS.Jg(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.fe(a)?(b=$CLJS.A(b),a=$CLJS.di(a),a.g?a.g(b):a.call(null,b)):$CLJS.E.h(a,$CLJS.A(b)):c};
bY=function(a,b){return $CLJS.tg(function(c){var d=$CLJS.vg;a:try{if($CLJS.le(c)&&1<=$CLJS.D(c))try{var e=$CLJS.tm.j(c,0,1);if($CLJS.le(e)&&1===$CLJS.D(e))try{var f=$CLJS.Pd(e,0);if($CLJS.m($CLJS.bg($CLJS.E,a)(f))){var g=$CLJS.tm.h(c,1);var l=new $CLJS.Q(null,1,5,$CLJS.R,[g],null)}else throw $CLJS.Z;}catch(q){if(q instanceof Error){var n=q;if(n===$CLJS.Z)throw $CLJS.Z;throw n;}throw q;}else throw $CLJS.Z;}catch(q){if(q instanceof Error){n=q;if(n===$CLJS.Z)throw $CLJS.Z;throw n;}throw q;}else throw $CLJS.Z;
}catch(q){if(q instanceof Error){n=q;if(n===$CLJS.Z){l=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[c],null)],null);break a}throw n;}throw q;}return $CLJS.A($CLJS.z(d($CLJS.Eb,l)))},$CLJS.F([b]))};
EX=function(a,b){for(;;)switch(b=$CLJS.ym.g($CLJS.vg($CLJS.Eb,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),cY.g?cY.g(a):cY.call(null,a);default:if($CLJS.m($CLJS.Vf($CLJS.bg(aY,a),b))){var c=a;b=bY(a,b);a=c}else{c=$CLJS.kg.h(cY,b);if($CLJS.E.h(c,b))return $CLJS.mh.h(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),b);b=c}}};X3=function(a){var b=$CLJS.ie(a);return b?(b=!$CLJS.Jg(a))?$CLJS.OA($CLJS.lf,$CLJS.Gb)($CLJS.A(a)):b:b};
V3=function(a){return $CLJS.m($CLJS.OA($CLJS.lf,$CLJS.Gb)(a))?$CLJS.zi.g($CLJS.Ys($X(a).toLowerCase(),/_/,"-")):a};Y3=function(a,b){var c=X3(b);return $CLJS.m(c)?(b=V3($CLJS.A(b)),$CLJS.fe(a)?(a=$CLJS.di(a),a.g?a.g(b):a.call(null,b)):$CLJS.E.h(a,b)):c};Ata=function(a){return $CLJS.K.j(new $CLJS.k(null,3,[$CLJS.Dj,$CLJS.Ve,$CLJS.Ik,V3,$CLJS.qO,V3],null),a,function(b){var c=$CLJS.K,d=c.h;b=$CLJS.Nf([a,b]);b=Z3.h?Z3.h(b,FX):Z3.call(null,b,FX);return d.call(c,b,a)})};
Bta=function(a){a=$CLJS.mh.j($CLJS.O,$CLJS.kg.g(function(b){var c=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);c=V3(c);var d=Ata(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,d.g?d.g(b):d.call(null,b)],null)}),a);return $CLJS.E.h($CLJS.Ik.g(a),$CLJS.JM)&&$CLJS.Bb($CLJS.qO.g(a))?$CLJS.T.j(a,$CLJS.qO,$CLJS.IH):a};Cta=function(a){return $CLJS.mh.j($CLJS.O,$CLJS.kg.g(function(b){var c=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);c=$X(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.T.j(Bta(b),$CLJS.W,c)],null)}),a)};
wia=function(a){a=Z3.g?Z3.g(a):Z3.call(null,a);return U3.g?U3.g(a):U3.call(null,a)};a4=function(a){return $CLJS.QX($CLJS.QX($CLJS.Ob(function(b,c){return $CLJS.QX(b,c,$CLJS.zi)},a,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.LD,$CLJS.pba,$CLJS.dP,xia,$CLJS.yz,$CLJS.kb],null)),$CLJS.XC,wia),$CLJS.sO,$CLJS.TX)};b4=function(a){return $CLJS.ve(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,a,null],null):a};d4=function(a){return c4.g(b4(a))};
e4=function(a){var b=$CLJS.z(a);a=$CLJS.A(b);b=$CLJS.B(b);return cY($CLJS.mh.h(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.kg.h(c4,b)))};f4=function(a){var b=$CLJS.z(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[a,d4(b)],null),$CLJS.kg.h(c4,c))};g4=function(a){var b=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return $CLJS.m(a)?new $CLJS.Q(null,2,5,$CLJS.R,[b,d4(a)],null):new $CLJS.Q(null,1,5,$CLJS.R,[b],null)};
h4=function(a){var b=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,d4(a)],null)};i4=function(a){var b=$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,c4.g(a)],null)};
U3=function(a){return j4(function(b){if($CLJS.je(b))return zta(b);if($CLJS.Bb(X3(b)))return b;try{return c4.g(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.uE($CLJS.tx);if($CLJS.m($CLJS.yE("metabase.mbql.normalize",d))){var e=$CLJS.$F("Invalid clause:");e instanceof Error?$CLJS.zE("metabase.mbql.normalize",d,$CLJS.VA.l($CLJS.F([b])),e):$CLJS.zE("metabase.mbql.normalize",d,$CLJS.VA.l($CLJS.F([e,b])),null)}throw $CLJS.mj($CLJS.ZF("Invalid MBQL clause: {0}",$CLJS.F([$CLJS.MX(c)])),new $CLJS.k(null,
1,[$CLJS.NV,b],null),c);}throw f;}},a)};Eta=function(a){return $CLJS.Ng($CLJS.eg(U3,k4(a)))};Fta=function(a){for(;;)if($CLJS.m(X3(a)))a=new $CLJS.Q(null,1,5,$CLJS.R,[a],null);else return $CLJS.Pf($CLJS.mm.h(b4,a))};l4=function(a){a=$CLJS.Of(a);var b=$CLJS.K.h(a,$CLJS.JM);return $CLJS.m(b)?$CLJS.nm.j(a,$CLJS.JM,c4):a};
Gta=function(a){return $CLJS.mh.h($CLJS.O,function(){return function d(c){return new $CLJS.uf(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.me(e)){var f=$CLJS.Wc(e),g=$CLJS.D(f),l=$CLJS.xf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.Yd(f,n),u=$CLJS.G(q,0,null);q=$CLJS.G(q,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,l4(q)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Af($CLJS.Ef(l),d($CLJS.Xc(e))):$CLJS.Af($CLJS.Ef(l),null)}f=$CLJS.A(e);l=$CLJS.G(f,0,null);f=$CLJS.G(f,1,null);return $CLJS.kf(new $CLJS.Q(null,
2,5,$CLJS.R,[l,l4(f)],null),d($CLJS.vd(e)))}return null}},null,null)}(a)}())};m4=function(a){a=$CLJS.Of(a);var b=$CLJS.K.h(a,$CLJS.aU);return $CLJS.m(b)?$CLJS.nm.j(a,$CLJS.aU,Gta):a};Hta=function(a){var b=$CLJS.Of(a);a=$CLJS.K.h(b,$CLJS.gT);b=$CLJS.Bb(a)?n4.g?n4.g(b):n4.call(null,b):b;return $CLJS.m(a)?m4(b):b};o4=function(a){return $CLJS.fe(a)?$CLJS.z(a):null!=a};
Ita=function(a){var b=$CLJS.Of(a);a=$CLJS.K.h(b,$CLJS.hN);var c=$CLJS.K.h(b,$CLJS.VN),d=$CLJS.K.h(b,$CLJS.rP),e=$CLJS.K.h(b,$CLJS.gT);try{if($CLJS.m(d)){var f=$CLJS.Of(b),g=$CLJS.K.h(f,$CLJS.rP);var l=$CLJS.bt($CLJS.$l.h(f,$CLJS.rP),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hN,$CLJS.rP],null),g)}else l=b;var n=$CLJS.m(a)?$CLJS.nm.j(l,$CLJS.hN,n4):l,q=$CLJS.m(c)?$CLJS.nm.j(n,$CLJS.VN,$CLJS.bg($CLJS.mm,U3)):n,u=$CLJS.m(e)?$CLJS.nm.j(q,$CLJS.gT,m4):q;return U3(u)}catch(v){if(v instanceof Error)throw n=v,$CLJS.mj($CLJS.ZF("Error canonicalizing query: {0}",
$CLJS.F([$CLJS.MX(n)])),new $CLJS.k(null,1,[$CLJS.hN,a],null),n);throw v;}};
Jta=function(a){a=$CLJS.Of(a);var b=$CLJS.K.h(a,$CLJS.hN),c=$CLJS.Of(b);b=$CLJS.K.h(c,$CLJS.cH);c=$CLJS.K.h(c,$CLJS.mG);return $CLJS.z(b)&&$CLJS.z(c)?(b=$CLJS.mh.j($CLJS.ai,$CLJS.Li,$CLJS.z($CLJS.vg($CLJS.Eb,function g(e,f){try{if($CLJS.le(f)&&3===$CLJS.D(f))try{var l=$CLJS.Pd(f,0);if($CLJS.N(l,$CLJS.oH)){var n=$CLJS.Pd(f,1),q=$CLJS.Pd(f,2);return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[f,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,n,$CLJS.$l.h(q,$CLJS.uH)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.sX(g,e,f);throw l;}throw u;}}($CLJS.Mg,b)))),$CLJS.nY.j(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hN,$CLJS.mG],null),$CLJS.gm.h($CLJS.Ng,$CLJS.bg($CLJS.lm,b)))):a};
Kta=function(a){try{return Jta(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.mj($CLJS.$F("Error performing whole query transformations"),new $CLJS.k(null,1,[$CLJS.hN,a],null),b);}throw c;}};
Lta=function(a,b){var c=$CLJS.mh.h($CLJS.Xd(a),function(){return function f(e){return new $CLJS.uf(null,function(){for(var g=e;;)if(g=$CLJS.z(g)){if($CLJS.me(g)){var l=$CLJS.Wc(g),n=$CLJS.D(l),q=$CLJS.xf(n);return function(){for(var y=0;;)if(y<n){var C=$CLJS.Yd(l,y),H=$CLJS.G(C,0,null);C=$CLJS.G(C,1,null);var J=$CLJS.gf.h(b,H);C=p4.h?p4.h(C,J):p4.call(null,C,J);null!=C&&q.add(new $CLJS.Q(null,2,5,$CLJS.R,[H,C],null));y+=1}else return!0}()?$CLJS.Af($CLJS.Ef(q),f($CLJS.Xc(g))):$CLJS.Af($CLJS.Ef(q),
null)}var u=$CLJS.A(g),v=$CLJS.G(u,0,null),x=$CLJS.G(u,1,null);u=function(){var y=x,C=$CLJS.gf.h(b,v);return p4.h?p4.h(y,C):p4.call(null,y,C)}();if(null!=u)return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[v,u],null),f($CLJS.vd(g)));g=$CLJS.vd(g)}else return null},null,null)}(a)}());return $CLJS.z(c)?c:null};Mta=function(a,b){a=$CLJS.mm.h(function(c){var d=$CLJS.gf.h(b,GX);return p4.h?p4.h(c,d):p4.call(null,c,d)},a);return $CLJS.m($CLJS.Vf($CLJS.Eb,a))?a:null};
VF=function VF(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Ng(d);return $CLJS.kf(f,new $CLJS.uf(null,function(){var g=e.length;g=d.index+(1>g?1:g);return g<=b.length?(g=b.substring(g),VF.h?VF.h(a,g):VF.call(null,a,g)):null},null,null))};
$CLJS.nY=function nY(a){switch(arguments.length){case 3:return nY.j(arguments[0],arguments[1],arguments[2]);case 4:return nY.B(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return nY.O(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return nY.aa(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return nY.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.rd(c.slice(6),0,null))}};$CLJS.nY.j=function(a,b,c){var d=$CLJS.z(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.T.j(a,b,$CLJS.nY.j($CLJS.K.h(a,b),d,c));else{d=$CLJS.T.j;var e=$CLJS.K.h(a,b);c=c.g?c.g(e):c.call(null,e);a=d.call($CLJS.T,a,b,c)}return a};
$CLJS.nY.B=function(a,b,c,d){var e=$CLJS.z(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.T.j(a,b,$CLJS.nY.B($CLJS.K.h(a,b),e,c,d));else{e=$CLJS.T.j;var f=$CLJS.K.h(a,b);c=c.h?c.h(f,d):c.call(null,f,d);a=e.call($CLJS.T,a,b,c)}return a};$CLJS.nY.O=function(a,b,c,d,e){var f=$CLJS.z(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.T.j(a,b,$CLJS.nY.O($CLJS.K.h(a,b),f,c,d,e));else{f=$CLJS.T.j;var g=$CLJS.K.h(a,b);c=c.j?c.j(g,d,e):c.call(null,g,d,e);a=f.call($CLJS.T,a,b,c)}return a};
$CLJS.nY.aa=function(a,b,c,d,e,f){var g=$CLJS.z(b);b=$CLJS.A(g);if(g=$CLJS.B(g))a=$CLJS.T.j(a,b,$CLJS.nY.aa($CLJS.K.h(a,b),g,c,d,e,f));else{g=$CLJS.T.j;var l=$CLJS.K.h(a,b);c=c.B?c.B(l,d,e,f):c.call(null,l,d,e,f);a=g.call($CLJS.T,a,b,c)}return a};$CLJS.nY.l=function(a,b,c,d,e,f,g){var l=$CLJS.z(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.T.j(a,b,$CLJS.P.l($CLJS.nY,$CLJS.K.h(a,b),l,c,d,$CLJS.F([e,f,g]))):$CLJS.T.j(a,b,$CLJS.P.l(c,$CLJS.K.h(a,b),d,e,f,$CLJS.F([g])))};
$CLJS.nY.D=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var g=$CLJS.B(f);f=$CLJS.A(g);g=$CLJS.B(g);return this.l(b,a,c,d,e,f,g)};$CLJS.nY.A=6;
var j4=function j4(a,b){return $CLJS.Es($CLJS.bg(j4,a),$CLJS.Ve,a.g?a.g(b):a.call(null,b))},cY=function cY(a){for(;;){if($CLJS.je(a))return $CLJS.Us(a,cY);if($CLJS.re(a))a=$CLJS.Ng(a);else if($CLJS.le(a)){if($CLJS.Bb($CLJS.Vf($CLJS.Eb,a)))return null;var c=a,d=$CLJS.z(c),e=$CLJS.A(d),f=$CLJS.B(d),g=e,l=f,n=$CLJS.G(l,0,null),q=l,u=g,v=u instanceof $CLJS.M?u.R:null;switch(v){case "not":if($CLJS.se(n)){var x=$CLJS.A(n),y=x instanceof $CLJS.M?x.R:null;switch(y){case "not":a=$CLJS.Vd(n);continue;case "and":return EX($CLJS.os,
$CLJS.kg.h(function(){return function(C){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qs,C],null)}}(a,x,y,u,v,c,d,e,f,g,l,n,q),$CLJS.vd(n)));case "or":return EX($CLJS.vs,$CLJS.kg.h(function(){return function(C){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qs,C],null)}}(a,x,y,u,v,c,d,e,f,g,l,n,q),$CLJS.vd(n)));default:return a}}else return a;case "and":return EX($CLJS.vs,q);case "or":return EX($CLJS.os,q);default:return $CLJS.mm.h(cY,a)}}else return a}},yia=new $CLJS.M(null,"value_field","value_field",
-980977878),HX=new $CLJS.M(null,"ascending","ascending",-988350486),IX=new $CLJS.M(null,"named","named",-422393479),JX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),KX=new $CLJS.M(null,"descending","descending",-24766135),NX=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158),SX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),zia=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),VX=new $CLJS.M(null,"field-literal","field-literal",
-1295883554),WX=new $CLJS.M(null,"viz-settings","viz-settings",256055379),Aia=new $CLJS.M(null,"rows","rows",850049680),Bia=new $CLJS.M(null,"special-fn","special-fn",1290649344),GX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),FX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Cia=new $CLJS.M(null,"label_field","label_field",-1573182765),Dia=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Eia=new $CLJS.M(null,"joined-field","joined-field",
-2048778268),xia=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var z4,Fia=$CLJS.ka($CLJS.O),Gia=$CLJS.ka($CLJS.O),Hia=$CLJS.ka($CLJS.O),Iia=$CLJS.ka($CLJS.O),Jia=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));z4=new $CLJS.hj($CLJS.Fi.h("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.gm.h(V3,$CLJS.A),Jia,Fia,Gia,Hia,Iia);z4.o(null,$CLJS.dH,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);b=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dH,b],null);return null!=a?$CLJS.gf.h(b,a):b});
z4.o(null,$CLJS.GB,function(a){$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GB,a instanceof $CLJS.M?$X(a):a],null)});z4.o(null,NX,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null),c=$CLJS.G(a,2,null);a=$CLJS.G(a,3,null);return $CLJS.m(a)?$CLJS.gf.h(z4.g(new $CLJS.Q(null,3,5,$CLJS.R,[NX,b,c],null)),a):new $CLJS.Q(null,3,5,$CLJS.R,[NX,Z3.h?Z3.h(b,FX):Z3.call(null,b,FX),V3(c)],null)});
z4.o(null,$CLJS.oH,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);var c=Z3.h?Z3.h(a,FX):Z3.call(null,a,FX);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,b,function(){var d=$CLJS.m($CLJS.JB.g(c))?$CLJS.nm.j(c,$CLJS.JB,$CLJS.zi):c;d=$CLJS.m($CLJS.uH.g(c))?$CLJS.nm.j(d,$CLJS.uH,$CLJS.zi):d;return $CLJS.m($CLJS.bV.g(c))?$CLJS.nm.j(d,$CLJS.bV,function(e){return $CLJS.m($CLJS.iG.g(e))?$CLJS.nm.j(e,$CLJS.iG,$CLJS.zi):e}):d}()],null)});
z4.o(null,VX,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[VX,b instanceof $CLJS.M?$X(b):b,$CLJS.zi.g(a)],null)});z4.o(null,SX,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null),c=$CLJS.G(a,2,null);a=$CLJS.G(a,3,null);return $CLJS.m(a)?new $CLJS.Q(null,4,5,$CLJS.R,[SX,Z3.h?Z3.h(b,FX):Z3.call(null,b,FX),$CLJS.Ct,V3(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[SX,Z3.h?Z3.h(b,FX):Z3.call(null,b,FX),V3(c)],null)});
z4.o(null,$CLJS.AH,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null),c=$CLJS.G(a,2,null),d=$CLJS.G(a,3,null);a=$CLJS.G(a,4,null);return $CLJS.m(a)?$CLJS.gf.h(z4.g(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.AH,b,c,d],null)),Z3.h?Z3.h(a,FX):Z3.call(null,a,FX)):new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.AH,Z3.h?Z3.h(b,FX):Z3.call(null,b,FX),$CLJS.ve(c)?c:V3(c),V3(d)],null)});
z4.o(null,$CLJS.IJ,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return $CLJS.m(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IJ,b,V3(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IJ,$CLJS.hv],null)});z4.o(null,$CLJS.BJ,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BJ,b,V3(a)],null)});
z4.o(null,$CLJS.QJ,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null),c=$CLJS.G(a,2,null);a=$CLJS.G(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.QJ,Z3.h?Z3.h(b,FX):Z3.call(null,b,FX),c,V3(a)],null)});z4.o(null,$CLJS.YJ,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null),c=$CLJS.G(a,2,null);a=$CLJS.G(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.YJ,Z3.h?Z3.h(b,FX):Z3.call(null,b,FX),c,V3(a)],null)});
z4.o(null,$CLJS.OJ,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return $CLJS.m(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OJ,Z3.h?Z3.h(b,FX):Z3.call(null,b,FX),V3(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OJ,Z3.h?Z3.h(b,FX):Z3.call(null,b,FX)],null)});
z4.o(null,$CLJS.UJ,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null),c=$CLJS.G(a,2,null);a=$CLJS.G(a,3,null);return $CLJS.m(a)?new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.UJ,Z3.h?Z3.h(b,FX):Z3.call(null,b,FX),V3(c),V3(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UJ,Z3.h?Z3.h(b,FX):Z3.call(null,b,FX),V3(c)],null)});
z4.o(null,$CLJS.AJ,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null),c=$CLJS.G(a,2,null);a=$CLJS.G(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.AJ,Z3.h?Z3.h(b,FX):Z3.call(null,b,FX),Z3.h?Z3.h(c,FX):Z3.call(null,c,FX),V3(a)],null)});z4.o(null,$CLJS.Yk,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yk,b,a],null)});
z4.o(null,$CLJS.Dj,function(a){var b=$CLJS.z(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.mh.j(new $CLJS.Q(null,1,5,$CLJS.R,[V3(a)],null),$CLJS.kg.g(function(c){return Z3.h?Z3.h(c,FX):Z3.call(null,c,FX)}),b)});
var A4=function A4(a){if($CLJS.m($CLJS.OA($CLJS.lf,$CLJS.Gb)(a))){var c=V3(a);var d=new $CLJS.U(null,new $CLJS.k(null,18,[$CLJS.bk,null,$CLJS.cs,null,$CLJS.EH,null,$CLJS.xH,null,$CLJS.Ft,null,$CLJS.pH,null,$CLJS.JH,null,$CLJS.KH,null,$CLJS.TG,null,$CLJS.$G,null,$CLJS.bs,null,$CLJS.UG,null,$CLJS.CH,null,$CLJS.vH,null,$CLJS.xl,null,$CLJS.Aw,null,$CLJS.iH,null,$CLJS.BH,null],null),null);c=d.g?d.g(c):d.call(null,c)}else c=null;return $CLJS.m(c)?c:$CLJS.m(X3(a))?(a=$CLJS.A(a),A4.g?A4.g(a):A4.call(null,
a)):null},$ta=new $CLJS.k(null,8,[$CLJS.Ik,V3,$CLJS.gT,function(a){a=W3(a);return $CLJS.z($CLJS.aU.g(a))?$CLJS.nm.j(a,$CLJS.aU,Cta):a},$CLJS.hN,new $CLJS.k(null,6,[$CLJS.dH,function B4(a){if($CLJS.m($CLJS.OA($CLJS.lf,$CLJS.Gb)(a)))return V3(a);if($CLJS.m(Y3(IX,a))){a=$CLJS.z(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[IX,B4.g?B4.g(a):B4.call(null,a)],null),c)}return $CLJS.m($CLJS.m(X3(a))?A4($CLJS.Vd(a)):null)?$CLJS.mm.h(B4,a):Z3.h?Z3.h(a,
FX):Z3.call(null,a,FX)},$CLJS.QR,function(a){return $CLJS.mh.h($CLJS.O,function(){return function d(c){return new $CLJS.uf(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.me(e)){var f=$CLJS.Wc(e),g=$CLJS.D(f),l=$CLJS.xf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.Yd(f,n),u=$CLJS.G(q,0,null);q=$CLJS.G(q,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[$X(u),Z3.h?Z3.h(q,FX):Z3.call(null,q,FX)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Af($CLJS.Ef(l),d($CLJS.Xc(e))):$CLJS.Af($CLJS.Ef(l),null)}f=
$CLJS.A(e);l=$CLJS.G(f,0,null);f=$CLJS.G(f,1,null);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[$X(l),Z3.h?Z3.h(f,FX):Z3.call(null,f,FX)],null),d($CLJS.vd(e)))}return null}},null,null)}(a)}())},$CLJS.xV,function(a){return $CLJS.Ng(function(){return function d(c){return new $CLJS.uf(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.me(e)){var f=$CLJS.Wc(e),g=$CLJS.D(f),l=$CLJS.xf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.Yd(f,n);q=$CLJS.m(X3(q))?z4.g(q):$CLJS.hf(z4.g($CLJS.hf(q)));l.add(q);n+=
1}else{f=!0;break a}return f?$CLJS.Af($CLJS.Ef(l),d($CLJS.Xc(e))):$CLJS.Af($CLJS.Ef(l),null)}l=$CLJS.A(e);return $CLJS.kf($CLJS.m(X3(l))?z4.g(l):$CLJS.hf(z4.g($CLJS.hf(l))),d($CLJS.vd(e)))}return null}},null,null)}(a)}())},$CLJS.hU,function(a){a=W3(a);a=$CLJS.Of(a);var b=$CLJS.K.h(a,$CLJS.gT);if($CLJS.m(b))return a=$CLJS.SL(a,new $CLJS.k(null,1,[$CLJS.gT,$CLJS.hN],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.gT],null),a=Z3.h?Z3.h(a,b):Z3.call(null,a,b),$CLJS.SL(a,new $CLJS.k(null,1,[$CLJS.hN,$CLJS.gT],
null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.hN],null);return Z3.h?Z3.h(a,b):Z3.call(null,a,b)},$CLJS.rP,new $CLJS.k(null,1,[GX,a4],null),$CLJS.dO,new $CLJS.k(null,1,[GX,function(a){a=Z3.h?Z3.h(a,$CLJS.hN):Z3.call(null,a,$CLJS.hN);var b=$CLJS.Of(a),c=$CLJS.K.h(b,$CLJS.iG),d=$CLJS.K.h(b,$CLJS.mG);a=$CLJS.K.h(b,$CLJS.qG);b=$CLJS.m(c)?$CLJS.nm.j(b,$CLJS.iG,V3):b;d=$CLJS.m($CLJS.OA($CLJS.lf,$CLJS.Gb)(d))?$CLJS.nm.j(b,$CLJS.mG,V3):b;return $CLJS.m(a)?$CLJS.nm.j(d,$CLJS.qG,$X):d}],null)],null),$CLJS.zx,
new $CLJS.k(null,1,[$CLJS.PO,$CLJS.Ve],null),$CLJS.VN,new $CLJS.k(null,1,[GX,function(a){var b=$CLJS.Of(a),c=$CLJS.K.h(b,$CLJS.Ik),d=$CLJS.K.h(b,$CLJS.cK),e=$CLJS.K.h(b,$CLJS.rk);a=$CLJS.K.h(b,JX);b=$CLJS.m(e)?$CLJS.nm.j(b,$CLJS.rk,$X):b;c=$CLJS.m(c)?$CLJS.nm.j(b,$CLJS.Ik,V3):b;d=$CLJS.m(d)?$CLJS.nm.j(c,$CLJS.cK,function(f){return Z3.h?Z3.h(f,FX):Z3.call(null,f,FX)}):c;d=$CLJS.m(a)?$CLJS.nY.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[JX,Cia],null),function(f){return Z3.h?Z3.h(f,FX):Z3.call(null,f,FX)}):d;return $CLJS.m(a)?
$CLJS.nY.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[JX,yia],null),function(f){return Z3.h?Z3.h(f,FX):Z3.call(null,f,FX)}):d}],null),$CLJS.QN,function(a){return null==a?null:V3(a)},$CLJS.rP,new $CLJS.k(null,1,[GX,a4],null),WX,V3],null),Z3=function Z3(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Z3.l(arguments[0],1<c.length?new $CLJS.rd(c.slice(1),0,null):null)};
Z3.l=function(a,b){b=$CLJS.G(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.Q(null,1,5,$CLJS.R,[b],null):$CLJS.Ng(b);b=$CLJS.z(c)?$CLJS.Ss($ta,c):null;try{return $CLJS.$d(b)?b.g?b.g(a):b.call(null,a):$CLJS.ke(a)?a:$CLJS.je(a)?$CLJS.mh.h($CLJS.O,function(){return function g(f){return new $CLJS.uf(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.me(l)){var n=$CLJS.Wc(l),q=$CLJS.D(n),u=$CLJS.xf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.Yd(n,v),y=$CLJS.G(x,0,null);x=$CLJS.G(x,1,null);y=V3(y);y=new $CLJS.Q(null,
2,5,$CLJS.R,[y,Z3.l(x,$CLJS.F([$CLJS.gf.h($CLJS.Ng(c),y)]))],null);u.add(y);v+=1}else{n=!0;break a}return n?$CLJS.Af($CLJS.Ef(u),g($CLJS.Xc(l))):$CLJS.Af($CLJS.Ef(u),null)}n=$CLJS.A(l);u=$CLJS.G(n,0,null);n=$CLJS.G(n,1,null);u=V3(u);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[u,Z3.l(n,$CLJS.F([$CLJS.gf.h($CLJS.Ng(c),u)]))],null),g($CLJS.vd(l)))}return null}},null,null)}(a)}()):$CLJS.m(X3(a))?z4.g(a):$CLJS.ie(a)?$CLJS.mm.h(function(e){return Z3.l(e,$CLJS.F([$CLJS.gf.h($CLJS.Ng(c),GX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.mj($CLJS.ZF("Error normalizing form: {0}",$CLJS.F([$CLJS.MX(d)])),new $CLJS.k(null,3,[$CLJS.vz,a,$CLJS.yl,c,Bia,b],null),d);}throw e;}};Z3.A=1;Z3.D=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var c4=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.m(X3(f))?$CLJS.A(f):null},e,a,b,c,d)}();c4.o(null,$CLJS.Dj,function(a){return a});
c4.o(null,$CLJS.oH,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);if($CLJS.m(Y3($CLJS.oH,b))){$CLJS.G(b,0,null);var c=$CLJS.G(b,1,null);b=$CLJS.G(b,2,null);return c4.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,c,$CLJS.Pf($CLJS.xm.l($CLJS.F([b,a])))],null))}return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,b,$CLJS.Pf(a)],null)});
c4.o(null,$CLJS.dH,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return $CLJS.ee(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dH,b],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dH,b,a],null)});c4.o(null,$CLJS.EL,function(a){$CLJS.G(a,0,null);a=$CLJS.G(a,1,null);return $CLJS.m(X3(a))?c4.g(a):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,a,null],null)});
c4.o(null,VX,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,b,new $CLJS.k(null,1,[$CLJS.JB,a],null)],null)});c4.o(null,Dia,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);var c=d4(b);$CLJS.G(c,0,null);b=$CLJS.G(c,1,null);$CLJS.G(c,2,null);c=d4(a);$CLJS.G(c,0,null);a=$CLJS.G(c,1,null);c=$CLJS.G(c,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,a,$CLJS.T.j(c,$CLJS.eO,b)],null)});
c4.o(null,Eia,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);a=d4(a);return $CLJS.P.B($CLJS.mY,a,$CLJS.T,$CLJS.F([$CLJS.UP,b]))});
c4.o(null,SX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.G(a,0,null);b=$CLJS.G(a,1,null);var c=$CLJS.G(a,2,null);a=d4(b);b=c;$CLJS.G(a,0,null);$CLJS.G(a,1,null);c=$CLJS.G(a,2,null);c=$CLJS.Of(c);var d=$CLJS.K.h(c,$CLJS.JB);$CLJS.Bb(d)||$CLJS.FQ.h(d,b)?a=$CLJS.P.B($CLJS.mY,a,$CLJS.T,$CLJS.F([$CLJS.uH,b])):(c=$CLJS.uE($CLJS.xx),$CLJS.m($CLJS.yE("metabase.mbql.util",c))&&(b=$CLJS.ZF("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.F([b,d,$CLJS.Oi.l($CLJS.F([a]))])),
b instanceof Error?$CLJS.zE("metabase.mbql.util",c,$CLJS.VA(),b):$CLJS.zE("metabase.mbql.util",c,$CLJS.VA.l($CLJS.F([b])),null)));return a;case 4:return $CLJS.G(a,0,null),b=$CLJS.G(a,1,null),$CLJS.G(a,2,null),c=$CLJS.G(a,3,null),c4.g(new $CLJS.Q(null,3,5,$CLJS.R,[SX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}});
c4.o(null,NX,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null),c=$CLJS.G(a,2,null),d=$CLJS.G(a,3,null);a=$CLJS.G(a,4,null);var e=d4(b);$CLJS.G(e,0,null);b=$CLJS.G(e,1,null);e=$CLJS.G(e,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,b,$CLJS.T.j(e,$CLJS.bV,$CLJS.xm.l($CLJS.F([new $CLJS.k(null,1,[$CLJS.iG,c],null),$CLJS.m(d)?$CLJS.Nf([c,d]):null,a])))],null)});
for(var XX=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,$CLJS.os,$CLJS.qs],null)),dY=null,eY=0,hY=0;;)if(hY<eY){var iY=dY.ha(null,hY);c4.o(null,iY,function(){return function(a){return e4(a)}}(XX,dY,eY,hY,iY));hY+=1}else{var kY=$CLJS.z(XX);if(kY){var lY=kY;if($CLJS.me(lY)){var oY=$CLJS.Wc(lY),Kia=$CLJS.Xc(lY),Lia=oY,Mia=$CLJS.D(oY);XX=Kia;dY=Lia;eY=Mia}else{var pY=$CLJS.A(lY);c4.o(null,pY,function(){return function(a){return e4(a)}}(XX,dY,eY,hY,pY,lY,kY));XX=$CLJS.B(lY);dY=null;eY=0}hY=0}else break}
c4.o(null,$CLJS.WG,function(a){a=$CLJS.z(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.mh.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WG,d4(a),d4(b)],null),c)});c4.o(null,$CLJS.AH,function(a){a=$CLJS.z(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=d4(a);a=$CLJS.m(aY($CLJS.oH,a))?$CLJS.mY.l(c,$CLJS.$l,$CLJS.F([$CLJS.uH])):c;return $CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AH,a],null),b)});
for(var qY=$CLJS.z(new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.fH,$CLJS.zH,$CLJS.nH,$CLJS.gH,$CLJS.Zr,$CLJS.GH,$CLJS.Vr,$CLJS.Wr,$CLJS.Tr,$CLJS.Ur,$CLJS.RG,$CLJS.YG,$CLJS.PG,$CLJS.aH,$CLJS.QG],null)),rY=null,sY=0,tY=0;;)if(tY<sY){var uY=rY.ha(null,tY);c4.o(null,uY,function(){return function(a){return f4(a)}}(qY,rY,sY,tY,uY));tY+=1}else{var vY=$CLJS.z(qY);if(vY){var wY=vY;if($CLJS.me(wY)){var xY=$CLJS.Wc(wY),Nia=$CLJS.Xc(wY),Oia=xY,Pia=$CLJS.D(xY);qY=Nia;rY=Oia;sY=Pia}else{var yY=$CLJS.A(wY);c4.o(null,yY,
function(){return function(a){return f4(a)}}(qY,rY,sY,tY,yY,wY,vY));qY=$CLJS.B(wY);rY=null;sY=0}tY=0}else break}c4.o(null,Aia,function(){return null});c4.o(null,$CLJS.UM,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UM,c4.g(b),a],null)});
c4.o(null,IX,function(a){a=$CLJS.z(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=c4.g;var e=$CLJS.R;b=c4.g(b);d=$CLJS.G(d,0,null);d=$CLJS.Of(d);a=!1===$CLJS.K.h(d,zia)?new $CLJS.k(null,1,[$CLJS.W,a],null):new $CLJS.k(null,1,[$CLJS.vF,a],null);return c.call(c4,new $CLJS.Q(null,3,5,e,[$CLJS.UM,b,a],null))});
for(var zY=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Aw,$CLJS.pH],null)),AY=null,BY=0,CY=0;;)if(CY<BY){var DY=AY.ha(null,CY);c4.o(null,DY,function(){return function(a){return g4(a)}}(zY,AY,BY,CY,DY));CY+=1}else{var EY=$CLJS.z(zY);if(EY){var FY=EY;if($CLJS.me(FY)){var GY=$CLJS.Wc(FY),Qia=$CLJS.Xc(FY),Ria=GY,Sia=$CLJS.D(GY);zY=Qia;AY=Ria;BY=Sia}else{var IY=$CLJS.A(FY);c4.o(null,IY,function(){return function(a){return g4(a)}}(zY,AY,BY,CY,IY,FY,EY));zY=$CLJS.B(FY);AY=null;BY=0}CY=0}else break}
for(var JY=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.iH,$CLJS.LH,$CLJS.TG,$CLJS.EH,$CLJS.BH,$CLJS.bk,$CLJS.xl,$CLJS.CH,$CLJS.UG],null)),KY=null,LY=0,MY=0;;)if(MY<LY){var NY=KY.ha(null,MY);c4.o(null,NY,function(){return function(a){return h4(a)}}(JY,KY,LY,MY,NY));MY+=1}else{var OY=$CLJS.z(JY);if(OY){var PY=OY;if($CLJS.me(PY)){var QY=$CLJS.Wc(PY),Tia=$CLJS.Xc(PY),Uia=QY,Via=$CLJS.D(QY);JY=Tia;KY=Uia;LY=Via}else{var YY=$CLJS.A(PY);c4.o(null,YY,function(){return function(a){return h4(a)}}(JY,KY,LY,
MY,YY,PY,OY));JY=$CLJS.B(PY);KY=null;LY=0}MY=0}else break}c4.o(null,$CLJS.$G,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$G,d4(b),a],null)});
for(var $Y=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vH,$CLJS.xH],null)),aZ=null,bZ=0,cZ=0;;)if(cZ<bZ){var dZ=aZ.ha(null,cZ);c4.o(null,dZ,function(){return function(a){return i4(a)}}($Y,aZ,bZ,cZ,dZ));cZ+=1}else{var eZ=$CLJS.z($Y);if(eZ){var fZ=eZ;if($CLJS.me(fZ)){var gZ=$CLJS.Wc(fZ),Wia=$CLJS.Xc(fZ),Xia=gZ,Yia=$CLJS.D(gZ);$Y=Wia;aZ=Xia;bZ=Yia}else{var hZ=$CLJS.A(fZ);c4.o(null,hZ,function(){return function(a){return i4(a)}}($Y,aZ,bZ,cZ,hZ,fZ,eZ));$Y=$CLJS.B(fZ);aZ=null;bZ=0}cZ=0}else break}
c4.o(null,$CLJS.JH,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JH,c4.g(b),c4.g(a)],null)});
c4.o(null,$CLJS.XG,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return $CLJS.m(a)?$CLJS.gf.h(c4.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XG,b],null)),Z3.l(a,$CLJS.F([FX]))):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XG,$CLJS.Ng(function(){return function e(d){return new $CLJS.uf(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.me(f)){var g=$CLJS.Wc(f),l=$CLJS.D(g),n=$CLJS.xf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.Yd(g,q),v=$CLJS.G(u,0,null);u=$CLJS.G(u,1,null);v=new $CLJS.Q(null,
2,5,$CLJS.R,[c4.g(v),c4.g(u)],null);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Af($CLJS.Ef(n),e($CLJS.Xc(f))):$CLJS.Af($CLJS.Ef(n),null)}g=$CLJS.A(f);n=$CLJS.G(g,0,null);g=$CLJS.G(g,1,null);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[c4.g(n),c4.g(g)],null),e($CLJS.vd(f)))}return null}},null,null)}(b)}())],null)});
c4.o(null,$CLJS.IG,function(a){a=$CLJS.z(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.mh.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IG,c4.g(a),$CLJS.E.h(0,b)?1:c4.g(b)],null),$CLJS.kg.h(c4,c))});
var k4=function k4(a){return function f(d,e){try{if($CLJS.m(function(){var x=RX($CLJS.re);return x.g?x.g(e):x.call(null,e)}()))return f(d,$CLJS.Ng(e));throw $CLJS.Z;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Z)try{if($CLJS.m(function(){var y=RX($CLJS.lf);return y.g?y.g(e):y.call(null,e)}()))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[e],null)],null);throw $CLJS.Z;}catch(y){if(y instanceof Error)if(d=y,d===$CLJS.Z)try{if($CLJS.le(e)&&2<=$CLJS.D(e))try{var g=$CLJS.tm.j(e,
0,2);if($CLJS.le(g)&&2===$CLJS.D(g))try{var l=$CLJS.Pd(g,0);if($CLJS.m($CLJS.CM($CLJS.lf,$CLJS.$f(new $CLJS.U(null,new $CLJS.k(null,5,[$CLJS.cs,null,$CLJS.Ft,null,$CLJS.KH,null,$CLJS.bs,null,IX,null],null),null)))(l)))try{var n=$CLJS.Pd(g,1);if($CLJS.m(A4(n)))return $CLJS.mh.j($CLJS.Mg,$CLJS.Mt(k4),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var q=C;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){q=C;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw C;
}else throw $CLJS.Z;}catch(C){if(C instanceof Error){q=C;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(q=C,q===$CLJS.Z)try{if($CLJS.le(e)&&1<=$CLJS.D(e))try{var u=$CLJS.tm.j(e,0,1);if($CLJS.le(u)&&1===$CLJS.D(u))try{if($CLJS.Pd(u,0)instanceof $CLJS.M)return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=
H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)return e;throw v;}throw H;}else throw q;else throw C;}else throw d;else throw y;}else throw d;else throw x;}}($CLJS.Mg,a)},u5=function u5(a){return function f(d,e){try{var g=RX($CLJS.re);var l=g.g?g.g(e):g.call(null,e);if($CLJS.m(l))return f(d,$CLJS.Ng(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.le(e)&&2===$CLJS.D(e))try{var n=$CLJS.Pd(e,1);
if($CLJS.N(n,$CLJS.lH)){var q=$CLJS.Pd(e,0);return f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lH,q],null))}throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{n=$CLJS.Pd(e,1);if($CLJS.N(n,$CLJS.eH))return q=$CLJS.Pd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eH,q],null));throw $CLJS.Z;}catch(J){if(J instanceof Error){var u=J;if(u===$CLJS.Z)try{n=$CLJS.Pd(e,1);if($CLJS.N(n,HX))return q=$CLJS.Pd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lH,q],null));throw $CLJS.Z;}catch(S){if(S instanceof
Error)if(l=S,l===$CLJS.Z)try{n=$CLJS.Pd(e,1);if($CLJS.N(n,KX))return q=$CLJS.Pd(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eH,q],null));throw $CLJS.Z;}catch(V){if(V instanceof Error)if(n=V,n===$CLJS.Z)try{var v=$CLJS.Pd(e,0);if($CLJS.N(v,HX))return q=$CLJS.Pd(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lH,q],null));throw $CLJS.Z;}catch(da){if(da instanceof Error)if(n=da,n===$CLJS.Z)try{v=$CLJS.Pd(e,0);if($CLJS.N(v,KX))return q=$CLJS.Pd(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eH,q],null));
throw $CLJS.Z;}catch(fa){if(fa instanceof Error)if(n=fa,n===$CLJS.Z)try{v=$CLJS.Pd(e,0);if($CLJS.N(v,$CLJS.lH))return q=$CLJS.Pd(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.lH,d4(q)],null);throw $CLJS.Z;}catch(ma){if(ma instanceof Error)if(n=ma,n===$CLJS.Z)try{v=$CLJS.Pd(e,0);if($CLJS.N(v,$CLJS.eH))return q=$CLJS.Pd(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eH,d4(q)],null);throw $CLJS.Z;}catch(Fa){if(Fa instanceof Error){q=Fa;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw Fa;}else throw n;else throw ma;
}else throw n;else throw fa;}else throw n;else throw da;}else throw n;else throw V;}else throw l;else throw S;}else throw u;}else throw J;}else throw l;else throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{if($CLJS.le(e)&&0<=$CLJS.D(e))try{var x=$CLJS.tm.j(e,0,0);if($CLJS.le(x)&&0===$CLJS.D(x))try{var y=$CLJS.tm.h(e,0);if($CLJS.E.h(y,a))return $CLJS.Ng($CLJS.ym.g($CLJS.kg.h(u5,a)));throw $CLJS.Z;}catch(J){if(J instanceof Error){u=J;if(u===$CLJS.Z)throw $CLJS.Z;throw u;
}throw J;}else throw $CLJS.Z;}catch(J){if(J instanceof Error){u=J;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw J;}else throw $CLJS.Z;}catch(J){if(J instanceof Error){u=J;if(u===$CLJS.Z)return $CLJS.UX(f,d,e);throw u;}throw J;}else throw l;else throw H;}else throw l;else throw C;}}($CLJS.Mg,a)},n4=$CLJS.gm.h(U3,function(a){var b=o4($CLJS.dH.g(a))?$CLJS.nm.j(a,$CLJS.dH,Eta):a;b=o4($CLJS.cH.g(a))?$CLJS.nm.j(b,$CLJS.cH,Fta):b;b=o4($CLJS.mG.g(a))?$CLJS.nm.j(b,$CLJS.mG,$CLJS.bg($CLJS.mm,b4)):b;b=o4($CLJS.xV.g(a))?
$CLJS.nm.j(b,$CLJS.xV,u5):b;return o4($CLJS.hU.g(a))?$CLJS.nm.j(b,$CLJS.hU,Hta):b}),pua=new $CLJS.k(null,3,[$CLJS.gT,$CLJS.Ve,$CLJS.hN,new $CLJS.k(null,2,[$CLJS.hU,function(a){a=$CLJS.Of(a);var b=$CLJS.K.h(a,$CLJS.gT);if($CLJS.m(b))return a=$CLJS.SL(a,new $CLJS.k(null,1,[$CLJS.gT,$CLJS.hN],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.gT],null),a=p4.h?p4.h(a,b):p4.call(null,a,b),$CLJS.SL(a,new $CLJS.k(null,1,[$CLJS.hN,$CLJS.gT],null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.hN],null);return p4.h?p4.h(a,
b):p4.call(null,a,b)},$CLJS.dO,new $CLJS.k(null,1,[GX,function(a){var b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.hN],null);return p4.h?p4.h(a,b):p4.call(null,a,b)}],null)],null),WX,$CLJS.Ve],null),p4=function p4(a){switch(arguments.length){case 1:return p4.g(arguments[0]);case 2:return p4.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};p4.g=function(a){return p4.h(a,$CLJS.Mg)};
p4.h=function(a,b){try{var c=$CLJS.z(b)?$CLJS.Ss(pua,b):null;return $CLJS.$d(c)?c.g?c.g(a):c.call(null,a):$CLJS.ke(a)?a:$CLJS.je(a)?Lta(a,b):$CLJS.ie(a)?Mta(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.mj("Error removing empty clauses from form.",new $CLJS.k(null,2,[$CLJS.vz,a,$CLJS.yl,b],null),d);throw d;}};p4.A=2;
$CLJS.v5=function(){var a=$CLJS.gm.l(p4,Kta,Ita,$CLJS.F([Z3]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.mj($CLJS.ZF("Error normalizing query: {0}",$CLJS.F([$CLJS.MX(c)])),new $CLJS.k(null,1,[$CLJS.hN,b],null),c);}throw d;}}}();$CLJS.Z$=function Z$(a,b){if($CLJS.Bb($CLJS.z(a)))a=(0,$CLJS.v5)(b);else{var d=$CLJS.K,e=d.h;var f=$CLJS.ei(a);b=$CLJS.Nf([$CLJS.Wd(a),b]);f=Z$.h?Z$.h(f,b):Z$.call(null,f,b);a=e.call(d,f,$CLJS.Wd(a))}return a};