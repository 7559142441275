var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var CJ,HJ,Hba,Iba,Jba,Kba,Lba,Mba;
$CLJS.jj=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.ij([$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-4",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(b),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),
$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a())].join("").toLowerCase())};$CLJS.$F=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.ZF(arguments[0],1<b.length?new $CLJS.rd(b.slice(1),0,null):null)};CJ=function(a){return $CLJS.le(a)&&$CLJS.A(a)instanceof $CLJS.M};
$CLJS.EJ=function(a){var b=function(){var c=CJ(a);return c?(c=$CLJS.je($CLJS.Vd(a)))?(c=$CLJS.Bf.g($CLJS.Vd(a)),$CLJS.m(c)?c:$CLJS.JB.g($CLJS.Vd(a))):c:c}();return $CLJS.m(b)?b:$CLJS.DJ.g(a)};
HJ=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,2,[$CLJS.Pt,"valid MBQL clause",$CLJS.Bt,function(c){c=$CLJS.Of(c);c=$CLJS.K.h(c,$CLJS.Yk);return["invalid MBQL clause: ",$CLJS.Oi.l($CLJS.F([c]))].join("")}],null),$CLJS.$f(CJ)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.kG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,b],null),function(c){c=$CLJS.EJ(c);
return GJ(c,a)}],null)],null)};$CLJS.lG=new $CLJS.M(null,"right-join","right-join",-56349359);Hba=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Iba=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Jba=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.mG=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.nG=new $CLJS.M(null,"expr","expr",745722291);$CLJS.oG=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.pG=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.qG=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.rG=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.sG=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.tG=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.uG=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.vG=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);Kba=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);
$CLJS.wG=new $CLJS.M(null,"left-join","left-join",-672831855);$CLJS.xG=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.yG=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.zG=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);
Lba=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);Mba=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.AG=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.BG=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);
$CLJS.kG=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.CG=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.DG=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.EG=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);
$CLJS.FG=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.DJ=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.schema.expression","type-of-method"),function(f){var g=$CLJS.IF(f);return $CLJS.E.h(g,$CLJS.IB)?$CLJS.Kb(f):g},e,a,b,c,d)}();
$CLJS.oJ(Iba,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.tG],null),$CLJS.gG],null));$CLJS.DJ.o(null,$CLJS.Dj,function(a){throw $CLJS.nj($CLJS.ZF("{0}: Don''t know how to determine the type of {1}",$CLJS.F([Lba,$CLJS.Oi.l($CLJS.F([a]))])),new $CLJS.k(null,1,[$CLJS.nG,a],null));});$CLJS.DJ.o(null,$CLJS.yG,function(a){$CLJS.G(a,0,null);$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return $CLJS.EJ(a)});
var GJ=function GJ(a,b){return $CLJS.ge(a)?$CLJS.Vf(function(d){return GJ.h?GJ.h(d,b):GJ.call(null,d,b)},a):$CLJS.ge(b)?$CLJS.Vf(function(d){return GJ.h?GJ.h(a,d):GJ.call(null,a,d)},b):$CLJS.E.h(a,$CLJS.tG)?!0:$CLJS.XF(a,b)};$CLJS.oJ($CLJS.rG,HJ($CLJS.Gj,"expression returning a boolean"));$CLJS.oJ($CLJS.CG,HJ($CLJS.Sj,"expression returning a string"));$CLJS.oJ($CLJS.FG,HJ($CLJS.wj,"expression returning an integer"));$CLJS.oJ(Jba,HJ($CLJS.YE,"expression returning a non-integer real number"));
$CLJS.oJ($CLJS.EG,HJ($CLJS.Ij,"expression returning a number"));$CLJS.oJ(Kba,HJ($CLJS.yD,"expression returning a date"));$CLJS.oJ(Mba,HJ($CLJS.aF,"expression returning a time"));$CLJS.oJ(Hba,HJ($CLJS.kE,"expression returning a date time"));$CLJS.oJ($CLJS.pG,HJ($CLJS.Mj,"expression returning a date, time, or date time"));$CLJS.VJ=new $CLJS.U(null,new $CLJS.k(null,3,[$CLJS.Ij,null,$CLJS.Sj,null,$CLJS.Mj,null],null),null);$CLJS.oJ($CLJS.zG,HJ($CLJS.VJ,"an expression that can be compared with :\x3e or :\x3c"));
var Nba=new $CLJS.U(null,new $CLJS.k(null,7,[$CLJS.hD,null,$CLJS.Ij,null,$CLJS.Sj,null,$CLJS.OD,null,$CLJS.Mj,null,$CLJS.Gj,null,$CLJS.WE,null],null),null);$CLJS.oJ($CLJS.BG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.CG],null),HJ($CLJS.OD,"expression returning a BSONID")],null));$CLJS.oJ($CLJS.xG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,HJ(Nba,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.oJ($CLJS.sG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,HJ($CLJS.Aj,"any type of expression")],null));
$CLJS.oJ($CLJS.oG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zj,new $CLJS.k(null,1,[$CLJS.bk,1],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sG],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gs,$CLJS.Fs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.uG,$CLJS.ql],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cs,$CLJS.Fs],null)],null)],null)],null));