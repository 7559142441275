var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var e2,T2,ata,bta,cta,a3,b3,c3,y7,Qna,z7,A7,Rna,Sna;$CLJS.U2=function(a){return $CLJS.j2.g(a)};e2=function(a){return $CLJS.T.j(a,$CLJS.su,$CLJS.n5)};T2=function(a,b){return $CLJS.E.h($CLJS.sF.g(a),$CLJS.Zh(b,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iG,$CLJS.qF,$CLJS.hG],null)))};ata=function(a){var b=new $CLJS.k(null,3,[$CLJS.eG,$CLJS.p.g($CLJS.jj()),$CLJS.JB,$CLJS.JB.g(a),$CLJS.Bf,$CLJS.OA($CLJS.Bf,$CLJS.JB)(a)],null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GB,b,$CLJS.W.g(a)],null)};
bta=function(a){return $CLJS.n2(a,new $CLJS.k(null,1,[$CLJS.iG,$CLJS.zi],null))};
$CLJS.W2=function(a,b){var c=$CLJS.Vf(function(e){return $CLJS.uU(function(f){return $CLJS.E.h($CLJS.K.h(f,e),a)},b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.s_,$CLJS.W],null));if($CLJS.m(c))return c;c=$CLJS.uE($CLJS.xx);if($CLJS.m($CLJS.yE("metabase.lib.field",c))){var d=$CLJS.ZF("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.F([$CLJS.Oi.l($CLJS.F([a])),$CLJS.Oi.l($CLJS.F([$CLJS.mm.h($CLJS.s_,b)]))]));d instanceof Error?$CLJS.zE("metabase.lib.field",c,$CLJS.VA(),d):$CLJS.zE("metabase.lib.field",
c,$CLJS.VA.l($CLJS.F([d])),null)}return null};
cta=function(a,b,c){if($CLJS.m(t7))return null;var d=t7;t7=!0;try{var e=$CLJS.V2(a,b),f=$CLJS.m(e)?$CLJS.JZ(a,e):$CLJS.JZ(a,b),g=function(){var n=$CLJS.u7.g(f);if($CLJS.m(n))return n;n=$CLJS.Ss(f,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GW,$CLJS.wX],null));if($CLJS.m(n))return n;n=$CLJS.m(function(){var u=$CLJS.TW.g(f);if($CLJS.m(u))return u;u=$CLJS.FP.g(f);if($CLJS.m(u))return u;u=$CLJS.QR.g(f);return $CLJS.m(u)?u:$CLJS.mG.g(f)}())?$CLJS.w1.j(a,b,f):null;if($CLJS.m(n))return n;n=$CLJS.uE($CLJS.xx);if($CLJS.m($CLJS.yE("metabase.lib.field",
n))){var q=$CLJS.ZF("Cannot resolve column {0}: stage has no metadata",$CLJS.F([$CLJS.Oi.l($CLJS.F([c]))]));return q instanceof Error?$CLJS.zE("metabase.lib.field",n,$CLJS.VA(),q):$CLJS.zE("metabase.lib.field",n,$CLJS.VA.l($CLJS.F([q])),null)}return null}(),l=function(){var n=$CLJS.z(g);return n?$CLJS.W2(c,g):n}();return $CLJS.m(l)?$CLJS.m(e)?$CLJS.T.j($CLJS.T.j($CLJS.t4($CLJS.$l.l(l,$CLJS.rk,$CLJS.F([$CLJS.IL,$CLJS.v7,$CLJS.w7])),null),$CLJS.W,function(){var n=$CLJS.s_.g(l);return $CLJS.m(n)?n:$CLJS.W.g(l)}()),
$CLJS.xL,$CLJS.YZ):l:null}finally{t7=d}};
a3=function(a,b,c){$CLJS.G(c,0,null);var d=$CLJS.G(c,1,null),e=$CLJS.Of(d);d=$CLJS.K.h(e,$CLJS.UP);var f=$CLJS.G(c,2,null);c=$CLJS.xm.l($CLJS.F([function(){var g=$CLJS.JB.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.JB,g],null):null}(),function(){var g=$CLJS.OA($CLJS.Bf,$CLJS.JB)(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.Bf,g],null):null}(),function(){var g=$CLJS.bV.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.v7,g],null):null}(),function(){var g=$CLJS.uH.g(e);return $CLJS.m(g)?new $CLJS.k(null,
1,[$CLJS.w7,g],null):null}(),$CLJS.ve(f)?function(){var g=$CLJS.q2(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.su,$CLJS.vL,$CLJS.W,f],null)}():$CLJS.m(d)?new $CLJS.k(null,2,[$CLJS.su,$CLJS.vL,$CLJS.W,f],null):function(){var g=cta(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.su,$CLJS.vL,$CLJS.W,f],null)}()]));return $CLJS.m(d)?$CLJS.t4(c,d):c};b3=function(a){a=$CLJS.Of(a);var b=$CLJS.K.h(a,$CLJS.w7);return $CLJS.m($CLJS.m(b)?$CLJS.Ee($CLJS.WT,b):b)?$CLJS.wj:$CLJS.OA($CLJS.Bf,$CLJS.JB)(a)};
$CLJS.x7=function(a,b,c,d){$CLJS.G(d,0,null);var e=$CLJS.G(d,1,null),f=$CLJS.Of(e),g=$CLJS.K.h(f,$CLJS.eG),l=$CLJS.K.h(f,$CLJS.JB),n=$CLJS.K.h(f,$CLJS.bV),q=$CLJS.K.h(f,$CLJS.Bf);e=$CLJS.K.h(f,$CLJS.UP);var u=$CLJS.K.h(f,$CLJS.eO),v=$CLJS.K.h(f,$CLJS.uH),x=$CLJS.xm.l;g=new $CLJS.k(null,2,[$CLJS.su,$CLJS.vL,$CLJS.s1,g],null);f=$CLJS.vF.g(f);a=$CLJS.m(f)?f:$CLJS.MZ.j(a,b,d);c=x.call($CLJS.xm,$CLJS.F([g,c,new $CLJS.k(null,1,[$CLJS.vF,a],null)]));c=$CLJS.m(q)?$CLJS.T.j(c,$CLJS.Bf,q):c;l=$CLJS.m(l)?$CLJS.T.j(c,
$CLJS.JB,l):c;v=$CLJS.m(v)?$CLJS.T.j(l,$CLJS.w7,v):l;n=$CLJS.m(n)?$CLJS.T.j(v,$CLJS.v7,n):v;u=$CLJS.m(u)?$CLJS.T.j(n,$CLJS.HL,u):n;return $CLJS.m(e)?$CLJS.t4(u,e):u};c3=function(a,b,c){return $CLJS.m($CLJS.Vf(function(d){return $CLJS.E.h($CLJS.kb.g(d),c)},a))?$CLJS.mm.h(function(d){var e=$CLJS.$l.h(d,b);return $CLJS.E.h($CLJS.kb.g(d),c)?$CLJS.T.j(e,b,!0):e},a):a};
y7=function(a){var b=$CLJS.m($CLJS.M1.g(a))?null:function(){var d=$CLJS.xL.g(a),e=new $CLJS.U(null,new $CLJS.k(null,3,[$CLJS.w_,null,$CLJS.C_,null,$CLJS.YZ,null],null),null);return e.g?e.g(d):e.call(null,d)}(),c=$CLJS.xm.l($CLJS.F([new $CLJS.k(null,3,[$CLJS.eG,$CLJS.p.g($CLJS.jj()),$CLJS.JB,$CLJS.JB.g(a),$CLJS.Bf,b3(a)],null),function(){var d=$CLJS.G1(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.UP,d],null):null}(),function(){var d=$CLJS.w7.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.uH,d],
null):null}(),function(){var d=$CLJS.v7.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.bV,d],null):null}(),function(){var d=$CLJS.HL.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.eO,d],null):null}()]));b=($CLJS.m(b)?$CLJS.OA($CLJS.s_,$CLJS.W):$CLJS.OA($CLJS.rk,$CLJS.W))(a);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,c,b],null)};
Qna=function(a,b){return $CLJS.vg(function(c){return $CLJS.E.h($CLJS.xL.g(c),$CLJS.e_)},$CLJS.w1.B(a,b,$CLJS.JZ(a,b),new $CLJS.k(null,3,[$CLJS.m1,!1,$CLJS.t1,!0,$CLJS.j1,!1],null)))};$CLJS.w7=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);z7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.u7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);
A7=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);Rna=new $CLJS.M(null,"earliest","earliest",-1928154382);$CLJS.v7=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);Sna=new $CLJS.M(null,"latest","latest",24323665);var Tna;$CLJS.o2.o(null,$CLJS.oH,function(a){var b=$CLJS.G(a,0,null),c=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zi.g(b),$CLJS.n2(c,new $CLJS.k(null,2,[$CLJS.uH,$CLJS.zi,$CLJS.bV,bta],null)),a],null)});var t7=!1,h3=function h3(a,b){var d=$CLJS.z1(a,z7.g(b));a=$CLJS.m(z7.g(d))?h3.h?h3.h(a,d):h3.call(null,a,d):d;a=$CLJS.Of(a);var e=$CLJS.K.h(a,$CLJS.W);return $CLJS.nm.j(b,$CLJS.W,function(f){return[$CLJS.p.g(e),".",$CLJS.p.g(f)].join("")})};
$CLJS.n_.o(null,$CLJS.vL,function(a,b,c){return b3(c)});$CLJS.n_.o(null,$CLJS.oH,function(a,b,c){$CLJS.G(c,0,null);var d=$CLJS.G(c,1,null);d=$CLJS.Of(d);d=$CLJS.K.h(d,$CLJS.uH);$CLJS.G(c,2,null);c=a3(a,b,c);c=$CLJS.m(d)?$CLJS.T.j(c,$CLJS.w7,d):c;return $CLJS.o_.j(a,b,c)});$CLJS.KZ.o(null,$CLJS.vL,function(a,b,c){a=$CLJS.Of(c);b=$CLJS.K.h(a,$CLJS.W);return $CLJS.T.j(a,$CLJS.W,b)});$CLJS.KZ.o(null,$CLJS.oH,function(a,b,c){var d=a3(a,b,c);b=$CLJS.x7(a,b,d,c);return $CLJS.m(z7.g(b))?h3(a,b):b});
$CLJS.j_.o(null,$CLJS.vL,function(a,b,c,d){c=$CLJS.Of(c);var e=$CLJS.K.h(c,$CLJS.vF),f=$CLJS.K.h(c,$CLJS.W),g=$CLJS.K.h(c,$CLJS.kb),l=$CLJS.K.h(c,$CLJS.v7),n=$CLJS.K.h(c,$CLJS.F_),q=$CLJS.K.h(c,$CLJS.HL),u=$CLJS.K.h(c,$CLJS.IL);e=$CLJS.m(e)?e:"string"===typeof f?$CLJS.t2.h($CLJS.RB,f):$CLJS.p.g(f);$CLJS.E.h(d,$CLJS.U0)&&-1==e.indexOf(" → ")?($CLJS.m(q)?(q=$CLJS.z1(a,q),$CLJS.m(q)?a=(0,$CLJS.za)($CLJS.Ys($CLJS.vF.g($CLJS.PZ.j(a,b,q)),$CLJS.iZ,"")):(u=$CLJS.F1(a,u),a=$CLJS.MZ.B(a,b,u,d))):a=null,n=
$CLJS.m(a)?a:$CLJS.m(n)?n:$CLJS.G1(c)):n=null;n=$CLJS.m(n)?[$CLJS.p.g(n)," → ",$CLJS.p.g(e)].join(""):e;return $CLJS.m(g)?(c=$CLJS.CE($CLJS.Ys($CLJS.fi(g),"-"," ")),$CLJS.va.j?$CLJS.va.j("%s: %s",n,c):$CLJS.va.call(null,"%s: %s",n,c)):$CLJS.m(l)?(c=$CLJS.f2(l,c),$CLJS.va.j?$CLJS.va.j("%s: %s",n,c):$CLJS.va.call(null,"%s: %s",n,c)):n});
$CLJS.j_.o(null,$CLJS.oH,function(a,b,c,d){$CLJS.G(c,0,null);var e=$CLJS.G(c,1,null),f=$CLJS.Of(e);e=$CLJS.K.h(f,$CLJS.bV);var g=$CLJS.K.h(f,$CLJS.UP),l=$CLJS.K.h(f,$CLJS.uH);f=$CLJS.K.h(f,$CLJS.eO);$CLJS.G(c,2,null);c=a3(a,b,c);c=$CLJS.m(g)?$CLJS.T.j(c,$CLJS.F_,g):c;l=$CLJS.m(l)?$CLJS.T.j(c,$CLJS.kb,l):c;e=$CLJS.m(e)?$CLJS.T.j(l,$CLJS.v7,e):l;e=$CLJS.m(f)?$CLJS.T.j(e,$CLJS.HL,f):e;return $CLJS.m(e)?$CLJS.MZ.B(a,b,e,d):$CLJS.$F("[Unknown Field]")});
$CLJS.k_.o(null,$CLJS.vL,function(a,b,c){a=$CLJS.Of(c);return $CLJS.K.h(a,$CLJS.W)});$CLJS.k_.o(null,$CLJS.oH,function(a,b,c){$CLJS.G(c,0,null);$CLJS.G(c,1,null);c=a3(a,b,c);return $CLJS.m(c)?$CLJS.l_.j(a,b,c):"unknown_field"});
$CLJS.p_.o(null,$CLJS.vL,function(a,b,c){return $CLJS.xm.l($CLJS.F([function(){var d=$CLJS.fj($CLJS.p_,$CLJS.Dj);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.h($CLJS.xL.g(c),$CLJS.w_)?function(){var d=$CLJS.E_.g(c);return $CLJS.m(d)?(d=$CLJS.V_(a,d),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.DX,new $CLJS.k(null,2,[$CLJS.W,$CLJS.W.g(d),$CLJS.vF,$CLJS.W.g(d)],null)],null):null):null}():null]))});$CLJS.x2.o(null,$CLJS.oH,function(a){$CLJS.G(a,0,null);var b=$CLJS.G(a,1,null);$CLJS.G(a,2,null);return $CLJS.uH.g(b)});
$CLJS.x2.o(null,$CLJS.vL,function(a){return $CLJS.w7.g(a)});$CLJS.w2.o(null,$CLJS.oH,function(a,b){$CLJS.G(a,0,null);var c=$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);if($CLJS.m(b)){var d=$CLJS.Ee($CLJS.WT,b),e=$CLJS.bD(A7,$CLJS.Bf,$CLJS.JB)(c);c=$CLJS.T.l(c,$CLJS.uH,b,$CLJS.F([$CLJS.Bf,d?$CLJS.wj:e,A7,e]));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,c,a],null)}b=A7.g(c);c=$CLJS.m(b)?$CLJS.$l.h($CLJS.T.j(c,$CLJS.Bf,b),A7):c;c=$CLJS.$l.h(c,$CLJS.uH);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oH,c,a],null)});
$CLJS.w2.o(null,$CLJS.vL,function(a,b){return $CLJS.m(b)?$CLJS.T.j(a,$CLJS.w7,b):$CLJS.$l.h(a,$CLJS.w7)});$CLJS.y2.o(null,$CLJS.oH,function(a,b,c){return $CLJS.d3.j(a,b,a3(a,b,c))});
$CLJS.y2.o(null,$CLJS.vL,function(a,b,c){if($CLJS.Yh.h($CLJS.xL.g(c),$CLJS.e_)){a=$CLJS.OA($CLJS.Bf,$CLJS.JB)(c);b=null==c?null:$CLJS.sO.g(c);if(null==b)var d=null;else try{var e=$CLJS.kE.g($CLJS.Ik.g(b));if($CLJS.m(e)){var f=$CLJS.Of(e),g=$CLJS.K.h(f,Rna),l=$CLJS.K.h(f,Sna),n=$CLJS.Zsa.l($CLJS.F([$CLJS.S2.g(g),$CLJS.S2.g(l)]));d=isNaN(n)?null:$CLJS.m($CLJS.vr.h?$CLJS.vr.h(1,n):$CLJS.vr.call(null,1,n))?$CLJS.ru:$CLJS.m($CLJS.vr.h?$CLJS.vr.h(31,n):$CLJS.vr.call(null,31,n))?$CLJS.uu:$CLJS.m($CLJS.vr.h?
$CLJS.vr.h(365,n):$CLJS.vr.call(null,365,n))?$CLJS.tj:$CLJS.xj}else d=null}catch(q){if(q instanceof Error)d=null;else throw q;}e=$CLJS.XF(a,$CLJS.kE)?$CLJS.usa:$CLJS.XF(a,$CLJS.yD)?$CLJS.tsa:$CLJS.XF(a,$CLJS.aF)?$CLJS.ssa:$CLJS.Mg;d=$CLJS.m(d)?c3(e,$CLJS.Dj,d):e;return $CLJS.m($CLJS.w7.g(c))?c3(d,$CLJS.d1,$CLJS.w7.g(c)):d}return $CLJS.Mg});
$CLJS.j2.o(null,$CLJS.oH,function(a){var b=null==a?null:$CLJS.KF(a);b=null==b?null:$CLJS.bV.g(b);return null==b?null:$CLJS.T.l(b,$CLJS.su,$CLJS.b6,$CLJS.F([$CLJS.$5,function(c,d){return a3(c,d,a)}]))});$CLJS.j2.o(null,$CLJS.vL,function(a){var b=null==a?null:$CLJS.v7.g(a);return null==b?null:$CLJS.T.l(b,$CLJS.su,$CLJS.b6,$CLJS.F([$CLJS.$5,$CLJS.ag(a)]))});$CLJS.i2.o(null,$CLJS.oH,function(a,b){return $CLJS.fU(a,$CLJS.dU,$CLJS.F([$CLJS.bV,b]))});
$CLJS.i2.o(null,$CLJS.vL,function(a,b){return $CLJS.dU(a,$CLJS.v7,b)});$CLJS.k2.o(null,$CLJS.oH,function(a,b,c){return $CLJS.e3.j(a,b,a3(a,b,c))});
$CLJS.k2.o(null,$CLJS.vL,function(a,b,c){b=$CLJS.Of(c);c=$CLJS.K.h(b,$CLJS.Bf);var d=$CLJS.K.h(b,$CLJS.sO),e=$CLJS.K.h(b,$CLJS.Lj);if($CLJS.Yh.h($CLJS.xL.g(b),$CLJS.e_)){var f=function(){var n=null==a?null:$CLJS.yZ($CLJS.DZ(a));n=null==n?null:$CLJS.Rz.g(n);return null==n?null:$CLJS.Ee(n,$CLJS.bV)}(),g=$CLJS.Ss(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,$CLJS.Ij],null)),l=$CLJS.U2(b);return function u(q){return new $CLJS.uf(null,function(){for(;;){var v=$CLJS.z(q);if(v){if($CLJS.me(v)){var x=$CLJS.Wc(v),
y=$CLJS.D(x),C=$CLJS.xf(y);return function(){for(var J=0;;)if(J<y){var S=$CLJS.Yd(x,J),V=C,da=S;S=T2(S,l)?$CLJS.T.j(da,$CLJS.d1,!0):da;V.add(S);J+=1}else return!0}()?$CLJS.Af($CLJS.Ef(C),u($CLJS.Xc(v))):$CLJS.Af($CLJS.Ef(C),null)}var H=$CLJS.A(v);return $CLJS.kf(function(){var J=H;return T2(H,l)?$CLJS.T.j(J,$CLJS.d1,!0):J}(),u($CLJS.vd(v)))}return null}},null,null)}($CLJS.Bb(function(){if($CLJS.m(f)){var q=$CLJS.bk.g(g);return $CLJS.m(q)?$CLJS.xl.g(g):q}return f}())?null:$CLJS.XF(e,$CLJS.yj)?$CLJS.mm.h(e2,
new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.c2(),new $CLJS.k(null,2,[$CLJS.vF,$CLJS.$F("Bin every 0.1 degrees"),$CLJS.sF,new $CLJS.k(null,2,[$CLJS.iG,$CLJS.hG,$CLJS.hG,.1],null)],null),new $CLJS.k(null,2,[$CLJS.vF,$CLJS.$F("Bin every 1 degree"),$CLJS.sF,new $CLJS.k(null,2,[$CLJS.iG,$CLJS.hG,$CLJS.hG,1],null)],null),new $CLJS.k(null,2,[$CLJS.vF,$CLJS.$F("Bin every 10 degrees"),$CLJS.sF,new $CLJS.k(null,2,[$CLJS.iG,$CLJS.hG,$CLJS.hG,10],null)],null),new $CLJS.k(null,2,[$CLJS.vF,$CLJS.$F("Bin every 20 degrees"),
$CLJS.sF,new $CLJS.k(null,2,[$CLJS.iG,$CLJS.hG,$CLJS.hG,20],null)],null)],null)):$CLJS.XF(c,$CLJS.Ij)&&!$CLJS.XF(e,$CLJS.gb)?$CLJS.mm.h(e2,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.c2(),new $CLJS.k(null,2,[$CLJS.vF,$CLJS.$F("10 bins"),$CLJS.sF,new $CLJS.k(null,2,[$CLJS.iG,$CLJS.qF,$CLJS.qF,10],null)],null),new $CLJS.k(null,2,[$CLJS.vF,$CLJS.$F("50 bins"),$CLJS.sF,new $CLJS.k(null,2,[$CLJS.iG,$CLJS.qF,$CLJS.qF,50],null)],null),new $CLJS.k(null,2,[$CLJS.vF,$CLJS.$F("100 bins"),$CLJS.sF,new $CLJS.k(null,2,
[$CLJS.iG,$CLJS.qF,$CLJS.qF,100],null)],null)],null)):null)}return $CLJS.Mg});$CLJS.cU.o(null,$CLJS.oH,function(a){return a});
$CLJS.cU.o(null,$CLJS.vL,function(a){a=$CLJS.Of(a);var b=$CLJS.K.h(a,$CLJS.xL);switch(b instanceof $CLJS.M?b.R:null){case "source/aggregations":return b=new $CLJS.k(null,2,[$CLJS.eG,$CLJS.p.g($CLJS.jj()),$CLJS.Bf,$CLJS.OA($CLJS.Bf,$CLJS.JB)(a)],null),a=$CLJS.s1.g(a),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dH,b,a],null);case "source/expressions":return ata(a);case "source/breakouts":return $CLJS.Ee(a,$CLJS.uG)?ata(a):y7(a);default:return y7(a)}});
$CLJS.G8=function(){function a(e,f,g){g=$CLJS.Pf($CLJS.mm.h($CLJS.eU,g));var l=Qna(e,f),n=$CLJS.mh.j($CLJS.ai,$CLJS.Nm(function(q){return $CLJS.D2.B(e,f,q,l)}),$CLJS.m(g)?g:$CLJS.Mg);n=$CLJS.lm.h(n,l);g=$CLJS.m(g)?$CLJS.mh.j(g,$CLJS.kg.g($CLJS.eU),n):null;return $CLJS.R3.l(e,f,$CLJS.dU,$CLJS.F([$CLJS.mG,g]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,g){return d.j?d.j(f,g,e):d.call(null,f,g,e)}}var d=null;d=function(e,f,g){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,g)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.h=b;d.j=a;return d}();$CLJS.I8=function(){function a(d,e){return $CLJS.mG.g($CLJS.JZ(d,e))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.J8=function(){function a(d,e){var f=$CLJS.w1.B(d,e,$CLJS.JZ(d,e),new $CLJS.k(null,3,[$CLJS.m1,!1,$CLJS.t1,!1,$CLJS.j1,!1],null)),g=$CLJS.I8.h(d,e);return $CLJS.ee(g)?$CLJS.mm.h(function(l){return $CLJS.T.j(l,$CLJS.u_,!0)},f):$CLJS.r4(d,e,f,g)}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
Tna=function(){function a(d,e,f){var g=$CLJS.JZ(d,e),l=$CLJS.E.h($CLJS.IF(f),$CLJS.dH)?$CLJS.v1:$CLJS.w1;g=l.j?l.j(d,e,g):l.call(null,d,e,g);return $CLJS.D2.B(d,e,f,g)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();
$CLJS.Una=function(){function a(d,e,f){f=$CLJS.VZ.j(d,e,f);return Tna.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();