var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var i0,n0,j0,Mqa,Nqa,DM,Uda,FM,gM,RL,hM,Vda,bM,GM,mM,nM,o0,HM,Wda,IM,Xda,KM,LM,MM,Yda,NM,OM,Zda,PM,$da,QM,RM,aea,TM,bea,cea,dea,VM,WM,XM,YM,ZM,$M,aN,bN,eea,cN,dN,eN,gN,iN,fea,jN,kN,lN,mN,gea,nN,oN,pN,qN,hea,rN,sN,tN,uN,iea,vN,jea,wN,xN,yN,kea,zN,AN,BN,CN,EN,FN,lea,GN,HN,mea,IN,JN,KN,LN,MN,nea,NN,oea,ON,PN,RN,pea,SN,TN,UN,qea,XN,YN,rea,sea,ZN,aO,bO,cO,tea,fO,gO,uea,vea,wea,hO,iO,jO,kO,lO,mO,xea,nO,yea,oO,pO,rO,tO,uO,vO,wO,zea,yO,Aea,Bea,zO,AO,BO,CO,DO,Cea,EO,Dea,Eea,FO,GO,HO,IO,JO,Fea,
Gea,Hea,KO,Iea,LO,Jea,NO,OO,Kea,QO,RO,SO,TO,Wea,WO,Xea,Yea,XO,YO,ZO,Lfa,$O,aP,bP,cP,Mfa,eP,fP,gP,hP,iP,Rfa,jP,kP,Sfa,lP,mP,nP,oP,pP,qP,sP,Tfa,tP,Ufa,uP,vP,wP,Vfa,Wfa,Xfa,yP,zP,AP,Yfa,BP,CP,DP,EP,Zfa,$fa,GP,aga,bga,HP,IP,JP,KP,LP,cga,MP,NP,OP,PP,dga,QP,RP,SP,TP,VP,ega,WP,XP,fga,YP,ZP,$P,gga,aQ,hga,cQ,iga,dQ,eQ,fQ,gQ,hQ,iQ,jQ,jga,kga,lga,kQ,mga,lQ,mQ,nga,oga,nQ,oQ,pQ,pga,qQ,qga,wQ,rga,sga,GQ,tga,uga,vga,mR,nR,wga,yR,PR,VR,xga,rS,yga,sS,LS,MS,Dga,Ega,NS,TS,YS,fT,Fga,hT,iT,Gga,jT,pT,rT,sT,tT,uT,AT,BT,
Hga,CT,DT,Jga,ET,Kga,FT,GT,HT,Lga,IT,JT,Mga,Nga,KT,YT,ZT,Pga,Qga,$T,Rga,Sga,bha,bU,fha,kU,hha,iha,lU,mU,jha,kha,lha,nU,oU,pU,qU,rU,sU,xU,oha,zU,AU,rha,BU,uha,vha,wha,CU,DU,EU,xha,FU,GU,yha,HU,zha,IU,Aha,Bha,KU,LU,MU,NU,OU,Cha,PU,QU,Dha,RU,SU,TU,UU,VU,XU,YU,ZU,Eha,$U,aV,cV,dV,eV,fV,gV,hV,iV,jV,kV,lV,Fha,mV,pV,qV,rV,sV,Gha,tV,uV,vV,wV,Hha,yV,Iha,Jha,FV,Kha,GV,Lha,HV,Mha,Nha,IV,Oha,JV,LV,MV,Pha,QV,RV,Qha,Rha,SV,TV,UV,VV,WV,XV,YV,$V,aW,Sha;
$CLJS.CM=function(a,b){return function(){function c(l,n,q){return $CLJS.te(function(){var u=a.g?a.g(l):a.call(null,l);return $CLJS.m(u)?(u=a.g?a.g(n):a.call(null,n),$CLJS.m(u)?(u=a.g?a.g(q):a.call(null,q),$CLJS.m(u)?(u=b.g?b.g(l):b.call(null,l),$CLJS.m(u)?(u=b.g?b.g(n):b.call(null,n),$CLJS.m(u)?b.g?b.g(q):b.call(null,q):u):u):u):u):u}())}function d(l,n){return $CLJS.te(function(){var q=a.g?a.g(l):a.call(null,l);return $CLJS.m(q)?(q=a.g?a.g(n):a.call(null,n),$CLJS.m(q)?(q=b.g?b.g(l):b.call(null,l),
$CLJS.m(q)?b.g?b.g(n):b.call(null,n):q):q):q}())}function e(l){var n=a.g?a.g(l):a.call(null,l);l=$CLJS.m(n)?b.g?b.g(l):b.call(null,l):n;return $CLJS.te(l)}var f=null,g=function(){function l(q,u,v,x){var y=null;if(3<arguments.length){y=0;for(var C=Array(arguments.length-3);y<C.length;)C[y]=arguments[y+3],++y;y=new $CLJS.rd(C,0,null)}return n.call(this,q,u,v,y)}function n(q,u,v,x){return $CLJS.te(function(){var y=f.j(q,u,v);return $CLJS.m(y)?$CLJS.Uf(function(C){var H=a.g?a.g(C):a.call(null,C);return $CLJS.m(H)?
b.g?b.g(C):b.call(null,C):H},x):y}())}l.A=3;l.D=function(q){var u=$CLJS.A(q);q=$CLJS.B(q);var v=$CLJS.A(q);q=$CLJS.B(q);var x=$CLJS.A(q);q=$CLJS.vd(q);return n(u,v,x,q)};l.l=n;return l}();f=function(l,n,q,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,n);case 3:return c.call(this,l,n,q);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.rd(x,0,null)}return g.l(l,n,q,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.D=g.D;f.v=function(){return!0};f.g=e;f.h=d;f.j=c;f.l=g.l;return f}()};
$CLJS.Lqa=function(a){var b=$CLJS.Mo.h(a,null),c=$CLJS.px(b,$CLJS.ct,function(d){return $CLJS.Qn(d,$CLJS.Mg)});return function(){function d(g,l,n){return(l=$CLJS.z(c.j?c.j(g,l,n):c.call(null,g,l,n)))?new $CLJS.k(null,3,[$CLJS.Cl,b,$CLJS.Yk,g,$CLJS.jt,l],null):null}function e(g){return f.j(g,$CLJS.Mg,$CLJS.Mg)}var f=null;f=function(g,l,n){switch(arguments.length){case 1:return e.call(this,g);case 3:return d.call(this,g,l,n)}throw Error("Invalid arity: "+arguments.length);};f.g=e;f.j=d;return f}()};
i0=function(a,b){return $CLJS.je(a)?$CLJS.K.h(a,b):a};n0=function(a,b,c,d){d=$CLJS.m(d)?d:$CLJS.Tn($CLJS.Mo.h($CLJS.Cl.g(a),null));if($CLJS.m(b)){var e=i0($CLJS.Bt.g(b),c);$CLJS.m(e)?(e=$CLJS.Iq(e,d),a=e.h?e.h(a,d):e.call(null,a,d)):a=null;return $CLJS.m(a)?a:i0($CLJS.Pt.g(b),c)}return null};j0=function(a,b){return $CLJS.ge(a)||$CLJS.he(a)?$CLJS.K.h(a,b):$CLJS.ie(a)?$CLJS.K.h($CLJS.Ng(a),b):null};
Mqa=function(a,b){a=$CLJS.Of(a);var c=$CLJS.K.h(a,$CLJS.Cl),d=$CLJS.K.h(a,$CLJS.Ik);b=$CLJS.Of(b);var e=$CLJS.K.j(b,$CLJS.jt,$CLJS.Pca),f=$CLJS.K.j(b,$CLJS.GC,!0),g=$CLJS.K.h(b,$CLJS.WC),l=$CLJS.K.j(b,$CLJS.RC,$CLJS.rC),n=n0(a,$CLJS.Kq.g(c),g,b);if($CLJS.m(n))return n;n=n0(a,$CLJS.Bs.g(c),g,b);if($CLJS.m(n))return n;n=n0(a,e.g?e.g(d):e.call(null,d),g,b);if($CLJS.m(n))return n;n=n0(a,function(){var q=$CLJS.yo.g(c);return e.g?e.g(q):e.call(null,q)}(),g,b);if($CLJS.m(n))return n;n=n0(a,$CLJS.Kq.g(c),
l,b);if($CLJS.m(n))return n;n=n0(a,$CLJS.Bs.g(c),l,b);if($CLJS.m(n))return n;d=n0(a,e.g?e.g(d):e.call(null,d),l,b);if($CLJS.m(d))return d;d=n0(a,function(){var q=$CLJS.yo.g(c);return e.g?e.g(q):e.call(null,q)}(),l,b);if($CLJS.m(d))return d;g=$CLJS.m(f)?n0(a,e.g?e.g($CLJS.pC):e.call(null,$CLJS.pC),g,b):f;return $CLJS.m(g)?g:$CLJS.m(f)?n0(a,e.g?e.g($CLJS.pC):e.call(null,$CLJS.pC),l,b):f};
Nqa=function(a,b,c){a=$CLJS.R;var d=$CLJS.mh.h;var e=$CLJS.sk.g(b);var f=$CLJS.Of(b);var g=$CLJS.K.h(f,$CLJS.Cl),l=$CLJS.Of(c);f=$CLJS.K.h(l,$CLJS.WC);l=$CLJS.K.j(l,$CLJS.RC,$CLJS.rC);g=$CLJS.Kq.g(g);f=i0($CLJS.sC.g(g),f);f=$CLJS.m(f)?f:i0($CLJS.sC.g(g),l);d=d.call($CLJS.mh,e,f);return new $CLJS.Q(null,2,5,a,[d,Mqa(b,c)],null)};
$CLJS.Oqa=function(a){var b=$CLJS.Of(a),c=$CLJS.K.h(b,$CLJS.Yk);a=$CLJS.K.h(b,$CLJS.jt);var d=$CLJS.Of(null),e=$CLJS.K.j(d,$CLJS.IC,$CLJS.ik),f=$CLJS.K.j(d,$CLJS.UC,Nqa);return $CLJS.m(a)?$CLJS.Ob(function(g,l){var n=f.j?f.j(b,l,d):f.call(null,b,l,d),q=$CLJS.G(n,0,null);n=$CLJS.G(n,1,null);l=$CLJS.T.j(l,$CLJS.ik,n);l=e.g?e.g(l):e.call(null,l);return o0(g,c,q,l)},null,a):null};
DM=function(a,b,c){var d=$CLJS.Ss($CLJS.r($CLJS.lF),new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null));if($CLJS.m(d))return d;c=c.v?c.v():c.call(null);$CLJS.Ri.B($CLJS.lF,$CLJS.bt,new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null),c);return c};$CLJS.EM=function(a){return DM($CLJS.Ml,a,function(){return $CLJS.rx.g(a)})};Uda=function(){var a=eT;return DM($CLJS.ct,a,function(){var b=$CLJS.rx.g(a),c=$CLJS.Lqa(a);return function(d){return $CLJS.m(b.g?b.g(d):b.call(null,d))?null:c.g?c.g(d):c.call(null,d)}})};
FM=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,$CLJS.tf(a)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,a],null):a],null)};gM=function(a){if($CLJS.le(a)){var b=$CLJS.G(a,0,null),c=$CLJS.G(a,1,null);switch(b instanceof $CLJS.M?b.R:null){case "optional":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ds,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,FM(c)],null)],null);case "rest":return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cs,FM(c)],null);default:return FM(a)}}else return FM(a)};
RL=function(a,b){var c=$CLJS.le(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.fe(a)?(b=$CLJS.A(b),a=$CLJS.di(a),a.g?a.g(b):a.call(null,b)):$CLJS.E.h(a,$CLJS.A(b)):c:c};
hM=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,["not a ",$CLJS.p.g(a)," clause"].join("")],null),$CLJS.bg(RL,a)],null),$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.is,new $CLJS.Q(null,2,5,$CLJS.R,["tag",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,a],null)],null)],null),function(){return function e(d){return new $CLJS.uf(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.me(f)){var g=$CLJS.Wc(f),l=$CLJS.D(g),n=$CLJS.xf(l);
a:for(var q=0;;)if(q<l){var u=$CLJS.Yd(g,q),v=$CLJS.G(u,0,null);u=$CLJS.G(u,1,null);v=new $CLJS.Q(null,2,5,$CLJS.R,[v,gM(u)],null);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Af($CLJS.Ef(n),e($CLJS.Xc(f))):$CLJS.Af($CLJS.Ef(n),null)}g=$CLJS.A(f);n=$CLJS.G(g,0,null);g=$CLJS.G(g,1,null);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[n,gM(g)],null),e($CLJS.vd(f)))}return null}},null,null)}($CLJS.UL(2,2,b))}())],null)};Vda=function(a){return $CLJS.le(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
bM=function(a){return $CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Wf,Vda,$CLJS.Pt,["valid instance of one of these MBQL clauses: ",$CLJS.Tt(", ",$CLJS.kg.h($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.uf(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.me(e)){var f=$CLJS.Wc(e),g=$CLJS.D(f),l=$CLJS.xf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.Yd(f,n),u=$CLJS.G(q,0,null);q=$CLJS.G(q,1,null);u=new $CLJS.Q(null,2,5,$CLJS.R,[u,
$CLJS.tf(q)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,q],null):q],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Af($CLJS.Ef(l),d($CLJS.Xc(e))):$CLJS.Af($CLJS.Ef(l),null)}f=$CLJS.A(e);l=$CLJS.G(f,0,null);f=$CLJS.G(f,1,null);return $CLJS.kf(new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.tf(f)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,f],null):f],null),d($CLJS.vd(e)))}return null}},null,null)}(a)}())};
GM=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.BM],null)],null)};$CLJS.lM={};mM={};nM={};$CLJS.bt=function bt(a,b,c){var e=$CLJS.z(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.T.j;var g=$CLJS.K.h(a,b);c=bt.j?bt.j(g,f,c):bt.call(null,g,f,c);a=e.call($CLJS.T,a,b,c)}else a=$CLJS.T.j(a,b,c);return a};
o0=function o0(a,b,c,d){var f=$CLJS.z(c);c=$CLJS.A(f);var g=$CLJS.B(f),l=j0(b,c);f=$CLJS.m(a)?a:$CLJS.ie(b)?$CLJS.Mg:$CLJS.ke(b)?$CLJS.O:$CLJS.Xd(b);return $CLJS.m($CLJS.m(c)?$CLJS.qC.g($CLJS.be(f)):c)?a:$CLJS.m(c)?(b=j0(f,c),d=o0.B?o0.B(b,l,g,d):o0.call(null,b,l,g,d),$CLJS.we(c)&&$CLJS.ie(f)&&c>$CLJS.D(f)&&(b=$CLJS.pg(c-$CLJS.D(f),null),b=$CLJS.sg.h(f,b),f=null==f||$CLJS.re(f)?b:$CLJS.mh.h($CLJS.Xd(f),b)),null==f||$CLJS.he(f)?$CLJS.T.j(f,c,d):$CLJS.ge(f)?$CLJS.gf.h(f,d):$CLJS.P.h($CLJS.Y,$CLJS.T.j($CLJS.Ng(f),
c,d))):$CLJS.je(a)?(c=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.TC],null),o0.B?o0.B(f,b,c,d):o0.call(null,f,b,c,d)):$CLJS.m($CLJS.qC.g($CLJS.be(f)))?$CLJS.gf.h(f,d):$CLJS.le($CLJS.Pf(f))?f:$CLJS.I(new $CLJS.Q(null,1,5,$CLJS.R,[d],null),new $CLJS.k(null,1,[$CLJS.qC,!0],null))};HM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Wda=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
IM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);$CLJS.JM=new $CLJS.M(null,"dimension","dimension",543254198);Xda=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);KM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);LM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);
MM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);Yda=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);NM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);OM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Zda=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
PM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);$da=new $CLJS.M(null,"from","from",1815293044);QM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);RM=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);aea=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.SM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);
TM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);bea=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);cea=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.UM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);dea=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);
VM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);WM=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);XM=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);YM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);ZM=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);$M=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
aN=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);bN=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);eea=new $CLJS.M(null,"lon-max","lon-max",1590224717);cN=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);dN=new $CLJS.w(null,"stddev","stddev",775056588,null);eN=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.fN=new $CLJS.M(null,"snippet","snippet",953581994);gN=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.hN=new $CLJS.M(null,"query","query",-1288509510);iN=new $CLJS.M(null,"string-expression","string-expression",-1395337766);fea=new $CLJS.M(null,"lat-field","lat-field",-830652957);jN=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);kN=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
lN=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);mN=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);gea=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);nN=new $CLJS.M("location","country","location/country",1666636202);oN=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
pN=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);qN=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);hea=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);rN=new $CLJS.M(null,"unary","unary",-989314568);sN=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
tN=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);uN=new $CLJS.w(null,"ceil","ceil",-184398425,null);iea=new $CLJS.M(null,"lon-min","lon-min",-787291357);vN=new $CLJS.w(null,"NonBlankString","NonBlankString",-719244809,null);jea=new $CLJS.M(null,"match","match",1220059550);wN=new $CLJS.w(null,"count-where","count-where",2025939247,null);xN=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
yN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);kea=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);zN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);AN=new $CLJS.w(null,"sum","sum",1777518341,null);BN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);CN=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.DN=new $CLJS.M("date","range","date/range",1647265776);EN=new $CLJS.w(null,"between","between",-1523336493,null);FN=new $CLJS.M(null,"clause-form","clause-form",1820463737);lea=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);GN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);HN=new $CLJS.w(null,"field","field",338095027,null);mea=new $CLJS.M(null,"segment-id","segment-id",1810133590);
IN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);JN=new $CLJS.w(null,"not-null","not-null",313812992,null);KN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);LN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);MN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);nea=new $CLJS.M(null,"template-tag","template-tag",310841038);
NN=new $CLJS.M(null,"invalid","invalid",412869516);oea=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);ON=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);PN=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.QN=new $CLJS.M(null,"context","context",-830191113);RN=new $CLJS.w(null,"get-year","get-year",704520253,null);
pea=new $CLJS.M(null,"format-rows?","format-rows?",992129486);SN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);TN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);UN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.VN=new $CLJS.M(null,"parameters","parameters",-1229919748);qea=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.WN=new $CLJS.M(null,"card","card",-1430355152);XN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);YN=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);rea=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);sea=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);ZN=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.$N=new $CLJS.M("date","month-year","date/month-year",1948031290);
aO=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);bO=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);cO=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.dO=new $CLJS.M(null,"joins","joins",1033962699);tea=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.eO=new $CLJS.M(null,"source-field","source-field",933829534);fO=new $CLJS.w(null,"Field","Field",430385967,null);
gO=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);uea=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);vea=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);wea=new $CLJS.M(null,"items","items",1031954938);hO=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);iO=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
jO=new $CLJS.M(null,"more","more",-2058821800);kO=new $CLJS.M(null,"first-clause","first-clause",-20953491);lO=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);mO=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);xea=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);nO=new $CLJS.w(null,"contains","contains",-1977535957,null);
yea=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);oO=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);pO=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.qO=new $CLJS.M(null,"widget-type","widget-type",1836256899);rO=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.sO=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
tO=new $CLJS.w(null,"is-null","is-null",-356519403,null);uO=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);vO=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);wO=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);zea=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.xO=new $CLJS.M(null,"required","required",1807647006);yO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);
Aea=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Bea=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);zO=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);AO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);BO=new $CLJS.M("string","contains","string/contains",1602423827);CO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);DO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);
Cea=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);EO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);Dea=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);Eea=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);FO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);
GO=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);HO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);IO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);JO=new $CLJS.w(null,"share","share",1051097594,null);Fea=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);
Gea=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);Hea=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);KO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Iea=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);LO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);
Jea=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.MO=new $CLJS.M(null,"collection","collection",-683361892);NO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);OO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);$CLJS.PO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);
Kea=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);QO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);RO=new $CLJS.w(null,"metric","metric",2049329604,null);SO=new $CLJS.w(null,"concat","concat",-467652465,null);TO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.UO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);Wea=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);
$CLJS.VO=new $CLJS.M("date","relative","date/relative",25987732);WO=new $CLJS.M("location","city","location/city",-1746973325);Xea=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);Yea=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);XO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);YO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);ZO=new $CLJS.M("number","between","number/between",97700581);
Lfa=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);$O=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);aP=new $CLJS.w(null,"sqrt","sqrt",370479598,null);bP=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);cP=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);$CLJS.dP=new $CLJS.M(null,"semantic_type","semantic_type",272485089);
Mfa=new $CLJS.M(null,"metric-id","metric-id",-686486942);eP=new $CLJS.w(null,"*","*",345799209,null);fP=new $CLJS.w(null,"+","+",-740910886,null);gP=new $CLJS.w(null,"-","-",-471816912,null);hP=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);iP=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Rfa=new $CLJS.M(null,"question","question",-1411720117);jP=new $CLJS.w(null,"asc","asc",1997386096,null);
kP=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Sfa=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);lP=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);mP=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);nP=new $CLJS.w(null,"\x3c","\x3c",993667236,null);oP=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);pP=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);
qP=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.rP=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);sP=new $CLJS.M("string","ends-with","string/ends-with",302681156);Tfa=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);tP=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Ufa=new $CLJS.w(null,"MetricID","MetricID",-2128635641,null);
uP=new $CLJS.w(null,"and","and",668631710,null);vP=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);wP=new $CLJS.w(null,"round","round",-645002441,null);Vfa=new $CLJS.M(null,"to","to",192099007);$CLJS.xP=new $CLJS.M("date","single","date/single",1554682003);Wfa=new $CLJS.M(null,"action-id","action-id",-1727958578);Xfa=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);yP=new $CLJS.w(null,"exp","exp",1378825265,null);
zP=new $CLJS.w(null,"Filter","Filter",-424893332,null);AP=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);Yfa=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);BP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);CP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);DP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
EP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.FP=new $CLJS.M(null,"source-table","source-table",-225307692);Zfa=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);$fa=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);GP=new $CLJS.w(null,"floor","floor",-772394748,null);aga=new $CLJS.M(null,"middleware","middleware",1462115504);
bga=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);HP=new $CLJS.M(null,"requires-features","requires-features",-101116256);IP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);JP=new $CLJS.M(null,"clause-name","clause-name",-996419059);KP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);LP=new $CLJS.w(null,"now","now",-9994004,null);
cga=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);MP=new $CLJS.w(null,"not","not",1044554643,null);NP=new $CLJS.w(null,"avg","avg",1837937727,null);OP=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);PP=new $CLJS.M(null,"max-results","max-results",-32858165);dga=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);QP=new $CLJS.w(null,"case","case",-1510733573,null);
RP=new $CLJS.w(null,"distinct","distinct",-148347594,null);SP=new $CLJS.w(null,"get-second","get-second",-425414791,null);TP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.UP=new $CLJS.M(null,"join-alias","join-alias",1454206794);VP=new $CLJS.w(null,"is-empty","is-empty",600228619,null);ega=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
WP=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);XP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);fga=new $CLJS.M(null,"original","original",-445386197);YP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);ZP=new $CLJS.w(null,"abs","abs",1394505050,null);$P=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);gga=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
aQ=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);hga=new $CLJS.w(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.bQ=new $CLJS.M(null,"date","date",-1463434462);cQ=new $CLJS.M(null,"second-clause","second-clause",-461435645);iga=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);dQ=new $CLJS.w(null,"rtrim","rtrim",979195078,null);
eQ=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);fQ=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);gQ=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);hQ=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);iQ=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);jQ=new $CLJS.w(null,"or","or",1876275696,null);
jga=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);kga=new $CLJS.M(null,"constraints","constraints",422775616);lga=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);kQ=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);mga=new $CLJS.M(null,"csv-download","csv-download",2141432084);lQ=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
mQ=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);nga=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);oga=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);nQ=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);oQ=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
pQ=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);pga=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);qQ=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);qga=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);wQ=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);rga=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
sga=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);GQ=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);tga=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);uga=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);vga=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.IQ=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.KQ=new $CLJS.M(null,"database","database",1849087575);mR=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);nR=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);wga=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);yR=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
PR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.QR=new $CLJS.M(null,"expressions","expressions",255689909);VR=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);xga=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);rS=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
yga=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);sS=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);LS=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);MS=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);Dga=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);Ega=new $CLJS.M(null,"action","action",-811238024);
NS=new $CLJS.w(null,"get-day","get-day",1768100384,null);TS=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);YS=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);fT=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.gT=new $CLJS.M(null,"native","native",-613060878);Fga=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);hT=new $CLJS.M(null,"page","page",849072397);
iT=new $CLJS.w(null,"length","length",-2065447907,null);Gga=new $CLJS.M(null,"dashboard","dashboard",-631747508);jT=new $CLJS.w(null,"get-week","get-week",752472178,null);pT=new $CLJS.w(null,"get-month","get-month",1271156796,null);rT=new $CLJS.w(null,"dimension","dimension",-2111181571,null);sT=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);tT=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
uT=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);AT=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);BT=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Hga=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);CT=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);DT=new $CLJS.w(null,"substring","substring",-1513569493,null);
Jga=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);ET=new $CLJS.M(null,"internal","internal",-854870097);Kga=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);FT=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);GT=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
HT=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);Lga=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);IT=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);JT=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);Mga=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
Nga=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);KT=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);YT=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);ZT=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
Pga=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Qga=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);$T=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.aU=new $CLJS.M(null,"template-tags","template-tags",1853115685);Rga=new $CLJS.M(null,"public-question","public-question",629369976);Sga=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
bha=new $CLJS.M(null,"binary","binary",-1802232288);bU=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);fha=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.hU=new $CLJS.M(null,"source-query","source-query",198004422);kU=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);hha=new $CLJS.w(null,"SegmentID","SegmentID",-1773652704,null);
iha=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);lU=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);mU=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);jha=new $CLJS.M(null,"executed-by","executed-by",-739811161);kha=new $CLJS.w("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
lha=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);nU=new $CLJS.M(null,"amount","amount",364489504);oU=new $CLJS.w(null,"percentile","percentile",1039342775,null);pU=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);qU=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);rU=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
sU=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);xU=new $CLJS.w(null,"trim","trim",-1880116002,null);oha=new $CLJS.w(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);zU=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);AU=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);rha=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
BU=new $CLJS.M("string","\x3d","string/\x3d",983744235);uha=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);vha=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);wha=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);CU=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);DU=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
EU=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);xha=new $CLJS.M(null,"lat-min","lat-min",1630784161);FU=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);GU=new $CLJS.w(null,"inside","inside",-681932758,null);yha=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);HU=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
zha=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);IU=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);Aha=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Bha=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.JU=new $CLJS.M(null,"card-id","card-id",-1770060179);KU=new $CLJS.M(null,"variadic","variadic",882626057);
LU=new $CLJS.w(null,"upper","upper",1886775433,null);MU=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);NU=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);OU=new $CLJS.w(null,"optional","optional",-600484260,null);Cha=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);PU=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
QU=new $CLJS.M(null,"sugar","sugar",-73788488);Dha=new $CLJS.M(null,"lat-max","lat-max",841568226);RU=new $CLJS.w(null,"power","power",702679448,null);SU=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);TU=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);UU=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
VU=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);XU=new $CLJS.w(null,"median","median",-2084869638,null);YU=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);ZU=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);Eha=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);$U=new $CLJS.M(null,"y","y",-1757859776);aV=new $CLJS.w(null,"aggregation","aggregation",-1056959073,null);
$CLJS.bV=new $CLJS.M(null,"binning","binning",1709835866);cV=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);dV=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);eV=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);fV=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);gV=new $CLJS.w(null,"Reference","Reference",2024574086,null);hV=new $CLJS.M(null,"b","b",1482224470);
iV=new $CLJS.M(null,"a","a",-2123407586);jV=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);kV=new $CLJS.w(null,"replace","replace",853943757,null);lV=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Fha=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);mV=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
pV=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);qV=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);rV=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);sV=new $CLJS.w(null,"segment","segment",675610331,null);Gha=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);tV=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
uV=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);vV=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);wV=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Hha=new $CLJS.w(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.xV=new $CLJS.M(null,"order-by","order-by",1527318070);yV=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Iha=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Jha=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.EV=new $CLJS.M(null,"condition","condition",1668437652);FV=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Kha=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);GV=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Lha=new $CLJS.M(null,"card-name","card-name",-2035606807);HV=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Mha=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Nha=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);IV=new $CLJS.w(null,"log","log",45015523,null);Oha=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
JV=new $CLJS.w(null,"time","time",-1268547887,null);$CLJS.KV=new $CLJS.M(null,"database_type","database_type",-54700895);LV=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);MV=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.NV=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.OV=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.PV=new $CLJS.M("date","all-options","date/all-options",549325958);
Pha=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);QV=new $CLJS.M("location","state","location/state",-114378652);RV=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);Qha=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Rha=new $CLJS.M(null,"lon-field","lon-field",517872067);SV=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);
TV=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);UV=new $CLJS.M(null,"numeric","numeric",-1495594714);VV=new $CLJS.w(null,"variable","variable",1359185035,null);WV=new $CLJS.w(null,"lower","lower",-1534114948,null);XV=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);YV=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.ZV=new $CLJS.M(null,"limit","limit",-1355822363);
$V=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);aW=new $CLJS.w(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Sha=new $CLJS.M(null,"pulse","pulse",-244494476);var bW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.HF],null),cW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gG],null),dW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.xF],null),eW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pF],null),fW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.bG],null),gW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.OL],null),Tha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.FL],null),Uha=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.AL],null),hW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,
$CLJS.aJ],null),iW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eJ],null),jW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WI],null),rQ=new $CLJS.U(null,new $CLJS.k(null,12,[$CLJS.ib,null,$CLJS.uu,null,$CLJS.tj,null,$CLJS.Dj,null,$CLJS.Cf,null,$CLJS.xj,null,$CLJS.ak,null,$CLJS.Ej,null,$CLJS.db,null,$CLJS.Cj,null,$CLJS.jb,null,$CLJS.Ue,null],null),null),sQ=new $CLJS.U(null,new $CLJS.k(null,7,[$CLJS.tu,null,$CLJS.YH,null,$CLJS.Dj,null,$CLJS.xe,null,$CLJS.aI,null,$CLJS.pj,null,$CLJS.ru,null],null),null),
tQ=$CLJS.yy.h(rQ,sQ),$la=$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"date bucketing unit"],null)],null),rQ),ama=$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"time bucketing unit"],null)],null),sQ),uQ=$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"datetime bucketing unit"],null)],null),tQ),vQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TJ],null),Vha=new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,
1,[$CLJS.Pt,"temporal extract unit"],null),$CLJS.bI,$CLJS.Ue,$CLJS.ak,$CLJS.zJ,$CLJS.aK,$CLJS.bK,$CLJS.Ej,$CLJS.Cf,$CLJS.xe,$CLJS.pj,$CLJS.ZH],null),Wha=new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"datetime-diff unit"],null),$CLJS.YH,$CLJS.ru,$CLJS.tu,$CLJS.uu,$CLJS.tj,$CLJS.xj,$CLJS.ib,$CLJS.db],null),kW=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"temporal-extract week extraction mode"],null),$CLJS.tJ,$CLJS.FJ,$CLJS.MJ],null),xQ=new $CLJS.Q(null,
10,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"relative-datetime unit"],null),$CLJS.Dj,$CLJS.ru,$CLJS.tu,$CLJS.uu,$CLJS.tj,$CLJS.xj,$CLJS.ib,$CLJS.db],null),EQ,kma,NQ,OQ,PQ,QQ,RQ,SQ,XQ,PS,QS,RS,rma,sma,SS,Bma,tma,uma;$CLJS.oJ(tN,hM($CLJS.IJ,$CLJS.F(["n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.hv],null),$CLJS.Yr],null),"unit",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,xQ],null)])));var yQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tN],null);
$CLJS.oJ(YP,hM($CLJS.BJ,$CLJS.F(["n",$CLJS.Yr,"unit",xQ])));var gma=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YP],null);
$CLJS.oJ(ON,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Pt,"valid :absolute-datetime clause",$CLJS.Wf,function(a){if($CLJS.Bb(RL($CLJS.xJ,a)))a=NN;else{a=$CLJS.Vd(a);var b=$CLJS.EM(hW);a=b.g?b.g(a):b.call(null,a);a=$CLJS.m(a)?$CLJS.bQ:$CLJS.JJ}return a}],null),new $CLJS.Q(null,2,5,$CLJS.R,[NN,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"not an :absolute-datetime clause"],null),$CLJS.ag(!1)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bQ,hM($CLJS.xJ,
$CLJS.F(["date",hW,"unit",$la]))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JJ,hM($CLJS.xJ,$CLJS.F(["datetime",iW,"unit",uQ]))],null)],null));var zQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ON],null);$CLJS.oJ(cO,hM($CLJS.yx,$CLJS.F(["time",jW,"unit",ama])));var AQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cO],null),BQ=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.os,new $CLJS.k(null,1,[$CLJS.Pt,"date or datetime literal"],null),zQ,iW,hW],null);
$CLJS.oJ(NM,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.os,new $CLJS.k(null,1,[$CLJS.Pt,"time literal"],null),AQ,jW],null));$CLJS.oJ(SV,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.os,new $CLJS.k(null,1,[$CLJS.Pt,"temporal literal"],null),BQ,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NM],null)],null));var CQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,SV],null);
$CLJS.oJ(rha,bM($CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof zQ?new $CLJS.pd(function(){return zQ},$CLJS.I(uea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,ET,$CLJS.rj,$CLJS.jk,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MS,"metabase/mbql/schema.cljc",69,$CLJS.xJ,1,!0,168,168,$CLJS.ud,"Schema for an `:absolute-datetime` clause.",$CLJS.m(zQ)?zQ.I:null])):null));return $CLJS.m(a)?a:MS}(),zQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof yQ?new $CLJS.pd(function(){return yQ},$CLJS.I(Pha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,
$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",29,$CLJS.IJ,1,131,131,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.IJ,$CLJS.Gv,$CLJS.Y($CLJS.il,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.hv],null),$CLJS.Yr],null)),$CLJS.kb,$CLJS.Y($CLJS.il,$CLJS.Y(OU,tP))],null),$CLJS.ud,"Schema for a valid relative-datetime clause.",
$CLJS.m(yQ)?yQ.I:null])):null));return $CLJS.m(a)?a:WP}(),yQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof AQ?new $CLJS.pd(function(){return AQ},$CLJS.I(sga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,ET,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JV,"metabase/mbql/schema.cljc",27,$CLJS.yx,1,!0,175,175,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.yx,$CLJS.yx,$CLJS.Y($CLJS.il,oha),$CLJS.kb,$CLJS.Y($CLJS.il,Wea)],null),$CLJS.ud,"Schema for a valid time clause.",$CLJS.m(AQ)?AQ.I:null])):null));return $CLJS.m(a)?a:JV}(),AQ],null)])));
$CLJS.oJ(HV,hM($CLJS.Yk,$CLJS.F(["value",$CLJS.Fs,"type-info",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KV,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,bW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LD,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,cW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dP,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.ns,dW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kb,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,uQ],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.W,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,bW],null)],null)],null)],null)])));var DQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HV],null);$CLJS.oJ(cP,hM($CLJS.GB,$CLJS.F(["expression-name",bW,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.Wj],null)])));
EQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cP],null);
kma=function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.Of(b);b=$CLJS.K.h(c,$CLJS.iG);c=$CLJS.K.h(c,$CLJS.qF);return $CLJS.E.h(b,$CLJS.qF)?c:!0}],null)],null)}(function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.Of(b);b=$CLJS.K.h(c,$CLJS.iG);c=$CLJS.K.h(c,$CLJS.hG);return $CLJS.E.h(b,$CLJS.hG)?c:!0}],null)],null)}(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.k(null,1,[$CLJS.Pt,"binning options"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iG,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"binning strategy"],null),$CLJS.qF,$CLJS.hG,$CLJS.Dj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qF,new $CLJS.k(null,1,[$CLJS.Js,!0],null),eW],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.hG,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,$CLJS.Ab,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"bin width must be \x3e\x3d 0."],null),$CLJS.$f($CLJS.bf)],null)],null)],null)],null)));$CLJS.FQ=function FQ(a){switch(arguments.length){case 1:return FQ.g(arguments[0]);case 2:return FQ.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
$CLJS.FQ.g=function(a){var b=$CLJS.Of(a);a=$CLJS.K.h(b,$CLJS.JB);b=$CLJS.K.h(b,$CLJS.uH);return $CLJS.FQ.h(a,b)};$CLJS.FQ.h=function(a,b){a=$CLJS.m($CLJS.m(b)?a:b)?$CLJS.XF(a,$CLJS.yD)?rQ:$CLJS.XF(a,$CLJS.aF)?sQ:$CLJS.XF(a,$CLJS.kE)?tQ:null:null;return $CLJS.m(a)?$CLJS.Ee(a,b):!0};$CLJS.FQ.A=2;
$CLJS.oJ(PU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Wj,new $CLJS.k(null,1,[$CLJS.Pt,"field options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JB,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,cW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eO,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,gW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uH,new $CLJS.k(null,
1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,uQ],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UP,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,bW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bV,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,kma],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.FQ],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.$f($CLJS.iG)],null)],null));
$CLJS.oJ(tV,function(a){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.G(b,0,null);var c=$CLJS.G(b,1,null);b=$CLJS.G(b,2,null);b=$CLJS.Of(b);b=$CLJS.K.h(b,$CLJS.JB);return"string"===typeof c?b:!0}],null)],null)}(hM($CLJS.oH,$CLJS.F(["id-or-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,gW,bW],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PU],null)],null)]))));var HQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tV],null);
$CLJS.oJ(kQ,bM($CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof EQ?new $CLJS.pd(function(){return EQ},$CLJS.I(oO,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZN,"metabase/mbql/schema.cljc",60,$CLJS.GB,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.GB,$CLJS.OV,$CLJS.Y($CLJS.il,vN),$CLJS.Qi,$CLJS.Y($CLJS.il,$CLJS.Y(OU,$CLJS.Wj))],null),$CLJS.ud,"Schema for a valid expression clause.",$CLJS.m(EQ)?EQ.I:null])):null));return $CLJS.m(a)?a:ZN}(),EQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof HQ?new $CLJS.pd(function(){return HQ},
$CLJS.I(GO,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.Qq,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,$CLJS.lb,$CLJS.Xj,$CLJS.fk],["0.39.0",$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HN,"metabase/mbql/schema.cljc",51,$CLJS.oH,1,382,382,$CLJS.ud,"Schema for a `:field` clause.",$CLJS.m(HQ)?HQ.I:null])):null));return $CLJS.m(a)?a:HN}(),HQ],null)])));
$CLJS.JQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kQ],null);$CLJS.oJ(HM,hM($CLJS.dH,$CLJS.F(["aggregation-clause-index",$CLJS.Yr,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.Wj],null)])));var lma=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HM],null);
$CLJS.oJ(pN,bM($CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof lma?new $CLJS.pd(function(){return lma},$CLJS.I(kha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aV,"metabase/mbql/schema.cljc",23,$CLJS.dH,1,418,418,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.dH,Qha,$CLJS.Y($CLJS.il,$CLJS.Yr),$CLJS.Qi,$CLJS.Y($CLJS.il,$CLJS.Y(OU,$CLJS.Wj))],null),$CLJS.ud,"Schema for a valid aggregation clause.",$CLJS.m(lma)?lma.I:null])):null));return $CLJS.m(a)?a:aV}(),lma],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof EQ?new $CLJS.pd(function(){return EQ},
$CLJS.I(oO,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZN,"metabase/mbql/schema.cljc",60,$CLJS.GB,1,249,249,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.GB,$CLJS.OV,$CLJS.Y($CLJS.il,vN),$CLJS.Qi,
$CLJS.Y($CLJS.il,$CLJS.Y(OU,$CLJS.Wj))],null),$CLJS.ud,"Schema for a valid expression clause.",$CLJS.m(EQ)?EQ.I:null])):null));return $CLJS.m(a)?a:ZN}(),EQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof HQ?new $CLJS.pd(function(){return HQ},$CLJS.I(GO,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.Qq,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,$CLJS.lb,
$CLJS.Xj,$CLJS.fk],["0.39.0",$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HN,"metabase/mbql/schema.cljc",51,$CLJS.oH,1,382,382,$CLJS.ud,"Schema for a `:field` clause.",$CLJS.m(HQ)?HQ.I:null])):null));return $CLJS.m(a)?a:HN}(),HQ],null)])));
var lW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pN],null),LQ=new $CLJS.U(null,new $CLJS.k(null,11,[$CLJS.Ut,null,$CLJS.HH,null,$CLJS.IG,null,$CLJS.NH,null,$CLJS.NG,null,$CLJS.rH,null,$CLJS.XG,null,$CLJS.cu,null,$CLJS.FH,null,$CLJS.qH,null,$CLJS.OH,null],null),null),MQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mN],null);
$CLJS.oJ(PM,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,1,[$CLJS.Wf,function(a){return"string"===typeof a?$CLJS.ql:$CLJS.m(RL(LQ,a))?iN:$CLJS.m(RL($CLJS.Yk,a))?$CLJS.Yk:$CLJS.Wt}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ql,$CLJS.ql],null),new $CLJS.Q(null,2,5,$CLJS.R,[iN,MQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yk,DQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wt,$CLJS.JQ],null)],null));NQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PM],null);
OQ=new $CLJS.U(null,new $CLJS.k(null,26,[$CLJS.KG,null,$CLJS.$J,null,$CLJS.cs,null,$CLJS.UJ,null,$CLJS.RJ,null,$CLJS.bH,null,$CLJS.Ft,null,$CLJS.HG,null,$CLJS.XJ,null,$CLJS.OJ,null,$CLJS.rH,null,$CLJS.KH,null,$CLJS.SJ,null,$CLJS.tH,null,$CLJS.LG,null,$CLJS.vJ,null,$CLJS.bs,null,$CLJS.OG,null,$CLJS.XG,null,$CLJS.fJ,null,$CLJS.rw,null,$CLJS.wJ,null,$CLJS.NJ,null,$CLJS.AJ,null,$CLJS.MH,null,$CLJS.mH,null],null),null);
PQ=new $CLJS.U(null,new $CLJS.k(null,9,[$CLJS.Wr,null,$CLJS.Tr,null,$CLJS.os,null,$CLJS.qs,null,$CLJS.Ur,null,$CLJS.GH,null,$CLJS.vs,null,$CLJS.Zr,null,$CLJS.Vr,null],null),null);QQ=new $CLJS.U(null,new $CLJS.k(null,17,[$CLJS.bk,null,$CLJS.EH,null,$CLJS.xH,null,$CLJS.pH,null,$CLJS.JH,null,$CLJS.LH,null,$CLJS.TG,null,$CLJS.$G,null,$CLJS.UM,null,$CLJS.UG,null,$CLJS.CH,null,$CLJS.vH,null,$CLJS.xl,null,$CLJS.Aw,null,$CLJS.iH,null,$CLJS.jH,null,$CLJS.BH,null],null),null);
RQ=new $CLJS.U(null,new $CLJS.k(null,5,[$CLJS.KJ,null,$CLJS.hJ,null,$CLJS.YJ,null,$CLJS.bs,null,$CLJS.QJ,null],null),null);SQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HU],null);XQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aO],null);$CLJS.VQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KT],null);$CLJS.TQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NU],null);
$CLJS.oJ(UU,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Pt,"numeric expression argument",$CLJS.Wf,function(a){return"number"===typeof a?$CLJS.nu:$CLJS.m(RL(OQ,a))?EP:$CLJS.m(RL(QQ,a))?$CLJS.dH:$CLJS.m(RL($CLJS.Yk,a))?$CLJS.Yk:$CLJS.oH}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nu,$CLJS.Ab],null),new $CLJS.Q(null,2,5,$CLJS.R,[EP,SQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dH,$CLJS.TQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yk,DQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oH,
$CLJS.JQ],null)],null));var UQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,UU],null);
$CLJS.oJ(FT,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Pt,"datetime expression argument",$CLJS.Wf,function(a){return $CLJS.m(RL(QQ,a))?$CLJS.dH:$CLJS.m(RL($CLJS.Yk,a))?$CLJS.Yk:$CLJS.m(RL(RQ,a))?hO:$CLJS.Wt}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dH,$CLJS.TQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yk,DQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[hO,$CLJS.VQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wt,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,BQ,$CLJS.JQ],null)],null)],null));
var WQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FT],null);
$CLJS.oJ(VR,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Pt,"expression argument",$CLJS.Wf,function(a){return"number"===typeof a?$CLJS.nu:$CLJS.qe(a)?$CLJS.Ds:$CLJS.m(RL(PQ,a))?sT:$CLJS.m(RL(OQ,a))?EP:$CLJS.m(RL(RQ,a))?hO:"string"===typeof a?$CLJS.ql:$CLJS.m(RL(LQ,a))?iN:$CLJS.m(RL($CLJS.Yk,a))?$CLJS.Yk:$CLJS.Wt}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nu,$CLJS.Ab],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ds,$CLJS.Ds],null),new $CLJS.Q(null,2,5,$CLJS.R,[sT,XQ],null),new $CLJS.Q(null,
2,5,$CLJS.R,[EP,SQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[hO,$CLJS.VQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ql,$CLJS.ql],null),new $CLJS.Q(null,2,5,$CLJS.R,[iN,MQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yk,DQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wt,$CLJS.JQ],null)],null));var YQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VR],null);$CLJS.oJ(PR,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.os,new $CLJS.k(null,1,[$CLJS.Pt,"numeric expression arg or interval"],null),gma,UQ],null));
var ZQ=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PR],null);$CLJS.oJ($M,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Pt,"int greater than zero or numeric expression",$CLJS.Wf,function(a){return"number"===typeof a?$CLJS.nu:$CLJS.Wt}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nu,eW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wt,SQ],null)],null));var mma=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$M],null);
$CLJS.oJ(iO,hM($CLJS.rH,$CLJS.F(["a",YQ,"b",YQ,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf,YQ],null)])));var $Q=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iO],null);$CLJS.oJ(MM,hM($CLJS.IG,$CLJS.F(["s",NQ,"start",mma,"length",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,UQ],null)])));var aR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MM],null);$CLJS.oJ(tT,hM($CLJS.rw,$CLJS.F(["s",NQ])));var bR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tT],null);$CLJS.oJ(oN,hM($CLJS.FH,$CLJS.F(["s",NQ])));
var cR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,oN],null);$CLJS.oJ(sN,hM($CLJS.OH,$CLJS.F(["s",NQ])));var dR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,sN],null);$CLJS.oJ(LN,hM($CLJS.HH,$CLJS.F(["s",NQ])));var eR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LN],null);$CLJS.oJ(wO,hM($CLJS.Ut,$CLJS.F(["s",NQ])));var fR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,wO],null);$CLJS.oJ(eN,hM($CLJS.cu,$CLJS.F(["s",NQ])));var gR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eN],null);
$CLJS.oJ(TU,hM($CLJS.NH,$CLJS.F(["s",NQ,"match",$CLJS.ql,"replacement",$CLJS.ql])));var hR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TU],null);$CLJS.oJ(XV,hM($CLJS.NG,$CLJS.F(["a",NQ,"b",NQ,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf,NQ],null)])));var iR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,XV],null);$CLJS.oJ(lP,hM($CLJS.qH,$CLJS.F(["s",NQ,"pattern",$CLJS.ql])));var jR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lP],null);
$CLJS.oJ(rV,hM($CLJS.bs,$CLJS.F(["x",ZQ,"y",ZQ,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf,ZQ],null)])));var kR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rV],null);$CLJS.oJ(pV,hM($CLJS.Ft,$CLJS.F(["x",UQ,"y",ZQ,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf,ZQ],null)])));var lR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pV],null);$CLJS.oJ(mV,hM($CLJS.KH,$CLJS.F(["x",UQ,"y",UQ,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf,UQ],null)])));var oR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mV],null);
$CLJS.oJ(qV,hM($CLJS.cs,$CLJS.F(["x",UQ,"y",UQ,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf,UQ],null)])));var pR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,qV],null);$CLJS.oJ(yR,hM($CLJS.mH,$CLJS.F(["x",UQ])));var qR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,yR],null);$CLJS.oJ(rU,hM($CLJS.KG,$CLJS.F(["x",UQ])));var rR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rU],null);$CLJS.oJ($V,hM($CLJS.tH,$CLJS.F(["x",UQ])));var sR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$V],null);$CLJS.oJ(CP,hM($CLJS.OG,$CLJS.F(["x",UQ])));
var tR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CP],null);$CLJS.oJ(IP,hM($CLJS.LG,$CLJS.F(["x",UQ,"y",UQ])));var uR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IP],null);$CLJS.oJ(eQ,hM($CLJS.bH,$CLJS.F(["x",UQ])));var vR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eQ],null);$CLJS.oJ(aN,hM($CLJS.HG,$CLJS.F(["x",UQ])));var wR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aN],null);$CLJS.oJ(LS,hM($CLJS.MH,$CLJS.F(["x",UQ])));var xR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LS],null);
$CLJS.oJ(BN,hM($CLJS.AJ,$CLJS.F(["datetime-x",WQ,"datetime-y",WQ,"unit",Wha])));var zR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BN],null);$CLJS.oJ(TN,hM($CLJS.UJ,$CLJS.F(["datetime",WQ,"unit",Vha,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,kW],null)])));var AR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TN],null);$CLJS.oJ(KP,hM($CLJS.wJ,$CLJS.F(["date",WQ])));var BR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KP],null);$CLJS.oJ(lQ,hM($CLJS.$J,$CLJS.F(["date",WQ])));
var CR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lQ],null);$CLJS.oJ(OM,hM($CLJS.fJ,$CLJS.F(["date",WQ])));var DR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OM],null);$CLJS.oJ(YV,hM($CLJS.OJ,$CLJS.F(["date",WQ,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,kW],null)])));var ER=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YV],null);$CLJS.oJ(XO,hM($CLJS.NJ,$CLJS.F(["date",WQ])));var FR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,XO],null);$CLJS.oJ(fQ,hM($CLJS.SJ,$CLJS.F(["date",WQ])));
var GR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fQ],null);$CLJS.oJ(xN,hM($CLJS.XJ,$CLJS.F(["datetime",WQ])));var HR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,xN],null);$CLJS.oJ(bP,hM($CLJS.RJ,$CLJS.F(["datetime",WQ])));var IR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bP],null);$CLJS.oJ(qN,hM($CLJS.vJ,$CLJS.F(["datetime",WQ])));var JR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,qN],null);$CLJS.oJ(HO,hM($CLJS.hJ,$CLJS.F(["datetime",WQ,"to",vQ,"from",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,vQ],null)])));
var KR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HO],null),LR=new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"datetime arithmetic unit"],null),$CLJS.aI,$CLJS.YH,$CLJS.ru,$CLJS.tu,$CLJS.uu,$CLJS.tj,$CLJS.xj,$CLJS.ib,$CLJS.db],null);$CLJS.oJ(LM,hM($CLJS.QJ,$CLJS.F(["datetime",WQ,"amount",UQ,"unit",LR])));var MR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LM],null);
$CLJS.oJ(jN,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return hM(arguments[0],1<b.length?new $CLJS.rd(b.slice(1),0,null):null)}($CLJS.KJ));var NR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jN],null);$CLJS.oJ(KM,hM($CLJS.YJ,$CLJS.F(["datetime",WQ,"amount",UQ,"unit",LR])));var OR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KM],null);
$CLJS.oJ(KT,bM($CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof kR?new $CLJS.pd(function(){return kR},$CLJS.I(wQ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fP,"metabase/mbql/schema.cljc",51,$CLJS.bs,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.bs,$CLJS.fG,$CLJS.Y($CLJS.il,mR),$U,$CLJS.Y($CLJS.il,mR),jO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,mR))],null),$CLJS.ud,"Schema for a valid + clause.",$CLJS.m(kR)?kR.I:null])):null));return $CLJS.m(a)?a:fP}(),kR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof MR?new $CLJS.pd(function(){return MR},
$CLJS.I(cga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[AO,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",67,$CLJS.QJ,1,703,703,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.QJ,$CLJS.JJ,$CLJS.Y($CLJS.il,TV),nU,$CLJS.Y($CLJS.il,
sU),$CLJS.kb,$CLJS.Y($CLJS.il,FU)],null),$CLJS.ud,"Schema for a valid datetime-add clause.",$CLJS.m(MR)?MR.I:null])):null));return $CLJS.m(a)?a:mQ}(),MR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof OR?new $CLJS.pd(function(){return OR},$CLJS.I(Wda,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,
$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[AO,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RV,"metabase/mbql/schema.cljc",72,$CLJS.YJ,1,710,710,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.YJ,$CLJS.JJ,$CLJS.Y($CLJS.il,TV),nU,$CLJS.Y($CLJS.il,sU),$CLJS.kb,$CLJS.Y($CLJS.il,FU)],null),$CLJS.ud,"Schema for a valid datetime-subtract clause.",$CLJS.m(OR)?OR.I:null])):null));
return $CLJS.m(a)?a:RV}(),OR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof KR?new $CLJS.pd(function(){return KR},$CLJS.I(zha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.hJ,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gO,"metabase/mbql/schema.cljc",71,$CLJS.hJ,1,695,695,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.hJ,$CLJS.JJ,$CLJS.Y($CLJS.il,TV),Vfa,$CLJS.Y($CLJS.il,cV),$da,$CLJS.Y($CLJS.il,$CLJS.Y(OU,cV))],null),$CLJS.ud,"Schema for a valid convert-timezone clause.",$CLJS.m(KR)?KR.I:null])):null));return $CLJS.m(a)?a:gO}(),KR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&
"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof NR?new $CLJS.pd(function(){return NR},$CLJS.I(Hga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.KJ,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",
45,$CLJS.KJ,1,708,708,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.KJ],null),$CLJS.ud,"Schema for a valid now clause.",$CLJS.m(NR)?NR.I:null])):null));return $CLJS.m(a)?a:LP}(),NR],null)])));$CLJS.RR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hQ],null);$CLJS.oJ(QM,hM($CLJS.vs,$CLJS.F(["first-clause",$CLJS.RR,"second-clause",$CLJS.RR,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf,$CLJS.RR],null)])));var SR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,QM],null);
$CLJS.oJ(DO,hM($CLJS.os,$CLJS.F(["first-clause",$CLJS.RR,"second-clause",$CLJS.RR,"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf,$CLJS.RR],null)])));var TR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DO],null);$CLJS.oJ(lN,hM($CLJS.qs,$CLJS.F(["clause",$CLJS.RR])));
var UR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lN],null),mW=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,3,[$CLJS.Pt,":field or :expression reference or :relative-datetime",$CLJS.Bt,$CLJS.ag(":field or :expression reference or :relative-datetime"),$CLJS.Wf,function(a){return $CLJS.m(RL($CLJS.IJ,a))?$CLJS.IJ:$CLJS.Wt}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IJ,yQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wt,$CLJS.JQ],null)],null);
$CLJS.oJ(HT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ns,new $CLJS.k(null,1,[$CLJS.Pt,"equality comparable"],null),new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.os,$CLJS.Ds,$CLJS.Ab,$CLJS.ql,CQ,mW,YQ,DQ],null)],null));var WR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HT],null);
$CLJS.oJ(qP,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Pt,"order comparable",$CLJS.Wf,function(a){return $CLJS.m(RL($CLJS.Yk,a))?$CLJS.Yk:$CLJS.Wt}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yk,DQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wt,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.os,$CLJS.Ab,$CLJS.ql,CQ,YQ,mW],null)],null)],null));var YR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,qP],null);
$CLJS.oJ(fV,hM($CLJS.Zr,$CLJS.F(["field",WR,"value-or-field",WR,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf,WR],null)])));var ZR=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,fV],null);$CLJS.oJ(jV,hM($CLJS.GH,$CLJS.F(["field",WR,"value-or-field",WR,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf,WR],null)])));var $R=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,jV],null);$CLJS.oJ(eV,hM($CLJS.Vr,$CLJS.F(["field",YR,"value-or-field",YR])));
var aS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eV],null);$CLJS.oJ(dV,hM($CLJS.Tr,$CLJS.F(["field",YR,"value-or-field",YR])));var bS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,dV],null);$CLJS.oJ(MN,hM($CLJS.Wr,$CLJS.F(["field",YR,"value-or-field",YR])));var cS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,MN],null);$CLJS.oJ(SN,hM($CLJS.Ur,$CLJS.F(["field",YR,"value-or-field",YR])));var dS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,SN],null);$CLJS.oJ(YS,hM($CLJS.QG,$CLJS.F(["field",YR,"min",YR,"max",YR])));
var eS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YS],null);$CLJS.oJ(kU,hM($CLJS.WG,$CLJS.F(["lat-field",YR,"lon-field",YR,"lat-max",YR,"lon-min",YR,"lat-min",YR,"lon-max",YR])));var fS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kU],null);$CLJS.oJ(vO,hM($CLJS.PG,$CLJS.F(["field",$CLJS.JQ])));var gS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vO],null);$CLJS.oJ(kP,hM($CLJS.aH,$CLJS.F(["field",$CLJS.JQ])));var hS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kP],null);$CLJS.oJ(NO,hM($CLJS.RG,$CLJS.F(["field",$CLJS.JQ])));
var iS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,NO],null);$CLJS.oJ(TM,hM($CLJS.YG,$CLJS.F(["field",$CLJS.JQ])));var jS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TM],null),kS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wH,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null)],null);$CLJS.oJ($O,hM($CLJS.fH,$CLJS.F(["field",NQ,"string-or-field",NQ,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,kS],null)])));var lS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$O],null);
$CLJS.oJ(TP,hM($CLJS.zH,$CLJS.F(["field",NQ,"string-or-field",NQ,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,kS],null)])));var mS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TP],null);$CLJS.oJ(LO,hM($CLJS.nH,$CLJS.F(["field",NQ,"string-or-field",NQ,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,kS],null)])));var nS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LO],null);$CLJS.oJ(rS,hM($CLJS.gH,$CLJS.F(["field",NQ,"string-or-field",NQ,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,kS],null)])));
var oS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rS],null);$CLJS.oJ(bN,hM($CLJS.AH,$CLJS.F(["field",$CLJS.JQ,"n",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,$CLJS.Yr,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ks,$CLJS.hv,$CLJS.Lw,$CLJS.VG],null)],null),"unit",xQ,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DH,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null)],null)],null)])));var pS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bN],null);
$CLJS.oJ(AT,hM($CLJS.MG,$CLJS.F(["segment-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.yL],null),bW],null)])));var qS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,AT],null);
$CLJS.oJ(aO,bM($CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof SR?new $CLJS.pd(function(){return SR},$CLJS.I(SU,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uP,"metabase/mbql/schema.cljc",15,$CLJS.vs,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.vs,kO,$CLJS.Y($CLJS.il,zP),cQ,$CLJS.Y($CLJS.il,zP),uO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,zP))],null),$CLJS.ud,"Schema for a valid and clause.",$CLJS.m(SR)?SR.I:null])):null));return $CLJS.m(a)?a:uP}(),SR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof TR?new $CLJS.pd(function(){return TR},
$CLJS.I(gQ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",14,$CLJS.os,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.os,kO,$CLJS.Y($CLJS.il,zP),cQ,$CLJS.Y($CLJS.il,zP),uO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,zP))],null),$CLJS.ud,
"Schema for a valid or clause.",$CLJS.m(TR)?TR.I:null])):null));return $CLJS.m(a)?a:jQ}(),TR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof UR?new $CLJS.pd(function(){return UR},$CLJS.I(BT,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MP,"metabase/mbql/schema.cljc",15,$CLJS.qs,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qs,$CLJS.NV,$CLJS.Y($CLJS.il,zP)],null),$CLJS.ud,"Schema for a valid not clause.",$CLJS.m(UR)?UR.I:null])):null));return $CLJS.m(a)?a:MP}(),UR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof aS?new $CLJS.pd(function(){return aS},$CLJS.I(pQ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,
$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",13,$CLJS.Vr,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Vr,$CLJS.oH,$CLJS.Y($CLJS.il,mO),DU,$CLJS.Y($CLJS.il,mO)],null),$CLJS.ud,"Schema for a valid \x3c clause.",$CLJS.m(aS)?aS.I:null])):null));return $CLJS.m(a)?a:nP}(),aS],null),
new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof cS?new $CLJS.pd(function(){return cS},$CLJS.I(GV,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mP,"metabase/mbql/schema.cljc",14,$CLJS.Wr,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wr,$CLJS.oH,$CLJS.Y($CLJS.il,mO),DU,$CLJS.Y($CLJS.il,mO)],null),$CLJS.ud,"Schema for a valid \x3c\x3d clause.",$CLJS.m(cS)?cS.I:null])):null));return $CLJS.m(a)?a:mP}(),cS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof bS?new $CLJS.pd(function(){return bS},$CLJS.I(nQ,new $CLJS.k(null,
1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",13,$CLJS.Tr,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Tr,$CLJS.oH,$CLJS.Y($CLJS.il,mO),DU,$CLJS.Y($CLJS.il,mO)],null),$CLJS.ud,"Schema for a valid \x3e clause.",$CLJS.m(bS)?bS.I:null])):
null));return $CLJS.m(a)?a:oP}(),bS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof dS?new $CLJS.pd(function(){return dS},$CLJS.I(uV,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vP,"metabase/mbql/schema.cljc",14,$CLJS.Ur,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ur,$CLJS.oH,$CLJS.Y($CLJS.il,mO),DU,$CLJS.Y($CLJS.il,mO)],null),$CLJS.ud,"Schema for a valid \x3e\x3d clause.",$CLJS.m(dS)?dS.I:null])):null));return $CLJS.m(a)?a:vP}(),dS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof ZR?new $CLJS.pd(function(){return ZR},$CLJS.I(oQ,new $CLJS.k(null,
1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",13,$CLJS.Zr,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Zr,$CLJS.oH,$CLJS.Y($CLJS.il,LV),DU,$CLJS.Y($CLJS.il,LV),IT,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,LV))],null),$CLJS.ud,"Schema for a valid \x3d clause.",
$CLJS.m(ZR)?ZR.I:null])):null));return $CLJS.m(a)?a:pP}(),ZR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof $R?new $CLJS.pd(function(){return $R},$CLJS.I(CN,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YN,"metabase/mbql/schema.cljc",14,$CLJS.GH,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.GH,$CLJS.oH,$CLJS.Y($CLJS.il,LV),DU,$CLJS.Y($CLJS.il,LV),IT,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,LV))],null),$CLJS.ud,"Schema for a valid !\x3d clause.",$CLJS.m($R)?$R.I:null])):null));return $CLJS.m(a)?a:YN}(),$R],null)])));
$CLJS.oJ(hQ,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Pt,"valid filter expression",$CLJS.Wf,function(a){return $CLJS.m(RL(RQ,a))?$CLJS.JJ:$CLJS.m(RL(OQ,a))?UV:$CLJS.m(RL(LQ,a))?$CLJS.ql:$CLJS.m(RL(PQ,a))?$CLJS.Ds:$CLJS.Wt}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JJ,$CLJS.VQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[UV,SQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ql,MQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ds,XQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wt,bM($CLJS.F([new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof SR?new $CLJS.pd(function(){return SR},$CLJS.I(SU,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",
15,$CLJS.vs,1,724,724,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.vs,kO,$CLJS.Y($CLJS.il,zP),cQ,$CLJS.Y($CLJS.il,zP),uO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,zP))],null),$CLJS.ud,"Schema for a valid and clause.",$CLJS.m(SR)?SR.I:null])):null));return $CLJS.m(a)?a:uP}(),SR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof TR?new $CLJS.pd(function(){return TR},$CLJS.I(gQ,new $CLJS.k(null,1,[$CLJS.Qj,
!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",14,$CLJS.os,1,729,729,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.os,kO,$CLJS.Y($CLJS.il,zP),cQ,$CLJS.Y($CLJS.il,zP),uO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,zP))],null),$CLJS.ud,"Schema for a valid or clause.",$CLJS.m(TR)?
TR.I:null])):null));return $CLJS.m(a)?a:jQ}(),TR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof UR?new $CLJS.pd(function(){return UR},$CLJS.I(BT,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MP,"metabase/mbql/schema.cljc",15,$CLJS.qs,1,734,734,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qs,$CLJS.NV,$CLJS.Y($CLJS.il,zP)],null),$CLJS.ud,"Schema for a valid not clause.",$CLJS.m(UR)?UR.I:null])):null));return $CLJS.m(a)?a:MP}(),UR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof ZR?new $CLJS.pd(function(){return ZR},$CLJS.I(oQ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,
$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",13,$CLJS.Zr,1,795,795,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Zr,$CLJS.oH,$CLJS.Y($CLJS.il,LV),DU,$CLJS.Y($CLJS.il,LV),IT,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,LV))],null),$CLJS.ud,"Schema for a valid \x3d clause.",$CLJS.m(ZR)?ZR.I:null])):
null));return $CLJS.m(a)?a:pP}(),ZR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof $R?new $CLJS.pd(function(){return $R},$CLJS.I(CN,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YN,"metabase/mbql/schema.cljc",14,$CLJS.GH,1,796,796,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.GH,$CLJS.oH,$CLJS.Y($CLJS.il,LV),DU,$CLJS.Y($CLJS.il,LV),IT,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,LV))],null),$CLJS.ud,"Schema for a valid !\x3d clause.",$CLJS.m($R)?$R.I:null])):null));return $CLJS.m(a)?a:YN}(),$R],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof aS?new $CLJS.pd(function(){return aS},
$CLJS.I(pQ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",13,$CLJS.Vr,1,798,798,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Vr,$CLJS.oH,$CLJS.Y($CLJS.il,mO),DU,$CLJS.Y($CLJS.il,mO)],null),$CLJS.ud,"Schema for a valid \x3c clause.",
$CLJS.m(aS)?aS.I:null])):null));return $CLJS.m(a)?a:nP}(),aS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof bS?new $CLJS.pd(function(){return bS},$CLJS.I(nQ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),oP,"metabase/mbql/schema.cljc",13,$CLJS.Tr,1,799,799,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Tr,$CLJS.oH,$CLJS.Y($CLJS.il,mO),DU,$CLJS.Y($CLJS.il,mO)],null),$CLJS.ud,"Schema for a valid \x3e clause.",$CLJS.m(bS)?bS.I:null])):null));return $CLJS.m(a)?a:oP}(),bS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof cS?new $CLJS.pd(function(){return cS},$CLJS.I(GV,new $CLJS.k(null,1,
[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mP,"metabase/mbql/schema.cljc",14,$CLJS.Wr,1,800,800,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wr,$CLJS.oH,$CLJS.Y($CLJS.il,mO),DU,$CLJS.Y($CLJS.il,mO)],null),$CLJS.ud,"Schema for a valid \x3c\x3d clause.",$CLJS.m(cS)?cS.I:null])):
null));return $CLJS.m(a)?a:mP}(),cS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof dS?new $CLJS.pd(function(){return dS},$CLJS.I(uV,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vP,"metabase/mbql/schema.cljc",14,$CLJS.Ur,1,801,801,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ur,$CLJS.oH,$CLJS.Y($CLJS.il,mO),DU,$CLJS.Y($CLJS.il,mO)],null),$CLJS.ud,"Schema for a valid \x3e\x3d clause.",$CLJS.m(dS)?dS.I:null])):null));return $CLJS.m(a)?a:vP}(),dS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof eS?new $CLJS.pd(function(){return eS},$CLJS.I(uga,new $CLJS.k(null,
1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EN,"metabase/mbql/schema.cljc",19,$CLJS.QG,1,804,804,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.QG,$CLJS.oH,$CLJS.Y($CLJS.il,mO),$CLJS.bk,$CLJS.Y($CLJS.il,mO),$CLJS.xl,$CLJS.Y($CLJS.il,mO)],null),$CLJS.ud,"Schema for a valid between clause.",
$CLJS.m(eS)?eS.I:null])):null));return $CLJS.m(a)?a:EN}(),eS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof lS?new $CLJS.pd(function(){return lS},$CLJS.I(wga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IU,"metabase/mbql/schema.cljc",23,$CLJS.fH,1,829,829,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.fH,$CLJS.oH,$CLJS.Y($CLJS.il,CU),XN,$CLJS.Y($CLJS.il,CU),$CLJS.Qi,$CLJS.Y($CLJS.il,$CLJS.Y(OU,uT))],null),$CLJS.ud,"Schema for a valid starts-with clause.",$CLJS.m(lS)?lS.I:null])):null));return $CLJS.m(a)?a:IU}(),lS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof mS?new $CLJS.pd(function(){return mS},
$CLJS.I(xea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bO,"metabase/mbql/schema.cljc",21,$CLJS.zH,1,830,830,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.zH,$CLJS.oH,$CLJS.Y($CLJS.il,CU),XN,$CLJS.Y($CLJS.il,CU),$CLJS.Qi,$CLJS.Y($CLJS.il,$CLJS.Y(OU,uT))],null),
$CLJS.ud,"Schema for a valid ends-with clause.",$CLJS.m(mS)?mS.I:null])):null));return $CLJS.m(a)?a:bO}(),mS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof nS?new $CLJS.pd(function(){return nS},$CLJS.I(Jea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,
1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",20,$CLJS.nH,1,831,831,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.nH,$CLJS.oH,$CLJS.Y($CLJS.il,CU),XN,$CLJS.Y($CLJS.il,CU),$CLJS.Qi,$CLJS.Y($CLJS.il,$CLJS.Y(OU,uT))],null),$CLJS.ud,"Schema for a valid contains clause.",$CLJS.m(nS)?nS.I:null])):null));return $CLJS.m(a)?a:nO}(),nS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==
typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof oS?new $CLJS.pd(function(){return oS},$CLJS.I(Nha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RM,"metabase/mbql/schema.cljc",36,$CLJS.gH,1,834,834,!0,new $CLJS.Q(null,
7,5,$CLJS.R,[$CLJS.gH,$CLJS.oH,$CLJS.Y($CLJS.il,CU),XN,$CLJS.Y($CLJS.il,CU),$CLJS.Qi,$CLJS.Y($CLJS.il,$CLJS.Y(OU,uT))],null),$CLJS.ud,"Schema for a valid does-not-contain clause.",$CLJS.m(oS)?oS.I:null])):null));return $CLJS.m(a)?a:RM}(),oS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof fS?new $CLJS.pd(function(){return fS},$CLJS.I(wha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),
$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GU,"metabase/mbql/schema.cljc",26,$CLJS.WG,1,807,807,!0,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.WG,fea,$CLJS.Y($CLJS.il,mO),Rha,$CLJS.Y($CLJS.il,mO),Dha,$CLJS.Y($CLJS.il,mO),iea,$CLJS.Y($CLJS.il,mO),xha,$CLJS.Y($CLJS.il,mO),eea,$CLJS.Y($CLJS.il,
mO)],null),$CLJS.ud,"Schema for a valid inside clause.",$CLJS.m(fS)?fS.I:null])):null));return $CLJS.m(a)?a:GU}(),fS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof iS?new $CLJS.pd(function(){return iS},$CLJS.I(Kea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,
1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",28,$CLJS.RG,1,821,821,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RG,$CLJS.oH,$CLJS.Y($CLJS.il,fO)],null),$CLJS.ud,"Schema for a valid is-empty clause.",$CLJS.m(iS)?iS.I:null])):null));return $CLJS.m(a)?a:VP}(),iS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&
"undefined"!==typeof jS?new $CLJS.pd(function(){return jS},$CLJS.I(Pga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fT,"metabase/mbql/schema.cljc",29,$CLJS.YG,1,822,822,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YG,$CLJS.oH,$CLJS.Y($CLJS.il,fO)],null),
$CLJS.ud,"Schema for a valid not-empty clause.",$CLJS.m(jS)?jS.I:null])):null));return $CLJS.m(a)?a:fT}(),jS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof gS?new $CLJS.pd(function(){return gS},$CLJS.I(Iha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,
1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",27,$CLJS.PG,1,816,816,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PG,$CLJS.oH,$CLJS.Y($CLJS.il,fO)],null),$CLJS.ud,"Schema for a valid is-null clause.",$CLJS.m(gS)?gS.I:null])):null));return $CLJS.m(a)?a:tO}(),gS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&
"undefined"!==typeof hS?new $CLJS.pd(function(){return hS},$CLJS.I(Xfa,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JN,"metabase/mbql/schema.cljc",28,$CLJS.aH,1,817,817,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aH,$CLJS.oH,$CLJS.Y($CLJS.il,fO)],null),
$CLJS.ud,"Schema for a valid not-null clause.",$CLJS.m(hS)?hS.I:null])):null));return $CLJS.m(a)?a:JN}(),hS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof pS?new $CLJS.pd(function(){return pS},$CLJS.I(Xda,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,
1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bU,"metabase/mbql/schema.cljc",33,$CLJS.AH,1,856,856,!0,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.AH,$CLJS.oH,$CLJS.Y($CLJS.il,fO),$CLJS.Gv,$CLJS.Y($CLJS.il,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,$CLJS.Yr,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ks,$CLJS.hv,$CLJS.Lw,$CLJS.VG],null)],null)),$CLJS.kb,$CLJS.Y($CLJS.il,tP),$CLJS.Qi,$CLJS.Y($CLJS.il,$CLJS.Y(OU,Dga))],null),$CLJS.ud,"Schema for a valid time-interval clause.",
$CLJS.m(pS)?pS.I:null])):null));return $CLJS.m(a)?a:bU}(),pS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof qS?new $CLJS.pd(function(){return qS},$CLJS.I(ega,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sV,"metabase/mbql/schema.cljc",27,$CLJS.MG,1,872,872,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MG,mea,$CLJS.Y($CLJS.il,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,hha,vN],null))],null),$CLJS.ud,"Schema for a valid segment clause.",$CLJS.m(qS)?qS.I:null])):null));return $CLJS.m(a)?a:sV}(),qS],null)]))],null)],null));
$CLJS.oJ(CT,hM($CLJS.XG,$CLJS.F(["clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.rs,new $CLJS.k(null,1,[$CLJS.Pt,":case subclause"],null),$CLJS.RR,YQ],null)],null)],null),"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.k(null,1,[$CLJS.Pt,":case options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Dj,new $CLJS.k(null,1,[$CLJS.Js,!0],null),YQ],null)],null)],null)])));
var tS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CT],null);
$CLJS.oJ(HU,bM($CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof kR?new $CLJS.pd(function(){return kR},$CLJS.I(wQ,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fP,"metabase/mbql/schema.cljc",51,$CLJS.bs,1,612,612,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.bs,$CLJS.fG,$CLJS.Y($CLJS.il,mR),$U,$CLJS.Y($CLJS.il,mR),jO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,mR))],null),$CLJS.ud,"Schema for a valid + clause.",$CLJS.m(kR)?kR.I:null])):null));return $CLJS.m(a)?a:fP}(),kR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof lR?new $CLJS.pd(function(){return lR},
$CLJS.I(qga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",51,$CLJS.Ft,1,615,615,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Ft,$CLJS.fG,$CLJS.Y($CLJS.il,sU),$U,
$CLJS.Y($CLJS.il,mR),jO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,mR))],null),$CLJS.ud,"Schema for a valid - clause.",$CLJS.m(lR)?lR.I:null])):null));return $CLJS.m(a)?a:gP}(),lR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM?new $CLJS.pd(function(){return oR},$CLJS.I(pga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,JP,$CLJS.Zf,$CLJS.rj,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,
new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Mz,"metabase/mbql/schema.cljc",$CLJS.KH,1,618,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.KH,$CLJS.fG,$CLJS.Y($CLJS.il,sU),$U,$CLJS.Y($CLJS.il,sU),jO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,sU))],null),$CLJS.ud,"Schema for a valid / clause.",$CLJS.m(oR)?oR.I:null])):null));return $CLJS.m(a)?a:$CLJS.Mz}(),oR],
null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof pR?new $CLJS.pd(function(){return pR},$CLJS.I(rga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eP,"metabase/mbql/schema.cljc",51,$CLJS.cs,1,620,620,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.cs,$CLJS.fG,$CLJS.Y($CLJS.il,sU),$U,$CLJS.Y($CLJS.il,sU),jO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,sU))],null),$CLJS.ud,"Schema for a valid * clause.",$CLJS.m(pR)?pR.I:null])):null));return $CLJS.m(a)?a:eP}(),pR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof $Q?new $CLJS.pd(function(){return $Q},
$CLJS.I(ZT,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zU,"metabase/mbql/schema.cljc",58,$CLJS.rH,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.rH,iV,$CLJS.Y($CLJS.il,WM),hV,$CLJS.Y($CLJS.il,
WM),jO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,WM))],null),$CLJS.ud,"Schema for a valid coalesce clause.",$CLJS.m($Q)?$Q.I:null])):null));return $CLJS.m(a)?a:zU}(),$Q],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof bR?new $CLJS.pd(function(){return bR},$CLJS.I(Nga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,
$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iT,"metabase/mbql/schema.cljc",56,$CLJS.rw,1,585,585,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rw,$CLJS.gx,$CLJS.Y($CLJS.il,CU)],null),$CLJS.ud,"Schema for a valid length clause.",$CLJS.m(bR)?bR.I:null])):null));return $CLJS.m(a)?a:iT}(),bR],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof qR?new $CLJS.pd(function(){return qR},$CLJS.I(yea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GP,"metabase/mbql/schema.cljc",55,$CLJS.mH,1,622,622,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mH,$CLJS.fG,$CLJS.Y($CLJS.il,sU)],null),$CLJS.ud,"Schema for a valid floor clause.",$CLJS.m(qR)?qR.I:null])):null));return $CLJS.m(a)?a:GP}(),qR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof rR?new $CLJS.pd(function(){return rR},$CLJS.I(sea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),
$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uN,"metabase/mbql/schema.cljc",54,$CLJS.KG,1,625,625,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KG,$CLJS.fG,$CLJS.Y($CLJS.il,sU)],null),$CLJS.ud,"Schema for a valid ceil clause.",$CLJS.m(rR)?
rR.I:null])):null));return $CLJS.m(a)?a:uN}(),rR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof sR?new $CLJS.pd(function(){return sR},$CLJS.I(Gea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,
1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",55,$CLJS.tH,1,628,628,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tH,$CLJS.fG,$CLJS.Y($CLJS.il,sU)],null),$CLJS.ud,"Schema for a valid round clause.",$CLJS.m(sR)?sR.I:null])):null));return $CLJS.m(a)?a:wP}(),sR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&
"undefined"!==typeof tR?new $CLJS.pd(function(){return tR},$CLJS.I(Oha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",53,$CLJS.OG,1,631,631,new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.OG,$CLJS.fG,$CLJS.Y($CLJS.il,sU)],null),$CLJS.ud,"Schema for a valid abs clause.",$CLJS.m(tR)?tR.I:null])):null));return $CLJS.m(a)?a:ZP}(),tR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof uR?new $CLJS.pd(function(){return uR},$CLJS.I(Bha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,
$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[yV,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RU,"metabase/mbql/schema.cljc",69,$CLJS.LG,1,634,634,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.LG,$CLJS.fG,$CLJS.Y($CLJS.il,sU),$U,$CLJS.Y($CLJS.il,sU)],null),$CLJS.ud,"Schema for a valid power clause.",$CLJS.m(uR)?uR.I:null])):null));return $CLJS.m(a)?a:RU}(),uR],null),
new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof vR?new $CLJS.pd(function(){return vR},$CLJS.I($fa,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[yV,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aP,"metabase/mbql/schema.cljc",68,$CLJS.bH,1,637,637,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bH,$CLJS.fG,$CLJS.Y($CLJS.il,sU)],null),$CLJS.ud,"Schema for a valid sqrt clause.",$CLJS.m(vR)?vR.I:null])):null));return $CLJS.m(a)?a:aP}(),vR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof wR?new $CLJS.pd(function(){return wR},$CLJS.I(Cha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,
$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[yV,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",67,$CLJS.HG,1,640,640,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HG,$CLJS.fG,$CLJS.Y($CLJS.il,sU)],null),$CLJS.ud,"Schema for a valid exp clause.",$CLJS.m(wR)?wR.I:null])):
null));return $CLJS.m(a)?a:yP}(),wR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof xR?new $CLJS.pd(function(){return xR},$CLJS.I(lea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[yV,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IV,"metabase/mbql/schema.cljc",67,$CLJS.MH,1,643,643,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MH,$CLJS.fG,$CLJS.Y($CLJS.il,sU)],null),$CLJS.ud,"Schema for a valid log clause.",$CLJS.m(xR)?xR.I:null])):null));return $CLJS.m(a)?a:IV}(),xR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==
typeof tS?new $CLJS.pd(function(){return tS},$CLJS.I(gN,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QP,"metabase/mbql/schema.cljc",61,$CLJS.XG,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,
[$CLJS.XG,$CLJS.Iv,$CLJS.Y($CLJS.il,zO),$CLJS.Qi,$CLJS.Y($CLJS.il,$CLJS.Y(OU,iQ))],null),$CLJS.ud,"Schema for a valid case clause.",$CLJS.m(tS)?tS.I:null])):null));return $CLJS.m(a)?a:QP}(),tS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof zR?new $CLJS.pd(function(){return zR},$CLJS.I(oea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,
JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.AJ,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$P,"metabase/mbql/schema.cljc",65,$CLJS.AJ,1,656,656,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.AJ,Aea,$CLJS.Y($CLJS.il,TV),Bea,$CLJS.Y($CLJS.il,TV),$CLJS.kb,$CLJS.Y($CLJS.il,hga)],null),$CLJS.ud,"Schema for a valid datetime-diff clause.",
$CLJS.m(zR)?zR.I:null])):null));return $CLJS.m(a)?a:$P}(),zR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof AR?new $CLJS.pd(function(){return AR},$CLJS.I(Sga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.UJ,"null"],null),null),$CLJS.I(qU,
new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GQ,"metabase/mbql/schema.cljc",71,$CLJS.UJ,1,661,661,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.UJ,$CLJS.JJ,$CLJS.Y($CLJS.il,TV),$CLJS.kb,$CLJS.Y($CLJS.il,Hha),$CLJS.Pj,$CLJS.Y($CLJS.il,$CLJS.Y(OU,aW))],null),$CLJS.ud,"Schema for a valid temporal-extract clause.",$CLJS.m(AR)?AR.I:null])):null));return $CLJS.m(a)?a:GQ}(),AR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof BR?new $CLJS.pd(function(){return BR},$CLJS.I(Tfa,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.UJ,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RN,"metabase/mbql/schema.cljc",71,$CLJS.wJ,1,667,667,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wJ,$CLJS.bQ,$CLJS.Y($CLJS.il,TV)],null),$CLJS.ud,"Schema for a valid get-year clause.",$CLJS.m(BR)?BR.I:null])):null));return $CLJS.m(a)?a:RN}(),BR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof CR?new $CLJS.pd(function(){return CR},$CLJS.I(Hea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),
$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.UJ,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",74,$CLJS.$J,1,670,670,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.$J,$CLJS.bQ,$CLJS.Y($CLJS.il,TV)],null),$CLJS.ud,"Schema for a valid get-quarter clause.",
$CLJS.m(CR)?CR.I:null])):null));return $CLJS.m(a)?a:qQ}(),CR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof DR?new $CLJS.pd(function(){return DR},$CLJS.I(oga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.UJ,"null"],null),null),$CLJS.I(qU,
new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pT,"metabase/mbql/schema.cljc",72,$CLJS.fJ,1,673,673,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fJ,$CLJS.bQ,$CLJS.Y($CLJS.il,TV)],null),$CLJS.ud,"Schema for a valid get-month clause.",$CLJS.m(DR)?DR.I:null])):null));return $CLJS.m(a)?a:pT}(),DR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&
"undefined"!==typeof nM&&"undefined"!==typeof ER?new $CLJS.pd(function(){return ER},$CLJS.I(Gha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.UJ,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jT,"metabase/mbql/schema.cljc",71,$CLJS.OJ,
1,676,676,!0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.OJ,$CLJS.bQ,$CLJS.Y($CLJS.il,TV),$CLJS.Pj,$CLJS.Y($CLJS.il,$CLJS.Y(OU,aW))],null),$CLJS.ud,"Schema for a valid get-week clause.",$CLJS.m(ER)?ER.I:null])):null));return $CLJS.m(a)?a:jT}(),ER],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof FR?new $CLJS.pd(function(){return FR},$CLJS.I(cea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,
$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.UJ,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NS,"metabase/mbql/schema.cljc",70,$CLJS.NJ,1,680,680,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.NJ,$CLJS.bQ,$CLJS.Y($CLJS.il,TV)],null),$CLJS.ud,"Schema for a valid get-day clause.",$CLJS.m(FR)?
FR.I:null])):null));return $CLJS.m(a)?a:NS}(),FR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof GR?new $CLJS.pd(function(){return GR},$CLJS.I(iga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.UJ,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,
1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nR,"metabase/mbql/schema.cljc",78,$CLJS.SJ,1,683,683,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SJ,$CLJS.bQ,$CLJS.Y($CLJS.il,TV)],null),$CLJS.ud,"Schema for a valid get-day-of-week clause.",$CLJS.m(GR)?GR.I:null])):null));return $CLJS.m(a)?a:nR}(),GR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==
typeof nM&&"undefined"!==typeof HR?new $CLJS.pd(function(){return HR},$CLJS.I(gga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.UJ,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XM,"metabase/mbql/schema.cljc",71,$CLJS.XJ,1,686,686,
!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.XJ,$CLJS.JJ,$CLJS.Y($CLJS.il,TV)],null),$CLJS.ud,"Schema for a valid get-hour clause.",$CLJS.m(HR)?HR.I:null])):null));return $CLJS.m(a)?a:XM}(),HR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof IR?new $CLJS.pd(function(){return IR},$CLJS.I(iha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,
$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.UJ,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MV,"metabase/mbql/schema.cljc",73,$CLJS.RJ,1,689,689,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.RJ,$CLJS.JJ,$CLJS.Y($CLJS.il,TV)],null),$CLJS.ud,"Schema for a valid get-minute clause.",$CLJS.m(IR)?IR.I:null])):null));return $CLJS.m(a)?a:
MV}(),IR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof JR?new $CLJS.pd(function(){return JR},$CLJS.I(dga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.UJ,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SP,"metabase/mbql/schema.cljc",73,$CLJS.vJ,1,692,692,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vJ,$CLJS.JJ,$CLJS.Y($CLJS.il,TV)],null),$CLJS.ud,"Schema for a valid get-second clause.",$CLJS.m(JR)?JR.I:null])):null));return $CLJS.m(a)?a:SP}(),JR],null)])));
$CLJS.oJ(mN,bM($CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof aR?new $CLJS.pd(function(){return aR},$CLJS.I(Lga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DT,"metabase/mbql/schema.cljc",59,$CLJS.IG,1,582,582,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.IG,$CLJS.gx,$CLJS.Y($CLJS.il,CU),$CLJS.Yu,$CLJS.Y($CLJS.il,Dea),$CLJS.rw,$CLJS.Y($CLJS.il,$CLJS.Y(OU,sU))],null),$CLJS.ud,"Schema for a valid substring clause.",$CLJS.m(aR)?aR.I:null])):null));return $CLJS.m(a)?a:DT}(),aR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof cR?new $CLJS.pd(function(){return cR},
$CLJS.I(Eha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xU,"metabase/mbql/schema.cljc",54,$CLJS.FH,1,588,588,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FH,$CLJS.gx,$CLJS.Y($CLJS.il,CU)],
null),$CLJS.ud,"Schema for a valid trim clause.",$CLJS.m(cR)?cR.I:null])):null));return $CLJS.m(a)?a:xU}(),cR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof eR?new $CLJS.pd(function(){return eR},$CLJS.I(jga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,
1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JT,"metabase/mbql/schema.cljc",55,$CLJS.HH,1,594,594,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HH,$CLJS.gx,$CLJS.Y($CLJS.il,CU)],null),$CLJS.ud,"Schema for a valid ltrim clause.",$CLJS.m(eR)?eR.I:null])):null));return $CLJS.m(a)?a:JT}(),eR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&
"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof dR?new $CLJS.pd(function(){return dR},$CLJS.I(Mga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dQ,"metabase/mbql/schema.cljc",
55,$CLJS.OH,1,591,591,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OH,$CLJS.gx,$CLJS.Y($CLJS.il,CU)],null),$CLJS.ud,"Schema for a valid rtrim clause.",$CLJS.m(dR)?dR.I:null])):null));return $CLJS.m(a)?a:dQ}(),dR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof hR?new $CLJS.pd(function(){return hR},$CLJS.I(Yda,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,
$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kV,"metabase/mbql/schema.cljc",57,$CLJS.NH,1,603,603,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.NH,$CLJS.gx,$CLJS.Y($CLJS.il,CU),jea,$CLJS.Y($CLJS.il,$CLJS.ql),$CLJS.jA,$CLJS.Y($CLJS.il,$CLJS.ql)],null),$CLJS.ud,"Schema for a valid replace clause.",
$CLJS.m(hR)?hR.I:null])):null));return $CLJS.m(a)?a:kV}(),hR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof gR?new $CLJS.pd(function(){return gR},$CLJS.I(Qga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,
new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WV,"metabase/mbql/schema.cljc",55,$CLJS.cu,1,600,600,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cu,$CLJS.gx,$CLJS.Y($CLJS.il,CU)],null),$CLJS.ud,"Schema for a valid lower clause.",$CLJS.m(gR)?gR.I:null])):null));return $CLJS.m(a)?a:WV}(),gR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==
typeof nM&&"undefined"!==typeof fR?new $CLJS.pd(function(){return fR},$CLJS.I(Fha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LU,"metabase/mbql/schema.cljc",55,$CLJS.Ut,1,597,597,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ut,$CLJS.gx,$CLJS.Y($CLJS.il,CU)],null),$CLJS.ud,"Schema for a valid upper clause.",$CLJS.m(fR)?fR.I:null])):null));return $CLJS.m(a)?a:LU}(),fR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof iR?new $CLJS.pd(function(){return iR},$CLJS.I(yga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,
$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",56,$CLJS.NG,1,606,606,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.NG,iV,$CLJS.Y($CLJS.il,CU),hV,$CLJS.Y($CLJS.il,CU),jO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,CU))],null),$CLJS.ud,"Schema for a valid concat clause.",$CLJS.m(iR)?
iR.I:null])):null));return $CLJS.m(a)?a:SO}(),iR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof jR?new $CLJS.pd(function(){return jR},$CLJS.I(Kga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.QR,"null",$CLJS.Tz,"null"],null),null),
$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",74,$CLJS.qH,1,609,609,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.qH,$CLJS.gx,$CLJS.Y($CLJS.il,CU),$CLJS.iA,$CLJS.Y($CLJS.il,$CLJS.ql)],null),$CLJS.ud,"Schema for a valid regex-match-first clause.",$CLJS.m(jR)?jR.I:null])):null));return $CLJS.m(a)?a:PN}(),jR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==
typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof $Q?new $CLJS.pd(function(){return $Q},$CLJS.I(ZT,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.QR,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
zU,"metabase/mbql/schema.cljc",58,$CLJS.rH,1,579,579,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.rH,iV,$CLJS.Y($CLJS.il,WM),hV,$CLJS.Y($CLJS.il,WM),jO,$CLJS.Y($CLJS.il,$CLJS.Y($CLJS.dk,WM))],null),$CLJS.ud,"Schema for a valid coalesce clause.",$CLJS.m($Q)?$Q.I:null])):null));return $CLJS.m(a)?a:zU}(),$Q],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof tS?new $CLJS.pd(function(){return tS},
$CLJS.I(gN,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QP,"metabase/mbql/schema.cljc",61,$CLJS.XG,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.XG,$CLJS.Iv,$CLJS.Y($CLJS.il,zO),$CLJS.Qi,
$CLJS.Y($CLJS.il,$CLJS.Y(OU,iQ))],null),$CLJS.ud,"Schema for a valid case clause.",$CLJS.m(tS)?tS.I:null])):null));return $CLJS.m(a)?a:QP}(),tS],null)])));
$CLJS.uS=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Pt,":field or :expression reference or expression",$CLJS.Wf,function(a){return $CLJS.m(RL(OQ,a))?UV:$CLJS.m(RL(LQ,a))?$CLJS.ql:$CLJS.m(RL(PQ,a))?$CLJS.Ds:$CLJS.m(RL(RQ,a))?$CLJS.JJ:$CLJS.m(RL($CLJS.XG,a))?$CLJS.XG:$CLJS.Wt}],null),new $CLJS.Q(null,2,5,$CLJS.R,[UV,SQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ql,MQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ds,XQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JJ,$CLJS.VQ],null),
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XG,tS],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wt,$CLJS.JQ],null)],null);$CLJS.oJ(vV,hM($CLJS.Aw,$CLJS.F(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.JQ],null)])));var vS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vV],null);$CLJS.oJ(aQ,hM($CLJS.pH,$CLJS.F(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.JQ],null)])));var wS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aQ],null);$CLJS.oJ(kN,hM($CLJS.iH,$CLJS.F(["field-or-expression",$CLJS.uS])));
var xS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kN],null);$CLJS.oJ(lV,hM($CLJS.LH,$CLJS.F(["field-or-expression",$CLJS.uS])));var yS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lV],null);$CLJS.oJ(KO,hM($CLJS.TG,$CLJS.F(["field-or-expression",$CLJS.uS])));var zS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KO],null);$CLJS.oJ(AU,hM($CLJS.BH,$CLJS.F(["field-or-expression",$CLJS.uS])));var AS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,AU],null);$CLJS.oJ(FO,hM($CLJS.bk,$CLJS.F(["field-or-expression",$CLJS.uS])));
var BS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,FO],null);$CLJS.oJ(rO,hM($CLJS.xl,$CLJS.F(["field-or-expression",$CLJS.uS])));var CS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rO],null);$CLJS.oJ(lO,hM($CLJS.JH,$CLJS.F(["field-or-expression",$CLJS.uS,"pred",$CLJS.RR])));var DS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lO],null);$CLJS.oJ(CO,hM($CLJS.xH,$CLJS.F(["pred",$CLJS.RR])));var ES=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,CO],null);$CLJS.oJ(mU,hM($CLJS.vH,$CLJS.F(["pred",$CLJS.RR])));
var FS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,mU],null);$CLJS.oJ(IN,hM($CLJS.EH,$CLJS.F(["field-or-expression",$CLJS.uS])));var GS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IN],null);$CLJS.oJ(IM,hM($CLJS.UG,$CLJS.F(["field-or-expression",$CLJS.uS])));var HS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IM],null);$CLJS.oJ(OO,hM($CLJS.CH,$CLJS.F(["field-or-expression",$CLJS.uS])));var IS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,OO],null);
$CLJS.oJ(BP,hM($CLJS.$G,$CLJS.F(["field-or-expression",$CLJS.uS,"percentile",UQ])));var JS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BP],null);$CLJS.oJ(pU,hM($CLJS.jH,$CLJS.F(["metric-id",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.wL],null),bW],null)])));var KS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,pU],null);
$CLJS.oJ(lU,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Pt,"unnamed aggregation clause or numeric expression",$CLJS.Wf,function(a){return $CLJS.m(RL(OQ,a))?EP:$CLJS.Wt}],null),new $CLJS.Q(null,2,5,$CLJS.R,[EP,SQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wt,bM($CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof xS?new $CLJS.pd(function(){return xS},$CLJS.I(Jga,
new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NP,"metabase/mbql/schema.cljc",60,$CLJS.iH,1,957,957,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iH,MU,$CLJS.Y($CLJS.il,EU)],null),$CLJS.ud,"Schema for a valid avg clause.",
$CLJS.m(xS)?xS.I:null])):null));return $CLJS.m(a)?a:NP}(),xS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof yS?new $CLJS.pd(function(){return yS},$CLJS.I(Lfa,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,
new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZU,"metabase/mbql/schema.cljc",64,$CLJS.LH,1,958,958,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LH,MU,$CLJS.Y($CLJS.il,EU)],null),$CLJS.ud,"Schema for a valid cum-sum clause.",$CLJS.m(yS)?yS.I:null])):null));return $CLJS.m(a)?a:ZU}(),yS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==
typeof nM&&"undefined"!==typeof zS?new $CLJS.pd(function(){return zS},$CLJS.I(Fea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RP,"metabase/mbql/schema.cljc",65,$CLJS.TG,1,959,959,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TG,MU,$CLJS.Y($CLJS.il,EU)],null),$CLJS.ud,"Schema for a valid distinct clause.",$CLJS.m(zS)?zS.I:null])):null));return $CLJS.m(a)?a:RP}(),zS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof GS?new $CLJS.pd(function(){return GS},$CLJS.I(yha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,
$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.yH,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dN,"metabase/mbql/schema.cljc",76,$CLJS.EH,1,973,973,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.EH,MU,$CLJS.Y($CLJS.il,EU)],null),$CLJS.ud,"Schema for a valid stddev clause.",$CLJS.m(GS)?GS.I:null])):null));return $CLJS.m(a)?a:dN}(),GS],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof AS?new $CLJS.pd(function(){return AS},$CLJS.I(tga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AN,"metabase/mbql/schema.cljc",60,$CLJS.BH,1,960,960,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BH,MU,$CLJS.Y($CLJS.il,EU)],null),$CLJS.ud,"Schema for a valid sum clause.",$CLJS.m(AS)?AS.I:null])):null));return $CLJS.m(a)?a:AN}(),AS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof BS?new $CLJS.pd(function(){return BS},$CLJS.I(gea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,
$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.pt,"metabase/mbql/schema.cljc",60,$CLJS.bk,1,961,961,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,MU,$CLJS.Y($CLJS.il,EU)],null),$CLJS.ud,"Schema for a valid min clause.",$CLJS.m(BS)?BS.I:
null])):null));return $CLJS.m(a)?a:$CLJS.pt}(),BS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof CS?new $CLJS.pd(function(){return CS},$CLJS.I(bea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,
1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ut,"metabase/mbql/schema.cljc",60,$CLJS.xl,1,962,962,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xl,MU,$CLJS.Y($CLJS.il,EU)],null),$CLJS.ud,"Schema for a valid max clause.",$CLJS.m(CS)?CS.I:null])):null));return $CLJS.m(a)?a:$CLJS.ut}(),CS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&
"undefined"!==typeof KS?new $CLJS.pd(function(){return KS},$CLJS.I(Cea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",18,$CLJS.jH,1,994,994,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jH,Mfa,$CLJS.Y($CLJS.il,new $CLJS.Q(null,3,5,
$CLJS.R,[$CLJS.os,Ufa,vN],null))],null),$CLJS.ud,"Schema for a valid metric clause.",$CLJS.m(KS)?KS.I:null])):null));return $CLJS.m(a)?a:RO}(),KS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof FS?new $CLJS.pd(function(){return FS},$CLJS.I(bga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,
$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JO,"metabase/mbql/schema.cljc",62,$CLJS.vH,1,970,970,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vH,$CLJS.el,$CLJS.Y($CLJS.il,zP)],null),$CLJS.ud,"Schema for a valid share clause.",$CLJS.m(FS)?FS.I:null])):null));return $CLJS.m(a)?a:JO}(),FS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof ES?new $CLJS.pd(function(){return ES},$CLJS.I(hea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wN,"metabase/mbql/schema.cljc",68,$CLJS.xH,1,967,967,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xH,$CLJS.el,$CLJS.Y($CLJS.il,zP)],null),$CLJS.ud,"Schema for a valid count-where clause.",$CLJS.m(ES)?ES.I:null])):null));return $CLJS.m(a)?a:wN}(),ES],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof DS?new $CLJS.pd(function(){return DS},$CLJS.I(Mha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),
$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",66,$CLJS.JH,1,964,964,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.JH,MU,$CLJS.Y($CLJS.il,EU),$CLJS.el,$CLJS.Y($CLJS.il,zP)],null),$CLJS.ud,"Schema for a valid sum-where clause.",
$CLJS.m(DS)?DS.I:null])):null));return $CLJS.m(a)?a:OP}(),DS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof tS?new $CLJS.pd(function(){return tS},$CLJS.I(gN,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,
new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QP,"metabase/mbql/schema.cljc",61,$CLJS.XG,1,909,909,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.XG,$CLJS.Iv,$CLJS.Y($CLJS.il,zO),$CLJS.Qi,$CLJS.Y($CLJS.il,$CLJS.Y(OU,iQ))],null),$CLJS.ud,"Schema for a valid case clause.",$CLJS.m(tS)?tS.I:null])):null));return $CLJS.m(a)?a:QP}(),tS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&
"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof IS?new $CLJS.pd(function(){return IS},$CLJS.I(lha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.kH,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XU,"metabase/mbql/schema.cljc",
68,$CLJS.CH,1,979,979,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.CH,MU,$CLJS.Y($CLJS.il,EU)],null),$CLJS.ud,"Schema for a valid median clause.",$CLJS.m(IS)?IS.I:null])):null));return $CLJS.m(a)?a:XU}(),IS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof JS?new $CLJS.pd(function(){return JS},$CLJS.I(nga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,
JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.kH,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oU,"metabase/mbql/schema.cljc",72,$CLJS.$G,1,982,982,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.$G,MU,$CLJS.Y($CLJS.il,EU),$CLJS.$G,$CLJS.Y($CLJS.il,sU)],null),$CLJS.ud,"Schema for a valid percentile clause.",$CLJS.m(JS)?JS.I:null])):
null));return $CLJS.m(a)?a:oU}(),JS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof HS?new $CLJS.pd(function(){return HS},$CLJS.I(rea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZM,"metabase/mbql/schema.cljc",77,$CLJS.UG,1,976,976,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UG,MU,$CLJS.Y($CLJS.il,EU)],null),$CLJS.ud,"Schema for a valid var clause.",$CLJS.m(HS)?HS.I:null])):null));return $CLJS.m(a)?a:ZM}(),HS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof wS?new $CLJS.pd(function(){return wS},$CLJS.I(dea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,
$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",74,$CLJS.pH,1,947,947,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pH,$CLJS.oH,$CLJS.Y($CLJS.il,$CLJS.Y(OU,fO))],null),$CLJS.ud,"Schema for a valid cum-count clause.",
$CLJS.m(wS)?wS.I:null])):null));return $CLJS.m(a)?a:AP}(),wS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof vS?new $CLJS.pd(function(){return vS},$CLJS.I(Eea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([HP,$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,QU,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.JG,"null"],null),null),$CLJS.I(qU,
new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Oj,"metabase/mbql/schema.cljc",70,$CLJS.Aw,1,946,946,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Aw,$CLJS.oH,$CLJS.Y($CLJS.il,$CLJS.Y(OU,fO))],null),$CLJS.ud,"Schema for a valid count clause.",$CLJS.m(vS)?vS.I:null])):null));return $CLJS.m(a)?a:$CLJS.Oj}(),vS],null)]))],null)],null));
$CLJS.oJ(sS,hM($CLJS.UM,$CLJS.F(["aggregation",lU,"options",new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.k(null,1,[$CLJS.Pt,":aggregation-options options"],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.W,new $CLJS.k(null,1,[$CLJS.Js,!0],null),bW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vF,new $CLJS.k(null,1,[$CLJS.Js,!0],null),bW],null)],null)])));
$CLJS.oJ(NU,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Pt,"aggregation clause or numeric expression",$CLJS.Wf,function(a){return $CLJS.m(RL($CLJS.UM,a))?$CLJS.UM:yO}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.UM,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,sS],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[yO,lU],null)],null));$CLJS.oJ(cN,hM($CLJS.lH,$CLJS.F(["field",lW])));var OS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cN],null);$CLJS.oJ(DP,hM($CLJS.eH,$CLJS.F(["field",lW])));
PS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DP],null);
QS=bM($CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof OS?new $CLJS.pd(function(){return OS},$CLJS.I(vha,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
jP,"metabase/mbql/schema.cljc",15,$CLJS.lH,1,1049,1049,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lH,$CLJS.oH,$CLJS.Y($CLJS.il,gV)],null),$CLJS.ud,"Schema for a valid asc clause.",$CLJS.m(OS)?OS.I:null])):null));return $CLJS.m(a)?a:jP}(),OS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof PS?new $CLJS.pd(function(){return PS},$CLJS.I(Yea,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,
$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$T,"metabase/mbql/schema.cljc",16,$CLJS.eH,1,1050,1050,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eH,$CLJS.oH,$CLJS.Y($CLJS.il,gV)],null),$CLJS.ud,"Schema for a valid desc clause.",$CLJS.m(PS)?PS.I:null])):null));return $CLJS.m(a)?a:$T}(),PS],null)]));
RS=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ks,$CLJS.fN,$CLJS.WN,$CLJS.JM,$CLJS.nu,$CLJS.QB,$CLJS.bQ],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.vF,bW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rk,new $CLJS.k(null,1,[$CLJS.Js,!0],null),bW],null)],null);
rma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,RS,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.fN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SM,bW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IQ,eW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.KQ,new $CLJS.k(null,1,[$CLJS.Js,!0],null),eW],null)],null)],null);
sma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,RS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.WN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JU,eW],null)],null)],null);
SS=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,RS,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Dj,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Fs],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xO,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null)],null)],null);Bma=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YU],null);
tma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,SS,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.JM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JM,HQ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qO,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,zN],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qi,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ts,$CLJS.as,$CLJS.Fs],null)],null)],null)],null);
$CLJS.nW=new $CLJS.U(null,new $CLJS.k(null,4,[$CLJS.bQ,null,$CLJS.nu,null,$CLJS.Ds,null,$CLJS.QB,null],null),null);uma=$CLJS.mh.h(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ks],null),$CLJS.nW);
$CLJS.oJ(GT,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,1,[$CLJS.Wf,$CLJS.Ik],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JM,tma],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fN,rma],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.WN,sma],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Vk,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,SS,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,uma],null)],null)],null)],null)],null));
var oW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aU,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ts,bW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Uf(function(b){var c=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);return $CLJS.E.h(c,
$CLJS.W.g(b))},a)}],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MO,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,bW],null)],null)],null),US=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,oW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hN,$CLJS.Fs],null)],null)],null),VS=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TS],null),WS=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,1,[$CLJS.Wf,function(a){return $CLJS.m($CLJS.CM($CLJS.je,
$CLJS.gT)(a))?$CLJS.gT:$CLJS.sF}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gT,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,oW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gT,$CLJS.Fs],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sF,VS],null)],null),XS=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,bW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.LD,cW],null),new $CLJS.Q(null,2,5,$CLJS.R,[vga,bW],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.dP,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,dW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sO,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Wj],null)],null)],null),ZS=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,Uha,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ps,new $CLJS.k(null,1,[$CLJS.Pt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),yma=$CLJS.mh.h(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ks],null),
new $CLJS.U(null,new $CLJS.k(null,4,[$CLJS.DG,null,$CLJS.lG,null,$CLJS.wG,null,$CLJS.AG,null],null),null)),$S=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,VU],null);
$CLJS.oJ(IO,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FP,new $CLJS.k(null,1,[$CLJS.Js,!0],null),ZS],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hU,new $CLJS.k(null,1,[$CLJS.Js,!0],null),WS],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EV,$CLJS.RR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iG,new $CLJS.k(null,1,[$CLJS.Js,!0],null),yma],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mG,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.os,new $CLJS.k(null,1,[$CLJS.Pt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ks,$CLJS.ix,$CLJS.Oz],null),$S],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qG,new $CLJS.k(null,1,[$CLJS.Js,!0],null),bW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HL,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,gW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rP,new $CLJS.k(null,
1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,XS],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.CM($CLJS.OA($CLJS.FP,$CLJS.hU),$CLJS.$f($CLJS.CM($CLJS.FP,$CLJS.hU)))],null)],null));var Xha=$CLJS.R,pW;var qW=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,IO],null)],null);
if($CLJS.ie(qW)&&$CLJS.E.h($CLJS.A(qW),$CLJS.zj)){var rW=$CLJS.z(qW);$CLJS.A(rW);var sW=$CLJS.B(rW),Yha=$CLJS.je($CLJS.A(sW))?sW:$CLJS.kf(null,sW),tW=$CLJS.z(Yha),Zha=$CLJS.A(tW),bia=$CLJS.B(tW);pW=$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,$CLJS.T.j(Zha,$CLJS.bk,1)],null),bia)}else pW=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,qW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"non-empty"],null),$CLJS.z],null)],null);
$CLJS.oJ(KN,new $CLJS.Q(null,3,5,Xha,[$CLJS.vs,pW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"All join aliases must be unique."],null),function(a){return $CLJS.jM($CLJS.vg($CLJS.Eb,$CLJS.kg.h($CLJS.qG,a)))}],null)],null));var Ama=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,KN],null);
$CLJS.oJ(VU,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Cl,new $CLJS.k(null,1,[$CLJS.Pt,"Distinct, non-empty sequence of Field clauses"],null),GM(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zj,new $CLJS.k(null,1,[$CLJS.bk,1],null),$CLJS.JQ],null))],null));
$CLJS.oJ(TS,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hU,new $CLJS.k(null,1,[$CLJS.Js,!0],null),WS],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FP,new $CLJS.k(null,1,[$CLJS.Js,!0],null),ZS],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dH,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zj,new $CLJS.k(null,1,[$CLJS.bk,1],null),$CLJS.TQ],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cH,new $CLJS.k(null,
1,[$CLJS.Js,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zj,new $CLJS.k(null,1,[$CLJS.bk,1],null),$CLJS.JQ],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QR,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ts,bW,$CLJS.uS],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mG,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$S],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SG,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.RR],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZV,new $CLJS.k(null,
1,[$CLJS.Js,!0],null),fW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xV,new $CLJS.k(null,1,[$CLJS.Js,!0],null),GM(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zj,new $CLJS.k(null,1,[$CLJS.bk,1],null),QS],null))],null),new $CLJS.Q(null,3,5,$CLJS.R,[hT,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[hT,eW],null),new $CLJS.Q(null,2,5,$CLJS.R,[wea,eW],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dO,new $CLJS.k(null,1,[$CLJS.Js,!0],null),Ama],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rP,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,XS],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.h(1,$CLJS.D($CLJS.Zh(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hU,$CLJS.FP],null))))}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,
new $CLJS.k(null,1,[$CLJS.Pt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.Of(a);a=$CLJS.K.h(b,$CLJS.cH);b=$CLJS.K.h(b,$CLJS.mG);return $CLJS.ee($CLJS.Gy.h($CLJS.di(a),$CLJS.di(b)))}],null)],null));
$CLJS.aT=$CLJS.Qh([$CLJS.IH,$CLJS.bQ,EO,pO,sP,QV,ZO,$CLJS.PV,$CLJS.nu,YO,nN,BU,FV,$CLJS.DN,wV,BO,$CLJS.xP,WO,$CLJS.rk,$CLJS.VO,VM,$CLJS.$N,$CLJS.UO,XP,$CLJS.Ds,$CLJS.QB,QO],[new $CLJS.k(null,1,[iP,new $CLJS.U(null,new $CLJS.k(null,5,[$CLJS.IH,null,$CLJS.bQ,null,$CLJS.nu,null,$CLJS.Ds,null,$CLJS.QB,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.bQ,iP,new $CLJS.U(null,new $CLJS.k(null,5,[$CLJS.IH,null,$CLJS.bQ,null,$CLJS.PV,null,$CLJS.xP,null,$CLJS.rk,null],null),null)],null),new $CLJS.k(null,
3,[$CLJS.Ik,$CLJS.ql,$CLJS.OF,KU,iP,new $CLJS.U(null,new $CLJS.k(null,1,[EO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Ik,UV,$CLJS.OF,KU,iP,new $CLJS.U(null,new $CLJS.k(null,5,[$CLJS.IH,null,pO,null,$CLJS.nu,null,$CLJS.rk,null,VM,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Ik,$CLJS.ql,$CLJS.OF,rN,iP,new $CLJS.U(null,new $CLJS.k(null,1,[sP,null],null),null)],null),new $CLJS.k(null,1,[iP,new $CLJS.U(null,new $CLJS.k(null,1,[QV,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Ik,UV,
$CLJS.OF,bha,iP,new $CLJS.U(null,new $CLJS.k(null,1,[ZO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.bQ,iP,new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.PV,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Ik,UV,iP,new $CLJS.U(null,new $CLJS.k(null,5,[$CLJS.IH,null,pO,null,$CLJS.nu,null,$CLJS.rk,null,VM,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Ik,UV,$CLJS.OF,rN,iP,new $CLJS.U(null,new $CLJS.k(null,1,[YO,null],null),null)],null),new $CLJS.k(null,1,[iP,new $CLJS.U(null,new $CLJS.k(null,
1,[nN,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Ik,$CLJS.ql,$CLJS.OF,KU,iP,new $CLJS.U(null,new $CLJS.k(null,8,[$CLJS.IH,null,QV,null,nN,null,BU,null,WO,null,$CLJS.rk,null,VM,null,$CLJS.QB,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Ik,$CLJS.ql,$CLJS.OF,rN,iP,new $CLJS.U(null,new $CLJS.k(null,1,[FV,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.bQ,iP,new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.PV,null,$CLJS.DN,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Ik,$CLJS.ql,
$CLJS.OF,rN,iP,new $CLJS.U(null,new $CLJS.k(null,1,[wV,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Ik,$CLJS.ql,$CLJS.OF,rN,iP,new $CLJS.U(null,new $CLJS.k(null,1,[BO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.bQ,iP,new $CLJS.U(null,new $CLJS.k(null,5,[$CLJS.IH,null,$CLJS.bQ,null,$CLJS.PV,null,$CLJS.xP,null,$CLJS.rk,null],null),null)],null),new $CLJS.k(null,1,[iP,new $CLJS.U(null,new $CLJS.k(null,1,[WO,null],null),null)],null),new $CLJS.k(null,1,[iP,new $CLJS.U(null,new $CLJS.k(null,
1,[$CLJS.rk,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.bQ,iP,new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.PV,null,$CLJS.VO,null],null),null)],null),new $CLJS.k(null,1,[iP,new $CLJS.U(null,new $CLJS.k(null,1,[VM,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.bQ,iP,new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.PV,null,$CLJS.$N,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.bQ,iP,new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.PV,null,$CLJS.UO,null],null),null)],null),
new $CLJS.k(null,3,[$CLJS.Ik,UV,$CLJS.OF,KU,iP,new $CLJS.U(null,new $CLJS.k(null,1,[XP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.Ds,iP,new $CLJS.U(null,new $CLJS.k(null,3,[$CLJS.IH,null,$CLJS.rk,null,$CLJS.Ds,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.ql,iP,new $CLJS.U(null,new $CLJS.k(null,8,[$CLJS.IH,null,QV,null,nN,null,BU,null,WO,null,$CLJS.rk,null,VM,null,$CLJS.QB,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Ik,UV,$CLJS.OF,rN,iP,new $CLJS.U(null,new $CLJS.k(null,
1,[QO,null],null),null)],null)]);$CLJS.oJ(YU,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"valid parameter type"],null)],null),$CLJS.Uh($CLJS.aT)));$CLJS.oJ(zN,$CLJS.mh.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,1,[$CLJS.Pt,"valid template tag widget type"],null),$CLJS.Oz],null),$CLJS.Uh($CLJS.aT)));
$CLJS.oJ(YT,hM(nea,$CLJS.F(["tag-name",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,bW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rk,bW],null)],null)],null)])));var bT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YT],null);$CLJS.oJ(YM,hM($CLJS.JM,$CLJS.F(["target",new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,$CLJS.JQ,bT],null)])));var cT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YM],null);$CLJS.oJ(UN,hM(TO,$CLJS.F(["target",bT])));
var dT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,UN],null),Cma=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,$CLJS.JQ,bM($CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof cT?new $CLJS.pd(function(){return cT},$CLJS.I(lga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,
1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rT,"metabase/mbql/schema.cljc",21,$CLJS.JM,1,1612,1612,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JM,$CLJS.cK,$CLJS.Y($CLJS.il,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,fO,hP],null))],null),$CLJS.ud,"Schema for a valid dimension clause.",$CLJS.m(cT)?cT.I:null])):null));return $CLJS.m(a)?a:rT}(),cT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=JP.g($CLJS.be("undefined"!==typeof $CLJS.lM&&
"undefined"!==typeof mM&&"undefined"!==typeof nM&&"undefined"!==typeof dT?new $CLJS.pd(function(){return dT},$CLJS.I(Fga,new $CLJS.k(null,1,[$CLJS.Qj,!0],null)),$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,JP,$CLJS.Zf,$CLJS.rj,$CLJS.jk,FN,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.I(qU,new $CLJS.k(null,1,[$CLJS.Xj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VV,"metabase/mbql/schema.cljc",20,TO,1,1615,1615,new $CLJS.Q(null,3,5,$CLJS.R,[TO,$CLJS.cK,
$CLJS.Y($CLJS.il,hP)],null),$CLJS.ud,"Schema for a valid variable clause.",$CLJS.m(dT)?dT.I:null])):null));return $CLJS.m(a)?a:VV}(),dT],null)]))],null),eT=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,GN],null);
$CLJS.oJ(GN,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.vs,a,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.CM($CLJS.OA($CLJS.gT,$CLJS.hN),$CLJS.$f($CLJS.CM($CLJS.gT,$CLJS.hN)))],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.Of(b);b=$CLJS.K.h(c,$CLJS.gT);var d=$CLJS.K.h(c,$CLJS.hN);c=$CLJS.K.h(c,$CLJS.Ik);c=c instanceof $CLJS.M?c.R:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.g(c)].join(""));}}],null)],null)}(new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KQ,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.os,new $CLJS.k(null,1,[$CLJS.Pt,"valid Database ID"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LL],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,$CLJS.GL],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ks,$CLJS.hN,$CLJS.gT],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gT,new $CLJS.k(null,1,[$CLJS.Js,!0],null),US],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hN,new $CLJS.k(null,1,[$CLJS.Js,!0],null),VS],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.VN,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,new $CLJS.Q(null,
8,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,Bma],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rk,new $CLJS.k(null,1,[$CLJS.Js,!0],null),bW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cK,new $CLJS.k(null,1,[$CLJS.Js,!0],null),Cma],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yk,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Fs],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.W,new $CLJS.k(null,1,[$CLJS.Js,!0],null),bW],null),new $CLJS.Q(null,3,5,$CLJS.R,[tea,new $CLJS.k(null,1,[$CLJS.Js,!0],null),
bW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Dj,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Fs],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JL,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[Kha,new $CLJS.k(null,1,[$CLJS.Js,!0],null),vQ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[kga,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,
new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[PP,new $CLJS.k(null,1,[$CLJS.Js,!0],null),fW],null),new $CLJS.Q(null,3,5,$CLJS.R,[yN,new $CLJS.k(null,1,[$CLJS.Js,!0],null),fW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.Of(a);a=$CLJS.K.h(b,PP);b=$CLJS.K.h(b,yN);return $CLJS.Bb($CLJS.m(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[aga,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[uha,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null),new $CLJS.Q(null,3,5,$CLJS.R,[pea,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null),new $CLJS.Q(null,3,5,$CLJS.R,[Zda,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null),new $CLJS.Q(null,3,5,$CLJS.R,[vea,new $CLJS.k(null,1,[$CLJS.Js,
!0],null),$CLJS.Ds],null),new $CLJS.Q(null,3,5,$CLJS.R,[fha,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Ds],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[aea,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Ds],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Aha,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Ds],null)],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zx,new $CLJS.k(null,
1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QN,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.ks,Ega,xga,$CLJS.MO,mga,Gga,Zfa,kea,qea,zea,Xea,Rga,Sha,Rfa,Iea],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[jha,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,eW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Wfa,
new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,eW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JU,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,Tha],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Lha,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,bW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Yfa,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,eW],null)],null),new $CLJS.Q(null,
3,5,$CLJS.R,[Jha,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ts,$CLJS.Fs,$CLJS.Fs],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Sfa,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,eW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PO,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ts,$CLJS.Fs,
$CLJS.Fs],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uL,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Fs],null)],null)],null)],null)],null)],null)),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.$f($CLJS.rP)],null)],null));var uW=$CLJS.EM(eT);
(function(){var a=Uda();return function(b){if($CLJS.m(uW.g?uW.g(b):uW.call(null,b)))return b;b=a.g?a.g(b):a.call(null,b);var c=$CLJS.Oqa(b);throw $CLJS.nj($CLJS.ZF("Invalid query: {0}",$CLJS.F([$CLJS.Oi.l($CLJS.F([c]))])),new $CLJS.k(null,2,[$CLJS.tx,c,fga,b],null));}})();