var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';$CLJS.OX=function(a,b){return $CLJS.Rc($CLJS.Ob(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Qc(c,d):c},$CLJS.Pc($CLJS.Mg),b))};$CLJS.F5=function(a,b,c){return null==c||$CLJS.Ee(a,b)?a:$CLJS.T.j(a,b,c)};$CLJS.X2=function(a){return $CLJS.eG.g($CLJS.KF(a))};$CLJS.Y2=function(a,b){return $CLJS.mh.j(new $CLJS.Q(null,2,5,$CLJS.R,[a,new $CLJS.k(null,1,[$CLJS.eG,$CLJS.p.g($CLJS.jj())],null)],null),$CLJS.kg.g($CLJS.iU),b)};
$CLJS.L_=function(a,b){if($CLJS.z(b)){if($CLJS.E.h($CLJS.D(b),1))return $CLJS.A(b);a=[" ",(0,$CLJS.za)(a)," "].join("");return $CLJS.E.h($CLJS.D(b),2)?[$CLJS.p.g($CLJS.A(b)),a,$CLJS.p.g($CLJS.Vd(b))].join(""):[$CLJS.Tt(", ",$CLJS.ei(b)),",",a,$CLJS.p.g($CLJS.Wd(b))].join("")}return null};$CLJS.I5=function(a,b){return $CLJS.w2.h(a,b instanceof $CLJS.M?b:$CLJS.kb.g(b))};var Nka,Oka,Pka,Qka,Rka,Z2;
$CLJS.v2=function(){function a(e,f){if($CLJS.Bb(f))return"";e=Math.abs(e);var g=$CLJS.zi.g(f);switch(g instanceof $CLJS.M?g.R:null){case "default":return $CLJS.ZX("Default period","Default periods",e);case "millisecond":return $CLJS.ZX("Millisecond","Milliseconds",e);case "second":return $CLJS.ZX("Second","Seconds",e);case "minute":return $CLJS.ZX("Minute","Minutes",e);case "hour":return $CLJS.ZX("Hour","Hours",e);case "day":return $CLJS.ZX("Day","Days",e);case "week":return $CLJS.ZX("Week","Weeks",
e);case "month":return $CLJS.ZX("Month","Months",e);case "quarter":return $CLJS.ZX("Quarter","Quarters",e);case "year":return $CLJS.ZX("Year","Years",e);case "minute-of-hour":return $CLJS.ZX("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.ZX("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.ZX("Day of week","Days of week",e);case "day-of-month":return $CLJS.ZX("Day of month","Days of month",e);case "day-of-year":return $CLJS.ZX("Day of year","Days of year",e);case "week-of-year":return $CLJS.ZX("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.ZX("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.ZX("Quarter of year","Quarters of year",e);default:return f=$CLJS.$s($CLJS.fi(f),/-/),e=$CLJS.z(f),f=$CLJS.A(e),e=$CLJS.B(e),$CLJS.Tt(" ",$CLJS.kf($CLJS.rE(f),e))}}function b(e){return d.h?d.h(1,e):d.call(null,1,e)}function c(){return d.h?d.h(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.v=c;d.g=b;d.h=a;return d}();$CLJS.w2=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.IF(f)},e,a,b,c,d)}();Nka=$CLJS.ka($CLJS.O);Oka=$CLJS.ka($CLJS.O);
Pka=$CLJS.ka($CLJS.O);Qka=$CLJS.ka($CLJS.O);Rka=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));$CLJS.x2=new $CLJS.hj($CLJS.Fi.h("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.IF,Rka,Nka,Oka,Pka,Qka);$CLJS.x2.o(null,$CLJS.Dj,function(){return null});$CLJS.x2.o(null,$CLJS.eI,function(a){return $CLJS.kb.g(a)});Z2=new $CLJS.U(null,new $CLJS.k(null,4,[$CLJS.YH,null,$CLJS.aI,null,$CLJS.bI,null,$CLJS.ZH,null],null),null);
$CLJS.ssa=$CLJS.mh.j($CLJS.Mg,$CLJS.gm.h($CLJS.lm.g(Z2),$CLJS.kg.g(function(a){var b=new $CLJS.k(null,2,[$CLJS.su,$CLJS.eI,$CLJS.kb,a],null);return $CLJS.E.h(a,$CLJS.tu)?$CLJS.T.j(b,$CLJS.Dj,!0):b})),$CLJS.Xma);$CLJS.tsa=$CLJS.mm.h(function(a){var b=new $CLJS.k(null,2,[$CLJS.su,$CLJS.eI,$CLJS.kb,a],null);return $CLJS.E.h(a,$CLJS.uu)?$CLJS.T.j(b,$CLJS.Dj,!0):b},$CLJS.PT);
$CLJS.usa=$CLJS.mh.j($CLJS.Mg,$CLJS.gm.h($CLJS.lm.g(Z2),$CLJS.kg.g(function(a){var b=new $CLJS.k(null,2,[$CLJS.su,$CLJS.eI,$CLJS.kb,a],null);return $CLJS.E.h(a,$CLJS.uu)?$CLJS.T.j(b,$CLJS.Dj,!0):b})),$CLJS.UT);$CLJS.j_.o(null,$CLJS.eI,function(a,b,c){a=$CLJS.Of(c);a=$CLJS.K.h(a,$CLJS.kb);return $CLJS.v2.g(a)});
$CLJS.p_.o(null,$CLJS.eI,function(a,b,c){return $CLJS.xm.l($CLJS.F([new $CLJS.k(null,2,[$CLJS.vF,$CLJS.MZ.j(a,b,c),$CLJS.r1,$CLJS.AE($CLJS.x2.g(c))],null),$CLJS.Zh(c,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Dj,$CLJS.d1],null))]))});
$CLJS.y2=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,g,l){return $CLJS.IF(l)},e,a,b,c,d)}();$CLJS.y2.o(null,$CLJS.Dj,function(){return $CLJS.ai});
$CLJS.d3=function(){function a(d,e,f){return $CLJS.y2.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();