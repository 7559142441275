var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var gJ,lJ,uJ,LJ,PJ,ZJ;$CLJS.fJ=new $CLJS.M(null,"get-month","get-month",-369374731);gJ=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.hJ=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);lJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.tJ=new $CLJS.M(null,"iso","iso",-1366207543);uJ=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.vJ=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.wJ=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.xJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.yJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.zJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.AJ=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.BJ=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.FJ=new $CLJS.M(null,"us","us",746429226);$CLJS.IJ=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.JJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.KJ=new $CLJS.M(null,"now","now",-1650525531);LJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.MJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.NJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.OJ=new $CLJS.M(null,"get-week","get-week",-888059349);
PJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.QJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.RJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.SJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.TJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.UJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.XJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.YJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);ZJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.$J=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.aK=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.bK=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.cK=new $CLJS.M(null,"target","target",253001721);$CLJS.xT($CLJS.BJ,$CLJS.F([$CLJS.Ft,$CLJS.JD,$CLJS.Yr,$CLJS.nF]));$CLJS.DJ.o(null,$CLJS.yJ,function(a){$CLJS.G(a,0,null);$CLJS.G(a,1,null);a=$CLJS.G(a,2,null);a=$CLJS.EJ(a);return $CLJS.ge(a)?(a=$CLJS.Gy.h(a,new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.yD,null,$CLJS.kE,null],null),null)),$CLJS.E.h($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.mT($CLJS.yJ,$CLJS.yG);
for(var dK=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QJ,$CLJS.YJ],null)),eK=null,fK=0,gK=0;;)if(gK<fK){var hK=eK.ha(null,gK);$CLJS.xT(hK,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null),$CLJS.Yr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.nF],null)]));$CLJS.mT(hK,$CLJS.yJ);gK+=1}else{var iK=$CLJS.z(dK);if(iK){var jK=iK;if($CLJS.me(jK)){var kK=$CLJS.Wc(jK),dda=$CLJS.Xc(jK),eda=kK,fda=$CLJS.D(kK);dK=dda;eK=eda;fK=fda}else{var lK=$CLJS.A(jK);$CLJS.xT(lK,$CLJS.F([new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.X,$CLJS.pG],null),$CLJS.Yr,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.nF],null)]));$CLJS.mT(lK,$CLJS.yJ);dK=$CLJS.B(jK);eK=null;fK=0}gK=0}else break}
for(var mK=$CLJS.z(new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.wJ,$CLJS.fJ,$CLJS.NJ,$CLJS.XJ,$CLJS.RJ,$CLJS.vJ,$CLJS.$J],null)),nK=null,oK=0,pK=0;;)if(pK<oK){var gda=nK.ha(null,pK);$CLJS.xT(gda,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null)],null)]));pK+=1}else{var qK=$CLJS.z(mK);if(qK){var rK=qK;if($CLJS.me(rK)){var sK=$CLJS.Wc(rK),hda=$CLJS.Xc(rK),ida=sK,jda=$CLJS.D(sK);mK=hda;nK=ida;oK=jda}else{var kda=$CLJS.A(rK);$CLJS.xT(kda,$CLJS.F([$CLJS.Ft,
$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null)],null)]));mK=$CLJS.B(rK);nK=null;oK=0}pK=0}else break}$CLJS.xT($CLJS.AJ,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cI],null)]));
for(var tK=$CLJS.z(new $CLJS.U(null,new $CLJS.k(null,8,[$CLJS.$J,null,$CLJS.RJ,null,$CLJS.XJ,null,$CLJS.SJ,null,$CLJS.vJ,null,$CLJS.fJ,null,$CLJS.wJ,null,$CLJS.NJ,null],null),null)),uK=null,vK=0,wK=0;;)if(wK<vK){var lda=uK.ha(null,wK);$CLJS.xT(lda,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null)],null)]));wK+=1}else{var xK=$CLJS.z(tK);if(xK){var yK=xK;if($CLJS.me(yK)){var zK=$CLJS.Wc(yK),mda=$CLJS.Xc(yK),nda=zK,oda=$CLJS.D(zK);
tK=mda;uK=nda;vK=oda}else{var pda=$CLJS.A(yK);$CLJS.xT(pda,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null)],null)]));tK=$CLJS.B(yK);uK=null;vK=0}wK=0}else break}$CLJS.oJ(ZJ,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ks,$CLJS.tJ,$CLJS.FJ,$CLJS.MJ],null));
$CLJS.zT($CLJS.OJ,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JJ,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ds,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,ZJ],null)],null)],null)],null)]));
$CLJS.oJ($CLJS.TJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,$CLJS.HF,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,$CLJS.mh.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ks,new $CLJS.k(null,2,[$CLJS.Pt,"valid timezone ID",$CLJS.Bt,function(a){a=$CLJS.Of(a);a=$CLJS.K.h(a,$CLJS.Yk);return["invalid timezone ID: ",$CLJS.Oi.l($CLJS.F([a]))].join("")}],null)],null),$CLJS.Le($CLJS.He,$CLJS.WJ.tz.names())),$CLJS.XI],null)],null));
$CLJS.zT($CLJS.hJ,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JJ,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TJ],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yz,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ds,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.TJ],null)],null)],null)],
null)]));$CLJS.mT($CLJS.hJ,$CLJS.yJ);$CLJS.xT($CLJS.KJ,$CLJS.F([$CLJS.Ft,$CLJS.BD]));$CLJS.oJ(uJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.gG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Vf(function(b){return $CLJS.XF(a,b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yD,$CLJS.kE],null))}],null)],null));
$CLJS.oJ(gJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.dG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JB,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uJ],null)],null)],null)],null));
$CLJS.PH.h($CLJS.xJ,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.gs,new $CLJS.k(null,1,[$CLJS.Pt,"valid :absolute-datetime clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.xJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gJ],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hs,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gs,new $CLJS.k(null,1,[$CLJS.Pt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.aJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.cJ],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VI],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.Dj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.dI],null)],null)],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.gs,new $CLJS.k(null,1,[$CLJS.Pt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.hv],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.eJ],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.Dj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.$H],null)],null)],null)],null)],null)],null));
$CLJS.DJ.o(null,$CLJS.xJ,function(a){$CLJS.G(a,0,null);$CLJS.G(a,1,null);var b=$CLJS.G(a,2,null);a=$CLJS.G(a,3,null);a=$CLJS.E.h(b,$CLJS.hv)?$CLJS.E.h(a,$CLJS.Dj)?$CLJS.kE:$CLJS.m($CLJS.oT($CLJS.dI,a))?$CLJS.yD:$CLJS.kE:null;if($CLJS.m(a))return a;a="string"===typeof b?$CLJS.m($CLJS.pi($CLJS.CV,b))?$CLJS.yD:$CLJS.m($CLJS.pi($CLJS.DV,b))?$CLJS.yD:null:null;if($CLJS.m(a))return a;b=$CLJS.EJ(b);b=$CLJS.ge(b)?$CLJS.mh.j($CLJS.ai,$CLJS.ug(function(c){return $CLJS.XF(c,$CLJS.Mj)}),b):b;return $CLJS.ge(b)&&
$CLJS.E.h($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.oJ(PJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.hv],null),$CLJS.Yr],null));
$CLJS.zT($CLJS.IJ,$CLJS.F([$CLJS.Ft,$CLJS.kE,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Gv,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,PJ],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kb,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ds,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.nF],null)],null)],null)],null)]));
$CLJS.xT($CLJS.yx,$CLJS.F([$CLJS.Ft,$CLJS.aF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.CG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.mF],null)]));$CLJS.oJ(lJ,new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.ks,$CLJS.bI,$CLJS.Ue,$CLJS.ak,$CLJS.zJ,$CLJS.aK,$CLJS.bK,$CLJS.Ej,$CLJS.Cf,$CLJS.xe,$CLJS.pj,$CLJS.ZH],null));$CLJS.oJ(LJ,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ks,$CLJS.tJ,$CLJS.FJ,$CLJS.MJ],null));
$CLJS.zT($CLJS.UJ,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JJ,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.pG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kb,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,lJ],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ds,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LJ],null)],null)],
null)],null)]));