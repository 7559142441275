var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var xZ,Hja,Ija,Jja,Kja,Lja,a_,Mja,Nja,Oja,d_,Pja,Qja,Rja,i_,r_,Sja,Tja,Uja,A_,Vja,Wja,Xja,D_,Yja,Zja,$ja,aka;xZ=function(){};$CLJS.CZ=function(a){return null!=a?$CLJS.t===a.aj?!0:a.Uc?!1:$CLJS.Jb(xZ,a):$CLJS.Jb(xZ,a)};Hja=new $CLJS.w(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.WZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Ija=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Jja=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.YZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.ZZ=new $CLJS.M(null,"database-id","database-id",1883826326);Kja=new $CLJS.w("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.$Z=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Lja=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);a_=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Mja=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Nja=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Oja=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.b_=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.c_=new $CLJS.M(null,"dataset","dataset",1159262238);d_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Pja=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.e_=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.f_=new $CLJS.M(null,"definition","definition",-1198729982);Qja=new $CLJS.M(null,"state","state",-1988618099);$CLJS.g_=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Rja=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.h_=new $CLJS.M("source","joins","source/joins",1225821486);i_=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
r_=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.s_=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.t_=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);Sja=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.u_=new $CLJS.M(null,"selected?","selected?",-742502788);
Tja=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Uja=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.v_=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.w_=new $CLJS.M("source","card","source/card",-139977973);$CLJS.x_=new $CLJS.M(null,"active","active",1895962068);$CLJS.y_=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.z_=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);A_=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Vja=new $CLJS.M(null,"details","details",1956795411);$CLJS.B_=new $CLJS.M("source","fields","source/fields",-649667981);Wja=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.C_=new $CLJS.M("source","native","source/native",-1444604147);
Xja=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);D_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.E_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Yja=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);Zja=new $CLJS.w(null,"metadata-provider?","metadata-provider?",1669871524,null);
$ja=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);aka=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.F_=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.G_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.oJ(i_,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.ks,$CLJS.w_,$CLJS.C_,$CLJS.YZ,$CLJS.$Z,$CLJS.B_,$CLJS.QL,$CLJS.KL,$CLJS.h_,$CLJS.e_,$CLJS.g_],null));$CLJS.oJ(A_,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,aka],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rk,$CLJS.DL],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HF],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.EL,$CLJS.OL],null)],null));
$CLJS.oJ(a_,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,Yja],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rk,$CLJS.DL],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HF],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,$CLJS.Fs],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[Lja,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,$CLJS.Fs],null)],null)],null));
$CLJS.oJ($CLJS.b_,new $CLJS.Q(null,18,5,$CLJS.R,[$CLJS.Wj,new $CLJS.k(null,1,[$CLJS.Pt,"Valid column metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.vL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.ql],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JB,$CLJS.gG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rk,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.OL],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vF,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.ns,$CLJS.ql],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Bf,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.gG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.HL,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.OL],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.t_,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.OL],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.F_,
new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.HF],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xL,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,i_],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.E_,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.FL],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WZ,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.HF],
null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.s_,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ql,new $CLJS.k(null,2,[$CLJS.bk,1,$CLJS.xl,60],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.u_,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null),new $CLJS.Q(null,3,5,$CLJS.R,[Sja,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,A_],null)],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$ja,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,a_],null)],null)],null)],null));
$CLJS.oJ(D_,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.v_,$CLJS.HF],null),new $CLJS.Q(null,2,5,$CLJS.R,[Pja,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[Oja,$CLJS.HF],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.JB,$CLJS.gG],null)],null)],null)],null)],null)],null));
$CLJS.oJ(d_,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.x_,$CLJS.Ds],null),new $CLJS.Q(null,2,5,$CLJS.R,[Qja,$CLJS.HF],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.v_,$CLJS.HF],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.f_,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,D_],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uL,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,
$CLJS.HF],null)],null)],null));
$CLJS.oJ(Wja,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Wj,new $CLJS.k(null,1,[$CLJS.Pt,"Valid Card metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.PL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rk,$CLJS.FL],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HF],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZZ,$CLJS.GL],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.y_,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Wj],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.z_,
new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,$CLJS.Wj],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.c_,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.IL,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.AL],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Jja,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,d_],null)],null)],null)],null));
$CLJS.oJ(Tja,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Wj,new $CLJS.k(null,1,[$CLJS.Pt,"Valid Segment metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.zL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rk,$CLJS.yL],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HF],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IL,$CLJS.AL],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.f_,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Wj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.du,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.HF],null)],null)],null));
$CLJS.oJ(Uja,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Wj,new $CLJS.k(null,1,[$CLJS.Pt,"Valid Metric metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.BL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rk,$CLJS.wL],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HF],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IL,$CLJS.AL],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.f_,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Wj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.du,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.HF],null)],null)],null));
$CLJS.oJ(Xja,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Wj,new $CLJS.k(null,1,[$CLJS.Pt,"Valid Table metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.CL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rk,$CLJS.AL],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HF],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vF,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.HF],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Cl,new $CLJS.k(null,
1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.HF],null)],null)],null));
$CLJS.oJ(Ija,new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Wj,new $CLJS.k(null,1,[$CLJS.Pt,"Valid Database metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.ML],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rk,$CLJS.GL],null),new $CLJS.Q(null,3,5,$CLJS.R,[Nja,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Wj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Vja,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Wj],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.G_,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.as],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Rz,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rg,$CLJS.as],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Rja,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.Ds],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JL,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Wj],null)],null)],null));
$CLJS.oJ(r_,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"Valid MetadataProvider"],null),new $CLJS.pd(function(){return $CLJS.CZ},Kja,$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,$CLJS.Zf,$CLJS.rj,$CLJS.jk,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[Hja,Zja,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.Y(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Jp],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.m($CLJS.CZ)?$CLJS.CZ.I:null]))],null));
$CLJS.oJ(Mja,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,r_],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.k(null,1,[$CLJS.Pt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,r_],null)],null)],null)],null));