var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var Pba,Qba,Rba,Sba,Tba,Uba,Vba,UH,Wba,Xba,Yba,Zba,$ba,aca,bca,cca,WH,dca;$CLJS.QH=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Pba=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.RH=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Qba=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Rba=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.SH=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Sba=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.TH=new $CLJS.M(null,"display-info","display-info",-816930907);Tba=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);Uba=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
Vba=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);UH=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Wba=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);Xba=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);Yba=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);Zba=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
$ba=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);aca=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);bca=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.VH=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);cca=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);WH=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.XH=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);dca=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.zT($CLJS.Aw,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GB,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ds,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sG],null)],null)],null)],null)]));$CLJS.zT($CLJS.pH,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GB,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ds,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sG],null)],null)],null)],null)]));
$CLJS.xT($CLJS.iH,$CLJS.F([$CLJS.Ft,$CLJS.YE,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));$CLJS.xT($CLJS.TG,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sG],null)],null)]));$CLJS.xT($CLJS.xH,$CLJS.F([$CLJS.Ft,$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rG],null)],null)]));
$CLJS.xT($CLJS.xl,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null)],null)]));$CLJS.mT($CLJS.xl,$CLJS.yG);$CLJS.xT($CLJS.CH,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));$CLJS.mT($CLJS.CH,$CLJS.yG);$CLJS.xT($CLJS.bk,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zG],null)],null)]));$CLJS.mT($CLJS.bk,$CLJS.yG);
$CLJS.oJ(UH,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.vs,new $CLJS.k(null,1,[$CLJS.Pt,"valid percentile"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.xT($CLJS.$G,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,UH],null)]));$CLJS.mT($CLJS.$G,$CLJS.yG);
$CLJS.xT($CLJS.vH,$CLJS.F([$CLJS.Ft,$CLJS.YE,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rG],null)],null)]));$CLJS.xT($CLJS.EH,$CLJS.F([$CLJS.Ft,$CLJS.YE,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));$CLJS.xT($CLJS.BH,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));
$CLJS.xT($CLJS.LH,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));$CLJS.mT($CLJS.BH,$CLJS.yG);$CLJS.mT($CLJS.LH,$CLJS.yG);$CLJS.xT($CLJS.JH,$CLJS.F([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rG],null)],null)]));$CLJS.mT($CLJS.JH,$CLJS.yG);
$CLJS.xT($CLJS.UG,$CLJS.F([$CLJS.Ft,$CLJS.YE,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.EG],null)],null)]));$CLJS.oJ(WH,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.os,cca,aca,Uba,Qba,Pba,Tba,Vba,Wba,Xba,$ba,Yba,dca,bca,Rba,Zba,$CLJS.Ib],null));$CLJS.oJ($CLJS.RH,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zj,new $CLJS.k(null,1,[$CLJS.bk,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,WH],null)],null));
$CLJS.tU=new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.k(null,4,[$CLJS.hH,$CLJS.Aw,$CLJS.SH,!1,$CLJS.VH,$CLJS.JG,$CLJS.TH,function(){return new $CLJS.k(null,3,[$CLJS.vF,$CLJS.$F("Count of rows"),$CLJS.ZG,$CLJS.$F("Count"),$CLJS.du,$CLJS.$F("Total number of rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.hH,$CLJS.BH,$CLJS.QH,$CLJS.Vj,$CLJS.SH,!0,$CLJS.VH,$CLJS.JG,$CLJS.TH,function(){return new $CLJS.k(null,3,[$CLJS.vF,$CLJS.$F("Sum of ..."),$CLJS.ZG,$CLJS.$F("Sum"),$CLJS.du,$CLJS.$F("Sum of all the values of a column.")],
null)}],null),new $CLJS.k(null,5,[$CLJS.hH,$CLJS.iH,$CLJS.QH,$CLJS.Vj,$CLJS.SH,!0,$CLJS.VH,$CLJS.JG,$CLJS.TH,function(){return new $CLJS.k(null,3,[$CLJS.vF,$CLJS.$F("Average of ..."),$CLJS.ZG,$CLJS.$F("Average"),$CLJS.du,$CLJS.$F("Average of all the values of a column")],null)}],null),new $CLJS.k(null,5,[$CLJS.hH,$CLJS.CH,$CLJS.QH,$CLJS.Vj,$CLJS.SH,!0,$CLJS.VH,$CLJS.kH,$CLJS.TH,function(){return new $CLJS.k(null,3,[$CLJS.vF,$CLJS.$F("Median of ..."),$CLJS.ZG,$CLJS.$F("Median"),$CLJS.du,$CLJS.$F("Median of all the values of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.hH,$CLJS.TG,$CLJS.QH,$CLJS.Fs,$CLJS.SH,!0,$CLJS.VH,$CLJS.JG,$CLJS.TH,function(){return new $CLJS.k(null,3,[$CLJS.vF,$CLJS.$F("Number of distinct values of ..."),$CLJS.ZG,$CLJS.$F("Distinct values"),$CLJS.du,$CLJS.$F("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.hH,$CLJS.LH,$CLJS.QH,$CLJS.Vj,$CLJS.SH,!0,$CLJS.VH,$CLJS.JG,$CLJS.TH,function(){return new $CLJS.k(null,3,[$CLJS.vF,$CLJS.$F("Cumulative sum of ..."),
$CLJS.ZG,$CLJS.$F("Sum"),$CLJS.du,$CLJS.$F("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.k(null,4,[$CLJS.hH,$CLJS.pH,$CLJS.SH,!1,$CLJS.VH,$CLJS.JG,$CLJS.TH,function(){return new $CLJS.k(null,3,[$CLJS.vF,$CLJS.$F("Cumulative count of rows"),$CLJS.ZG,$CLJS.$F("Count"),$CLJS.du,$CLJS.$F("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.k(null,5,[$CLJS.hH,$CLJS.EH,$CLJS.QH,$CLJS.Vj,$CLJS.SH,
!0,$CLJS.VH,$CLJS.yH,$CLJS.TH,function(){return new $CLJS.k(null,3,[$CLJS.vF,$CLJS.$F("Standard deviation of ..."),$CLJS.ZG,$CLJS.$F("SD"),$CLJS.du,$CLJS.$F("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.hH,$CLJS.bk,$CLJS.QH,$CLJS.qg,$CLJS.SH,!0,$CLJS.VH,$CLJS.JG,$CLJS.TH,function(){return new $CLJS.k(null,3,[$CLJS.vF,$CLJS.$F("Minimum of ..."),$CLJS.ZG,$CLJS.$F("Min"),$CLJS.du,$CLJS.$F("Minimum value of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.hH,$CLJS.xl,$CLJS.QH,$CLJS.qg,$CLJS.SH,!0,$CLJS.VH,$CLJS.JG,$CLJS.TH,function(){return new $CLJS.k(null,3,[$CLJS.vF,$CLJS.$F("Maximum of ..."),$CLJS.ZG,$CLJS.$F("Max"),$CLJS.du,$CLJS.$F("Maximum value of a column")],null)}],null)],null);
$CLJS.oJ(Sba,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.XH],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.hH,$CLJS.mh.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.ks],null),$CLJS.kg.g($CLJS.hH),$CLJS.tU)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QH,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.as],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SH,$CLJS.Ds],null),new $CLJS.Q(null,2,
5,$CLJS.R,[$CLJS.VH,$CLJS.as],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TH,$CLJS.$d],null)],null));