var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var tua,sua,y5,vua,wua,Woa;tua=function(a,b){return $CLJS.vE(function(c){return function(d,e,f){return $CLJS.m(a.g?a.g(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};sua=function(a,b){return $CLJS.Rc($CLJS.Ob(function(c,d){return $CLJS.ph.j(c,a.g?a.g(d):a.call(null,d),d)},$CLJS.Pc($CLJS.O),b))};y5=function(a){return $CLJS.mh.j($CLJS.ai,$CLJS.gm.h($CLJS.Mt(function(b){return $CLJS.WF(b,a)}),$CLJS.kg.g($CLJS.Vd)),uua)};
vua=function(a){return new $CLJS.k(null,3,[$CLJS.Ik,$CLJS.QB,$CLJS.W,a,$CLJS.rk,$CLJS.p.g($CLJS.jj())],null)};
wua=function(a){a=$CLJS.Of(a);var b=$CLJS.K.h(a,$CLJS.W);return $CLJS.xm.l($CLJS.F([a,function(){var c=$CLJS.pi(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.m(c)?($CLJS.G(c,0,null),c=$CLJS.G(c,1,null),c=$CLJS.sJ(c)):c=null;return $CLJS.m(c)?new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.WN,$CLJS.JU,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.za)(b.substring(8)):null;return $CLJS.m(c)?new $CLJS.k(null,2,[$CLJS.Ik,$CLJS.fN,$CLJS.SM,c],null):null}(),$CLJS.m($CLJS.vF.g(a))?null:new $CLJS.k(null,
1,[$CLJS.vF,$CLJS.t2.h($CLJS.RB,b)],null)]))};$CLJS.W7=function(a){a=$CLJS.yZ($CLJS.DZ(a));return $CLJS.m($CLJS.Ss(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Rz,Woa],null)))?$CLJS.gf.h($CLJS.ai,$CLJS.MO):$CLJS.ai};$CLJS.X7=function(a,b){var c=$CLJS.W7(a);return(0,$CLJS.R3)(a,0,function(d){var e=$CLJS.Fy.h(Xoa,c);d=$CLJS.P.j($CLJS.$l,d,e);d=$CLJS.xm.l($CLJS.F([d,$CLJS.Zh(b,c)]));$CLJS.Fy.h(c,$CLJS.di($CLJS.Uh(b)));$CLJS.JZ(a,0);return d})};$CLJS.Y7=function(a){return $CLJS.Pf($CLJS.Zh($CLJS.JZ(a,0),$CLJS.W7(a)))};
$CLJS.Z7=function(a){return $CLJS.gT.g($CLJS.JZ(a,0))};Woa=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.Yoa=new $CLJS.M(null,"write","write",-1857649168);$CLJS.Zoa=new $CLJS.M(null,"native-permissions","native-permissions",1277672164);var uua,Xoa;uua=new $CLJS.Q(null,3,5,$CLJS.R,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.$7=function(){function a(d,e){d=$CLJS.Pf(y5(d));var f=$CLJS.Pf($CLJS.di($CLJS.Uh(e)));if($CLJS.m($CLJS.m(d)?d:f)){var g=$CLJS.Fy.h(d,f);d=$CLJS.Fy.h(f,d);if($CLJS.E.l(1,$CLJS.D(g),$CLJS.F([$CLJS.D(d)]))){d=$CLJS.A(d);g=$CLJS.A(g);f=$CLJS.K.h(e,d);var l=$CLJS.E.h($CLJS.vF.g(f),$CLJS.t2.h($CLJS.RB,d))?$CLJS.t2.h($CLJS.RB,g):$CLJS.vF.g(f);f=$CLJS.T.l($CLJS.$l.l(f,$CLJS.SM,$CLJS.F([$CLJS.JU,$CLJS.IQ])),$CLJS.vF,l,$CLJS.F([$CLJS.W,g]));e=$CLJS.T.j($CLJS.$l.h(e,d),g,f)}else e=$CLJS.xm.l($CLJS.F([tua($CLJS.$f(d),
e),sua($CLJS.W,$CLJS.kg.h(vua,g))]));e=$CLJS.Us(e,wua)}else e=$CLJS.O;return e}function b(d){return $CLJS.$7.h?$CLJS.$7.h(d,null):$CLJS.$7.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();Xoa=new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.MO,null],null),null);
$CLJS.oJ(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MO,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.HF],null)],null));
$CLJS.a8=function(){function a(d,e,f,g){var l=$CLJS.$7.g(e);return $CLJS.X7($CLJS.B1.h(d,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.gU(new $CLJS.k(null,4,[$CLJS.su,$CLJS.fX,$CLJS.GW,f,$CLJS.aU,l,$CLJS.gT,e],null))],null)),g)}function b(d,e){return $CLJS.a8.B?$CLJS.a8.B(d,e,null,null):$CLJS.a8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.B=a;return c}();
$CLJS.b8=function(){function a(d,e,f){$CLJS.JZ(d,0);return $CLJS.X7($CLJS.B1.h(e,$CLJS.$L.g(d)),f)}function b(d,e){return $CLJS.b8.j?$CLJS.b8.j(d,e,null):$CLJS.b8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.j=a;return c}();$CLJS.A1.o(null,$CLJS.fX,function(a){return $CLJS.x1($CLJS.W7(a),$CLJS.di($CLJS.Uh($CLJS.Y7(a))))&&!$CLJS.at($CLJS.Z7(a))});