var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var nV,oV,Zca,$ca,ada,YI,ZI,$I,bJ,bda,dJ;nV=function(a){switch(arguments.length){case 2:return $CLJS.oT(arguments[0],arguments[1]);case 3:return $CLJS.nT(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};oV=function(a){return["(?:",$CLJS.p.g($CLJS.P.h($CLJS.p,a)),")?"].join("")};Zca=new $CLJS.M("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
$ca=new $CLJS.M("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.VI=new $CLJS.M("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);ada=new $CLJS.M("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.WI=new $CLJS.M("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
$CLJS.XI=new $CLJS.M("metabase.lib.schema.literal","string.zone-offset","metabase.lib.schema.literal/string.zone-offset",-437074263);YI=new $CLJS.M("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);ZI=new $CLJS.M("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);$I=new $CLJS.M("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);
$CLJS.aJ=new $CLJS.M("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);bJ=new $CLJS.M("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);bda=new $CLJS.M("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.cJ=new $CLJS.M("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);
dJ=new $CLJS.M("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);$CLJS.eJ=new $CLJS.M("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.DJ.o(null,$CLJS.OB,function(){return $CLJS.Aj});$CLJS.oJ(dJ,$CLJS.Ds);$CLJS.DJ.o(null,$CLJS.UB,function(){return $CLJS.Gj});$CLJS.oJ(dJ,$CLJS.Ds);$CLJS.oJ(bda,$CLJS.Yr);$CLJS.DJ.o(null,$CLJS.BB,function(){return $CLJS.wj});$CLJS.oJ($ca,$CLJS.Xr);$CLJS.DJ.o(null,$CLJS.yB,function(){return $CLJS.YE});$CLJS.oJ(ada,$CLJS.ql);
var foa=[":\\d{2}",oV($CLJS.F(["\\.\\d{1,6}"]))].join(""),zV=["\\d{2}:\\d{2}",oV($CLJS.F([foa]))].join(""),AV=["\\d{4}-\\d{2}-\\d{2}T",zV].join(""),BV=["(?:Z|(?:[+-]",zV,"))"].join(""),cda=$CLJS.Bn(["(?:Z|(?:[+-]",zV,"))"].join("")),goa=$CLJS.Bn("^\\d{4}-\\d{2}-\\d{2}$"),hoa=$CLJS.Bn(["^",zV,"$"].join("")),ioa=$CLJS.Bn(["^",zV,BV,"$"].join("")),joa=$CLJS.Bn(["^",AV,"$"].join("")),koa=$CLJS.Bn(["^",AV,BV,"$"].join(""));
$CLJS.oJ(ZI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ps,new $CLJS.k(null,1,[$CLJS.Pt,"date string literal"],null),goa],null));$CLJS.oJ($CLJS.XI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ps,new $CLJS.k(null,1,[$CLJS.Pt,"timezone offset string literal"],null),cda],null));
$CLJS.oJ(YI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ps,new $CLJS.k(null,1,[$CLJS.Pt,"local time string literal"],null),hoa],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ps,new $CLJS.k(null,1,[$CLJS.Pt,"offset time string literal"],null),ioa],null)],null));
$CLJS.oJ($I,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ps,new $CLJS.k(null,1,[$CLJS.Pt,"local date time string literal"],null),joa],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ps,new $CLJS.k(null,1,[$CLJS.Pt,"offset date time string literal"],null),koa],null)],null));
$CLJS.DJ.o(null,$CLJS.AB,function(a){return $CLJS.m($CLJS.oT?$CLJS.oT($I,a):nV.call(null,$I,a))?new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.Sj,null,$CLJS.kE,null],null),null):$CLJS.m($CLJS.oT?$CLJS.oT(ZI,a):nV.call(null,ZI,a))?new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.yD,null,$CLJS.Sj,null],null),null):$CLJS.m($CLJS.oT?$CLJS.oT(YI,a):nV.call(null,YI,a))?new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.aF,null,$CLJS.Sj,null],null),null):$CLJS.Sj});$CLJS.oJ($CLJS.aJ,ZI);$CLJS.oJ($CLJS.WI,YI);
$CLJS.oJ($CLJS.eJ,$I);$CLJS.oJ(Zca,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.os,$CLJS.aJ,$CLJS.WI,$CLJS.eJ],null));$CLJS.CV=$CLJS.Bn("^\\d{4}-\\d{2}$");$CLJS.oJ($CLJS.cJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ps,new $CLJS.k(null,1,[$CLJS.Pt,"year-month string literal"],null),$CLJS.CV],null));$CLJS.DV=$CLJS.Bn("^\\d{4}$");$CLJS.oJ($CLJS.VI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ps,new $CLJS.k(null,1,[$CLJS.Pt,"year string literal"],null),$CLJS.DV],null));
$CLJS.oJ(bJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.dG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Bf,$CLJS.gG],null)],null)],null));$CLJS.PH.h($CLJS.Yk,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.rs,new $CLJS.k(null,1,[$CLJS.Pt,"Value :value clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.Yk],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,bJ],null),$CLJS.Ib],null));