var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var mg=function(a,b){return new $CLJS.uf(null,function(){a:for(var c=a,d=b;;){d=$CLJS.z(d);var e;if(e=d)e=$CLJS.A(d),e=c.g?c.g(e):c.call(null,e);if($CLJS.m(e))d=$CLJS.vd(d);else break a}return d},null,null)},mi=function(a,b){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gi(a,b),mg(a,b)],null)},S1=function(a){var b=$CLJS.xm.l;var c=$CLJS.Of(a);var d=$CLJS.K.h(c,$CLJS.A5);c=$CLJS.K.h(c,$CLJS.B5);if($CLJS.m($CLJS.m(d)?$CLJS.E.h(c,"currency"):d)){c=$CLJS.q5.g;d=$CLJS.zi.g(d);var e=$CLJS.r($CLJS.C1);
d=e.g?e.g(d):e.call(null,d);c=c.call($CLJS.q5,d);c=new $CLJS.k(null,2,[$CLJS.t5,c,$CLJS.C5,c],null)}else c=new $CLJS.k(null,1,[$CLJS.C5,2],null);b=b.call($CLJS.xm,$CLJS.F([c,a]));return $CLJS.m($CLJS.K5.g(a))?$CLJS.T.l($CLJS.$l.h(b,$CLJS.K5),$CLJS.C5,$CLJS.K5.g(a),$CLJS.F([$CLJS.t5,$CLJS.K5.g(a)])):b},R1=function(a,b){if(null!=a&&null!=a.vf)a=a.vf(a,b);else{var c=R1[$CLJS.Ka(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=R1._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Lb("NumberFormatter.format-number-basic",
a);}return a},Rra=function(a,b){var c=$CLJS.hE(a.formatToParts(b),$CLJS.F([new $CLJS.k(null,1,[$CLJS.Rj,!0],null)]));a=$CLJS.m($CLJS.Vf(function(d){return $CLJS.E.h($CLJS.Ik.g(d),"exponentMinusSign")},c))?c:function(){var d=mi(function(f){return $CLJS.Yh.h($CLJS.Ik.g(f),"exponentInteger")},c),e=$CLJS.G(d,0,null);d=$CLJS.G(d,1,null);return $CLJS.sg.l(e,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.k(null,2,[$CLJS.Ik,"exponentPlusSign",$CLJS.Yk,"+"],null)],null),$CLJS.F([d]))}();return $CLJS.P.h($CLJS.p,
$CLJS.kg.h(function(d){var e=$CLJS.Of(d);d=$CLJS.K.h(e,$CLJS.Ik);e=$CLJS.K.h(e,$CLJS.Yk);switch(d){case "exponentSeparator":return"e";default:return e}},a))},Sra=function(a,b){switch($CLJS.B5.g(b)){case "scientific":return function(c){return Rra(a,c)};default:return function(c){return a.format(c)}}},R5=function(a,b,c,d,e){this.options=a;this.dh=b;this.gh=c;this.Ke=d;this.di=e;this.C=393216;this.J=0},Tra=function(a,b){return $CLJS.P.h($CLJS.p,function(){return function e(d){return new $CLJS.uf(null,
function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.me(f)){var g=$CLJS.Wc(f),l=$CLJS.D(g),n=$CLJS.xf(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.Yd(g,v),y=$CLJS.Of(x);x=$CLJS.K.h(y,$CLJS.Ik);y=$CLJS.K.h(y,$CLJS.Yk);var C=new $CLJS.U(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);C=C.g?C.g(x):C.call(null,x);$CLJS.m(C)&&(x=$CLJS.E.h(x,"integer")?b:y,n.add(x));v+=1}else return!0}()?$CLJS.Af($CLJS.Ef(n),e($CLJS.Xc(f))):$CLJS.Af($CLJS.Ef(n),null)}var q=$CLJS.A(f);q=$CLJS.Of(q);
var u=$CLJS.K.h(q,$CLJS.Ik);q=$CLJS.K.h(q,$CLJS.Yk);if($CLJS.m(function(){var v=new $CLJS.U(null,new $CLJS.k(null,2,["currency",null,"integer",null],null),null);return v.g?v.g(u):v.call(null,u)}()))return $CLJS.kf($CLJS.E.h(u,"integer")?b:q,e($CLJS.vd(f)));f=$CLJS.vd(f)}else return null},null,null)}($CLJS.hE(a.dh.formatToParts(1),$CLJS.F([$CLJS.Rj,!0])))}())},U1=function(a){var b=$CLJS.E.h($CLJS.B5.g(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.zm($CLJS.wE($CLJS.Eb,$CLJS.Qh([$CLJS.Fma,
$CLJS.pma,$CLJS.wma,$CLJS.zma,$CLJS.Hma,$CLJS.A5,$CLJS.xma,$CLJS.Dma,$CLJS.qma,$CLJS.vma],[$CLJS.C5.h(a,b),$CLJS.O5.g(a),$CLJS.N5.g(a),$CLJS.Ema.g(a),$CLJS.E.h($CLJS.B5.g(a),"scientific")?null:$CLJS.B5.h(a,"decimal"),$CLJS.A5.g(a),!0,$CLJS.t5.h(a,b),$CLJS.E.h($CLJS.B5.g(a),"scientific")?"scientific":null,$CLJS.Q5.g(a)]))));var c=$CLJS.O5.g(a);var d=$CLJS.A5.g(a);c=$CLJS.m(d)?null==c||$CLJS.E.h(c,"symbol"):d;d=Sra(b,a);return new R5(a,b,c,d,$CLJS.O)},V1=function(a,b){return U1(S1(b)).vf(null,a)},X1=
function(a,b){var c=$CLJS.$l.l(b,$CLJS.$B,$CLJS.F([$CLJS.B5])),d=Math.abs(a);if(0===a)return"0";if(1E3>d)return $CLJS.W1.h?$CLJS.W1.h(a,c):$CLJS.W1.call(null,a,c);b=$CLJS.A($CLJS.vg(function(f){return d>=$CLJS.A(f)},Ura));var e=$CLJS.G(b,0,null);b=$CLJS.G(b,1,null);return[$CLJS.p.g(function(){var f=a/e,g=$CLJS.xm.l($CLJS.F([c,new $CLJS.k(null,2,[$CLJS.t5,1,$CLJS.C5,1],null)]));return $CLJS.W1.h?$CLJS.W1.h(f,g):$CLJS.W1.call(null,f,g)}()),$CLJS.p.g(b)].join("")},Vra=function(a,b){var c=S1(b);if($CLJS.m(S5.g(c)))b=
S5.g(c);else if($CLJS.Bb($CLJS.K5.g(c))&&$CLJS.Bb($CLJS.t5.g(c))&&$CLJS.Yh.h($CLJS.B5.g(c),"currency")&&Math.abs(a)<($CLJS.E.h($CLJS.B5.g(c),"percent")?.01:1)){b=$CLJS.T.j;var d=$CLJS.$l.h(c,$CLJS.C5);c=$CLJS.Q5.h(c,0);b=U1(b.call($CLJS.T,d,$CLJS.N5,2>c?2:c))}else b=U1(c);return R1(b,a)};R5.prototype.M=function(a,b){return new R5(this.options,this.dh,this.gh,this.Ke,b)};R5.prototype.K=function(){return this.di};
R5.prototype.vf=function(a,b){a=this.Ke.g?this.Ke.g(b):this.Ke.call(null,b);var c=$CLJS.Gma.g(this.options);$CLJS.m($CLJS.m(c)?$CLJS.Yh.h(c,".,"):c)&&(b=$CLJS.A(c),c=$CLJS.Vd(c),a=$CLJS.Ys(a,/[\.,]/,new $CLJS.k(null,2,[",",$CLJS.m(c)?c:"",".",b],null)));$CLJS.m(this.gh)&&(b=$CLJS.A5.g(this.options),c=$CLJS.D1(b),a=$CLJS.Ys($CLJS.Ys(a,[$CLJS.fi(b)," "].join(""),c),$CLJS.fi(b),c));return a};
var S5=new $CLJS.M(null,"number-formatter","number-formatter",929788393),W5=new $CLJS.M(null,"scale","scale",-230427353),X5=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),Ima=new $CLJS.M(null,"scientific","scientific",316285837);var a2=new $CLJS.k(null,1,[$CLJS.O5,"symbol"],null),Xra=$CLJS.zm(a2),Ura=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[1E12,"T"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E9,"B"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E6,"M"],null),new $CLJS.Q(null,2,5,$CLJS.R,[1E3,"k"],null)],null),b2=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,g){f=$CLJS.Of(g);return $CLJS.K.h(f,$CLJS.B5)},e,a,b,c,d)}();b2.o(null,$CLJS.Dj,function(a,b){return X1(a,b)});b2.o(null,"percent",function(a,b){return[$CLJS.p.g(X1(100*a,b)),"%"].join("")});b2.o(null,"currency",function(a,b){b=$CLJS.xm.l($CLJS.F([b,a2]));var c=U1(b);return 1E3>Math.abs(a)?c.vf(null,a):Tra(c,X1(a,b))});b2.o(null,"scientific",function(a,b){return V1(a,$CLJS.xm.l($CLJS.F([b,new $CLJS.k(null,2,[$CLJS.C5,1,$CLJS.t5,1],null)])))});
$CLJS.W1=function W1(a,b){b=$CLJS.FE(b);var d=$CLJS.Of(b);b=$CLJS.K.h(d,$CLJS.$B);var e=$CLJS.K.h(d,X5),f=$CLJS.K.h(d,$CLJS.B5),g=$CLJS.K.h(d,W5);return $CLJS.m($CLJS.m(g)?!isNaN(g):g)?(b=g*a,f=$CLJS.$l.h(d,W5),W1.h?W1.h(b,f):W1.call(null,b,f)):$CLJS.m(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.g(function(){var l=-a,n=$CLJS.T.j(d,X5,!1);return W1.h?W1.h(l,n):W1.call(null,l,n)}()),")"].join(""):$CLJS.m(b)?b2.h(a,S1($CLJS.$l.h(d,$CLJS.$B))):$CLJS.E.h($CLJS.zi.g(f),Ima)?V1(a,d):Vra(a,d)};
module.exports={compact_currency_options_js:Xra,format_number:$CLJS.W1};