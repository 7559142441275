var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var c9,s9,n9,Mwa,y9,i9,Aqa,Gqa,Hqa,v9,Iqa,E9,Jqa,Rqa,Yqa;c9=function(a,b){return $CLJS.m($CLJS.m(a)?b in a:a)?$CLJS.Ra(a,b):null};s9=function(a,b){return n9(a,b,$CLJS.O)};
n9=function(a,b,c){c=$CLJS.Of(c);c=$CLJS.K.j(c,v9,!0);if($CLJS.je(b))return $CLJS.mh.j($CLJS.O,a,b);c=$CLJS.m(c)?function(){var d=c9(b,"_plainObject");d=null==d?null:$CLJS.iE(d);return null==d?null:$CLJS.Pf(d)}():null;return $CLJS.m(c)?$CLJS.mh.j($CLJS.O,a,c):$CLJS.mh.j($CLJS.O,$CLJS.gm.j($CLJS.kg.g(function(d){return new $CLJS.Q(null,2,5,$CLJS.R,[d,c9(b,d)],null)}),$CLJS.lm.g(function(d){$CLJS.G(d,0,null);return"function"===typeof $CLJS.G(d,1,null)}),a),$CLJS.Qa(b))};
Mwa=function(a){var b=e9.g(a),c=f9.g(a),d=x9.g(a);return $CLJS.gm.j($CLJS.kg.g(function(e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);var g=$CLJS.R;f=$CLJS.zi.g((0,$CLJS.EE)(f));if($CLJS.m(d)){var l=d.g?d.g(f):d.call(null,f);f=$CLJS.m(l)?l:f}return new $CLJS.Q(null,2,5,g,[f,e],null)}),$CLJS.ee(b)?$CLJS.Ve:$CLJS.lm.g(function(e){var f=$CLJS.G(e,0,null);$CLJS.G(e,1,null);return $CLJS.Ee(b,f)}),$CLJS.Bb(c)?$CLJS.Ve:$CLJS.kg.g(function(e){var f=$CLJS.G(e,0,null);e=$CLJS.G(e,1,null);return new $CLJS.Q(null,
2,5,$CLJS.R,[f,c.h?c.h(f,e):c.call(null,f,e)],null)}))};
y9=function(a,b){var c=Mwa(a),d=g9.g(a);return function(e){try{var f=$CLJS.T.j(n9(c,e,b),$CLJS.su,d),g=$CLJS.uE($CLJS.qx);$CLJS.m($CLJS.yE("metabase.lib.js.metadata",g))&&$CLJS.zE("metabase.lib.js.metadata",g,$CLJS.SY("Parsed metadata %s %s\n%s",$CLJS.F([a,$CLJS.rk.g(f),$CLJS.TY(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,g=$CLJS.uE($CLJS.tx),$CLJS.m($CLJS.yE("metabase.lib.js.metadata",g))&&(f instanceof Error?$CLJS.zE("metabase.lib.js.metadata",g,$CLJS.SY("Error parsing %s %s: %s",
$CLJS.F([a,$CLJS.Oi.l($CLJS.F([e])),$CLJS.MX(f)])),f):$CLJS.zE("metabase.lib.js.metadata",g,$CLJS.SY(f,$CLJS.F(["Error parsing %s %s: %s",a,$CLJS.Oi.l($CLJS.F([e])),$CLJS.MX(f)])),null)),null;throw l;}}};i9=function(a){return $CLJS.mm.h(y9($CLJS.oH,$CLJS.O),a)};Aqa=new $CLJS.M(null,"plain-object","plain-object",-943264281);$CLJS.A9=new $CLJS.M(null,"cards","cards",169174038);Gqa=new $CLJS.M(null,"db","db",993250759);$CLJS.B9=new $CLJS.M(null,"metrics","metrics",394093469);
Hqa=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.C9=new $CLJS.M(null,"segments","segments",1937535949);v9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);Iqa=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.D9=new $CLJS.M(null,"tables","tables",1334623052);E9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);Jqa=new $CLJS.M(null,"schema-name","schema-name",1666725119);Rqa=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);
Yqa=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);$CLJS.H9=new $CLJS.M(null,"databases","databases",2040134125);var e9,Zqa=$CLJS.ka($CLJS.O),$qa=$CLJS.ka($CLJS.O),ara=$CLJS.ka($CLJS.O),bra=$CLJS.ka($CLJS.O),cra=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null)),p9,sra,tra,ura,vra,wra;e9=new $CLJS.hj($CLJS.Fi.h("metabase.lib.js.metadata","excluded-keys"),$CLJS.zi,cra,Zqa,$qa,ara,bra);e9.o(null,$CLJS.Dj,function(){return null});var f9,dra=$CLJS.ka($CLJS.O),era=$CLJS.ka($CLJS.O),fra=$CLJS.ka($CLJS.O),gra=$CLJS.ka($CLJS.O),hra=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));
f9=new $CLJS.hj($CLJS.Fi.h("metabase.lib.js.metadata","parse-field-fn"),$CLJS.zi,hra,dra,era,fra,gra);f9.o(null,$CLJS.Dj,function(){return null});var g9,ira=$CLJS.ka($CLJS.O),jra=$CLJS.ka($CLJS.O),kra=$CLJS.ka($CLJS.O),lra=$CLJS.ka($CLJS.O),mra=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));g9=new $CLJS.hj($CLJS.Fi.h("metabase.lib.js.metadata","lib-type"),$CLJS.zi,mra,ira,jra,kra,lra);
var x9,nra=$CLJS.ka($CLJS.O),ora=$CLJS.ka($CLJS.O),pra=$CLJS.ka($CLJS.O),qra=$CLJS.ka($CLJS.O),rra=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));x9=new $CLJS.hj($CLJS.Fi.h("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Ve,rra,nra,ora,pra,qra);x9.o(null,$CLJS.Dj,function(){return null});
$CLJS.o9=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.zi.g(f)},e,a,b,c,d)}();sra=$CLJS.ka($CLJS.O);tra=$CLJS.ka($CLJS.O);ura=$CLJS.ka($CLJS.O);vra=$CLJS.ka($CLJS.O);wra=$CLJS.K.j($CLJS.O,$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));
p9=new $CLJS.hj($CLJS.Fi.h("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.zi,wra,sra,tra,ura,vra);$CLJS.o9.o(null,$CLJS.Dj,function(a,b){var c=y9(a,$CLJS.O);return s9($CLJS.kg.g(function(d){var e=$CLJS.G(d,0,null),f=$CLJS.G(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sJ(e),new $CLJS.Ji(function(){return c.g?c.g(f):c.call(null,f)})],null)}),c9(b,p9.g(a)))});g9.o(null,$CLJS.KQ,function(){return $CLJS.ML});
e9.o(null,$CLJS.KQ,function(){return new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.mG,null,$CLJS.D9,null],null),null)});f9.o(null,$CLJS.KQ,function(){return function(a,b){switch(a instanceof $CLJS.M?a.R:null){case "dbms-version":return $CLJS.hE(b,$CLJS.F([$CLJS.Rj,!0]));case "features":return $CLJS.mh.j($CLJS.ai,$CLJS.kg.g($CLJS.zi),b);case "native-permissions":return $CLJS.zi.g(b);default:return b}}});p9.o(null,$CLJS.KQ,function(){return"databases"});g9.o(null,$CLJS.DX,function(){return $CLJS.CL});
e9.o(null,$CLJS.DX,function(){return new $CLJS.U(null,new $CLJS.k(null,5,[E9,null,$CLJS.mG,null,$CLJS.C9,null,$CLJS.KQ,null,$CLJS.B9,null],null),null)});f9.o(null,$CLJS.DX,function(){return function(a,b){switch(a instanceof $CLJS.M?a.R:null){case "entity-type":return $CLJS.zi.g(b);case "field-order":return $CLJS.zi.g(b);case "initial-sync-status":return $CLJS.zi.g(b);case "visibility-type":return $CLJS.zi.g(b);default:return b}}});
$CLJS.o9.o(null,$CLJS.DX,function(a,b){var c=y9(a,$CLJS.O);return s9($CLJS.gm.h($CLJS.lm.g(function(d){var e=$CLJS.G(d,0,null);$CLJS.G(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.kg.g(function(d){var e=$CLJS.G(d,0,null),f=$CLJS.G(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sJ(e),new $CLJS.Ji(function(){return c.g?c.g(f):c.call(null,f)})],null)})),c9(b,"tables"))});g9.o(null,$CLJS.oH,function(){return $CLJS.vL});
e9.o(null,$CLJS.oH,function(){return new $CLJS.U(null,new $CLJS.k(null,7,[E9,null,$CLJS.DX,null,$CLJS.pM,null,Yqa,null,$CLJS.KQ,null,Rqa,null,$CLJS.B9,null],null),null)});x9.o(null,$CLJS.oH,function(){return new $CLJS.k(null,1,[$CLJS.yz,$CLJS.xL],null)});
f9.o(null,$CLJS.oH,function(){return function(a,b){switch(a instanceof $CLJS.M?a.R:null){case "base-type":return $CLJS.zi.g(b);case "coercion-strategy":return $CLJS.zi.g(b);case "effective-type":return $CLJS.zi.g(b);case "fingerprint":return $CLJS.je(b)?$CLJS.TX(b):$CLJS.hE(b,$CLJS.F([$CLJS.Rj,!0]));case "has-field-values":return $CLJS.zi.g(b);case "lib/source":return $CLJS.E.h(b,"aggregation")?$CLJS.QL:$CLJS.zi.h("source",b);case "semantic-type":return $CLJS.zi.g(b);case "visibility-type":return $CLJS.zi.g(b);
case "id":return $CLJS.le(b)?$CLJS.Vd(b):b;default:return b}}});
$CLJS.o9.o(null,$CLJS.oH,function(a,b){var c=y9(a,$CLJS.O),d=c9(b,"fields");return s9($CLJS.Nm(function(e){var f=$CLJS.G(e,0,null),g=$CLJS.G(e,1,null);e=function(){var l=$CLJS.sJ(f);if($CLJS.m(l))return l;l=$CLJS.pi(/card__\d+:(\d+)/,f);if($CLJS.m(l)){$CLJS.G(l,0,null);l=$CLJS.G(l,1,null);var n=null==c9(d,l);return n?$CLJS.sJ(l):n}return null}();return $CLJS.m(e)?new $CLJS.Q(null,2,5,$CLJS.R,[e,new $CLJS.Ji(function(){return c.g?c.g(g):c.call(null,g)})],null):null}),d)});g9.o(null,$CLJS.WN,function(){return $CLJS.PL});
e9.o(null,$CLJS.WN,function(){return new $CLJS.U(null,new $CLJS.k(null,11,[E9,null,$CLJS.Cl,null,Hqa,null,$CLJS.DX,null,Gqa,null,Aqa,null,$CLJS.C9,null,$CLJS.KQ,null,Iqa,null,$CLJS.B9,null,Jqa,null],null),null)});
f9.o(null,$CLJS.WN,function(){return function(a,b){switch(a instanceof $CLJS.M?a.R:null){case "result-metadata":return $CLJS.m($CLJS.OA($CLJS.ie,$CLJS.zb)(b))?i9(b):$CLJS.hE(b,$CLJS.F([$CLJS.Rj,!0]));case "fields":return i9(b);case "visibility-type":return $CLJS.zi.g(b);case "dataset-query":return $CLJS.hE(b,$CLJS.F([$CLJS.Rj,!0]));case "dataset":return b;default:return b}}});
$CLJS.o9.o(null,$CLJS.WN,function(a,b){return $CLJS.mh.j($CLJS.O,$CLJS.kg.g(function(c){return new $CLJS.Q(null,2,5,$CLJS.R,[c,new $CLJS.Ji(function(){var d=y9($CLJS.WN,new $CLJS.k(null,1,[v9,!1],null)),e=y9($CLJS.WN,$CLJS.O),f=$CLJS.xm.l;d=$CLJS.T.j(d(c9(c9(b,"tables"),["card__",$CLJS.p.g(c)].join(""))),$CLJS.rk,c);var g=c9(c9(b,"questions"),$CLJS.p.g(c));var l=c9(g,"_card");g=$CLJS.m(l)?l:g;e=e.g?e.g(g):e.call(null,g);return f.call($CLJS.xm,$CLJS.F([d,e]))})],null)}),$CLJS.mh.j($CLJS.mh.j($CLJS.ai,
$CLJS.Nm($CLJS.yX),$CLJS.Qa(c9(b,"tables"))),$CLJS.kg.g($CLJS.sJ),$CLJS.Qa(c9(b,"questions"))))});g9.o(null,$CLJS.jH,function(){return $CLJS.BL});e9.o(null,$CLJS.jH,function(){return new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.DX,null,$CLJS.KQ,null],null),null)});f9.o(null,$CLJS.jH,function(){return function(a,b){return b}});p9.o(null,$CLJS.jH,function(){return"metrics"});g9.o(null,$CLJS.MG,function(){return $CLJS.zL});
e9.o(null,$CLJS.MG,function(){return new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.DX,null,$CLJS.KQ,null],null),null)});f9.o(null,$CLJS.MG,function(){return function(a,b){return b}});p9.o(null,$CLJS.MG,function(){return"segments"});$CLJS.I9=y9($CLJS.oH,$CLJS.O);