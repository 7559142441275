var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Os,kqa,gY,WY,lqa,mqa,oqa,jY,via,uia;Os=function(a){return a+1};$CLJS.sJ=function(a){if("string"===typeof a){var b=$CLJS.pi(/[+-]?\d+/,a);return $CLJS.m(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.on(a));};
kqa=function(a){var b=new $CLJS.nb,c=$CLJS.Ty;$CLJS.Ty=new $CLJS.cd(b);try{var d=$CLJS.Ty,e=$CLJS.Bb($CLJS.aA(d)),f=$CLJS.Ty;$CLJS.Ty=e?$CLJS.Sz(d):d;try{d=$CLJS.cA;$CLJS.cA=!0;try{$CLJS.eA(a)}finally{$CLJS.cA=d}$CLJS.E.h(0,$CLJS.$y($CLJS.Ty,$CLJS.xu))||$CLJS.Mc($CLJS.Ty,"\n");$CLJS.Zy()}finally{$CLJS.Ty=f}$CLJS.Qy($CLJS.p.g(b))}finally{$CLJS.Ty=c}};$CLJS.SY=function(a,b){return $CLJS.P.j($CLJS.va,a,b)};
$CLJS.TY=function(a){var b=new $CLJS.nb,c=$CLJS.ha,d=$CLJS.wb;$CLJS.ha=!0;$CLJS.wb=function(e){return b.append(e)};try{kqa(a)}finally{$CLJS.wb=d,$CLJS.ha=c}return $CLJS.p.g(b)};
gY=function(a){a=$CLJS.Of(a);var b=$CLJS.K.j(a,$CLJS.LW,$CLJS.Ve),c=$CLJS.K.j(a,$CLJS.JW,function(f,g){return[$CLJS.p.g(f),"_",$CLJS.p.g(g)].join("")}),d=$CLJS.ka($CLJS.O),e=$CLJS.ka($CLJS.O);return function(){function f(n,q){for(;;){var u=b.g?b.g(q):b.call(null,q),v=function(){var y=new $CLJS.Q(null,2,5,$CLJS.R,[n,u],null),C=$CLJS.r(d);return C.g?C.g(y):C.call(null,y)}();if($CLJS.m(v))return v;var x=$CLJS.K.h($CLJS.Ri.B(e,$CLJS.nm,u,$CLJS.cg(Os,0)),u);if($CLJS.E.h(x,1))return $CLJS.Ri.B(d,$CLJS.T,
new $CLJS.Q(null,2,5,$CLJS.R,[n,u],null),q),q;q=function(){var y=q,C=$CLJS.p.g(x);return c.h?c.h(y,C):c.call(null,y,C)}()}}function g(n){var q=l,u=q.h;null==jY&&(jY=$CLJS.ka(0));var v=$CLJS.Fi.g([$CLJS.p.g("G__"),$CLJS.p.g($CLJS.Ri.h(jY,Os))].join(""));return u.call(q,v,n)}var l=null;l=function(n,q){switch(arguments.length){case 1:return g.call(this,n);case 2:return f.call(this,n,q)}throw Error("Invalid arity: "+arguments.length);};l.g=g;l.h=f;return l}()};
$CLJS.UY=function(a){return $CLJS.le(a)&&1<$CLJS.D(a)&&$CLJS.A(a)instanceof $CLJS.M&&$CLJS.je($CLJS.Vd(a))&&$CLJS.Ee($CLJS.Vd(a),$CLJS.eG)};$CLJS.VY=function(a){return $CLJS.UY(a)?$CLJS.Ss(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,$CLJS.uG],null)):null};$CLJS.l0=function(a,b){return $CLJS.bt($CLJS.UY(a)?a:new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yk,new $CLJS.k(null,2,[$CLJS.eG,$CLJS.p.g($CLJS.jj()),$CLJS.Bf,$CLJS.EJ(a)],null),a],null),new $CLJS.Q(null,2,5,$CLJS.R,[1,$CLJS.uG],null),b)};
WY=function(a,b,c){var d=$CLJS.Ee(a,b)?$CLJS.nm.j(a,b,function(e){return $CLJS.le(e)&&$CLJS.E.h($CLJS.A(e),$CLJS.vs)?$CLJS.Ng($CLJS.jg(1,e)):new $CLJS.Q(null,1,5,$CLJS.R,[e],null)}):a;return $CLJS.Ee(a,b)?$CLJS.SL(d,$CLJS.Nf([b,c])):d};lqa=function(a){var b=$CLJS.Zh(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FP,$CLJS.hU],null));b=XY.g?XY.g(b):XY.call(null,b);return $CLJS.gU($CLJS.T.l(WY($CLJS.$l.l(a,$CLJS.FP,$CLJS.F([$CLJS.hU])),$CLJS.EV,$CLJS.dM),$CLJS.su,$CLJS.VL,$CLJS.F([$CLJS.$L,b])))};
mqa=function(a){return $CLJS.mm.h(lqa,a)};oqa=function(a){return $CLJS.m(a)?$CLJS.T.j($CLJS.nm.j($CLJS.se(a)?new $CLJS.k(null,1,[$CLJS.wX,a],null):a,$CLJS.wX,function(b){return $CLJS.mm.h(function(c){return $CLJS.T.j($CLJS.xE(c,$CLJS.EE),$CLJS.su,$CLJS.vL)},b)}),$CLJS.su,uia):null};
$CLJS.ZY=function(a){if($CLJS.E.h($CLJS.su.g(a),$CLJS.lX))return a;var b=$CLJS.Ik.g(a);b=b instanceof $CLJS.M?b.R:null;switch(b){case "native":return $CLJS.xm.l($CLJS.F([new $CLJS.k(null,2,[$CLJS.su,$CLJS.lX,$CLJS.$L,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.xm.l($CLJS.F([new $CLJS.k(null,1,[$CLJS.su,$CLJS.fX],null),$CLJS.SL($CLJS.gT.g(a),new $CLJS.k(null,1,[$CLJS.hN,$CLJS.gT],null))]))],null)],null),$CLJS.$l.l(a,$CLJS.Ik,$CLJS.F([$CLJS.gT]))]));case "query":return $CLJS.xm.l($CLJS.F([new $CLJS.k(null,
2,[$CLJS.su,$CLJS.lX,$CLJS.$L,XY($CLJS.hN.g(a))],null),$CLJS.$l.l(a,$CLJS.Ik,$CLJS.F([$CLJS.hN]))]));default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.xX=function(a,b){a=$CLJS.Of(a);a=$CLJS.K.h(a,$CLJS.$L);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.nj($CLJS.ZF("Stage {0} does not exist",$CLJS.F([b])),new $CLJS.k(null,1,[via,$CLJS.D(a)],null));return c};
$CLJS.JZ=function(a,b){a=$CLJS.ZY(a);a=$CLJS.Of(a);var c=$CLJS.K.h(a,$CLJS.$L);return $CLJS.K.h($CLJS.Ng(c),$CLJS.xX(a,b))};$CLJS.yX=function(a){return"string"===typeof a&&(a=$CLJS.qi(/^card__(\d+)$/,a),$CLJS.m(a))?($CLJS.G(a,0,null),a=$CLJS.G(a,1,null),$CLJS.sJ(a)):null};$CLJS.M5=function(){return $CLJS.gm.h(L5,gY($CLJS.F([$CLJS.LW,$CLJS.BE,$CLJS.JW,function(a,b){return L5.g([$CLJS.p.g(a),"_",$CLJS.p.g(b)].join(""))}])))};jY=null;
$CLJS.zX=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.AX=new $CLJS.M(null,"join","join",-758861890);$CLJS.BX=new $CLJS.M(null,"stage-number","stage-number",-1752729638);$CLJS.CX=new $CLJS.M(null,"stage","stage",1843544772);via=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.wX=new $CLJS.M(null,"columns","columns",1998437288);$CLJS.DX=new $CLJS.M(null,"table","table",-564943036);
uia=new $CLJS.M("metadata","results","metadata/results",330329298);var XY,L5;
XY=function XY(a){a=$CLJS.Of(a);var c=$CLJS.K.h(a,$CLJS.hU),d=$CLJS.K.h(a,$CLJS.rP);c=$CLJS.m(c)?XY.g?XY.g(c):XY.call(null,c):$CLJS.Mg;d=oqa(d);var e=$CLJS.D(c)-1;c=$CLJS.m($CLJS.m(d)?!(0>e):d)?$CLJS.bt(c,new $CLJS.Q(null,2,5,$CLJS.R,[e,$CLJS.GW],null),d):c;d=$CLJS.m($CLJS.gT.g(a))?$CLJS.fX:$CLJS.ZW;a=$CLJS.xm.l($CLJS.F([new $CLJS.k(null,1,[$CLJS.su,d],null),$CLJS.$l.l(a,$CLJS.hU,$CLJS.F([$CLJS.rP]))]));a=$CLJS.z($CLJS.dO.g(a))?$CLJS.nm.j(a,$CLJS.dO,mqa):a;a=WY(a,$CLJS.SG,$CLJS.nX);return $CLJS.gf.h(c,a)};
$CLJS.R3=function(){function a(c,d,e,f){var g=null;if(3<arguments.length){g=0;for(var l=Array(arguments.length-3);g<l.length;)l[g]=arguments[g+3],++g;g=new $CLJS.rd(l,0,null)}return b.call(this,c,d,e,g)}function b(c,d,e,f){c=$CLJS.ZY(c);c=$CLJS.Of(c);var g=$CLJS.K.h(c,$CLJS.$L);d=$CLJS.xX(c,d);e=$CLJS.P.O($CLJS.nm,$CLJS.Ng(g),d,e,f);return $CLJS.T.j(c,$CLJS.$L,e)}a.A=3;a.D=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.vd(c);return b(d,e,f,c)};a.l=
b;return a}();
L5=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.jU.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.g(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.g(f)].join("")}function b(d){return c.h?c.h(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.h=a;return c}();$CLJS.iZ=RegExp(" id$","i");