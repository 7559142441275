var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var f6,j6,k6,v6,x6,y6,dna,z6,I6,T6,U6,W6;f6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.common","metabase.lib.schema.drill-thru/drill-thru.common",1573044895);$CLJS.g6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.pk","metabase.lib.schema.drill-thru/drill-thru.pk",339574481);$CLJS.h6=new $CLJS.M("drill-thru","quick-filter","drill-thru/quick-filter",-1465756422);$CLJS.i6=new $CLJS.M(null,"row","row",-570139521);
j6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.fk-details.fk-column","metabase.lib.schema.drill-thru/drill-thru.fk-details.fk-column",-380335831);k6=new $CLJS.M("metabase.lib.schema.drill-thru","context.row.value","metabase.lib.schema.drill-thru/context.row.value",1438731480);$CLJS.l6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.distribution","metabase.lib.schema.drill-thru/drill-thru.distribution",937770403);$CLJS.m6=new $CLJS.M(null,"pivots","pivots",90109371);
$CLJS.n6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.pivot","metabase.lib.schema.drill-thru/drill-thru.pivot",-1408664484);$CLJS.o6=new $CLJS.M("metabase.lib.schema.drill-thru","context","metabase.lib.schema.drill-thru/context",122725764);$CLJS.p6=new $CLJS.M(null,"location","location",1815599388);$CLJS.q6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.column-filter","metabase.lib.schema.drill-thru/drill-thru.column-filter",603928893);
$CLJS.r6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.summarize-column-by-time","metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time",1590982743);$CLJS.s6=new $CLJS.M(null,"initial-op","initial-op",918033121);$CLJS.t6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.summarize-column","metabase.lib.schema.drill-thru/drill-thru.summarize-column",1770935578);$CLJS.u6=new $CLJS.M("drill-thru","column-filter","drill-thru/column-filter",1535293733);
v6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.automatic-insights","metabase.lib.schema.drill-thru/drill-thru.automatic-insights",671448614);$CLJS.w6=new $CLJS.M("drill-thru","fk-filter","drill-thru/fk-filter",1598101197);x6=new $CLJS.M("drill-thru","automatic-insights","drill-thru/automatic-insights",10252211);y6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries.next-unit","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries.next-unit",-2018118498);
dna=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru","metabase.lib.schema.drill-thru/drill-thru",-124537581);z6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.object-details","metabase.lib.schema.drill-thru/drill-thru.object-details",-725614444);$CLJS.A6=new $CLJS.M("drill-thru","sort","drill-thru/sort",511059541);$CLJS.B6=new $CLJS.M("drill-thru","summarize-column","drill-thru/summarize-column",-156285576);$CLJS.C6=new $CLJS.M(null,"sort-directions","sort-directions",300459345);
$CLJS.D6=new $CLJS.M(null,"aggregations","aggregations",-1081114338);$CLJS.E6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.fk-details","metabase.lib.schema.drill-thru/drill-thru.fk-details",233147406);$CLJS.F6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.quick-filter","metabase.lib.schema.drill-thru/drill-thru.quick-filter",-1703184103);$CLJS.G6=new $CLJS.M(null,"row-count","row-count",1060167988);$CLJS.H6=new $CLJS.M("drill-thru","distribution","drill-thru/distribution",1480876450);
I6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.quick-filter.operator","metabase.lib.schema.drill-thru/drill-thru.quick-filter.operator",-1269703311);$CLJS.J6=new $CLJS.M("drill-thru","underlying-records","drill-thru/underlying-records",-1420917644);$CLJS.K6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.fk-filter","metabase.lib.schema.drill-thru/drill-thru.fk-filter",-1219793148);
$CLJS.L6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.zoom","metabase.lib.schema.drill-thru/drill-thru.zoom",-262809778);$CLJS.M6=new $CLJS.M(null,"next-unit","next-unit",166270362);$CLJS.N6=new $CLJS.M("drill-thru","summarize-column-by-time","drill-thru/summarize-column-by-time",-765720821);$CLJS.O6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries",-890057316);
$CLJS.P6=new $CLJS.M("drill-thru","pivot","drill-thru/pivot",-1038336574);$CLJS.Q6=new $CLJS.M("drill-thru","zoom-in.timeseries","drill-thru/zoom-in.timeseries",-168450975);$CLJS.R6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.underlying-records","metabase.lib.schema.drill-thru/drill-thru.underlying-records",1571614324);$CLJS.S6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.sort","metabase.lib.schema.drill-thru/drill-thru.sort",-920673199);
T6=new $CLJS.M("metabase.lib.schema.drill-thru","context.row","metabase.lib.schema.drill-thru/context.row",-1467255282);U6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.summarize-column.aggregation-type","metabase.lib.schema.drill-thru/drill-thru.summarize-column.aggregation-type",1395206778);$CLJS.V6=new $CLJS.M("drill-thru","zoom","drill-thru/zoom",-1212878631);W6=new $CLJS.M("metabase.lib.schema.drill-thru","pivot-types","metabase.lib.schema.drill-thru/pivot-types",1174694312);$CLJS.oJ(W6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ks,$CLJS.IH,$CLJS.p6,$CLJS.yx],null));$CLJS.oJ(f6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,$CLJS.lf],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.tM],null)],null)],null));
$CLJS.oJ(z6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zf,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b_],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iM,$CLJS.Fs],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fM,$CLJS.Ds],null)],null)],null));
$CLJS.oJ($CLJS.g6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,z6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.qM],null)],null)],null)],null));$CLJS.oJ(j6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b_],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.t_,$CLJS.OL],null)],null)],null));
$CLJS.oJ($CLJS.E6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,z6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.rM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zf,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,j6],null)],null)],null)],null));
$CLJS.oJ($CLJS.L6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,z6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.V6],null)],null)],null)],null));$CLJS.oJ(I6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.W,$CLJS.HF],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rG],null)],null)],null));
$CLJS.oJ($CLJS.F6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.h6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.oM,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,I6],null)],null)],null)],null));
$CLJS.oJ($CLJS.K6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.w6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SG,$CLJS.rG],null)],null)],null));
$CLJS.oJ($CLJS.l6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.H6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zf,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b_],null)],null)],null)],null));
$CLJS.oJ($CLJS.n6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.P6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.m6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ts,W6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b_],null)],null)],null)],null)],null)],null));
$CLJS.oJ($CLJS.S6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.A6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.C6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,$CLJS.kM],null)],null)],null)],null));$CLJS.oJ(U6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ks,$CLJS.iH,$CLJS.TG,$CLJS.BH],null));
$CLJS.oJ($CLJS.t6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.B6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zf,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b_],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.D6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,U6],null)],null)],null)],null)],null));
$CLJS.oJ($CLJS.r6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.N6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zf,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b_],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b_],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kb,$CLJS.fI],null)],null)],null));
$CLJS.oJ($CLJS.q6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.u6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zf,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b_],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.s6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.AK],null)],null)],null)],null));
$CLJS.oJ($CLJS.R6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.J6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.G6,$CLJS.Ab],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.v_,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Gb],null)],null)],null)],null));
$CLJS.oJ(v6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,x6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.tM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zf,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b_],null)],null)],null)],null));
$CLJS.oJ(y6,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ks,$CLJS.ib,$CLJS.xj,$CLJS.tj,$CLJS.uu,$CLJS.tu,$CLJS.ru],null));
$CLJS.oJ($CLJS.O6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,f6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ik,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.Q6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zf,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b_],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yk,$CLJS.Eb],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.M6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,y6],null)],null)],null)],null));
$CLJS.oJ(dna,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,f6,new $CLJS.Q(null,16,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Wf,$CLJS.Ik,$CLJS.Bt,function(a){a=$CLJS.Of(a);a=$CLJS.K.h(a,$CLJS.Yk);return["Invalid drill thru (unknown :type): ",$CLJS.Oi.l($CLJS.F([a]))].join("")}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.qM,$CLJS.g6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rM,$CLJS.E6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.V6,$CLJS.L6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.h6,$CLJS.F6],null),
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.w6,$CLJS.K6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.H6,$CLJS.l6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.P6,$CLJS.n6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.A6,$CLJS.S6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B6,$CLJS.t6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.N6,$CLJS.r6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.u6,$CLJS.q6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.J6,$CLJS.R6],null),new $CLJS.Q(null,2,5,$CLJS.R,[x6,v6],null),new $CLJS.Q(null,2,
5,$CLJS.R,[$CLJS.Q6,$CLJS.O6],null)],null)],null));$CLJS.oJ(k6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZG,$CLJS.Gb],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yk,$CLJS.Fs],null)],null));$CLJS.oJ(T6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,k6],null)],null));
$CLJS.oJ($CLJS.o6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zf,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b_],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,$CLJS.Fs],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.i6,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,T6],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pM,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ns,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,T6],null)],null)],null)],null));