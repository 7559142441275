var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var MW,OW,PW,dpa,tX,UW,VW,jia,WW,XW,YW,$W,aX,bX,cX,dX,eX,gX,hX,iX,jX,kX,oX,uX,iia;MW=function(a){return $CLJS.mh.j($CLJS.O,$CLJS.lm.g(function(b){var c=$CLJS.G(b,0,null);$CLJS.G(b,1,null);return $CLJS.tf(c)}),a)};
$CLJS.NW=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.P.h($CLJS.am,function(){return function e(d){return new $CLJS.uf(null,function(){for(;;){var f=$CLJS.z(d);if(f){var g=f;if($CLJS.me(g)){var l=$CLJS.Wc(g),n=$CLJS.D(l),q=$CLJS.xf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.Yd(l,v);$CLJS.Df(q,$CLJS.gna(x,function(){return function(y){return $CLJS.$l.l(MW(y),$CLJS.JB,$CLJS.F([$CLJS.Bf]))}}(v,x,l,n,q,g,f,b)));v+=1}else return!0}()?$CLJS.Af($CLJS.Ef(q),e($CLJS.Xc(g))):$CLJS.Af($CLJS.Ef(q),
null)}var u=$CLJS.A(g);return $CLJS.kf($CLJS.gna(u,function(){return function(v){return $CLJS.$l.l(MW(v),$CLJS.JB,$CLJS.F([$CLJS.Bf]))}}(u,g,f,b)),e($CLJS.vd(g)))}return null}},null,null)}(a)}())};
OW=function(a,b){a=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mg,a],null)],null);for(var c=$CLJS.Mg;;){var d=$CLJS.ce(a);if($CLJS.m(d)){var e=d,f=$CLJS.G(e,0,null),g=$CLJS.G(e,1,null),l=e,n=$CLJS.de(a);a=function(q,u,v,x,y,C,H,J){return function(S){return $CLJS.mh.j(v,$CLJS.kg.g(function(V,da,fa,ma,Fa){return function(ab){var Cb=$CLJS.G(ab,0,null);ab=$CLJS.G(ab,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gf.h(Fa,Cb),ab],null)}}(q,u,v,x,y,C,H,J)),S)}}(a,c,n,e,f,g,l,d);
$CLJS.m(b.g?b.g(g):b.call(null,g))?(a=n,c=$CLJS.gf.h(c,l)):a=$CLJS.je(g)?a(g):$CLJS.ie(g)?a($CLJS.dg($CLJS.rm,g)):n}else return c}};PW=function(a,b,c){return $CLJS.le(c)&&$CLJS.E.h(a,$CLJS.A(c))&&!$CLJS.Ee(b,$CLJS.K.h(c,2))};$CLJS.QW=function(a){var b=$CLJS.mh.j($CLJS.ai,$CLJS.kg.g($CLJS.gm.h($CLJS.uG,$CLJS.Vd)),$CLJS.QR.g(a));return OW($CLJS.$l.l(a,$CLJS.dO,$CLJS.F([$CLJS.GW])),function(c){return PW($CLJS.GB,b,c)})};
$CLJS.RW=function(a){var b=$CLJS.mh.j($CLJS.ai,$CLJS.kg.g($CLJS.gm.h($CLJS.eG,$CLJS.Vd)),$CLJS.dH.g(a));return OW($CLJS.$l.l(a,$CLJS.dO,$CLJS.F([$CLJS.GW])),function(c){return PW($CLJS.dH,b,c)})};dpa=function(a){function b(d){return $CLJS.tg(c,$CLJS.F([$CLJS.dO.g(d)]))}function c(d){return $CLJS.kf($CLJS.qG.g(d),$CLJS.tg(b,$CLJS.F([$CLJS.$L.g(d)])))}return $CLJS.m($CLJS.TW.g(a))?$CLJS.ag(!0):$CLJS.di(b(a))};
tX=function(a){if($CLJS.ie(a))for(var b=$CLJS.ag(!1),c=$CLJS.z(a),d=$CLJS.A(c),e=$CLJS.B(c),f=b,g=0,l=a;;){var n=f,q=g,u=l,v=$CLJS.z(u),x=$CLJS.A(v),y=$CLJS.B(v),C=x,H=y,J=$CLJS.OA(n,dpa(C));f=$CLJS.A($CLJS.z($CLJS.vg($CLJS.Eb,function(S,V,da,fa,ma,Fa){return function Xb(Cb,Rb){try{if($CLJS.le(Rb)&&3===$CLJS.D(Rb))try{var jc=$CLJS.Pd(Rb,0);if($CLJS.N(jc,$CLJS.oH))try{var Db=$CLJS.Pd(Rb,1);if($CLJS.m($CLJS.UP.g(Db)))try{var Vb=$CLJS.Pd(Rb,1);if(null!=Vb?Vb.C&256||$CLJS.t===Vb.Rf||(Vb.C?0:$CLJS.Jb($CLJS.hc,
Vb)):$CLJS.Jb($CLJS.hc,Vb))try{var xd=$CLJS.K.j(Vb,$CLJS.UP,$CLJS.KW);if($CLJS.m($CLJS.$f(fa)(xd))){var $t=$CLJS.K.h(Vb,$CLJS.UP);$CLJS.Pd(Rb,2);return new $CLJS.Q(null,1,5,$CLJS.R,[["Invalid :field reference in stage ",$CLJS.p.g(Fa),": no join named ",$CLJS.Oi.l($CLJS.F([$t]))].join("")],null)}throw $CLJS.Z;}catch(Xf){if(Xf instanceof Error){var jh=Xf;if(jh===$CLJS.Z)throw $CLJS.Z;throw jh;}throw Xf;}else throw $CLJS.Z;}catch(Xf){if(Xf instanceof Error){jh=Xf;if(jh===$CLJS.Z)throw $CLJS.Z;throw jh;
}throw Xf;}else throw $CLJS.Z;}catch(Xf){if(Xf instanceof Error){jh=Xf;if(jh===$CLJS.Z)throw $CLJS.Z;throw jh;}throw Xf;}else throw $CLJS.Z;}catch(Xf){if(Xf instanceof Error){jh=Xf;if(jh===$CLJS.Z)throw $CLJS.Z;throw jh;}throw Xf;}else throw $CLJS.Z;}catch(Xf){if(Xf instanceof Error){jh=Xf;if(jh===$CLJS.Z)return $CLJS.sX(Xb,Cb,Rb);throw jh;}throw Xf;}}}(f,g,l,J,n,q,u,v,x,y,C,H,b,0,a,a,c,d,e,d,e)($CLJS.Mg,$CLJS.$l.l(C,$CLJS.dO,$CLJS.F([iia]))))));if($CLJS.m(f))return f;if($CLJS.z(H))g=q+1,f=J,l=H;
else return null}else return null};UW=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);VW=new $CLJS.w("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);jia=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);WW=new $CLJS.w(null,"distinct-refs?","distinct-refs?",-2065255505,null);
XW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);YW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.ZW=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);$W=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
aX=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);bX=new $CLJS.w(null,"refs","refs",80480079,null);cX=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);dX=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);eX=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.fX=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);gX=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);hX=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);iX=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);jX=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
kX=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.lX=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.TW=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.mX=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.nX=new $CLJS.M(null,"filters","filters",974726919);oX=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);
uX=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);$CLJS.vX=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);iia=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.oJ(cX,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.fX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gT,$CLJS.Eb],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,$CLJS.Ib],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.MO,new $CLJS.k(null,1,[$CLJS.Js,!0],null),$CLJS.HF],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aU,new $CLJS.k(null,1,[$CLJS.Js,
!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.yW],null)],null)],null));$CLJS.oJ(hX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sH],null));
$CLJS.oJ(UW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zj,new $CLJS.k(null,1,[$CLJS.bk,1],null),hX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"Breakouts must be distinct"],null),new $CLJS.pd(function(){return $CLJS.NW},VW,$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,$CLJS.Zf,$CLJS.rj,$CLJS.jk,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.HW,WW,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.Y(new $CLJS.Q(null,1,5,$CLJS.R,[bX],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.m($CLJS.NW)?$CLJS.NW.I:null]))],null)],null));
$CLJS.oJ(iX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zj,new $CLJS.k(null,1,[$CLJS.bk,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sH],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,":fields must be distinct"],null),new $CLJS.pd(function(){return $CLJS.NW},VW,$CLJS.Qh([$CLJS.pk,$CLJS.W,$CLJS.gk,$CLJS.mb,$CLJS.Zf,$CLJS.rj,$CLJS.jk,$CLJS.lb,$CLJS.Xj,$CLJS.fk],[$CLJS.HW,WW,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.Y(new $CLJS.Q(null,
1,5,$CLJS.R,[bX],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.m($CLJS.NW)?$CLJS.NW.I:null]))],null)],null));$CLJS.oJ(jX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.rG],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.rs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.MG],null),$CLJS.Wj,$CLJS.ql],null)],null));
$CLJS.oJ(gX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zj,new $CLJS.k(null,1,[$CLJS.bk,1],null),jX],null));
var LX=$CLJS.OA(function(a){a=$CLJS.A($CLJS.QW(a));if($CLJS.m(a)){var b=$CLJS.Ss(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.m(b)?["Invalid :expression reference: no expression named ",$CLJS.Oi.l($CLJS.F([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.g($CLJS.K.h(a,1))].join("")}return null},function(a){a=$CLJS.A($CLJS.RW(a));if($CLJS.m(a)){var b=$CLJS.Ss(a,new $CLJS.Q(null,2,5,$CLJS.R,[1,2],null));return $CLJS.m(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.g(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.g($CLJS.K.h(a,1))].join("")}return null});$CLJS.oJ(kX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,2,[$CLJS.Pt,"Valid references for a single query stage",$CLJS.Bt,function(a){a=$CLJS.Of(a);a=$CLJS.K.h(a,$CLJS.Yk);return LX(a)}],null),$CLJS.$f(LX)],null));
$CLJS.oJ($CLJS.vX,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.ZW],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dO,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.YL],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QR,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.cH,new $CLJS.k(null,1,[$CLJS.Js,!0],null),UW],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dH,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.RH],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mG,new $CLJS.k(null,1,[$CLJS.Js,!0],null),iX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.nX,new $CLJS.k(null,1,[$CLJS.Js,!0],null),gX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xV,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.sM],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FP,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.AL],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TW,new $CLJS.k(null,1,[$CLJS.Js,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.FL],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Ee(a,$CLJS.hU)}],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,
new $CLJS.k(null,1,[$CLJS.Pt,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.$f($CLJS.CM($CLJS.FP,$CLJS.TW))],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,kX],null)],null));$CLJS.oJ(XW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.vX],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FP,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.AL],null)],null)],null)],null));
$CLJS.oJ(YW,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.vX],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.FL],null)],null)],null)],null));$CLJS.oJ(aX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,XW],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,YW],null)],null));
$CLJS.oJ(dX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.vX],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.$f($CLJS.OA($CLJS.FP,$CLJS.TW))],null)],null));$CLJS.oJ(eX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ks,$CLJS.fX,$CLJS.ZW],null));
$CLJS.oJ(jia,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,eX],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,1,[$CLJS.Wf,$CLJS.su],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.vX],null)],null)],null)],null));
$CLJS.oJ(uX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,eX],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,1,[$CLJS.Wf,$CLJS.su],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fX,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,cX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZW,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,aX],null)],null)],null)],null));$CLJS.oJ($W,dX);
$CLJS.oJ(oX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,2,[$CLJS.Pt,"Valid references for all query stages",$CLJS.Bt,function(a){a=$CLJS.Of(a);a=$CLJS.K.h(a,$CLJS.Yk);return tX.g?tX.g(a):tX.call(null,a)}],null),$CLJS.$f(tX)],null));
$CLJS.oJ($CLJS.WL,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,uX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cs,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$W],null)],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,oX],null)],null));
$CLJS.oJ($CLJS.mX,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vs,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.su,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,$CLJS.lX],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KQ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.os,$CLJS.GL,$CLJS.LL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$L,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WL],null)],null)],null),$CLJS.cpa],null));