var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var mx,GG,kT,Oba;mx=function(a){if(null!=a&&null!=a.pa)a=a.cache;else{var b=mx[$CLJS.Ka(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=mx._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Lb("Cached.-cache",a);}return a};
$CLJS.px=function(a,b,c){if(null!=a&&$CLJS.t===a.bb){var d=mx(a);var e=$CLJS.r(d);e=e.g?e.g(b):e.call(null,b);if($CLJS.m(e))return e;a=$CLJS.Ri.B(d,$CLJS.T,b,c.g?c.g(a):c.call(null,a));return a.g?a.g(b):a.call(null,b)}return c.g?c.g(a):c.call(null,a)};$CLJS.nT=function(a,b,c){a=$CLJS.rx.h(a,c);return a.g?a.g(b):a.call(null,b)};$CLJS.oT=function(a,b){return $CLJS.nT(a,b,null)};$CLJS.mT=function(a,b){$CLJS.Ri.B($CLJS.JF,$CLJS.GE,a,b)};$CLJS.lT=function(a,b){a=kT(a,b);return $CLJS.m(a)?a:$CLJS.Aj};
GG=function(){return $CLJS.mh.j(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.us,new $CLJS.k(null,2,[$CLJS.Wf,$CLJS.A,$CLJS.Bt,function(a){a=$CLJS.Of(a);a=$CLJS.K.h(a,$CLJS.Yk);return $CLJS.le(a)?["Invalid ",$CLJS.Oi.l($CLJS.F([$CLJS.A(a)]))," clause: ",$CLJS.Oi.l($CLJS.F([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Vk,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ls,new $CLJS.k(null,1,[$CLJS.Pt,"not a known MBQL clause"],null),$CLJS.ag(!1)],null)],null)],null),$CLJS.kg.g(function(a){return new $CLJS.Q(null,
2,5,$CLJS.R,[a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.zi.h("mbql.clause",$CLJS.fi(a))],null)],null)}),$CLJS.r(qT))};$CLJS.xT=function(a,b){$CLJS.P.B(vT,wT,a,b)};$CLJS.zT=function(a,b){$CLJS.P.B(vT,yT,a,b)};$CLJS.fl.prototype.pa=$CLJS.Ja(26,function(){return this.cache});$CLJS.hl.prototype.pa=$CLJS.Ja(25,function(){return this.cache});$CLJS.ll.prototype.pa=$CLJS.Ja(24,function(){return this.cache});$CLJS.ol.prototype.pa=$CLJS.Ja(23,function(){return this.cache});
$CLJS.sl.prototype.pa=$CLJS.Ja(22,function(){return this.cache});$CLJS.ul.prototype.pa=$CLJS.Ja(21,function(){return this.cache});$CLJS.Al.prototype.pa=$CLJS.Ja(20,function(){return this.cache});$CLJS.Dl.prototype.pa=$CLJS.Ja(19,function(){return this.cache});$CLJS.Fl.prototype.pa=$CLJS.Ja(18,function(){return this.cache});$CLJS.Hl.prototype.pa=$CLJS.Ja(17,function(){return this.cache});$CLJS.Jl.prototype.pa=$CLJS.Ja(16,function(){return this.cache});$CLJS.Nl.prototype.pa=$CLJS.Ja(15,function(){return this.cache});
$CLJS.Pl.prototype.pa=$CLJS.Ja(14,function(){return this.cache});$CLJS.Rl.prototype.pa=$CLJS.Ja(13,function(){return this.cache});$CLJS.Ul.prototype.pa=$CLJS.Ja(12,function(){return this.cache});$CLJS.Xl.prototype.pa=$CLJS.Ja(11,function(){return this.cache});$CLJS.jm.prototype.pa=$CLJS.Ja(10,function(){return this.cache});$CLJS.dn.prototype.pa=$CLJS.Ja(9,function(){return this.cache});$CLJS.Hn.prototype.pa=$CLJS.Ja(8,function(){return this.cache});$CLJS.go.prototype.pa=$CLJS.Ja(7,function(){return this.cache});
$CLJS.gp.prototype.pa=$CLJS.Ja(6,function(){return this.cache});$CLJS.dF.prototype.pa=$CLJS.Ja(5,function(){return this.cache});$CLJS.rx=function rx(a){switch(arguments.length){case 1:return rx.g(arguments[0]);case 2:return rx.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.rx.g=function(a){return $CLJS.rx.h(a,null)};$CLJS.rx.h=function(a,b){return $CLJS.px($CLJS.Mo.h(a,b),$CLJS.Ml,$CLJS.Pn)};$CLJS.rx.A=2;
kT=function kT(a,b){return $CLJS.E.h(a,$CLJS.Aj)?null:$CLJS.E.h(b,$CLJS.Aj)?null:$CLJS.XF(a,b)?b:$CLJS.XF(b,a)?a:$CLJS.Vf(function(d){return $CLJS.Vf(function(e){return $CLJS.E.h(new $CLJS.Q(null,2,5,$CLJS.R,[d,e],null),new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null))?null:kT.h?kT.h(d,e):kT.call(null,d,e)},$CLJS.kf(b,$CLJS.bj(b)))},$CLJS.kf(a,$CLJS.bj(a)))};$CLJS.HG=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.IG=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.JG=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.KG=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.LG=new $CLJS.M(null,"power","power",-937852079);$CLJS.MG=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.NG=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.OG=new $CLJS.M(null,"abs","abs",-246026477);$CLJS.PG=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.QG=new $CLJS.M(null,"between","between",1131099276);
$CLJS.RG=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.SG=new $CLJS.M(null,"filter","filter",-948537934);$CLJS.TG=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.UG=new $CLJS.M(null,"var","var",-769682797);$CLJS.VG=new $CLJS.M(null,"next","next",-117701485);$CLJS.WG=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.XG=new $CLJS.M(null,"case","case",1143702196);$CLJS.YG=new $CLJS.M(null,"not-empty","not-empty",388922063);
$CLJS.ZG=new $CLJS.M(null,"column-name","column-name",551523580);$CLJS.$G=new $CLJS.M(null,"percentile","percentile",-601188752);$CLJS.aH=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.bH=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.cH=new $CLJS.M(null,"breakout","breakout",-732419050);$CLJS.dH=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.eH=new $CLJS.M(null,"desc","desc",2093485764);$CLJS.fH=new $CLJS.M(null,"starts-with","starts-with",366503009);
$CLJS.gH=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.hH=new $CLJS.M(null,"short","short",1928760516);$CLJS.iH=new $CLJS.M(null,"avg","avg",197406200);$CLJS.jH=new $CLJS.M(null,"metric","metric",408798077);$CLJS.kH=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.lH=new $CLJS.M(null,"asc","asc",356854569);$CLJS.mH=new $CLJS.M(null,"floor","floor",1882041021);$CLJS.nH=new $CLJS.M(null,"contains","contains",676899812);
$CLJS.oH=new $CLJS.M(null,"field","field",-1302436500);$CLJS.pH=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.qH=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);$CLJS.rH=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.sH=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.tH=new $CLJS.M(null,"round","round",2009433328);$CLJS.uH=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);
$CLJS.vH=new $CLJS.M(null,"share","share",-589433933);$CLJS.wH=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.xH=new $CLJS.M(null,"count-where","count-where",385407720);$CLJS.yH=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.zH=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.AH=new $CLJS.M(null,"time-interval","time-interval",704622015);$CLJS.BH=new $CLJS.M(null,"sum","sum",136986814);
$CLJS.CH=new $CLJS.M(null,"median","median",569566131);Oba=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.DH=new $CLJS.M(null,"include-current","include-current",-1602371981);$CLJS.EH=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.FH=new $CLJS.M(null,"trim","trim",774319767);$CLJS.GH=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.HH=new $CLJS.M(null,"ltrim","ltrim",1654269283);
$CLJS.IH=new $CLJS.M(null,"category","category",-593092832);$CLJS.JH=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.KH=new $CLJS.M(null,"/","/",1282502798);$CLJS.LH=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.MH=new $CLJS.M(null,"log","log",-1595516004);$CLJS.NH=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.OH=new $CLJS.M(null,"rtrim","rtrim",-661336449);var qT=$CLJS.ka($CLJS.ai),yT;$CLJS.oJ($CLJS.kG,GG());$CLJS.LF(qT,Oba,function(){return $CLJS.oJ($CLJS.kG,GG())});
$CLJS.PH=function(){function a(d,e,f,g){c.h?c.h(d,g):c.call(null,d,g);$CLJS.DJ.o(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.zi.h("mbql.clause",$CLJS.fi(d));$CLJS.oJ(f,e);$CLJS.Ee($CLJS.r(qT),d)||$CLJS.Ri.j(qT,$CLJS.gf,d);return null}var c=null;c=function(d,e,f,g){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.B=a;return c}();
yT=function yT(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return yT.l(arguments[0],1<c.length?new $CLJS.rd(c.slice(1),0,null):null)};
yT.l=function(a,b){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,$CLJS.mh.h(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.is,new $CLJS.k(null,1,[$CLJS.Pt,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,a],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Qi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Cl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.dG],null)],null)],null)],null),b)],null)};yT.A=1;
yT.D=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};var wT=function wT(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return wT.l(arguments[0],1<c.length?new $CLJS.rd(c.slice(1),0,null):null)};
wT.l=function(a,b){return $CLJS.mh.h(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.rs,new $CLJS.k(null,1,[$CLJS.Pt,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zr,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.dG],null)],null),b)};wT.A=1;wT.D=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var vT=function vT(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return vT.l(arguments[0],arguments[1],2<c.length?new $CLJS.rd(c.slice(2),0,null):null)};vT.l=function(a,b,c){c=$CLJS.E.h($CLJS.A(c),$CLJS.Ft)?$CLJS.kf($CLJS.Vd(c),$CLJS.jg(2,c)):$CLJS.kf(null,c);var d=$CLJS.z(c);c=$CLJS.A(d);d=$CLJS.B(d);a=$CLJS.P.j(a,b,d);return $CLJS.m(c)?$CLJS.PH.B(b,$CLJS.Ft,c,a):$CLJS.PH.h(b,a)};vT.A=2;
vT.D=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};