var $CLJS = module.exports = {};
/*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/
'use strict';var ta,ua,aa,ba,wa,ca;ta=function(){var a=$CLJS.sa.navigator;return a&&(a=a.userAgent)?a:""};ua=function(a){return-1!=ta().indexOf(a)};aa=function(){return ua("Firefox")||ua("FxiOS")};ba=function(){return(ua("Chrome")||ua("CriOS"))&&!ua("Edge")||ua("Silk")};
$CLJS.va=function(a,b){const c=Array.prototype.slice.call(arguments),d=c.shift();if("undefined"==typeof d)throw Error("[goog.string.format] Template required");return d.replace(/%([0\- \+]*)(\d+)?(\.(\d+))?([%sfdiu])/g,function(e,f,g,l,n,q,u,v){if("%"==q)return"%";const x=c.shift();if("undefined"==typeof x)throw Error("[goog.string.format] Not enough arguments");arguments[0]=x;return $CLJS.va.Yc[q].apply(null,arguments)})};wa=function(){return ua("iPhone")&&!ua("iPod")&&!ua("iPad")};
ca=function(){wa()||ua("iPad")||ua("iPod")};$CLJS.xa=$CLJS.xa||{};$CLJS.sa=global;$CLJS.ya="closure_uid_"+(1E9*Math.random()>>>0);$CLJS.sa=global;$CLJS.za=String.prototype.trim?function(a){return a.trim()}:function(a){return/^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1]};var Aa=String.prototype.repeat?function(a,b){return a.repeat(b)}:function(a,b){return Array(b+1).join(a)};$CLJS.Ca=class{constructor(a,b){this.name=a;this.value=b}toString(){return this.name}};$CLJS.Da=new $CLJS.Ca("OFF",Infinity);$CLJS.Ea=[$CLJS.Da,new $CLJS.Ca("SHOUT",1200),new $CLJS.Ca("SEVERE",1E3),new $CLJS.Ca("WARNING",900),new $CLJS.Ca("INFO",800),new $CLJS.Ca("CONFIG",700),new $CLJS.Ca("FINE",500),new $CLJS.Ca("FINER",400),new $CLJS.Ca("FINEST",300),new $CLJS.Ca("ALL",0)];$CLJS.va.Yc={};$CLJS.va.Yc.s=function(a,b,c){return isNaN(c)||""==c||a.length>=Number(c)?a:a=-1<b.indexOf("-",0)?a+Aa(" ",Number(c)-a.length):Aa(" ",Number(c)-a.length)+a};
$CLJS.va.Yc.f=function(a,b,c,d,e){d=a.toString();isNaN(e)||""==e||(d=parseFloat(a).toFixed(e));let f;f=0>Number(a)?"-":0<=b.indexOf("+")?"+":0<=b.indexOf(" ")?" ":"";0<=Number(a)&&(d=f+d);if(isNaN(c)||d.length>=Number(c))return d;d=isNaN(e)?Math.abs(Number(a)).toString():Math.abs(Number(a)).toFixed(e);a=Number(c)-d.length-f.length;0<=b.indexOf("-",0)?d=f+d+Aa(" ",a):(b=0<=b.indexOf("0",0)?"0":" ",d=f+Aa(b,a)+d);return d};
$CLJS.va.Yc.d=function(a,b,c,d,e,f,g,l){return $CLJS.va.Yc.f(parseInt(a,10),b,c,d,0,f,g,l)};$CLJS.va.Yc.i=$CLJS.va.Yc.d;$CLJS.va.Yc.u=$CLJS.va.Yc.d;ua("Opera");ua("Trident")||ua("MSIE");ua("Edge");!ua("Gecko")||-1!=ta().toLowerCase().indexOf("webkit")&&!ua("Edge")||ua("Trident")||ua("MSIE")||ua("Edge");-1!=ta().toLowerCase().indexOf("webkit")&&!ua("Edge")&&ua("Mobile");ua("Macintosh");ua("Windows");ua("Linux")||ua("CrOS");var ea=$CLJS.sa.navigator||null;ea&&(ea.appVersion||"").indexOf("X11");ua("Android");wa();ua("iPad");ua("iPod");ca();ta().toLowerCase().indexOf("kaios");Date.now();aa();wa()||ua("iPod");ua("iPad");!ua("Android")||ba()||aa()||ua("Opera")||ua("Silk");ba();!ua("Safari")||ba()||ua("Coast")||ua("Opera")||ua("Edge")||ua("Edg/")||ua("OPR")||aa()||ua("Silk")||ua("Android")||ca();