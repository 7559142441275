var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var G5,Aua,d8,Cua,Dua,Eua,Fua,Gua,Hua,Iua,V5,Y5,Z5,Kua,e8;
G5=function(a){return function(b){var c=$CLJS.ig($CLJS.ai);return function(){function d(l,n){var q=a.g?a.g(n):a.call(null,n);if($CLJS.Ee($CLJS.r(c),q))return l;c.le(null,$CLJS.gf.h(c.fc(null),q));return b.h?b.h(l,n):b.call(null,l,n)}function e(l){return b.g?b.g(l):b.call(null,l)}function f(){return b.v?b.v():b.call(null)}var g=null;g=function(l,n){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,n)}throw Error("Invalid arity: "+arguments.length);
};g.v=f;g.g=e;g.h=d;return g}()}};Aua=function(a,b,c){return $CLJS.mh.j($CLJS.Mg,$CLJS.Mt(function(d){return $CLJS.w1.B(a,b,d,new $CLJS.k(null,2,[$CLJS.o1,c,$CLJS.j1,!1],null))}),$CLJS.dO.g($CLJS.JZ(a,b)))};d8=function(a,b,c){return $CLJS.mh.j($CLJS.Mg,$CLJS.Mt(function(d){return $CLJS.v1.B(a,b,d,c)}),$CLJS.dO.g($CLJS.JZ(a,b)))};$CLJS.Bua=function(a,b){return $CLJS.Ob(function(c,d){return $CLJS.R3.l(c,d,$CLJS.T,$CLJS.F([$CLJS.u7,$CLJS.v1.j(c,d,$CLJS.JZ(c,d))]))},a,$CLJS.wu(0,$CLJS.xX(a,b)))};
Cua=function(a,b){a=$CLJS.JZ(a,b);a=$CLJS.Of(a);var c=$CLJS.K.h(a,$CLJS.su),d=$CLJS.K.h(a,$CLJS.TW);b=$CLJS.u7.g(a);if($CLJS.m(b))return b;var e=$CLJS.GW.g(a);if($CLJS.m(e)&&$CLJS.m(function(){var g=$CLJS.E.h(c,$CLJS.fX);return g?g:d}())){var f=function(){var g=c instanceof $CLJS.M?c.R:null;switch(g){case "mbql.stage/native":return $CLJS.C_;case "mbql.stage/mbql":return $CLJS.w_;default:throw Error(["No matching clause: ",$CLJS.p.g(g)].join(""));}}();return $CLJS.Pf(function(){return function n(l){return new $CLJS.uf(null,
function(){for(;;){var q=$CLJS.z(l);if(q){if($CLJS.me(q)){var u=$CLJS.Wc(q),v=$CLJS.D(u),x=$CLJS.xf(v);a:for(var y=0;;)if(y<v){var C=$CLJS.Yd(u,y);C=$CLJS.xm.l($CLJS.F([new $CLJS.k(null,2,[$CLJS.WZ,$CLJS.W.g(C),$CLJS.s_,$CLJS.W.g(C)],null),C,new $CLJS.k(null,1,[$CLJS.xL,f],null)]));x.add(C);y+=1}else{u=!0;break a}return u?$CLJS.Af($CLJS.Ef(x),n($CLJS.Xc(q))):$CLJS.Af($CLJS.Ef(x),null)}x=$CLJS.A(q);return $CLJS.kf($CLJS.xm.l($CLJS.F([new $CLJS.k(null,2,[$CLJS.WZ,$CLJS.W.g(x),$CLJS.s_,$CLJS.W.g(x)],
null),x,new $CLJS.k(null,1,[$CLJS.xL,f],null)])),n($CLJS.vd(q)))}return null}},null,null)}($CLJS.wX.g(e))}())}return null};
Dua=function(a,b,c){return $CLJS.Pf(function(){return function f(e){return new $CLJS.uf(null,function(){for(;;){var g=$CLJS.z(e);if(g){if($CLJS.me(g)){var l=$CLJS.Wc(g),n=$CLJS.D(l),q=$CLJS.xf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.Yd(l,v),y=q,C=$CLJS.T,H=C.l,J=x,S=$CLJS.OA($CLJS.WZ,$CLJS.W)(x);x=$CLJS.J1(a,x);x=c.g?c.g(x):c.call(null,x);C=H.call(C,J,$CLJS.xL,$CLJS.KL,$CLJS.F([$CLJS.WZ,S,$CLJS.s_,x]));y.add(C);v+=1}else return!0}()?$CLJS.Af($CLJS.Ef(q),f($CLJS.Xc(g))):$CLJS.Af($CLJS.Ef(q),
null)}var u=$CLJS.A(g);return $CLJS.kf($CLJS.T.l(u,$CLJS.xL,$CLJS.KL,$CLJS.F([$CLJS.WZ,$CLJS.OA($CLJS.WZ,$CLJS.W)(u),$CLJS.s_,function(){var v=$CLJS.J1(a,u);return c.g?c.g(v):c.call(null,v)}()])),f($CLJS.vd(g)))}return null}},null,null)}($CLJS.P5(a,b))}())};
Eua=function(a,b,c){return $CLJS.Pf(function(){return function f(e){return new $CLJS.uf(null,function(){for(;;){var g=$CLJS.z(e);if(g){if($CLJS.me(g)){var l=$CLJS.Wc(g),n=$CLJS.D(l),q=$CLJS.xf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.Yd(l,v),y=q,C=$CLJS.T,H=C.l,J=x,S=$CLJS.W.g(x);x=$CLJS.W.g(x);x=c.g?c.g(x):c.call(null,x);C=H.call(C,J,$CLJS.xL,$CLJS.QL,$CLJS.F([$CLJS.WZ,S,$CLJS.s_,x]));y.add(C);v+=1}else return!0}()?$CLJS.Af($CLJS.Ef(q),f($CLJS.Xc(g))):$CLJS.Af($CLJS.Ef(q),null)}var u=
$CLJS.A(g);return $CLJS.kf($CLJS.T.l(u,$CLJS.xL,$CLJS.QL,$CLJS.F([$CLJS.WZ,$CLJS.W.g(u),$CLJS.s_,function(){var v=$CLJS.W.g(u);return c.g?c.g(v):c.call(null,v)}()])),f($CLJS.vd(g)))}return null}},null,null)}($CLJS.T5.h(a,b))}())};
Fua=function(a,b,c){var d=$CLJS.JZ(a,b);if($CLJS.m(d)){d=$CLJS.Of(d);var e=$CLJS.K.h(d,$CLJS.mG);return $CLJS.Pf(function(){return function l(g){return new $CLJS.uf(null,function(){for(;;){var n=$CLJS.z(g);if(n){if($CLJS.me(n)){var q=$CLJS.Wc(n),u=$CLJS.D(q),v=$CLJS.xf(u);return function(){for(var J=0;;)if(J<u){var S=$CLJS.Yd(q,J),V=$CLJS.G(S,0,null),da=function(){var ma=V;ma=ma instanceof $CLJS.M?ma.R:null;switch(ma){case "field":return $CLJS.B_;case "expression":return $CLJS.e_;default:throw Error(["No matching clause: ",
$CLJS.p.g(ma)].join(""));}}(),fa=$CLJS.T0.j(a,b,S);$CLJS.Df(v,$CLJS.T.l(fa,$CLJS.xL,da,$CLJS.F([$CLJS.WZ,$CLJS.l_.j(a,b,fa),$CLJS.s_,function(){var ma=$CLJS.J1(a,fa);return c.g?c.g(ma):c.call(null,ma)}()])));J+=1}else return!0}()?$CLJS.Af($CLJS.Ef(v),l($CLJS.Xc(n))):$CLJS.Af($CLJS.Ef(v),null)}var x=$CLJS.A(n),y=$CLJS.G(x,0,null),C=function(){var J=y;J=J instanceof $CLJS.M?J.R:null;switch(J){case "field":return $CLJS.B_;case "expression":return $CLJS.e_;default:throw Error(["No matching clause: ",
$CLJS.p.g(J)].join(""));}}(),H=$CLJS.T0.j(a,b,x);return $CLJS.kf($CLJS.T.l(H,$CLJS.xL,C,$CLJS.F([$CLJS.WZ,$CLJS.l_.j(a,b,H),$CLJS.s_,function(){var J=$CLJS.J1(a,H);return c.g?c.g(J):c.call(null,J)}()])),l($CLJS.vd(n)))}return null}},null,null)}(e)}())}return null};Gua=function(a,b,c){return $CLJS.Pf($CLJS.mh.j($CLJS.Mg,$CLJS.Mt(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.Q(null,2,5,$CLJS.R,[Dua,Eua],null)))};
Hua=function(a,b,c){var d=$CLJS.V2(a,b);return $CLJS.m(d)?$CLJS.Pf(function(){return function g(f){return new $CLJS.uf(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.me(l)){var n=$CLJS.Wc(l),q=$CLJS.D(n),u=$CLJS.xf(q);return function(){for(var y=0;;)if(y<q){var C=$CLJS.Yd(n,y);var H=$CLJS.OA($CLJS.s_,$CLJS.WZ)(C);H=$CLJS.m(H)?H:$CLJS.l_.j(a,b,C);C=$CLJS.$l.h($CLJS.xm.l($CLJS.F([C,new $CLJS.k(null,3,[$CLJS.xL,$CLJS.YZ,$CLJS.WZ,H,$CLJS.s_,c.g?c.g(H):c.call(null,H)],null),$CLJS.m($CLJS.M1.g(C))?
$CLJS.Zh(C,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.M1],null)):null])),$CLJS.w7);u.add(C);y+=1}else return!0}()?$CLJS.Af($CLJS.Ef(u),g($CLJS.Xc(l))):$CLJS.Af($CLJS.Ef(u),null)}var v=$CLJS.A(l),x=function(){var y=$CLJS.OA($CLJS.s_,$CLJS.WZ)(v);return $CLJS.m(y)?y:$CLJS.l_.j(a,b,v)}();return $CLJS.kf($CLJS.$l.h($CLJS.xm.l($CLJS.F([v,new $CLJS.k(null,3,[$CLJS.xL,$CLJS.YZ,$CLJS.WZ,x,$CLJS.s_,c.g?c.g(x):c.call(null,x)],null),$CLJS.m($CLJS.M1.g(v))?$CLJS.Zh(v,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.M1],null)):null])),
$CLJS.w7),g($CLJS.vd(l)))}return null}},null,null)}($CLJS.v1.j(a,d,$CLJS.JZ(a,d)))}()):null};Iua=function(a,b,c,d){return $CLJS.m(c)?(c=$CLJS.V_(a,c),$CLJS.m(c)?$CLJS.Pf($CLJS.w1.B(a,b,c,d)):null):null};
V5=function(a,b,c){return $CLJS.Pf(function(){return function f(e){return new $CLJS.uf(null,function(){for(;;){var g=$CLJS.z(e);if(g){if($CLJS.me(g)){var l=$CLJS.Wc(g),n=$CLJS.D(l),q=$CLJS.xf(n);return function(){for(var v=0;;)if(v<n){var x=$CLJS.Yd(l,v),y=q;var C=$CLJS.JB.g(x);var H=$CLJS.T.l,J=x,S=$CLJS.W.g(x);x=$CLJS.W.g(x);x=c.g?c.g(x):c.call(null,x);C=$CLJS.F5(H.call($CLJS.T,J,$CLJS.xL,$CLJS.e_,$CLJS.F([$CLJS.WZ,S,$CLJS.s_,x])),$CLJS.Bf,$CLJS.m(C)?C:$CLJS.Aj);y.add(C);v+=1}else return!0}()?$CLJS.Af($CLJS.Ef(q),
f($CLJS.Xc(g))):$CLJS.Af($CLJS.Ef(q),null)}var u=$CLJS.A(g);return $CLJS.kf(function(){var v=$CLJS.JB.g(u),x=$CLJS.T.l,y=u,C=$CLJS.W.g(u);var H=$CLJS.W.g(u);H=c.g?c.g(H):c.call(null,H);return $CLJS.F5(x.call($CLJS.T,y,$CLJS.xL,$CLJS.e_,$CLJS.F([$CLJS.WZ,C,$CLJS.s_,H])),$CLJS.Bf,$CLJS.m(v)?v:$CLJS.Aj)}(),f($CLJS.vd(g)))}return null}},null,null)}($CLJS.U5.h(a,b))}())};
Y5=function(a,b,c){var d=$CLJS.Of(c),e=$CLJS.K.h(d,$CLJS.o1);return $CLJS.mm.h(function(f){return $CLJS.$l.l(f,$CLJS.H_,$CLJS.F([$CLJS.w7,$CLJS.v7,$CLJS.HL]))},function(){var f=Hua(a,b,e);if($CLJS.m(f))return f;f=$CLJS.JZ(a,b);f=$CLJS.Of(f);var g=$CLJS.K.h(f,$CLJS.FP),l=$CLJS.K.h(f,$CLJS.TW),n=$CLJS.m(g)?function(){var q=$CLJS.R0(a,g);return $CLJS.w1.B(a,b,q,d)}():null;if($CLJS.m(n))return n;l=$CLJS.m(l)?Iua(a,b,l,$CLJS.T.j(d,$CLJS.j1,!1)):null;return $CLJS.m(l)?l:function v(u){return new $CLJS.uf(null,
function(){for(;;){var x=$CLJS.z(u);if(x){if($CLJS.me(x)){var y=$CLJS.Wc(x),C=$CLJS.D(y),H=$CLJS.xf(C);return function(){for(var S=0;;)if(S<C){var V=$CLJS.Yd(y,S),da=H,fa=$CLJS.T,ma=fa.l,Fa=V,ab=$CLJS.W.g(V);V=$CLJS.W.g(V);V=e.g?e.g(V):e.call(null,V);fa=ma.call(fa,Fa,$CLJS.xL,$CLJS.C_,$CLJS.F([$CLJS.WZ,ab,$CLJS.s_,V]));da.add(fa);S+=1}else return!0}()?$CLJS.Af($CLJS.Ef(H),v($CLJS.Xc(x))):$CLJS.Af($CLJS.Ef(H),null)}var J=$CLJS.A(x);return $CLJS.kf($CLJS.T.l(J,$CLJS.xL,$CLJS.C_,$CLJS.F([$CLJS.WZ,$CLJS.W.g(J),
$CLJS.s_,function(){var S=$CLJS.W.g(J);return e.g?e.g(S):e.call(null,S)}()])),v($CLJS.vd(x)))}return null}},null,null)}($CLJS.wX.g($CLJS.GW.g(f)))}())};
Z5=function(a,b){var c=$CLJS.G(a,0,null);$CLJS.G(a,1,null);var d=$CLJS.G(a,2,null);switch(c instanceof $CLJS.M?c.R:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.E.h(d,$CLJS.rk.g(b));throw $CLJS.nj("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.k(null,2,[$CLJS.NV,a,$CLJS.Zf,b],null));case "expression":return $CLJS.E.h(d,$CLJS.W.g(b));default:throw $CLJS.nj("unknown clause in lib.stage/ref-to?",new $CLJS.k(null,2,[$CLJS.NV,a,$CLJS.Zf,b],null));}};
Kua=function(a,b,c){var d=$CLJS.cH.g($CLJS.JZ(a,b));return $CLJS.m(d)?function g(f){return new $CLJS.uf(null,function(){for(;;){var l=$CLJS.z(f);if(l){var n=l;if($CLJS.me(n)){var q=$CLJS.Wc(n),u=$CLJS.D(q),v=$CLJS.xf(u);return function(){for(var y=0;;)if(y<u){var C=$CLJS.Yd(q,y);$CLJS.Df(v,function(){var H=$CLJS.uU(function(V,da){return function(fa){return Z5(fa,da)}}(y,C,q,u,v,n,l,d,d),d);if($CLJS.m(H)){var J=$CLJS.U2(H);H=$CLJS.H5(H);H=$CLJS.Of(H);H=$CLJS.K.h(H,$CLJS.kb);var S=C;J=$CLJS.m(J)?$CLJS.J5(S,
J):S;return $CLJS.m(H)?$CLJS.I5(J,H):J}return C}());y+=1}else return!0}()?$CLJS.Af($CLJS.Ef(v),g($CLJS.Xc(n))):$CLJS.Af($CLJS.Ef(v),null)}var x=$CLJS.A(n);return $CLJS.kf(function(){var y=$CLJS.uU(function(J){return function(S){return Z5(S,J)}}(x,n,l,d,d),d);if($CLJS.m(y)){var C=$CLJS.U2(y);y=$CLJS.H5(y);y=$CLJS.Of(y);y=$CLJS.K.h(y,$CLJS.kb);var H=x;C=$CLJS.m(C)?$CLJS.J5(H,C):H;return $CLJS.m(y)?$CLJS.I5(C,y):C}return x}(),g($CLJS.vd(n)))}return null}},null,null)}(c):c};
e8=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.Hla={};$CLJS.mT($CLJS.ZW,e8);$CLJS.mT($CLJS.fX,e8);$CLJS.o2.o(null,$CLJS.ZW,function(a){return $CLJS.n2(a,new $CLJS.k(null,2,[$CLJS.dH,$CLJS.bg($CLJS.mm,$CLJS.o2),$CLJS.nX,$CLJS.bg($CLJS.mm,$CLJS.o2)],null))});$CLJS.KZ.o(null,e8,function(){throw $CLJS.nj("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.O);});
$CLJS.q_.o(null,e8,function(a,b,c,d){d=$CLJS.Of(d);c=$CLJS.K.h(d,$CLJS.o1);var e=$CLJS.K.h(d,$CLJS.j1);a=$CLJS.Bua(a,b);var f=$CLJS.Of(d);var g=$CLJS.K.h(f,$CLJS.o1),l=$CLJS.K.h(f,$CLJS.m1),n=$CLJS.K.h(f,$CLJS.t1);f=$CLJS.sg.l(Y5(a,b,f),$CLJS.m(n)?V5(a,b,g):null,$CLJS.F([$CLJS.m(l)?Aua(a,b,g):null]));g=$CLJS.sg.h;d=$CLJS.m(e)?(e=$CLJS.Bb($CLJS.TW.g($CLJS.JZ(a,b))))?e:$CLJS.q1.g(d):e;return Kua(a,b,g.call($CLJS.sg,f,$CLJS.m(d)?$CLJS.K1(a,b,f,c):null))});
$CLJS.u1.o(null,e8,function(a,b,c,d){c=$CLJS.Of(d);d=$CLJS.K.h(c,$CLJS.o1);var e=Cua(a,b);if($CLJS.m(e))return e;a=$CLJS.Bua(a,b);e=Gua(a,b,d);var f=Fua(a,b,d);return $CLJS.m(e)?$CLJS.mh.h(e,f):$CLJS.m(f)?($CLJS.oi(f),$CLJS.mh.j($CLJS.Mg,G5(function(g){return $CLJS.$l.l(g,$CLJS.F_,$CLJS.F([$CLJS.xL,$CLJS.s1,$CLJS.s_]))}),$CLJS.sg.h(f,d8(a,b,c)))):$CLJS.sg.l(Y5(a,b,new $CLJS.k(null,2,[$CLJS.j1,!1,$CLJS.o1,d],null)),V5(a,b,d),$CLJS.F([d8(a,b,c)]))});$CLJS.j_.o(null,$CLJS.fX,function(){return $CLJS.$F("Native query")});
var f8=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FP,$CLJS.TW,$CLJS.dO],null),g8=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.dH,$CLJS.cH,$CLJS.nX,$CLJS.xV,$CLJS.ZV],null);
$CLJS.j_.o(null,$CLJS.ZW,function(a,b,c,d){var e=$CLJS.Bua(a,b);a=$CLJS.Pf(function(){var f=$CLJS.mh.j($CLJS.O,$CLJS.gm.h($CLJS.Li,$CLJS.kg.g(function(l){return new $CLJS.Q(null,2,5,$CLJS.R,[l,$CLJS.a6.j(e,b,l)],null)})),new $CLJS.Q(null,2,5,$CLJS.R,[f8,g8],null)),g=$CLJS.Tt(" + ",$CLJS.lm.h($CLJS.at,$CLJS.kg.h(f,f8)));f=$CLJS.kg.h(f,g8);return $CLJS.Tt(", ",$CLJS.lm.h($CLJS.at,$CLJS.kf(g,f)))}());if($CLJS.m(a))return a;a=$CLJS.V2(e,b);return $CLJS.m(a)?$CLJS.MZ.B(e,a,$CLJS.JZ(e,a),d):null});