var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var A2=function(a){return $CLJS.m(z2)?["(",$CLJS.p.g(a),")"].join(""):a},Kva=function(a,b,c){var d=$CLJS.JZ(a,b);a=$CLJS.w1.j(a,b,d);var e=$CLJS.BE(c);return $CLJS.Vf(function(f){return $CLJS.E.h($CLJS.BE($CLJS.W.g(f)),e)},a)},tna=function(a,b){var c=$CLJS.nm.B(a,$CLJS.QR,$CLJS.cg($CLJS.gf,$CLJS.Mg),b);return $CLJS.le($CLJS.mG.g(a))?$CLJS.nm.B(c,$CLJS.mG,$CLJS.gf,$CLJS.gU(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.GB,$CLJS.O,$CLJS.VY(b)],null))):c},una=function(a,b,c){var d=$CLJS.VY(c);return $CLJS.T.l($CLJS.T0.j(a,
b,c),$CLJS.xL,$CLJS.e_,$CLJS.F([$CLJS.W,d,$CLJS.vF,d]))},l7=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);var vna=function(){function a(d,e,f){var g=$CLJS.JZ(d,e);g=$CLJS.uU($CLJS.gm.h($CLJS.bi([f]),$CLJS.VY),$CLJS.QR.g(g));if($CLJS.m(g))return g;throw $CLJS.nj($CLJS.ZF("No expression named {0}",$CLJS.F([$CLJS.Oi.l($CLJS.F([f]))])),new $CLJS.k(null,3,[$CLJS.OV,f,$CLJS.hN,d,$CLJS.BX,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.j=a;return c}();$CLJS.n_.o(null,$CLJS.GB,function(a,b,c){$CLJS.G(c,0,null);$CLJS.G(c,1,null);c=$CLJS.G(c,2,null);c=vna.j(a,b,c);return $CLJS.o_.j(a,b,c)});$CLJS.KZ.o(null,$CLJS.GB,function(a,b,c){$CLJS.G(c,0,null);var d=$CLJS.G(c,1,null),e=$CLJS.G(c,2,null);return new $CLJS.k(null,7,[$CLJS.su,$CLJS.vL,$CLJS.s1,$CLJS.eG.g(d),$CLJS.W,e,$CLJS.uG,e,$CLJS.vF,$CLJS.MZ.j(a,b,c),$CLJS.JB,$CLJS.o_.j(a,b,c),$CLJS.xL,$CLJS.e_],null)});$CLJS.j_.o(null,$CLJS.BB,function(a,b,c){return $CLJS.p.g(c)});
$CLJS.j_.o(null,$CLJS.yB,function(a,b,c){return $CLJS.p.g(c)});$CLJS.j_.o(null,$CLJS.AB,function(a,b,c){return['"',$CLJS.p.g(c),'"'].join("")});$CLJS.j_.o(null,$CLJS.GB,function(a,b,c){$CLJS.G(c,0,null);$CLJS.G(c,1,null);return $CLJS.G(c,2,null)});$CLJS.k_.o(null,$CLJS.GB,function(a,b,c){$CLJS.G(c,0,null);$CLJS.G(c,1,null);return $CLJS.G(c,2,null)});
for(var z2=!1,vsa=new $CLJS.k(null,4,[$CLJS.bs,"+",$CLJS.Ft,"-",$CLJS.cs,"×",$CLJS.KH,"÷"],null),m7=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bs,$CLJS.Ft,$CLJS.KH,$CLJS.cs],null)),n7=null,o7=0,p7=0;;)if(p7<o7){var wna=n7.ha(null,p7);$CLJS.mT(wna,l7);p7+=1}else{var q7=$CLJS.z(m7);if(q7){var r7=q7;if($CLJS.me(r7)){var s7=$CLJS.Wc(r7),Bna=$CLJS.Xc(r7),Cna=s7,Dna=$CLJS.D(s7);m7=Bna;n7=Cna;o7=Dna}else{var Ena=$CLJS.A(r7);$CLJS.mT(Ena,l7);m7=$CLJS.B(r7);n7=null;o7=0}p7=0}else break}
$CLJS.j_.o(null,l7,function(a,b,c){var d=$CLJS.z(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.K.h(vsa,c);a:{var e=z2;z2=!0;try{var f=$CLJS.Tt([" ",$CLJS.fi(c)," "].join(""),$CLJS.kg.h($CLJS.Ay($CLJS.MZ,a,b),d));break a}finally{z2=e}f=void 0}return A2(f)});$CLJS.k_.o(null,l7,function(){return"expression"});
$CLJS.n_.o(null,$CLJS.jI,function(a,b,c){c=$CLJS.z(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Re($CLJS.lT,function(){return function g(f){return new $CLJS.uf(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.me(l)){var n=$CLJS.Wc(l),q=$CLJS.D(n),u=$CLJS.xf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.Yd(n,v);x=$CLJS.o_.j(a,b,x);u.add(x);v+=1}else{n=!0;break a}return n?$CLJS.Af($CLJS.Ef(u),g($CLJS.Xc(l))):$CLJS.Af($CLJS.Ef(u),null)}u=$CLJS.A(l);return $CLJS.kf($CLJS.o_.j(a,
b,u),g($CLJS.vd(l)))}return null}},null,null)}(d)}())});$CLJS.j_.o(null,$CLJS.QJ,function(a,b,c,d){$CLJS.G(c,0,null);$CLJS.G(c,1,null);var e=$CLJS.G(c,2,null),f=$CLJS.G(c,3,null);c=$CLJS.G(c,4,null);a=$CLJS.p.g($CLJS.MZ.B(a,b,e,d));b=$CLJS.p;d=b.g;c=$CLJS.v2.h(f,c).toLowerCase();0<f?f=$CLJS.va.j?$CLJS.va.j("+ %d %s",f,c):$CLJS.va.call(null,"+ %d %s",f,c):(f=Math.abs(f),f=$CLJS.va.j?$CLJS.va.j("- %d %s",f,c):$CLJS.va.call(null,"- %d %s",f,c));f=A2(f);return[a," ",d.call(b,f)].join("")});
$CLJS.k_.o(null,$CLJS.QJ,function(a,b,c){$CLJS.G(c,0,null);$CLJS.G(c,1,null);var d=$CLJS.G(c,2,null),e=$CLJS.G(c,3,null);c=$CLJS.G(c,4,null);a=$CLJS.p.g($CLJS.l_.j(a,b,d));b=$CLJS.p;d=b.g;c=$CLJS.v2.h(e,c).toLowerCase();0<e?e=$CLJS.va.j?$CLJS.va.j("plus_%s_%s",e,c):$CLJS.va.call(null,"plus_%s_%s",e,c):(e=Math.abs(e),e=$CLJS.va.j?$CLJS.va.j("minus_%d_%s",e,c):$CLJS.va.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.j_.o(null,$CLJS.rH,function(a,b,c,d){$CLJS.G(c,0,null);$CLJS.G(c,1,null);var e=$CLJS.G(c,2,null);$CLJS.G(c,3,null);return $CLJS.MZ.B(a,b,e,d)});$CLJS.k_.o(null,$CLJS.rH,function(a,b,c){$CLJS.G(c,0,null);$CLJS.G(c,1,null);var d=$CLJS.G(c,2,null);$CLJS.G(c,3,null);return $CLJS.l_.j(a,b,d)});
$CLJS.M8=function(){function a(d,e,f,g){e=$CLJS.m(e)?e:-1;if($CLJS.m(Kva(d,e,f)))throw $CLJS.nj("Expression name conflicts with a column in the same query stage",new $CLJS.k(null,1,[$CLJS.OV,f],null));return $CLJS.R3.l(d,e,tna,$CLJS.F([$CLJS.l0($CLJS.iU.g(g),f)]))}function b(d,e,f){return c.B?c.B(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.B=a;return c}();$CLJS.U5=function(){function a(d,e){var f=$CLJS.Pf($CLJS.QR.g($CLJS.JZ(d,e)));return null==f?null:$CLJS.mm.h($CLJS.Ay(una,d,e),f)}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();
$CLJS.K8=function(){function a(d,e){return $CLJS.Pf($CLJS.QR.g($CLJS.JZ(d,e)))}function b(d){return c.h?c.h(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.h=a;return c}();$CLJS.cU.o(null,$CLJS.GB,function(a){return a});
$CLJS.L8=function(){function a(d,e,f){var g=$CLJS.mh.h($CLJS.O,$CLJS.dg(function(n,q){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VY(q),n],null)},$CLJS.K8.h(d,e))),l=$CLJS.JZ(d,e);d=$CLJS.w1.j(d,e,l);return $CLJS.Pf($CLJS.OX(function(n){return $CLJS.Bb(f)||$CLJS.Yh.h($CLJS.xL.g(n),$CLJS.e_)||$CLJS.K.h(g,$CLJS.W.g(n))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.j=a;return c}();