var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var a1,pka,b1,c1,qka,rka,ska,tka,vka,wka,xka,uka,B2,C2;a1=function(a,b){var c=$CLJS.De,d=$0;for(a=$CLJS.z(a);;)if(null!=a){d=$CLJS.K.j(d,$CLJS.A(a),c);if(c===d)return b;a=$CLJS.B(a)}else return d};
$CLJS.p2=function(a,b){return function f(d,e){return new $CLJS.uf(null,function(){var g=$CLJS.z(e);if(g){if($CLJS.me(g)){for(var l=$CLJS.Wc(g),n=$CLJS.D(l),q=$CLJS.xf(n),u=0;;)if(u<n){var v=function(){var x=d+u,y=$CLJS.Yd(l,u);return a.h?a.h(x,y):a.call(null,x,y)}();null!=v&&q.add(v);u+=1}else break;return $CLJS.Af($CLJS.Ef(q),f(d+n,$CLJS.Xc(g)))}n=function(){var x=$CLJS.A(g);return a.h?a.h(d,x):a.call(null,d,x)}();return null==n?f(d+1,$CLJS.vd(g)):$CLJS.kf(n,f(d+1,$CLJS.vd(g)))}return null},null,
null)}(0,b)};$CLJS.V2=function(a,b){a=$CLJS.xX(a,b);return 0<a?a-1:null};
$CLJS.t8=function(a,b,c,d){a=$CLJS.ZY(a);b=$CLJS.m(b)?b:-1;var e=$CLJS.JZ(a,b);e=!($CLJS.z($CLJS.dH.g(e))||$CLJS.z($CLJS.cH.g(e)));c=$CLJS.R3.l(a,b,$CLJS.nm,$CLJS.F([c,function(f){return $CLJS.gf.h($CLJS.Ng(f),$CLJS.iU.g(d))}]));return e?$CLJS.nm.O((0,$CLJS.R3)(c,b,function(f){return $CLJS.QX($CLJS.$l.l(f,$CLJS.xV,$CLJS.F([$CLJS.mG])),$CLJS.dO,function(g){return $CLJS.mm.h(function(l){return $CLJS.$l.h(l,$CLJS.mG)},g)})}),$CLJS.$L,$CLJS.gm.h(function(f){return $CLJS.mh.h($CLJS.Mg,f)},$CLJS.tm),0,
$CLJS.xX(a,b)+1):c};pka=function(a,b){b=$CLJS.V_(a,b);return $CLJS.m(b)?$CLJS.msa(a,b):null};b1=function(a){return $CLJS.mh.j($CLJS.ai,$CLJS.lm.g(function(b){return $CLJS.tf(b)&&$CLJS.Yh.h(b,$CLJS.su)}),$CLJS.Uh(a))};c1=function(a){return $CLJS.mh.j($CLJS.O,$CLJS.Gn(function(b,c){$CLJS.G(c,0,null);c=$CLJS.G(c,1,null);c=$CLJS.Of(c);c=$CLJS.K.h(c,$CLJS.eG);return new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null)}),$CLJS.dH.g(a))};
$CLJS.q2=function(a,b,c){var d=$CLJS.xm.l;b=$CLJS.Bb($CLJS.V2(a,b))?function(){var f=$CLJS.TW.g($CLJS.A($CLJS.$L.g(a)));return $CLJS.m(f)?(f=pka(a,f),$CLJS.m(f)?$CLJS.uU(function(g){return $CLJS.E.h($CLJS.rk.g(g),c)},f):null):null}():null;try{var e=$CLJS.z1(a,c)}catch(f){e=null}return d.call($CLJS.xm,$CLJS.F([b,e]))};
qka=function(a,b,c){var d=$CLJS.Pf($CLJS.vg(function(e){return $CLJS.E.h($CLJS.s_.g(e),a)&&$CLJS.E.h($CLJS.OA($CLJS.H_,$CLJS.F_)(e),b)},c));return $CLJS.m(d)?d:$CLJS.vg(function(e){return $CLJS.E.h($CLJS.W.g(e),a)&&$CLJS.E.h($CLJS.OA($CLJS.H_,$CLJS.F_)(e),b)},c)};
rka=function(a,b,c,d){var e=$CLJS.Pf($CLJS.vg(function(f){var g=$CLJS.E.h($CLJS.rk.g(f),a);if(g){if(g=$CLJS.Bb(b)){g=$CLJS.xL.g(f);var l=new $CLJS.U(null,new $CLJS.k(null,2,[$CLJS.w_,null,$CLJS.B_,null],null),null);g=l.g?l.g(g):l.call(null,g)}return $CLJS.m(g)?g:$CLJS.E.h($CLJS.OA($CLJS.H_,$CLJS.F_)(f),b)}return g},c));if($CLJS.m(e))return e;c=$CLJS.m(d)?$CLJS.Pf($CLJS.vg(function(f){return $CLJS.E.h($CLJS.rk.g(f),a)},c)):null;return $CLJS.m(c)?c:$CLJS.Mg};
ska=function(a,b){var c=$CLJS.Pf($CLJS.lm.h($CLJS.HL,b));if($CLJS.m(c)){if($CLJS.Bb($CLJS.B(c)))return $CLJS.A(c);throw $CLJS.nj("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.k(null,2,[$CLJS.X,a,$CLJS.wX,b],null));}return null};
tka=function(a,b){b=$CLJS.Pf($CLJS.lm.h(function(c){var d=$CLJS.OA($CLJS.H_,$CLJS.F_)(c);$CLJS.m(d)?(c=$CLJS.xL.g(c),d=new $CLJS.U(null,new $CLJS.k(null,1,[$CLJS.w_,null],null),null),c=d.g?d.g(c):d.call(null,c),c=$CLJS.Bb(c)):c=d;return c},b));return $CLJS.m(b)?$CLJS.Bb($CLJS.B(b))?$CLJS.A(b):ska(a,b):null};
vka=function(a,b){var c=$CLJS.KF(a);c=$CLJS.Of(c);var d=$CLJS.K.h(c,$CLJS.UP);if($CLJS.m(d)){b=$CLJS.Pf($CLJS.vg(function(e){return $CLJS.E.h($CLJS.OA($CLJS.H_,$CLJS.F_)(e),d)},b));if($CLJS.m(b)){if($CLJS.Bb($CLJS.B(b)))return $CLJS.A(b);throw $CLJS.nj("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.k(null,2,[$CLJS.X,a,uka,b],null));}return null}return tka(a,b)};wka=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
xka=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.s2=new $CLJS.M(null,"generous?","generous?",1247836118);$CLJS.yka=new $CLJS.M(null,"matching-refs","matching-refs",324693611);uka=new $CLJS.M(null,"matches","matches",635497998);B2=new $CLJS.M(null,"left","left",-399115937);C2=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);var f1=function(){var a=$CLJS.ka($CLJS.O),b=$CLJS.ka($CLJS.O),c=$CLJS.ka($CLJS.O),d=$CLJS.ka($CLJS.O),e=$CLJS.K.j(new $CLJS.k(null,1,[$CLJS.oj,$CLJS.JF],null),$CLJS.oj,$CLJS.Zi.v?$CLJS.Zi.v():$CLJS.Zi.call(null));return new $CLJS.hj($CLJS.Fi.h("metabase.lib.equality","\x3d"),function(f,g){f=$CLJS.IF(f);g=$CLJS.IF(g);return $CLJS.Yh.h(f,g)?C2:f},e,a,b,c,d)}();f1.o(null,C2,function(){return!1});
f1.o(null,$CLJS.FB,function(a,b){var c=b1(a),d=b1(b);return $CLJS.E.h(c,d)&&$CLJS.Uf(function(e){return f1.h($CLJS.K.h(a,e),$CLJS.K.h(b,e))},c)});f1.o(null,$CLJS.DB,function(a,b){var c=$CLJS.E.h($CLJS.D(a),$CLJS.D(b));if(c)for(c=$CLJS.z(a),$CLJS.A(c),$CLJS.B(c),c=$CLJS.z(b),$CLJS.A(c),$CLJS.B(c),c=a,a=b;;){c=$CLJS.z(c);b=$CLJS.A(c);var d=$CLJS.B(c);c=b;b=d;a=$CLJS.z(a);d=$CLJS.A(a);a=$CLJS.B(a);c=f1.h(c,d);if($CLJS.m(c)){if(c=$CLJS.ee(b))return c;c=b}else return c}else return c});var $0=null;
f1.o(null,$CLJS.ZW,function(a,b){var c=$0;$0=new $CLJS.k(null,2,[B2,c1(a),$CLJS.Yv,c1(b)],null);try{var d=$CLJS.fj(f1,$CLJS.FB);return d.h?d.h(a,b):d.call(null,a,b)}finally{$0=c}});
f1.o(null,$CLJS.dH,function(a,b){var c=$CLJS.G(a,0,null),d=$CLJS.G(a,1,null),e=$CLJS.G(a,2,null),f=$CLJS.G(b,0,null),g=$CLJS.G(b,1,null),l=$CLJS.G(b,2,null);return(a=$CLJS.E.l(3,$CLJS.D(a),$CLJS.F([$CLJS.D(b)])))?(c=$CLJS.E.h(c,f))?(d=f1.h(d,g),$CLJS.m(d)?$CLJS.m($0)?$CLJS.E.h(a1(new $CLJS.Q(null,2,5,$CLJS.R,[B2,e],null),xka),a1(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yv,l],null),wka)):$CLJS.E.h(e,l):d):c:a});
f1.o(null,$CLJS.Dj,function(a,b){if($CLJS.je(a)){var c=$CLJS.fj(f1,$CLJS.FB);return c.h?c.h(a,b):c.call(null,a,b)}return $CLJS.ie(a)?(c=$CLJS.fj(f1,$CLJS.DB),c.h?c.h(a,b):c.call(null,a,b)):$CLJS.E.h(a,b)});
$CLJS.D2=function(){function a(f,g,l,n,q){$CLJS.G(l,0,null);$CLJS.G(l,1,null);var u=$CLJS.G(l,2,null),v=e.j?e.j(l,n,q):e.call(null,l,n,q);return $CLJS.m(v)?v:$CLJS.m($CLJS.m(f)?"number"===typeof u:f)&&(n=$CLJS.Pf($CLJS.lm.h($CLJS.rk,n)),$CLJS.m(n)&&(g=$CLJS.q2(f,g,u),$CLJS.m(g)))?(f=$CLJS.T.j,u=$CLJS.s_.g(g),g=$CLJS.m(u)?u:$CLJS.W.g(g),l=f.call($CLJS.T,l,2,g),e.j?e.j(l,n,q):e.call(null,l,n,q)):null}function b(f,g,l,n){return e.O?e.O(f,g,l,n,$CLJS.O):e.call(null,f,g,l,n,$CLJS.O)}function c(f,g,l){var n=
$CLJS.G(f,0,null),q=$CLJS.G(f,1,null),u=$CLJS.G(f,2,null);l=$CLJS.Of(l);l=$CLJS.K.h(l,$CLJS.s2);switch(n instanceof $CLJS.M?n.R:null){case "aggregation":return $CLJS.uU(function(v){return $CLJS.E.h($CLJS.xL.g(v),$CLJS.QL)&&$CLJS.E.h($CLJS.s1.g(v),u)},g);case "expression":case "field":switch(g="string"===typeof u?qka(u,$CLJS.UP.g(q),g):rka(u,$CLJS.UP.g(q),g,l),$CLJS.D(g)){case 0:return null;case 1:return $CLJS.A(g);default:return vka(f,g)}default:throw $CLJS.nj("Unknown type of ref",new $CLJS.k(null,
1,[$CLJS.X,f],null));}}function d(f,g){return e.j?e.j(f,g,$CLJS.O):e.call(null,f,g,$CLJS.O)}var e=null;e=function(f,g,l,n,q){switch(arguments.length){case 2:return d.call(this,f,g);case 3:return c.call(this,f,g,l);case 4:return b.call(this,f,g,l,n);case 5:return a.call(this,f,g,l,n,q)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.j=c;e.B=b;e.O=a;return e}();
$CLJS.zka=function(){function a(d,e,f,g){return $CLJS.D2.B(d,e,$CLJS.VZ.j(d,e,f),g)}function b(d,e,f){return c.B?c.B(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,g){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,g)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.B=a;return c}();